import { client } from './client';

export async function callGetWebPageMetadata({ url }: { url: string }) {
    try {
        const { data } = await client.get(
            '/students_app/supporters/web-page-metadata',
            {
                params: {
                    url,
                },
            },
        );
        return {
            ...data,
        };
    } catch (error: any) {
        return null;
    }
}
