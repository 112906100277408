import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as LeanAIMetadata;

export const leanAIMetadataSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        set(state, { payload }: PayloadAction<LeanAIMetadata>) {
            return { ...payload };
        },
        clear(state) {
            return { ...initialState };
        },
    },
});

export default leanAIMetadataSlice.reducer;
