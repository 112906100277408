//@ts-nocheck

import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';

defineElement(lottie.loadAnimation);

export function Loading() {
    return (
        <div className="loading-container row align-items-center mx-auto">
            <div className="padding-box bg-white rounded-4 text-center">
                <div>
                    <lord-icon
                        class="loading-icon"
                        src="https://cdn.lordicon.com/xjovhxra.json"
                        colors="primary:#7b61ff,secondary:#7b61ff"
                        trigger="loop"
                    />
                </div>
                <div className="pb-5">
                    <h1 className="fw-bold lh-base">
                        수고하셨습니다.
                        <br />
                        검사 결과를 분석하고 있습니다.
                    </h1>
                </div>
            </div>
        </div>
    );
}
