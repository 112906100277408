import React, { useEffect } from 'react';

import {
    pro12Slice,
    ResearchContentAndReferencesFormState,
    useAppDispatch,
    useAppSelector,
} from '@store';
import { BorderBox, SectionTitle, SectionWrapper } from '../components';
import RCRCard from './RCRCard';
import RCRDraft from './RCRDraft';
import useWithReferencesFormGroup from './useWithReferencesFormGroup';
import FormErrorMessage from '@components/FormErrorMessage';

export default function SectionB({
    setCompletion,
    shouldShowValidationErrorMessage,
}: {
    setCompletion: (bool: boolean) => void;
    shouldShowValidationErrorMessage: boolean;
}) {
    const researchContents = useAppSelector(
        ({ pro12 }) => pro12.researchOverviewForm.contents ?? [],
    );
    const initialState = useAppSelector(
        ({ pro12 }) => pro12.researchContentAndReferencesForms,
    );
    const dispatch = useAppDispatch();

    const { forms, appendForm, updateForm, removeForm, removeReference } =
        useWithReferencesFormGroup<ResearchContentAndReferencesFormState>(
            initialState,
        );

    function setIsComplete(formIndex: number, value: boolean) {
        const newForm = { ...forms[formIndex], isComplete: value };
        updateForm(formIndex, newForm);
    }

    useEffect(
        function storeState() {
            dispatch(
                pro12Slice.actions.setResearchContentAndReferencesForms(forms),
            );
        },
        [forms],
    );

    useEffect(
        function createSameNumberOfFormsAsResearchContents() {
            (function syncForms() {
                researchContents.forEach(({ value }, index) => {
                    const form = forms[index];
                    if (!form) {
                        appendForm({
                            researchContent: value,
                            researchContentDescription: '',
                            references: [],
                            isComplete: false,
                        });
                    } else if (form.researchContent !== value) {
                        updateForm(index, {
                            ...form,
                            researchContent: value,
                        });
                    }
                });
            })();

            (function removeExtraForms() {
                if (forms.length > researchContents.length) {
                    for (
                        let i = forms.length - 1;
                        i >= researchContents.length;
                        i--
                    ) {
                        removeForm(i);
                    }
                }
            })();
        },
        [forms, researchContents],
    );

    useEffect(
        function complete() {
            const unCompletedForms = forms.filter((value) => !value.isComplete);
            setCompletion(unCompletedForms.length === 0);
        },
        [forms],
    );

    return (
        <SectionWrapper id={'B'}>
            <SectionTitle>B. 탐구 내용</SectionTitle>
            {shouldShowValidationErrorMessage && (
                <FormErrorMessage message={'탐구 내용을 입력해주세요'} />
            )}
            {forms.map((value, index) => (
                <BorderBox variant="danger" key={index} className="mt-2">
                    <div className="row justify-content-center">
                        {value.isComplete ? (
                            <RCRCard
                                onEditButtonClick={() =>
                                    setIsComplete(index, false)
                                }
                                onReferenceRemoveButtonClick={(refIndex) =>
                                    removeReference(index, refIndex)
                                }
                                value={value}
                                index={index}
                            />
                        ) : (
                            <RCRDraft
                                initialValue={value}
                                onComplete={(draft) => {
                                    updateForm(index, {
                                        ...draft,
                                        isComplete: true,
                                    });
                                }}
                                index={index}
                            />
                        )}
                    </div>
                </BorderBox>
            ))}
        </SectionWrapper>
    );
}
