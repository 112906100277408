import '@styles/components/subjectCard.scss';

import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const IssuedSubjectCard: React.FC<{ issuedSubject: IssuedSubjectSummary }> = ({
    issuedSubject,
}) => {
    const navigate = useNavigate();
    const backgroundRepr = issuedSubject.backgroundInEnglish
        ? `${issuedSubject.background} (${issuedSubject.backgroundInEnglish})`
        : issuedSubject.background;

    const issuedAtRepr = `${issuedSubject.issuedAt.getFullYear()}-${
        issuedSubject.issuedAt.getMonth() + 1
    }-${issuedSubject.issuedAt.getDate()}`;

    return (
        <Col lg={4} md={6}>
            <Card className="subject-card-base container-md">
                <Card.Body className="card-container">
                    <div className="card-label">주제 원리명</div>
                    <div className="card-title">{backgroundRepr}</div>
                    <div className="card-date">{issuedAtRepr}</div>
                    <div className="card-label">세특 기입 과목</div>
                    <div className="card-item-box">
                        <div className="text-box square card-item">
                            {issuedSubject.appliedSchoolSubject
                                ? issuedSubject.appliedSchoolSubject.name
                                : '기타'}
                        </div>
                    </div>
                    <div className="card-bottom-button-box">
                        <button
                            className="card-button"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                    `/issued-subjects/${issuedSubject.id}`,
                                );
                            }}
                        >
                            주제 확인
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default IssuedSubjectCard;
