import React from 'react';

export default function CheckOptions<O extends string | Record<any, any>>({
    options,
    getValue,
    getLabel,
    getKey,
    inputProps = {},
    getInputProps = (inputProps, option, index) => inputProps,
    renderOption,
}: {
    options: O[];
    getValue?: O extends string
        ? (option: O) => string
        : keyof O | ((option: O) => string);
    getLabel?: O extends string
        ? (option: O) => React.ReactNode
        : keyof O | ((option: O) => React.ReactNode);
    getKey?: O extends string
        ? (option: O) => string
        : keyof O | ((option: O) => string);
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    getInputProps?: (
        inputProps: React.InputHTMLAttributes<HTMLInputElement>,
        option: O,
        index: number,
    ) => React.InputHTMLAttributes<HTMLInputElement>;
    renderOption?: (option: O, index: number) => React.ReactNode;
}) {
    function _getKey(option: O, index: number) {
        return typeof getKey === 'undefined'
            ? index
            : typeof getKey === 'function'
            ? getKey(option)
            : option[getKey];
    }

    function _getValue(option: O) {
        return typeof getValue === 'function'
            ? getValue(option)
            : option[getValue];
    }

    function _getLabel(option: O) {
        return typeof getLabel === 'undefined'
            ? _getValue(option)
            : typeof getLabel === 'function'
            ? getLabel(option)
            : option[getLabel];
    }

    function _getInputProps(option: O, index: number) {
        return getInputProps(
            {
                ...inputProps,
                value: _getValue(option),
            },
            option,
            index,
        );
    }

    return (
        <>
            {options.map((option, index) => (
                <div className={'form-check'} key={_getKey(option, index)}>
                    {renderOption ? (
                        renderOption(option, index)
                    ) : (
                        <>
                            <input {..._getInputProps(option, index)} />
                            <label
                                className={'form-check-label'}
                                htmlFor={inputProps.name}
                            >
                                {_getLabel(option)}
                            </label>
                        </>
                    )}
                </div>
            ))}
        </>
    );
}
