import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import TextRadio from '../components/TextRadio';
import TextInputGathering from '../components/TextInputGathering';
import { BorderBox } from '@pages/pro12/components';

export default function Step1({
    setFormRef,
    initialValue,
}: {
    setFormRef: (form: UseFormReturn<ToughPointForm>) => void;
    initialValue?: ToughPointForm;
}) {
    const form = useForm<ToughPointForm>({ defaultValues: initialValue ?? {} });

    useEffect(() => {
        setFormRef(form);
    }, []);

    return (
        <BorderBox variant={'danger'}>
            <h4 className="fs-6 fw-semibold mb-2">[1단계] 어려웠던 지점</h4>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    {...form.register('toughPointSummary')}
                    value="정보 습득의 어려움 (정보 원천과 관련)"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    정보 습득의 어려움 (정보 원천과 관련)
                </label>
                {form.watch('toughPointSummary') ===
                    '정보 습득의 어려움 (정보 원천과 관련)' && (
                    <>
                        {' '}
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                어떤 정보를 얻기가 왜 어려웠나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('toughPointDescription', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1 $2에 관한 정보를 얻기가 어려웠다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떤 이유로',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder: '무엇',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    {...form.register('toughPointSummary')}
                    value="탐구 내용의 어려움 (지식 수준, 외국어 능력 등과 관련)"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    탐구 내용의 어려움 (지식 수준, 외국어 능력 등과 관련)
                </label>
                {form.watch('toughPointSummary') ===
                    '탐구 내용의 어려움 (지식 수준, 외국어 능력 등과 관련)' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                어떤 내용을 탐구하기가 왜 어려웠나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('toughPointDescription', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1 $2에 관한 탐구하기가 어려웠다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떤 이유로',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder: '무엇',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    {...form.register('toughPointSummary')}
                    value="탐구 방법의 어려움 (조사 및 분석 방법, 컴퓨터 활용 등과 관련)"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    탐구 방법의 어려움 (조사 및 분석 방법, 컴퓨터 활용 등과
                    관련)
                </label>
                {form.watch('toughPointSummary') ===
                    '탐구 방법의 어려움 (조사 및 분석 방법, 컴퓨터 활용 등과 관련)' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                탐구 방법을 활용하는데 어떤 어려움을 겪었나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('toughPointDescription', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '탐구 방법으로 $1을(를) 활용하는 것이 $2 어려웠다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무엇(방법)',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떤 점에서',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="기타"
                    {...form.register('toughPointSummary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    기타
                </label>
                {form.watch('toughPointSummary') === '기타' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                내용을 자유롭게 작성해보세요.
                            </p>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={true}
                                readOnly
                            />
                            <label
                                className="form-check-label w-50 mt-2"
                                htmlFor="flexRadioDefault1"
                            >
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="직접 입력하기"
                                    onChange={(e) =>
                                        form.setValue(
                                            'toughPointDescription',
                                            e.target.value,
                                        )
                                    }
                                />
                            </label>
                        </div>
                    </>
                )}
            </div>
        </BorderBox>
    );
}
