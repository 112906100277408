export const editorDefaultInitConfiguration = {
    language: 'ko_KR',
    height: 500,
    menubar: false,
    statusbar: false,
    placeholder:
        '이론적 배경 및 용어에 대한 내용을 작성하세요. (이미지 첨부 가능)',
    plugins: [
        'lists',
        'advlist',
        'autolink',
        'emoticons',
        'help',
        'image',
        'link',
        'media',
        'searchreplace',
    ],
    toolbar:
        'blocks | ' +
        'forecolor | alignleft aligncenter ' +
        'alignright alignjustify | ' +
        'bold italic underline strikethrough | ' +
        'link image media emoticons',
    file_picker_types: 'image',
    skin: false,
    content_css: false,
};
