import React from 'react';
import { ArrowRightAltOutlined, DeleteOutline } from '@mui/icons-material';
import Bookmark from '@components/Bookmark';

export default function ReferenceCard({
    reference,
    removeReference,
}: {
    reference: Reference;
    removeReference?: () => void;
}) {
    const isBookBound =
        reference.startPageForBook ||
        reference.startLineForBook ||
        reference.endPageForBook ||
        reference.endLineForBook;
    const isVideoBound =
        reference.startMinuteForVideo ||
        reference.startSecondForVideo ||
        reference.endMinuteForVideo ||
        reference.endSecondForVideo;
    return (
        <>
            <div className={'row align-items-center justify-content-between'}>
                <div className={'col-auto'}>
                    <h5 className={'fs-6 fw-bold'}>
                        {reference.type || '기타자료'}
                    </h5>
                </div>
                {removeReference && (
                    <div className={'col-1'}>
                        <button
                            type={'button'}
                            className={'btn btn-outline btn-sm'}
                            onClick={() => removeReference()}
                        >
                            <DeleteOutline className={'text-danger'} />
                        </button>
                    </div>
                )}
            </div>
            {reference.bookmark &&
            (reference.bookmark.image || reference.bookmark.description) ? (
                <Bookmark
                    title={reference.bookmark.title}
                    link={reference.link || reference.bookmark.url}
                    image={reference.bookmark.image}
                    description={reference.bookmark.description}
                />
            ) : (
                <div className={'row mt-4 align-items-center'}>
                    <div className={'col-auto'}>
                        <strong className={'fs-6 fw-semibold'}>링크: </strong>
                    </div>
                    <div className={'col text-truncate'}>
                        {reference.link ? (
                            <a href={reference.link} target="_blank">
                                {reference.link}
                            </a>
                        ) : (
                            <span>-</span>
                        )}
                    </div>
                </div>
            )}
            {reference.content && (
                <div className={'row mt-4 align-items-center'}>
                    <div className={'col-auto'}>
                        <strong className={'fs-6 fw-semibold'}>
                            내용 (요약):{' '}
                        </strong>
                    </div>
                    <div className={'col text-truncate'}>
                        <p className="mb-0">{reference.content || '-'}</p>
                    </div>
                </div>
            )}
            {reference.quote && (
                <div className={'row mt-3 ps-0 align-items-center'}>
                    <div className={'col-auto'}>
                        <strong className={'fs-6 fw-semibold'}>
                            발췌 내용 (인용):{' '}
                        </strong>
                    </div>
                    <div className={'col text-truncate'}>
                        <p className="mb-0">{reference.quote || '-'}</p>
                    </div>
                </div>
            )}
            {isBookBound && (
                <div className={'row mt-3 ps-0 align-items-center'}>
                    <div className={'col-auto'}>
                        <strong className={'fs-6 fw-semibold'}>
                            발췌 구간 (서적):{' '}
                        </strong>
                    </div>
                    <div className={'col'}>
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.startPageForBook || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>쪽</span>
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.startLineForBook || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>줄</span>
                        <ArrowRightAltOutlined className={'ms-2'} />
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.endPageForBook || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>쪽</span>
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.endLineForBook || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>줄</span>
                    </div>
                </div>
            )}
            {isVideoBound && (
                <div className={'row mt-3 ps-0 align-items-center'}>
                    <div className={'col-auto'}>
                        <strong className={'fs-6 fw-semibold'}>
                            발췌 구간 (영상):{' '}
                        </strong>
                    </div>
                    <div className={'col'}>
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.startMinuteForVideo || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>분</span>
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.startSecondForVideo || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>초</span>
                        <ArrowRightAltOutlined className={'ms-2'} />
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.endMinuteForVideo || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>분</span>
                        <input
                            className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                            style={{ width: '70px' }}
                            type="text"
                            value={reference.endSecondForVideo || '-'}
                            disabled
                        />
                        <span className={'ms-2'}>초</span>
                    </div>
                </div>
            )}
            {reference.summary && (
                <div className={'row mt-3 ps-0 align-items-center'}>
                    <div className={'col-auto'}>
                        <strong className={'fs-6 fw-semibold'}>
                            참고문헌 정리:
                        </strong>
                    </div>
                    <div className={'col text-truncate'}>
                        <p className="mb-0">{reference.summary || '-'}</p>
                    </div>
                </div>
            )}
        </>
    );
}
