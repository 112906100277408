import 'animate.css';
import '@styles/index.scss';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';
import tracker from './tracker';

store.subscribe(() => {
    const { student } = store.getState();

    if (student.id) {
        tracker.setUserID(student.code);
        tracker.setMetadata('id', student.id.toString());
        tracker.setMetadata(
            'consultant',
            `${student.cramSchool}: ${student.consultantName}`,
        );
        tracker.setMetadata('phoneNumber', student.phoneNumber);
        tracker.setMetadata('name', student.name);
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
