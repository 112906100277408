import '@styles/pages/pro12/ResearchOverviewPage.scss';

import React, { useContext } from 'react';
import { useAppSelector } from '@store';
import { useNavigate } from 'react-router-dom';
import { PATH, resolveRoute } from 'src/ProjectRoutes';
import Pro12Context from '../Pro12Context';
import {
    FeedbackDisplay,
    SectionA,
    SectionB,
    SectionC,
    SectionD,
    SelfChecklist,
} from './components';

export default function ResearchReviewPage() {
    const navigate = useNavigate();
    const { assignment, updateAssignmentReport, createAssignmentReport } =
        useContext(Pro12Context);
    const data = useAppSelector(({ pro12 }) => pro12);

    async function onSubmit() {
        if (!assignment) {
            return;
        }

        const caller = assignment.report ? patch : create;
        await caller();
    }

    function navigateToAssignmentList() {
        if (!assignment) {
            return;
        }

        navigate(
            resolveRoute(PATH.PRO12_ASSIGNMENT_LIST, {
                semester: assignment.semester,
                grade: assignment.grade,
            }),
        );
    }

    async function call(resolve: Promise<void>) {
        if (!assignment) {
            return;
        }

        await resolve;
        alert('제출하였습니다.');
        navigateToAssignmentList();
    }

    const patch = () =>
        call(
            updateAssignmentReport({
                ...data,
                isCompleted: true,
                isResubmitted: assignment?.report?.feedback?.isCompleted
                    ? true
                    : false,
            }),
        );

    const create = () =>
        call(
            createAssignmentReport({
                ...data,
                isCompleted: true,
            }),
        );

    return (
        <div id="research-overview-page" className={'container-fluid'}>
            <div className={'row'}>
                <div className={'col-lg-8'}>
                    <SectionA />
                    <SectionB />
                    <SectionC />
                    <SectionD />
                </div>
                <div className={'col-lg-4'}>
                    {assignment?.report?.feedback?.isCompleted ? (
                        <FeedbackDisplay
                            assignmentReport={assignment.report}
                            onSubmit={patch}
                        />
                    ) : (
                        <SelfChecklist onSubmit={onSubmit} />
                    )}
                </div>
            </div>
        </div>
    );
}
