import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default function Tooltip({
    label,
    children,
    triggerNode,
}: {
    label?: React.ReactNode;
    children: React.ReactNode;
    triggerNode: React.ReactElement;
}) {
    return (
        <>
            {label}
            <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="right"
                overlay={
                    <Popover style={{ maxWidth: 550 }}>
                        <Popover.Body>{children}</Popover.Body>
                    </Popover>
                }
                defaultShow={false}
                delay={{ show: 0, hide: 200 }}
            >
                {triggerNode}
            </OverlayTrigger>
        </>
    );
}
