import React, { ReactNode, useEffect, useState } from 'react';

function useTextRadio({
    setValue: setTargetValue,
    options,
}: {
    setValue: (value: string) => void;
    options: {
        render: (setValue: (value: string) => void) => ReactNode;
    }[];
}) {
    const [value, _setValue] = useState<string>('');
    const [memo, setMemo] = useState<Record<number, string>>({});
    const [currentRadioIndex, setCurrentRadioIndex] = useState(0);

    function memoValue(index: number, value: string) {
        setMemo((prev) => ({ ...prev, [index]: value }));
    }

    function getMemo(index: number) {
        return memo[index];
    }

    function isChecked(index: number) {
        return index === currentRadioIndex;
    }

    function changeCurrentRadio(index: number) {
        setCurrentRadioIndex(index);
        _setValue(getMemo(index));
    }

    function setValue(index: number, value: string) {
        if (isChecked(index)) {
            _setValue(value);
        } else {
            memoValue(index, value);
        }
    }

    useEffect(() => {
        memoValue(currentRadioIndex, value);
        setTargetValue(value);
    }, [value]);

    return {
        value,
        setValue,
        isChecked,
        changeCurrentRadio,
    };
}

export default function TextRadio({
    setValue: setTargetValue,
    options,
}: {
    setValue: (value: string) => void;
    options: {
        render: (setValue: (value: string) => void) => ReactNode;
    }[];
}) {
    const textRadio = useTextRadio({ setValue: setTargetValue, options });

    return (
        <>
            {options.map((option, index) => (
                <div className="row m-1" key={index}>
                    <div className="form-check d-flex align-items-center">
                        <input
                            className="form-check-input me-2"
                            type="radio"
                            checked={textRadio.isChecked(index)}
                            onChange={() => textRadio.changeCurrentRadio(index)}
                        />
                        <label className="form-check-label w-100">
                            <div className="row align-items-center">
                                {option.render((value) =>
                                    textRadio.setValue(index, value),
                                )}
                            </div>
                        </label>
                    </div>
                </div>
            ))}
        </>
    );
}
