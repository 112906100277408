import TextRadio from '../../components/TextRadio';
import TextInputGathering from '../../components/TextInputGathering';
import { UseFormReturn } from 'react-hook-form';
import { RetrospectionForm } from '@pages/pro12/ResearchConclusionPage/types';

export default function EtcSchoolSubjectRelated({
    form,
}: {
    form: UseFormReturn<RetrospectionForm>;
}) {
    return (
        <>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="다른 과목과의 연관성을 발견하여 함께 학습할 필요성을 느꼈다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    다른 과목과의 연관성을 발견하여 함께 학습할 필요성을 느꼈다.
                </label>
                {form.watch('summary') ===
                    '다른 과목과의 연관성을 발견하여 함께 학습할 필요성을 느꼈다.' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                무슨 과목과, 어떤 점에서 연관성이 있나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1이(가) $2과도 깊은 관련이 있음을 확인하여, 두 과목의 관련성을 고려하며 효과적으로 학습해야겠다는 생각이 들었다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 탐구 내용',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 과목',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="다른 과목의 관점에서 같은 주제를 탐구해볼 가능성을 발견하였다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    다른 과목의 관점에서 같은 주제를 탐구해볼 가능성을
                    발견하였다.
                </label>
                {form.watch('summary') ===
                    '다른 과목의 관점에서 같은 주제를 탐구해볼 가능성을 발견하였다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            무슨 과목에서 같은 주제를 다뤄볼 수 있을까요? 그
                            과목의 관점에서는 주제의 어떤 측면에 주목하게
                            될까요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1에서 같은 주제를 탐구해볼 가능성을 발견하였다. 해당 과목의 관점에서 탐구할 경우, $2에 조금 더 주목해볼 수 있을 것 같다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 과목',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '주제의 어떤 부분(측면)',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="다른 과목에서 탐구한 주제를 새로운 관점에서 살핌으로써 현상을 보는 시야가 확대되었다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    다른 과목에서 탐구한 주제를 새로운 관점에서 살핌으로써
                    현상을 보는 시야가 확대되었다.
                </label>
                {form.watch('summary') ===
                    '다른 과목에서 탐구한 주제를 새로운 관점에서 살핌으로써 현상을 보는 시야가 확대되었다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            무슨 과목에서 탐구한 주제였나요? 그때는 어떤
                            관점에서 탐구했었나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '비슷한 주제를 $1에서 탐구한 적이 있는데, 당시 $2에 주목했던 것과 달리 이번에는 $3에 초점을 둠으로써 현상을 보는 시야가 확대되었다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 과목',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '(주제의) 어떤 점',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $3: {
                                                        type: 'text',
                                                        placeholder:
                                                            '(주제의) 어떤 점',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="기타"
                    {...form.register('summary')}
                />
                <label className="form-check-label">기타</label>
                {form.watch('summary') === '기타' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                내용을 자유롭게 작성해보세요.
                            </p>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={true}
                                readOnly
                            />
                            <label className="form-check-label w-75">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="직접 입력하기"
                                    onChange={(e) =>
                                        form.setValue(
                                            'description',
                                            e.target.value,
                                        )
                                    }
                                />
                            </label>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
