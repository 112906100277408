import { Location } from 'react-router-dom';
import 가보자고 from '@images/stamps/가보자고.png';
import 감동심해 from '@images/stamps/감동심해.png';
import 단디해라 from '@images/stamps/단디해라.png';
import 쉽살재빙 from '@images/stamps/쉽살재빙.png';
import 이건다시 from '@images/stamps/이건다시.png';
import 이건인정 from '@images/stamps/이건인정.png';
import 이걸해냄 from '@images/stamps/이걸해냄.png';
import 짜짜박박 from '@images/stamps/짜짜박박.png';
import 쪼끔애쉽 from '@images/stamps/쪼끔애쉽.png';
import 쫌더힘내 from '@images/stamps/쫌더힘내.png';
import 찢었다능 from '@images/stamps/찢었다능.png';
import 하면된다 from '@images/stamps/하면된다.png';

import 가보자고원형 from '@images/stamps/가보자고원형.png';
import 감동심해원형 from '@images/stamps/감동심해원형.png';
import 단디해라원형 from '@images/stamps/단디해라원형.png';
import 쉽살재빙원형 from '@images/stamps/쉽살재빙원형.png';
import 이건다시원형 from '@images/stamps/이건다시원형.png';
import 이건인정원형 from '@images/stamps/이건인정원형.png';
import 이걸해냄원형 from '@images/stamps/이걸해냄원형.png';
import 짜짜박박원형 from '@images/stamps/짜짜박박원형.png';
import 쪼끔애쉽원형 from '@images/stamps/쪼끔애쉽원형.png';
import 쫌더힘내원형 from '@images/stamps/쫌더힘내원형.png';
import 찢었다능원형 from '@images/stamps/찢었다능원형.png';
import 하면된다원형 from '@images/stamps/하면된다원형.png';

export function getIndexInKorean(index: number) {
    switch (index) {
        case 0:
            return '첫째';
        case 1:
            return '둘째';
        case 2:
            return '셋째';
        case 3:
            return '넷째';
        case 4:
            return '다섯째';
        case 5:
            return '여섯째';
        case 6:
            return '일곱째';
        case 7:
            return '여덟째';
        case 8:
            return '아홉째';
        case 9:
            return '열째';
        default:
            return 'N째';
    }
}

export class EventEmitter {
    private events: { [key: string]: Function[] } = {};

    on(eventName: string, callback: Function) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
    }

    emit(eventName: string, data?: any) {
        const event = this.events[eventName];
        if (event) {
            event.forEach((callback) => callback(data));
        }
    }

    off(eventName: string, callback: Function) {
        const event = this.events[eventName];
        if (!event) {
            return;
        }
        this.events[eventName] = event.filter(
            (listener) => listener !== callback,
        );
    }
}

export function replaceStep(location: Location, step: number, hash?: string) {
    const { pathname, search } = location;
    const path = pathname.split('/');
    path[path.length - 1] = step.toString();
    return `${path.join('/')}${search}${hash || ''}`;
}

export function formatDate(date: string | Date) {
    if (typeof date === 'string') {
        date = new Date(date);
    }

    return `${date.getFullYear()}년 ${
        date.getMonth() + 1
    }월 ${date.getDate()}일`;
}

export function scrollToSection(hash: string) {
    setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, 100);
}

export function 피드백도장가져오기(도장이름: string, 유형: string = '원형') {
    switch (도장이름) {
        case '가보자고':
            return 유형 === '원형' ? 가보자고원형 : 가보자고;
        case '감동심해':
            return 유형 === '원형' ? 감동심해원형 : 감동심해;
        case '단디해라':
            return 유형 === '원형' ? 단디해라원형 : 단디해라;
        case '쉽살재빙':
            return 유형 === '원형' ? 쉽살재빙원형 : 쉽살재빙;
        case '이건다시':
            return 유형 === '원형' ? 이건다시원형 : 이건다시;
        case '이건인정':
            return 유형 === '원형' ? 이건인정원형 : 이건인정;
        case '이걸해냄':
            return 유형 === '원형' ? 이걸해냄원형 : 이걸해냄;
        case '짜짜박박':
            return 유형 === '원형' ? 짜짜박박원형 : 짜짜박박;
        case '쪼끔애쉽':
            return 유형 === '원형' ? 쪼끔애쉽원형 : 쪼끔애쉽;
        case '쫌더힘내':
            return 유형 === '원형' ? 쫌더힘내원형 : 쫌더힘내;
        case '찢었다능':
            return 유형 === '원형' ? 찢었다능원형 : 찢었다능;
        case '하면된다':
            return 유형 === '원형' ? 하면된다원형 : 하면된다;
    }
}
