import { useAppSelector } from '@store';
import {
    callFetchAssignment,
    callFetchAssignmentIndexes,
    callFetchAssignmentProgressingCounts,
    callFetchAssignments,
    callPatchAssignmentReport,
    callSubmitAssignmentReport,
} from '@client';
import { callFetchAssignmentReport } from '@client/call-fetch-assignment-report';

export function useAssignmentFacade() {
    const student = useAppSelector((state) => state.student);

    async function getAssignmentProgressingCounts(params: any) {
        return await callFetchAssignmentProgressingCounts(params);
    }

    async function listAssignmentIndexes(params: any) {
        return await callFetchAssignmentIndexes(params);
    }

    async function listAssignments(params: any) {
        return await callFetchAssignments(params);
    }

    async function getAssignment(assignmentId: AssignmentId) {
        const assignment = await callFetchAssignment({
            studentId: student.id,
            assignmentId,
        });

        return assignment;
    }

    async function getAssignmentReport(assignmentReportId: AssignmentReportId) {
        const report = await callFetchAssignmentReport({
            studentId: student.id,
            assignmentReportId,
        });

        return report;
    }

    function getReportIdFromAssignment(assignment: Assignment) {
        return assignment.report.id;
    }

    function getReportFromAssignment(assignment: Assignment) {
        return assignment.report;
    }

    async function submitAssignmentReport(
        assignmentId: AssignmentId,
        assignmentReportOrFile: { [key: string]: any } | File,
    ) {
        const payload = buildPayload(assignmentReportOrFile, { assignmentId });

        return await callSubmitAssignmentReport({
            studentId: student.id,
            ...payload,
        });
    }

    async function completeAssignment(assignmentId: AssignmentId) {
        await callSubmitAssignmentReport({
            studentId: student.id,
            assignmentId,
        });
    }

    function buildPayload<T extends { [k: string]: any }>(
        assignmentReportOrFile: { [p: string]: any } | File,
        extra: T,
    ) {
        const payload: T & {
            report?: { [key: string]: any };
            file?: File;
        } = {
            ...extra,
        };

        if (assignmentReportOrFile instanceof File) {
            payload.file = assignmentReportOrFile as File;
        } else {
            payload.report = assignmentReportOrFile as { [key: string]: any };
        }
        return payload;
    }

    async function modifyAssignmentReport(
        assignmentReportId: AssignmentReportId,
        assignmentReportOrFile: { [key: string]: any } | File,
    ) {
        const payload = buildPayload(assignmentReportOrFile, {
            assignmentReportId,
        });

        return await callPatchAssignmentReport({
            studentId: student.id,
            ...payload,
        })
    }

    async function resubmitAssignmentReport(
        assignmentReportId: AssignmentReportId,
        assignmentReportOrFile: { [key: string]: any } | File,
    ) {
        const payload = buildPayload(assignmentReportOrFile, {
            assignmentReportId,
        });

        const report = await callPatchAssignmentReport({
            studentId: student.id,
            ...payload,
        })
        await callPatchAssignmentReport({
            studentId: student.id,
            assignmentReportId,
            report: report.data ? {
                ...report.data,
                isResubmitted: true,
            } : {
                isResubmitted: true,
            }
        })

        return report
    }

    return {
        getAssignmentProgressingCounts,
        listAssignmentIndexes,
        listAssignments,
        submitAssignmentReport,
        modifyAssignmentReport,
        completeAssignment,
        getAssignment,
        getReportFromAssignment,
        getAssignmentReport,
        getReportIdFromAssignment,
        resubmitAssignmentReport,
    };
}
