import { useState } from 'react';
import { ResearchReferencesType } from '@constants/enums';
import { callFetchResearchReferences } from '@client';
import { AxiosError } from 'axios';

export function useResearchReferencesFacade() {
    const [advancedResearchReferences, setAdvancedResearchReferences] =
        useState<AdvancedResearchReference[]>([]);
    const [newsResearchReferences, setNewsResearchReferences] = useState<
        NewsResearchReference[]
    >([]);
    const [videoResearchReferences, setVideoResearchReferences] = useState<
        VideoResearchReference[]
    >([]);
    const [errors, setErrors] = useState({
        [ResearchReferencesType.AdvancedResearchReferences]: null,
        [ResearchReferencesType.NewsResearchReferences]: null,
        [ResearchReferencesType.VideoResearchReferences]: null,
    });

    async function call({
        issuedSubjectId,
        type,
    }: {
        issuedSubjectId: IssuedSubjectId;
        type: ResearchReferencesType;
    }) {
        try {
            return await callFetchResearchReferences({
                issuedSubjectId,
                type,
            });
        } catch (e: any) {
            setErrors({
                ...errors,
                [type]: e.response.data.detail,
            });
            return [];
        }
    }

    async function getAdvancedResearchReferencesList(
        issuedSubjectId: IssuedSubjectId,
    ): Promise<string | string[]> {
        const data = await call({
            issuedSubjectId,
            type: ResearchReferencesType.AdvancedResearchReferences,
        });
        setAdvancedResearchReferences(data);
        return data;
    }

    async function getNewsResearchReferencesList(
        issuedSubjectId: IssuedSubjectId,
    ): Promise<string | string[]> {
        const data = await call({
            issuedSubjectId,
            type: ResearchReferencesType.NewsResearchReferences,
        });
        setNewsResearchReferences(data);
        return data;
    }

    async function getVideoResearchReferencesList(
        issuedSubjectId: IssuedSubjectId,
    ): Promise<string | string[]> {
        const data = await call({
            issuedSubjectId,
            type: ResearchReferencesType.VideoResearchReferences,
        });
        setVideoResearchReferences(data);
        return data;
    }

    function initAll(issuedSubjectId: IssuedSubjectId) {
        getAdvancedResearchReferencesList(issuedSubjectId);
        getNewsResearchReferencesList(issuedSubjectId);
        getVideoResearchReferencesList(issuedSubjectId);
    }

    return {
        initAll,
        advancedResearchReferences,
        newsResearchReferences,
        videoResearchReferences,
        errors,
    };
}
