import '@styles/pages/majorCategoryExplorationTournament.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@store';
import { useMajorCategoryExplorationFacade } from '@facades';
import { moment } from '@utils';

function ExplorationDetail({
    explorationDetail,
    rank,
}: {
    explorationDetail: ExplorationDetail;
    rank: number;
}) {
    function renderWithComma(data: string[]) {
        return data.reduce((p, c, i) => {
            return i === 0 ? c : `${p}, ${c}`
        }, "");
    }

    return (
        <>
            <header className="row p-5">
                <div className="col-11">
                    <h2 className="fw-semibold lh-base">
                        <span className="text-purple">{rank}순위</span>
                        <br />
                        {explorationDetail.category}
                    </h2>
                </div>
                <div className="col-1">
                    <a
                        className="material-icons-outlined text-purple fs-1 text-decoration-none"
                        href="javascript:window.print();"
                    >
                        print
                    </a>
                </div>
            </header>

            <article className="px-5 pb-5">
                <h4>계열 소개</h4>
                <div className="bg-light-purple rounded-3 py-3 px-5 mt-3">
                    <span>{explorationDetail.description}</span>
                </div>
            </article>

            <article className="px-5 pb-5">
                <h4>관련 학과</h4>
                <div className="bg-light-purple rounded-3 py-3 px-5 mt-3">
                    <span>{renderWithComma(explorationDetail.relatedMajors)}</span>
                </div>
            </article>

            <article className="px-5 pb-5">
                <h4>커리큘럼 예시</h4>
                <span className="text-purple">
                    *아래의 커리큘럼은 여러분의 계열 이해를 돕기 위한 예시로서,
                    학교/학과에 따라 강의명 및 교육과정이 다를 수 있습니다.
                </span>
                <div className="bg-light-purple rounded-3 py-3 px-5 mt-3">
                    <span>{renderWithComma(explorationDetail.curriculums)}</span>
                </div>
            </article>
        </>
    );
}

export default function ResultPage() {
    const { load, exploration } = useMajorCategoryExplorationFacade();
    const [explorationDetails, setExplorationDetails] = useState<
        ExplorationDetail[]
    >([]);
    const [index, setIndex] = useState(0);
    const student = useAppSelector((state) => state.student);
    const navigate = useNavigate();

    async function initExplorationResult() {
        await load();
    }

    function navigateToBack() {
        navigate(-1);
    }

    useEffect(() => {
        if (exploration !== null) {
            setExplorationDetails(exploration.results);
        }
    }, [exploration]);

    useEffect(() => {
        initExplorationResult();
    }, []);

    return (
        <>
            <div className="body-wrapper">
                <div className="mx-auto body-container">
                    <header className="py-5">
                        <div className="go-back-btn-container container">
                            <div
                                onClick={navigateToBack}
                                className="go-back-btn"
                            >
                                <span className="material-icons">
                                    arrow_back_ios
                                </span>
                                <span>뒤로가기</span>
                            </div>
                        </div>
                        <div></div>
                    </header>
                    <section className="bg-white rounded-4 pb-5">
                        <header>
                            <div className="row p-5">
                                <div className="col-10">
                                    <h2 className="text-purple">
                                        계열선택검사 결과지
                                    </h2>
                                </div>
                                <div className="col-2 text-end small">
                                    <div>{student.name}</div>
                                    <div>
                                        {exploration &&
                                            moment(
                                                exploration.createdAt,
                                            ).format('YYYY.MM.DD')}
                                    </div>
                                </div>
                            </div>
                            <article className="border-bottom border-2">
                                <div className="d-flex justify-content-center">
                                    {explorationDetails.map((e, innerIndex) => (
                                        <div key={innerIndex}>
                                            <button
                                                onClick={() =>
                                                    setIndex(innerIndex)
                                                }
                                                className={`result-page-btn p-3 border-0 mx-2 ${
                                                    index === innerIndex
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                type="button"
                                            >
                                                <div className="text-start text-black-50 small">
                                                    {innerIndex + 1} 순위
                                                </div>
                                                <h5 className="text-dark">
                                                    {e.category}
                                                </h5>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </article>
                            {explorationDetails[index] && (
                                <ExplorationDetail
                                    rank={index + 1}
                                    explorationDetail={
                                        explorationDetails[index]
                                    }
                                />
                            )}
                        </header>
                    </section>
                </div>
            </div>
        </>
    );
}
