import { QUALITY_RANK, QUANTITY_RANK } from '@constants/enums';

const DIAGNOSIS_SENTENCE: Record<
    string,
    SchoolRecordAnalysisDiagnosisTemplate
> = {
    BEST_QUANTITY_A_QUALITY: {
        title: '순탄하고 수월하게, 꽃길이 열리리라',
        idiom: '탄탄대로(坦坦大路)',
        ranking: '상위 1%',
        position: 'rightTop',
        description:
            '희망 진로가 한 눈에 보이네요! 희망 진로와 관련한 탐구 과정과 결과가 대부분의 과목에서 구체적으로 드러납니다. 이대로만 관리한다면 학생부종합전형에서도 좋은 결과를 기대할 수 있겠습니다.😊',
    },
    BEST_QUANTITY_B_QUALITY: {
        title: '모든 일이 뜻하는 대로 잘 되리라',
        idiom: '만사여의(萬事如意)',
        ranking: '상위 5%',
        position: 'centerTop',
        description:
            '희망 진로가 한 눈에 보이네요! 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다. 이대로만 관리한다면 학생부종합전형에서도 좋은 결과를 기대할 수 있겠습니다.😊',
    },
    BEST_QUANTITY_C_QUALITY: {
        title: '뜻이 있으니 반드시 성공하리',
        idiom: '유지경성(有志竟成)',
        ranking: '상위 10%',
        position: 'leftTop',
        description:
            '다양한 과목에서 희망 진로가 드러나네요! 기울인 노력에 비해, 기록된 내용의 구체성이 다소 떨어지는 게 아쉽습니다. 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다. 입학사정관이 궁금해할 만한 학생이로군요.😉',
    },
    BEST_QUANTITY_F_QUALITY: {
        title: '땀 흘리지 않고서야 무엇을 이루리오',
        idiom: '무한불성(無汗不成)',
        ranking: '하위 10%',
        position: 'leftBottom',
        description:
            '아직 진로를 설정하지 못했나요? 학생부종합전형의 키는 ‘한 우물 파기’입니다. 늦었다고 생각한 때가 가장 빠른 법입니다. 바로 지금, 희망 진로를 설정하여 달려 봅시다.',
    },
    EXCELLENT_QUANTITY_A_QUALITY: {
        title: '모든 일이 뜻하는 대로 잘 될지어다',
        idiom: '만사형통(萬事亨通)',
        ranking: '상위 5%',
        position: 'rightTop',
        description:
            '진로에 대한 열정이 가득하네요! 희망 진로와 관련한 탐구 과정과 결과가 구체적으로 드러납니다. 이대로만 관리한다면 학생부종합전형에서도 좋은 결과를 기대할 수 있겠습니다.😊',
    },
    EXCELLENT_QUANTITY_B_QUALITY: {
        title: '기운차게 거침없이 해결해 나가리라',
        idiom: '승승장구(乘勝長驅)',
        ranking: '상위 10%',
        position: 'centerTop',
        description:
            '진로에 대한 열정이 가득하네요! 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다. 입학사정관이 궁금해할 만한 학생이로군요.😉',
    },
    EXCELLENT_QUANTITY_C_QUALITY: {
        title: '’넓지만 얕게’에서 ‘넓고 깊게’를 향해',
        idiom: '박이부정(博而不精)',
        ranking: '상위 30%',
        position: 'leftTop',
        description:
            '진로에 대한 열정이 가득하네요! 기울인 노력에 비해, 기록된 내용의 구체성이 다소 떨어지는 게 아쉽습니다. 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다.',
    },
    EXCELLENT_QUANTITY_F_QUALITY: {
        title: '땀 흘리지 않고서야 무엇을 이루리오',
        idiom: '무한불성(無汗不成)',
        ranking: '하위 10%',
        position: 'leftBottom',
        description:
            '아직 진로를 설정하지 못했나요? 학생부종합전형의 키는 ‘한 우물 파기’입니다. 늦었다고 생각한 때가 가장 빠른 법입니다. 바로 지금, 희망 진로를 설정하여 달려 봅시다.',
    },
    GOOD_QUANTITY_A_QUALITY: {
        title: '부지런히 한다면 마침내 성공하리',
        idiom: '근자필성(勤者必成)',
        ranking: '상위 10%',
        position: 'rightMiddle',
        description:
            '학교 생활이 알찬 대학 생활의 발판이 되고 있군요! 희망 진로와 관련한 탐구 과정과 결과가 구체적으로 드러납니다. 다음 학기에는 1~2과목에서 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    GOOD_QUANTITY_B_QUALITY: {
        title: '무럭무럭 무성히 자라리라',
        idiom: '흔흔향영(欣欣向榮)',
        ranking: '상위 30%',
        position: 'centerMiddle',
        description:
            '학교 생활이 알찬 대학 생활의 발판이 되고 있군요! 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다. 다음 학기에는 1~2과목 정도 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    GOOD_QUANTITY_C_QUALITY: {
        title: '참되고 실속있게 힘써 실행하리',
        idiom: '무실역행(務實力行)',
        ranking: '상위 50%',
        position: 'leftMiddle',
        description:
            '학교 생활이 알찬 대학 생활의 발판이 되고 있군요! 기울인 노력에 비해, 기록된 내용의 구체성이 다소 떨어지는 게 아쉽습니다. 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다.다음 학기에는 1~2과목 정도 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    GOOD_QUANTITY_F_QUALITY: {
        title: '땀 흘리지 않고서야 무엇을 이루리오',
        idiom: '무한불성(無汗不成)',
        ranking: '하위 10%',
        position: 'leftBottom',
        description:
            '아직 진로를 설정하지 못했나요? 학생부종합전형의 키는 ‘한 우물 파기’입니다. 늦었다고 생각한 때가 가장 빠른 법입니다. 바로 지금, 희망 진로를 설정하여 달려 봅시다.',
    },
    BAD_QUANTITY_A_QUALITY: {
        title: '보름달이 차오르듯 채워나가리',
        idiom: '여월지항(如月之恒)',
        ranking: '상위 30%',
        position: 'rightBottom',
        description:
            '무한한 잠재력을 지니고 있군요! 희망 진로와 관련한 탐구 과정과 결과가 구체적으로 드러납니다. 다음 학기에는 2~3과목 정도 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    BAD_QUANTITY_B_QUALITY: {
        title: '끊임없이 노력하면 반드시 이루리라',
        idiom: '우공이산(愚公移山)',
        ranking: '상위 50%',
        position: 'centerBottom',
        description:
            '무한한 잠재력을 지니고 있군요! 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다. 다음 학기에는 2~3과목 정도 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    BAD_QUANTITY_C_QUALITY: {
        title: '도끼를 갈아 바늘을 만드리라',
        idiom: '마부작침(磨斧作針)',
        ranking: '하위 30%',
        position: 'leftBottom',
        description:
            '무한한 잠재력을 지니고 있군요! 기울인 노력에 비해, 기록된 내용의 구체성이 다소 떨어지는 게 아쉽습니다. 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다.다음 학기에는 2~3과목 정도 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    BAD_QUANTITY_F_QUALITY: {
        title: '땀 흘리지 않고서야 무엇을 이루리오',
        idiom: '무한불성(無汗不成)',
        ranking: '하위 10%',
        position: 'leftBottom',
        description:
            '아직 진로를 설정하지 못했나요? 학생부종합전형의 키는 ‘한 우물 파기’입니다. 늦었다고 생각한 때가 가장 빠른 법입니다. 바로 지금, 희망 진로를 설정하여 달려 봅시다.',
    },
    WORST_QUANTITY_A_QUALITY: {
        title: '정성을 다하면 하늘이 도우리라',
        idiom: '지성감천(至誠感天)',
        ranking: '상위 50%',
        position: 'rightBottom',
        description:
            '작은 고추가 매운 법! 소수의 과목이지만 희망 진로에 대한 탐구 과정과 결과가 구체적으로 드러납니다. 다음 학기에는 3~4과목 정도 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    WORST_QUANTITY_B_QUALITY: {
        title: '마음만 먹으면 쇠도 돌도 뚫으리',
        idiom: '금석위개(金石爲開)',
        ranking: '하위 30%',
        position: 'centerBottom',
        description:
            '훌륭한 원석이나 다듬어지지 못하여 아쉽습니다. 진로 관련 활동의 동기와 과정, 배우고 느낀 점이 보다 자세히 드러날 수 있도록 노력해 봅시다. 다음 학기에는 3~4과목 정도 더, 진로역량을 어필하는 것을 목표로 삼아 볼까요?',
    },
    WORST_QUANTITY_C_QUALITY: {
        title: '땀 흘리지 않고서야 무엇을 이루리오',
        idiom: '무한불성(無汗不成)',
        ranking: '하위 10%',
        position: 'leftBottom',
        description:
            '아직 진로를 설정하지 못했나요? 학생부종합전형의 키는 ‘한 우물 파기’입니다. 늦었다고 생각한 때가 가장 빠른 법입니다. 바로 지금, 희망 진로를 설정하여 달려 봅시다.',
    },
    WORST_QUANTITY_F_QUALITY: {
        title: '땀 흘리지 않고서야 무엇을 이루리오',
        idiom: '무한불성(無汗不成)',
        ranking: '하위 10%',
        position: 'leftBottom',
        description:
            '아직 진로를 설정하지 못했나요? 학생부종합전형의 키는 ‘한 우물 파기’입니다. 늦었다고 생각한 때가 가장 빠른 법입니다. 바로 지금, 희망 진로를 설정하여 달려 봅시다.',
    },
};

export default function getAnalysisProposalDiagnosisTemplate({
    quantity,
    quality,
}: {
    quantity: QUANTITY_RANK;
    quality: QUALITY_RANK;
}) {
    switch (quantity) {
        case QUANTITY_RANK.BEST:
            switch (quality) {
                case QUALITY_RANK.A:
                    return DIAGNOSIS_SENTENCE.BEST_QUANTITY_A_QUALITY;
                case QUALITY_RANK.B:
                    return DIAGNOSIS_SENTENCE.BEST_QUANTITY_B_QUALITY;
                case QUALITY_RANK.C:
                    return DIAGNOSIS_SENTENCE.BEST_QUANTITY_C_QUALITY;
                case QUALITY_RANK.F:
                    return DIAGNOSIS_SENTENCE.BEST_QUANTITY_F_QUALITY;
                default:
                    return null;
            }
        case QUANTITY_RANK.EXCELLENT:
            switch (quality) {
                case QUALITY_RANK.A:
                    return DIAGNOSIS_SENTENCE.EXCELLENT_QUANTITY_A_QUALITY;
                case QUALITY_RANK.B:
                    return DIAGNOSIS_SENTENCE.EXCELLENT_QUANTITY_B_QUALITY;
                case QUALITY_RANK.C:
                    return DIAGNOSIS_SENTENCE.EXCELLENT_QUANTITY_C_QUALITY;
                case QUALITY_RANK.F:
                    return DIAGNOSIS_SENTENCE.EXCELLENT_QUANTITY_F_QUALITY;
                default:
                    return null;
            }

        case QUANTITY_RANK.GOOD:
            switch (quality) {
                case QUALITY_RANK.A:
                    return DIAGNOSIS_SENTENCE.GOOD_QUANTITY_A_QUALITY;
                case QUALITY_RANK.B:
                    return DIAGNOSIS_SENTENCE.GOOD_QUANTITY_B_QUALITY;
                case QUALITY_RANK.C:
                    return DIAGNOSIS_SENTENCE.GOOD_QUANTITY_C_QUALITY;
                case QUALITY_RANK.F:
                    return DIAGNOSIS_SENTENCE.GOOD_QUANTITY_F_QUALITY;
                default:
                    return null;
            }
        case QUANTITY_RANK.BAD:
            switch (quality) {
                case QUALITY_RANK.A:
                    return DIAGNOSIS_SENTENCE.BAD_QUANTITY_A_QUALITY;
                case QUALITY_RANK.B:
                    return DIAGNOSIS_SENTENCE.BAD_QUANTITY_B_QUALITY;
                case QUALITY_RANK.C:
                    return DIAGNOSIS_SENTENCE.BAD_QUANTITY_C_QUALITY;
                case QUALITY_RANK.F:
                    return DIAGNOSIS_SENTENCE.BAD_QUANTITY_F_QUALITY;
                default:
                    return null;
            }
        case QUANTITY_RANK.WORST:
            switch (quality) {
                case QUALITY_RANK.A:
                    return DIAGNOSIS_SENTENCE.WORST_QUANTITY_A_QUALITY;
                case QUALITY_RANK.B:
                    return DIAGNOSIS_SENTENCE.WORST_QUANTITY_B_QUALITY;
                case QUALITY_RANK.C:
                    return DIAGNOSIS_SENTENCE.WORST_QUANTITY_C_QUALITY;
                case QUALITY_RANK.F:
                    return DIAGNOSIS_SENTENCE.WORST_QUANTITY_F_QUALITY;
                default:
                    return null;
            }
        default:
            return null;
    }
}
