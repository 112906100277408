import React from 'react';
import { Path, UseFormRegister } from 'react-hook-form';

import CheckOptions from './CheckOptions';

export default function StringCheckOptions<
    F extends Record<string, any> = Record<string, any>,
>({
    options,
    fieldName,
    register,
    registerOptions = {},
    inputProps = {},
    required = true,
    ...rest
}: Partial<React.ComponentProps<typeof CheckOptions<string>>> & {
    options: string[];
    fieldName: Path<F>;
    // register: UseFormRegister<F>;
    register: any;
    registerOptions?: Parameters<UseFormRegister<F>>[1];
    inputProps: React.InputHTMLAttributes<HTMLInputElement>;
    required?: boolean;
}) {
    return (
        <CheckOptions
            options={options}
            getValue={(option) => option}
            inputProps={{
                ...register(fieldName, { required: true, ...registerOptions }),
                ...inputProps,
            }}
            {...rest}
        />
    );
}
