import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@store';

import 'src/assets/scss/components/schoolRecordModal.scss';
import { useGradeFacade } from '@facades';
import { Modal } from 'react-bootstrap';
import { BACKEND_URL } from '@constants/config';
import SchoolRecordAgreement from '@components/SchoolRecordAgreement';

export default function SchoolRecordModal({
    modalShow,
    closeModal,
    data,
}: {
    modalShow: boolean;
    closeModal: () => void;
    data: SchoolRecordModalProps | null;
}) {
    const student = useAppSelector((state) => state.student);

    const [fileName, setFileName] = useState('');
    const [uploadedFile, setUploadedFile] = useState('');
    const schoolRecordInputElement = useRef<HTMLInputElement>(null);
    const [schoolRecord, setSchoolRecord] = useState(null);
    const [privateAgreement, setPrivateAgreement] = useState(false);
    const [sensitiveAgreement, setSensitiveAgreement] = useState(false);

    const gradeFacade = useGradeFacade();

    function setSchoolRecordName() {
        if (
            schoolRecordInputElement.current &&
            schoolRecordInputElement.current.files
        ) {
            setFileName(schoolRecordInputElement.current.files[0].name);
        }
    }

    async function uploadSchoolRecord() {
        if (
            !data ||
            !data.grade ||
            !schoolRecordInputElement.current ||
            !schoolRecordInputElement.current.files
        ) {
            return;
        }

        const schoolRecord = schoolRecordInputElement.current.files[0];
        await gradeFacade.uploadSchoolRecord({
            schoolRecord,
            grade: data.grade,
            studentId: student.id,
        });
        closeModal();
    }

    useEffect(() => {
        if (!data || !data.grade || !student) {
            return;
        }
    }, [data?.grade, student]);

    useEffect(() => {
        if (modalShow) {
            if (data?.uploadedSchoolRecord) {
                const splited = decodeURI(data.uploadedSchoolRecord).split('/');
                setUploadedFile(splited[splited.length - 1]);
            }
        } else {
            setSchoolRecord(null);
            setFileName('');
            setUploadedFile('');
            setPrivateAgreement(false);
            setSensitiveAgreement(false);
        }
    }, [modalShow]);

    return (
        data && (
            <>
                <Modal
                    show={modalShow}
                    onHide={closeModal}
                    className="school-record-modal-base"
                    centered
                    size={fileName ? 'lg' : undefined}
                >
                    <Modal.Header className="school-record-modal-header">
                        <div
                            className="material-icons modal-close-button"
                            onClick={closeModal}
                        >
                            close
                        </div>
                        <div className="modal-title">
                            {data.grade}학년 생기부 제출
                        </div>
                    </Modal.Header>
                    <Modal.Body className="school-record-modal-body">
                        {data.uploadedSchoolRecord && (
                            <a
                                className="report-download"
                                href={`${BACKEND_URL}${data.uploadedSchoolRecord}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {uploadedFile}
                            </a>
                        )}
                        {/* <div className="modal-label">파일 업로드</div> */}
                        <label htmlFor="file-upload">
                            {!fileName ? 'HTML 파일 업로드' : fileName}
                        </label>
                        <input
                            id="file-upload"
                            ref={schoolRecordInputElement}
                            onChange={setSchoolRecordName}
                            type="file"
                            accept=".html"
                        />
                        {fileName && (
                            <>
                                <div className="agreement-box">
                                    <SchoolRecordAgreement />
                                </div>
                                <div className="checkbox-group">
                                    <div className="checkbox-line">
                                        <input
                                            type="checkbox"
                                            id="school-record-modal-checkbox-1"
                                            checked={privateAgreement}
                                            onChange={() =>
                                                setPrivateAgreement(
                                                    !privateAgreement,
                                                )
                                            }
                                        />
                                        <label htmlFor="school-record-modal-checkbox-1">
                                            개인정보 수집 및 이용에 동의합니다.
                                        </label>
                                    </div>
                                    <div className="checkbox-line">
                                        <input
                                            type="checkbox"
                                            id="school-record-modal-checkbox-2"
                                            checked={sensitiveAgreement}
                                            onChange={() =>
                                                setSensitiveAgreement(
                                                    !sensitiveAgreement,
                                                )
                                            }
                                        />
                                        <label htmlFor="school-record-modal-checkbox-2">
                                            민감정보 수집 및 이용에 동의합니다.
                                        </label>
                                    </div>
                                </div>
                                <div className="button-group">
                                    {privateAgreement && sensitiveAgreement ? (
                                        <button
                                            className="submit-btn"
                                            onClick={uploadSchoolRecord}
                                        >
                                            제출하기
                                        </button>
                                    ) : (
                                        <button className="submit-btn" disabled>
                                            제출하기
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </Modal.Body>
                </Modal>
            </>
        )
    );
}
