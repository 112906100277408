import { RoundType, RoundTypes } from './core';

const images = {} as {
    [key: string]: {
        [key: string]: string;
    };
};

(function initialize() {
    const roundTypePathMap = {
        wordclouds: RoundTypes.WordCloud,
        'related-majors': RoundTypes.RelatedMajor,
        icons: RoundTypes.Image,
        'recommended-books': RoundTypes.RecommendedBook,
    } as {
        [key: string]: string;
    };

    Object.values(roundTypePathMap).forEach((v) => {
        images[v] = {};
    });

    const importedImages = require.context(
        'src/assets/images/major-category-exploration',
        true,
        /\.(png)$/,
    );

    importedImages.keys().forEach((image) => {
        const splittedPath = image.split('/');
        const roundTypeInPath = splittedPath[1];
        const categoryName = splittedPath[2].replace('.png', '');

        images[roundTypePathMap[roundTypeInPath]][categoryName] =
            importedImages(image);
    });
})();

export function getParticipantImage({
    type,
    categoryName,
}: {
    type: RoundType;
    categoryName: string;
}) {
    return images[type][categoryName];
}
