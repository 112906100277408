import '@styles/pages/majorCategoryExplorationTournament.scss';
import { useNavigate } from 'react-router-dom';
import { useMajorCategoryExplorationFacade } from '@facades';
import { PATH } from 'src/ProjectRoutes';

export default function InitializorPage() {
    const { isLoaded, exploration } = useMajorCategoryExplorationFacade();

    const navigate = useNavigate();

    function navigateToMain() {
        navigate(PATH.LANDING);
    }

    function navigateToExploraitonPage() {
        navigate(PATH.MAJOR_CATEGORY_EXPLORATION);
    }

    function navigateToResultPage() {
        navigate(PATH.MAJOR_CATEGORY_EXPLORATION_RESULT);
    }

    return (
        <>
            <div className="body-wrapper">
                <div className="body-container mx-auto">
                    <header className="py-5">
                        <div className="go-back-btn-container container">
                            <div
                                onClick={navigateToMain}
                                className="go-back-btn fs-4 fw-normal"
                            >
                                <span className="material-icons fs-4">
                                    arrow_back_ios
                                </span>
                                <span>나가기</span>
                            </div>
                        </div>
                        <div className="text-center">
                            <h1 className="mt-1 fw-bold">계열선택검사</h1>
                        </div>
                    </header>

                    <section className="bg-white rounded-4 p-5">
                        <div className="border border-purple my-5 rounded-4 py-4 px-5 mx-5">
                            <div className="text-center mb-5">
                                <h4 className="fw-bold">검사 안내</h4>
                            </div>
                            <div className="lh-lg">
                                <ul className="list-disc">
                                    <li>
                                        학쫑프로의 계열선택검사는 여러분의
                                        흥미와 적성을 고려하여 학과를 선택하는
                                        데 도움을 주기 위해 만들어졌습니다.
                                        (예체능 계열 제외)
                                    </li>
                                    <li>
                                        문항 수는 약 200문항이며, 검사에는 시간
                                        제한이 없으나 보통 10 ~ 15분 정도
                                        소요됩니다.
                                    </li>
                                    <li>
                                        준비가 완료되면 ‘검사 시작’ 버튼을
                                        클릭하세요.
                                    </li>
                                    <li className="text-[#EE4444]">
                                        검사는 계정당 1회 가능합니다.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="text-center pb-5 mb-5">
                            {isLoaded &&
                                (exploration ? (
                                    <button
                                        onClick={navigateToResultPage}
                                        className="text-white border-0 py-2 px-5 rounded-3 bg-purple"
                                        type="button"
                                    >
                                        결과 보기
                                    </button>
                                ) : (
                                    <button
                                        onClick={navigateToExploraitonPage}
                                        className="text-white border-0 py-2 px-5 rounded-3 bg-purple"
                                        type="button"
                                    >
                                        검사 시작
                                    </button>
                                ))}
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
