import { client } from './client';

export async function callFetchAssignmentReport({
    studentId,
    assignmentReportId,
}: {
    studentId: StudentId;
    assignmentReportId: AssignmentReportId;
}) {
    const { data } = await client.get(
        `/assignment_app/assignment-reports/${assignmentReportId}/`,
        {
            params: {
                clientStudentId: studentId,
            },
        },
    );
    return data;
}
