import '@styles/pages/assignmentPage.scss';

import { useEffect, useState } from 'react';
import { Container, Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '@store';
import AssignmentCard from '@components/AssignmentCard';
import {
    useAssignmentFacade,
    useIssuedSubjectFacade,
    useLeanAIMetadataFacade,
} from '@facades';
import { PATH } from '../ProjectRoutes';
import { useAssignmentSummary } from './hooks';

function StudentDetailDisplay() {
    const student = useAppSelector((state) => state.student);

    return student ? (
        <Container className="user-info-container">
            <div className="user-info-box">
                <span className="user-info-label">고유코드</span>
                <span className="user-info-text">{student.code}</span>
                <span className="user-info-label">학교</span>
                <span className="user-info-text">{student.school}</span>
                <span className="user-info-label">입학년도</span>
                <span className="user-info-text">
                    {student.highSchoolEnteringYear
                        ? `${student.highSchoolEnteringYear}학년도`
                        : '-'}
                </span>
            </div>
            <div className="user-info-box">
                <span className="user-info-label">이름</span>
                <span className="user-info-text">{student.name}</span>
                <span className="user-info-label">학년</span>
                <span className="user-info-text">{student.grade}</span>
                <span className="user-info-label">연락처</span>
                <span className="user-info-text">{student.phoneNumber}</span>
                <span className="user-info-label">학부모 연락처</span>
                <span className="user-info-text">
                    {student.parentPhoneNumber || '-'}
                </span>
                <span className="user-info-label">성별</span>
                <span className="user-info-text">
                    {student.gender === 'm' ? '남자' : '여자'}
                </span>
            </div>
        </Container>
    ) : (
        <></>
    );
}

const AssignmentProgressingStepValue: AssignmentProgressingStep = {
    BeforeSubmit: '진행 중 과제',
    AfterSubmit: '대기 중 과제',
    Completed: '완료 된 과제',
};

const assignmentProgressingProperties: AssignmentProgressProperty[] = [
    {
        label: AssignmentProgressingStepValue.BeforeSubmit,
        key: 'beforeSubmit',
        params: {
            isBeforeSubmit: true,
            isCompleted: false,
        },
    },
    {
        label: AssignmentProgressingStepValue.AfterSubmit,
        key: 'afterSubmit',
        params: {
            isRequested: true,
            isSubmitted: true,
            isCompleted: false,
        },
    },
    {
        label: AssignmentProgressingStepValue.Completed,
        key: 'completed',
        params: {
            isCompleted: true,
        },
    },
];

function AssignmentProgressingSummaryDisplay({
    summary,
    selectedAssignmentProperty,
    onTabClick = (property) => {},
}: {
    summary: AssignmentProgressingSummary;
    selectedAssignmentProperty: AssignmentProgressProperty;
    onTabClick: (property: AssignmentProgressProperty) => void;
}) {
    function handleTabClick(property: AssignmentProgressProperty) {
        onTabClick(property);
    }

    return (
        <>
            <div className="assignment-tab-container d-none d-sm-none d-lg-flex">
                {assignmentProgressingProperties.map((property, index) => (
                    <div
                        key={index}
                        onClick={() => handleTabClick(property)}
                        className={`assignment-tab ${
                            selectedAssignmentProperty.label === property.label
                                ? 'active'
                                : ''
                        }`}
                    >
                        {property.label} ({summary[property.key]})
                    </div>
                ))}
            </div>
        </>
    );
}

function AssignmentProgressingSummaryDropdown({
    summary,
    selectedAssignmentProperty,
    onItemClick = (property) => {},
}: {
    summary: AssignmentProgressingSummary;
    selectedAssignmentProperty: AssignmentProgressProperty;
    onItemClick: (property: AssignmentProgressProperty) => void;
}) {
    return (
        <Dropdown className="d-sm-block d-lg-none d-xl-none">
            <Dropdown.Toggle variant="" id="dropdown-basic">
                {selectedAssignmentProperty.label} (
                {summary[selectedAssignmentProperty.key]})
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {assignmentProgressingProperties.map((property, index) => (
                    <Dropdown.Item
                        onClick={() => onItemClick(property)}
                        key={index}
                    >
                        {property.label} ({summary[property.key]})
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function setRelatedAssignmentsToIssuedSubjects(
    issuedSubjects: IssuedSubjectSummary[],
    assignments: Assignment[],
) {
    return issuedSubjects.map((issuedSubject) => {
        issuedSubject.assignments = assignments
            .filter(
                (assignment) => issuedSubject.id === assignment.issuedSubjectId,
            )
            .sort((a, b) => a.id - b.id);
        return issuedSubject;
    });
}

function sortWithGivenIds(
    ids: number[],
    issuedSubjects: IssuedSubjectSummary[],
) {
    const indexed: {
        [k: number]: IssuedSubjectSummary;
    } = {};
    issuedSubjects.forEach((e) => (indexed[e.id] = e));
    return ids.map((id) => indexed[id]);
}

export default function AssignmentPage() {
    const student = useAppSelector((state) => state.student);

    const navigate = useNavigate();
    const gotoMainPage = () => {
        navigate(PATH.MAIN);
    };
    const { grade, semester } = useParams();
    const { listAssignmentIndexes, listAssignments } = useAssignmentFacade();
    const leanAIMetadata = useLeanAIMetadataFacade().get();
    const { getIssuedSubjects } = useIssuedSubjectFacade(leanAIMetadata);

    // summary related
    const { summary, init: initSummary } = useAssignmentSummary();
    const defaultParams = {
        clientStudentId: student.id,
        student: student.id,
        semester,
        grade,
    };

    // assignment related
    const [selectedAssignmentProperty, setSelectedAssignmentProperty] =
        useState(assignmentProgressingProperties[0]);

    const [issuedSubjects, setIssuedSubjects] = useState<
        IssuedSubjectSummary[]
    >([]);

    async function initAssignmentIndexes(
        params: any,
    ): Promise<Paginated<AssignmentIndex>> {
        return await listAssignmentIndexes(params);
    }

    async function initIssuedSubjects(
        params: any,
    ): Promise<Paginated<IssuedSubjectSummary>> {
        return await getIssuedSubjects(params);
    }

    async function initAssignments(params: any): Promise<Assignment[]> {
        return await listAssignments(params);
    }

    async function initPage() {
        const { results: assignmentIndexes } = await initAssignmentIndexes({
            ...selectedAssignmentProperty.params,
            ...defaultParams,
        });

        if (assignmentIndexes.length > 0) {
            const issuedSubjectIds = assignmentIndexes.map(
                (a) => a.issuedSubjectId,
            );

            const { results: issuedSubjects } = await initIssuedSubjects({
                id: issuedSubjectIds.join(','),
                ...defaultParams,
            });

            const assignments = await initAssignments({
                issuedSubjectId: issuedSubjects
                    .map((i) => (i as IssuedSubjectSummary).id)
                    .join(','),
                clientStudentId: student.id,
            });

            const realtionSetIssuedSubjects =
                setRelatedAssignmentsToIssuedSubjects(
                    issuedSubjects,
                    assignments,
                );

            const result = sortWithGivenIds(
                issuedSubjectIds,
                realtionSetIssuedSubjects,
            );

            setIssuedSubjects(result);
        } else {
            setIssuedSubjects([]);
        }
    }

    async function init() {
        initPage();
        initSummary(defaultParams);
    }

    useEffect(() => {
        init();
    }, [grade, semester, selectedAssignmentProperty]);

    return (
        <div className="page-base">
            <Container className="go-back-btn-container">
                <div className="go-back-btn" onClick={gotoMainPage}>
                    <span className="material-icons">arrow_back_ios</span>
                    <span> 뒤로가기</span>
                </div>
            </Container>
            <StudentDetailDisplay />
            <Container className="assignment-page-container">
                <div className="content-title">
                    {grade}학년 {semester}학기
                </div>
                <AssignmentProgressingSummaryDisplay
                    summary={summary}
                    selectedAssignmentProperty={selectedAssignmentProperty}
                    onTabClick={setSelectedAssignmentProperty}
                />
                <AssignmentProgressingSummaryDropdown
                    summary={summary}
                    selectedAssignmentProperty={selectedAssignmentProperty}
                    onItemClick={setSelectedAssignmentProperty}
                />
                <div className="content-container">
                    <Container className="content-box col-sm-12">
                        {issuedSubjects.map((issuedSubject) => (
                            <AssignmentCard
                                key={issuedSubject.id}
                                issuedSubject={issuedSubject}
                                onAssignmentReportUpdated={init}
                            />
                        ))}
                    </Container>
                    {/*<Pagination />*/}
                </div>
            </Container>
        </div>
    );
}
