import React from 'react';

export default function Container({
    children,
    ...rest
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    rest.className = `container-fluid bg-light ${rest.className || ''}`;
    return (
        <div {...rest}>
            <div className={'row p-5'}>{children}</div>
        </div>
    );
}
