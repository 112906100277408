import { client } from './client';

export async function callGetRecommendation({
    issuedSubjectId,
    type,
} : {
    issuedSubjectId: IssuedSubjectId,
    type: RecommendationType,
}) {
    const { data } = await client.get(
        '/students_app/supporters/recommendation/',
        {
            params: {
                issuedSubjectId,
                type,
            },
        },
    );
    return data;
}
