import { useState, useEffect } from 'react';
import { useLeanAIMetadataFacade } from '@facades';
import { useSearchParams } from 'react-router-dom';

export function useMajorCategoryQueryParams() {
    const [majorCategories, setMajorCategories] = useState<MajorCategory[]>([]);
    const leanAIMetadataFacade = useLeanAIMetadataFacade();
    const leanAIMetadata = leanAIMetadataFacade.get();
    const [searchParams] = useSearchParams();
    const majorCategoryIds = (searchParams.get('majorCategoryIds') || '')
        .split(',')
        .map((id) => +id);

    useEffect(() => {
        if (leanAIMetadataFacade.isReady && majorCategoryIds) {
            setMajorCategories(
                majorCategoryIds.map(
                    (id) => leanAIMetadata.majorCategories[id],
                ),
            );
        }
    }, [leanAIMetadataFacade.isReady]);

    return {
        majorCategories,
        majorCategoryIds,
    };
}
