import { client } from './client';
import {
    SAT_ORDER_DISCOUNT_AMOUNT,
    SAT_ORDER_ORIGINAL_PRICE,
} from '@constants/products';

export async function callGetOrderInfo(orderId: string) {
    const { data } = await client.get(`/ticket_mall_app/orders/${orderId}/`);
    return {
        ...data,
        originalPrice: SAT_ORDER_ORIGINAL_PRICE,
        discountAmount: SAT_ORDER_DISCOUNT_AMOUNT,
    };
}
