import { Modal } from 'react-bootstrap';
import React from 'react';

import 'src/assets/scss/components/schoolGradeModal.scss';

export default function SchoolGradeModal({
    modalShow,
    closeModal,
    data,
}: {
    modalShow: boolean;
    closeModal: () => void;
    data: Array<SchoolGrade> | null;
}) {
    return (
        <Modal
            show={modalShow}
            onHide={closeModal}
            className="school-grade-modal-base"
            centered
        >
            <Modal.Header className="school-grade-modal-header">
                <div
                    className="material-icons modal-close-button"
                    onClick={closeModal}
                >
                    close
                </div>
                <div className="modal-title">내신 등급 확인</div>
            </Modal.Header>
            <Modal.Body className="school-grade-modal-body">
                <table className="school-grade-info-container table">
                    <thead>
                        <tr>
                            <th>과목계열</th>
                            <th>과목명</th>
                            <th>등급</th>
                            <th>이수단위</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((row, i) => (
                                <tr key={i}>
                                    <td>{row['과목계열']}</td>
                                    <td>{row['과목명']}</td>
                                    <td>{row['등급']}</td>
                                    <td>{row['이수단위']}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}
