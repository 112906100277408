import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '@styles/pages/recordAnalysisPage.scss';
import { useGradeFacade } from '@facades';
import { useAppSelector } from '@store';
import { PATH, resolveRoute } from '../ProjectRoutes';
import SchoolRecordAnalysisDiagnosisImageCard from '@components/SchoolRecordAnalysisDiagnosisImageCard';
import getAnalysisProposalDiagnosisTemplate from '@pages/helpers';

export default function SchoolRecordAnalysisResultPage() {
    const navigate = useNavigate();
    const { grade: _grade } = useParams();
    const student = useAppSelector((state) => state.student);
    const gradeFacade = useGradeFacade();
    const gotoMainPage = () => {
        navigate(PATH.MAIN);
    };

    const [analysisProposal, setAnalysisProposal] =
        useState<SchoolRecordAnalysisProposal | null>();
    const [schoolSubjects, setSchoolSubjects] = useState<string[]>();
    const [diagnosisTemplate, setDiagnosisTemplate] =
        useState<SchoolRecordAnalysisDiagnosisTemplate>(
            {} as SchoolRecordAnalysisDiagnosisTemplate,
        );

    const keywords = (
        (analysisProposal?.keywords ?? []) as unknown as { keyword: string }[]
    ).map(({ keyword }) => keyword);

    useEffect(
        function loadSchoolRecordAnalysis() {
            if (!_grade) {
                return;
            }

            (async function () {
                const proposal =
                    await gradeFacade.getProposedSchoolRecordAnalysisProposal(
                        parseInt(_grade),
                    );
                setAnalysisProposal(proposal);
            })();
        },
        [_grade],
    );

    useEffect(
        function getSchoolSubjectSet() {
            const schoolSubjects: string[] = [];
            analysisProposal?.selectedSentences.forEach(({ schoolSubject }) => {
                if (!schoolSubjects.includes(schoolSubject)) {
                    schoolSubjects.push(schoolSubject);
                }
            });
            setSchoolSubjects(schoolSubjects);
        },
        [analysisProposal],
    );

    useEffect(
        function loadAnalysisProposalDiagnosis() {
            if (analysisProposal) {
                (async function () {
                    const diagnosis =
                        await gradeFacade.getAnalysisProposalDiagnosis(
                            analysisProposal.id,
                        );
                    const diagnosisTemplate =
                        getAnalysisProposalDiagnosisTemplate({
                            quantity: diagnosis.quantity,
                            quality: diagnosis.quality,
                        });
                    if (diagnosisTemplate) {
                        setDiagnosisTemplate(diagnosisTemplate);
                    }
                })();
            }
        },
        [analysisProposal],
    );

    function gotoSchoolRecordPage() {
        navigate(resolveRoute(PATH.SCHOOL_RECORD));
    }

    function renderSentencesOfSchoolSubjects() {
        return (
            <>
                {schoolSubjects?.map((schoolSubject, index) => (
                    <div className="mb-5" key={index}>
                        <div className="mb-2 text-center mx-auto">
                            <span className="border text-center mb-2 rounded-3 py-1 px-4">
                                {schoolSubject}
                            </span>
                        </div>
                        <div className="lh-lg">
                            {analysisProposal?.selectedSentences
                                .filter(
                                    ({ schoolSubject: ss }) =>
                                        ss === schoolSubject,
                                )
                                .map(({ sentence }, index) => (
                                    <div
                                        className="d-flex align-items-center"
                                        key={index}
                                    >
                                        <span className="material-icons-outlined me-3">
                                            check
                                        </span>
                                        {highlightKeywordInSentence(
                                            sentence,
                                            keywords,
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </>
        );
    }
    function highlightKeywordInSentence(sentence: string, keywords: string[]) {
        for (const keyword of keywords) {
            const matching = sentence.match(keyword);
            if (matching) {
                const parts = sentence.split(new RegExp(`(${keyword})`, 'gi'));
                return (
                    <span key={keyword}>
                        {parts.map((part, i) => {
                            return part.toLowerCase() ===
                                keyword.toLowerCase() ? (
                                <span className="text-danger fw-bold" key={i}>
                                    {part}
                                </span>
                            ) : (
                                part
                            );
                        })}
                    </span>
                );
            }
        }
        return (
            <React.Fragment key={sentence}>
                <span>{sentence}</span>
            </React.Fragment>
        );
    }

    return (
        <div className="container" style={{ minHeight: '90vh' }}>
            <div className="d-flex justify-content-between my-4 align-items-center">
                <div className="col ms-5">
                    <button
                        className="d-flex text-primary bg-transparent fs-5 border-0 align-items-center"
                        onClick={() => gotoSchoolRecordPage()}
                    >
                        <span className="material-icons-outlined">
                            {' '}
                            arrow_back_ios{' '}
                        </span>
                        목록으로
                    </button>
                </div>
                <div className="col text-center">
                    <h2 className="mb-0">생기부 진단 확인 - {_grade}학년</h2>
                </div>
                <div className="col text-end me-5"></div>
            </div>
            <div className="bg-white rounded-4 mt-3 pt-3 pb-1 px-5">
                <div className="col text-center">
                    <h5>진로 관련 문장</h5>
                </div>
                <div className="col border-top-blue mt-3 text-black-50 px-5 pt-3">
                    {renderSentencesOfSchoolSubjects()}
                </div>
            </div>
            <div className="bg-white rounded-4 mt-3 py-3 px-5">
                <div className="col text-center">
                    <h5>생기부 주요 키워드</h5>
                </div>
                <div className="row border-top-blue py-2 px-3">
                    {keywords.map((keyword, i) => (
                        <div
                            className="border-blue rounded-3 bg-white px-3 py-2 mx-3 col-auto my-1"
                            key={i}
                        >
                            {keyword}
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-white rounded-4 mt-3 py-3 px-5">
                <div className="col text-center">
                    <h5>생기부 진단 결과</h5>
                </div>
                <div className="col border-top-blue mt-3 py-3 px-5">
                    <div className="col text-center">
                        <span className="text-blue">
                            {diagnosisTemplate.title}
                        </span>
                    </div>
                    <div className="col my-2 text-center">
                        <h2 className="text-blue">{diagnosisTemplate.idiom}</h2>
                    </div>
                    <div className="col fw-semibold text-center">
                        <span>
                            {student.name} 학생의 세특은
                            <span className="text-blue">
                                {diagnosisTemplate.ranking}
                            </span>
                            입니다.
                        </span>
                    </div>
                    <div className="col my-4">
                        <span className="text-black-50">
                            {diagnosisTemplate.description}
                        </span>
                    </div>
                    <SchoolRecordAnalysisDiagnosisImageCard
                        position={diagnosisTemplate.position}
                    />
                </div>
            </div>
            <div className="col text-end me-5 my-5">
                <button
                    className="bg-primary text-white rounded-3 border-0 py-2 px-5"
                    onClick={() => gotoMainPage()}
                >
                    확인
                </button>
            </div>
        </div>
    );
}
