import styled from 'styled-components';
import {useContext, useEffect, useState} from 'react';
import {DescriptionOutlined, Save} from '@mui/icons-material';

import {useAppSelector} from '@store';
import {replaceStep} from '@pages/pro12/helpers';
import Pro12Context from '../Pro12Context';
import {useLocation, useNavigate} from 'react-router-dom';

const defaultStyle = {
    textDecoration: 'none',
    color: 'unset',
};

const Active = styled.a`
    color: var(--bs-primary) !important;
    font-weight: var(--bs-font-weight-bold);
    border-bottom: 2px solid var(--bs-primary);
    font-weight: bold;
    margin-bottom: -2px;
`;

const Inactive = styled.a``;

function Step(props: {
    title: string;
    active?: boolean;
    onClick?: () => void;
}) {
    const isActive = 'active' in props ? props.active : false;
    const className = 'text-decoration-none col py-2';

    return isActive ? (
        <Active href={'#'} style={defaultStyle} className={className}>
            {props.title}
        </Active>
    ) : (
        <Inactive
            href={'#'}
            style={defaultStyle}
            className={className}
            onClick={(e) => {
                e.preventDefault();
                props.onClick && props.onClick();
            }}
        >
            {props.title}
        </Inactive>
    );
}

export default function Toolbar() {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        assignment,
        updateAssignmentReport,
        createAssignmentReport,
        steps,
        currentStep,
        eventEmitter,
    } = useContext(Pro12Context);
    const data = useAppSelector(({ pro12 }) => pro12);
    const [doTempSave, setDoTempSave] = useState(false);

    async function patch() {
        await updateAssignmentReport({
            ...data,
            isCompleted: false,
        });
        alert('임시 저장하였습니다.');
    }

    async function create() {
        await createAssignmentReport({
            ...data,
            isCompleted: false,
        });
        alert('임시 저장하였습니다.');
    }

    function handleTemporalSaveButtonClick() {
        if (!assignment) {
            return;
        }

        eventEmitter.emit('before-temp-save');
        setDoTempSave(true);
    }

    useEffect(() => {
        if (!doTempSave) {
            return;
        }
        setDoTempSave(false);
        const caller = assignment?.report ? patch : create;
        caller();
    }, [doTempSave, assignment?.report]);

    const stepTitles = steps.map(({ title }: { title: string }) => title);

    function onStepClick(step: number) {
        navigate(replaceStep(location, step, '#A'));
    }

    const showTempSaveButton = !data.isCompleted;

    return (
        <div>
            <div className="row align-items-center justify-content-between  mb-3">
                <div className="col-auto d-flex fs-3">
                    <span>
                        <DescriptionOutlined className={'me-2'} />
                        과제 설계
                    </span>
                </div>

                <div className="col-6 d-flex border-bottom border-2 text-center">
                    {stepTitles.map((title, step, steps) => (
                        <Step
                            key={step}
                            title={title}
                            active={step === currentStep}
                            onClick={
                                step < currentStep
                                    ? () => onStepClick(step)
                                    : undefined
                            }
                        />
                    ))}
                </div>

                <div className="col-auto">
                    {showTempSaveButton && (
                        <button
                            onClick={() => handleTemporalSaveButtonClick()}
                            type="button"
                            className="d-flex bg-white border border-primary text-primary rounded-5 py-2 px-4"
                        >
                            <Save className="me-2" />
                            <span>임시 저장</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
