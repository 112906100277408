import React, { useEffect, useState } from 'react';

import { pro12Slice, useAppDispatch } from '@store';
import { useBeforeTempSave } from '../hooks';
import { Navigator } from '../components';
import Summary from './Summary';
import { ContentSummaryForm, RetrospectionForm } from './types';
import LearningPointAndRetrospection from './LearningPointAndRetrospection';
import { scrollToSection } from '@pages/pro12/helpers';

export default function ResearchConclusionPage() {
    const dispatch = useAppDispatch();

    const [contentSummaryList, setContentSummaryList] = useState<
        ContentSummaryForm[]
    >([]);
    const [retrospectionList, setRetrospectionList] = useState<
        RetrospectionForm[]
    >([]);

    const [
        shouldShowValidationErrorMessage,
        setShouldShowValidationErrorMessage,
    ] = useState(false);
    const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);
    const [
        isLearningPointAndRetrospectionCompleted,
        setIsLearningPointAndRetrospectionCompleted,
    ] = useState(false);
    const [shouldFocus, setShouldFocus] = useState<boolean>(false);

    function storeForm() {
        dispatch(
            pro12Slice.actions.setResearchContentSummaryList(
                contentSummaryList,
            ),
        );
        dispatch(
            pro12Slice.actions.setResearchRetrospectionList(retrospectionList),
        );
    }

    useBeforeTempSave(storeForm);

    const isValid =
        isSummaryCompleted && isLearningPointAndRetrospectionCompleted;

    useEffect(
        function focusOnError() {
            if (shouldFocus) {
                if (!isSummaryCompleted) {
                    scrollToSection('#A');
                }
                setShouldFocus(false);
            }
        },
        [shouldFocus],
    );

    return (
        <>
            <Summary
                setContentSummaryList={setContentSummaryList}
                setCompletion={setIsSummaryCompleted}
                shouldShowValidationErrorMessage={
                    shouldShowValidationErrorMessage && !isSummaryCompleted
                }
            />
            <LearningPointAndRetrospection
                setRetrospectionList={setRetrospectionList}
                setCompletion={setIsLearningPointAndRetrospectionCompleted}
                shouldShowValidationErrorMessage={
                    shouldShowValidationErrorMessage &&
                    !isLearningPointAndRetrospectionCompleted
                }
            />
            <Navigator
                onNextStepClick={(next) => {
                    if (isValid) {
                        storeForm();
                        next();
                    } else {
                        setShouldFocus(true);
                        setShouldShowValidationErrorMessage(true);
                    }
                }}
                onPreviousStepClick={(prev) => {
                    storeForm();
                    prev();
                }}
            />
        </>
    );
}
