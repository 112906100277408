import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/ProjectRoutes';

export function useNavigateToMainPage() {
    const navigate = useNavigate();
    return function () {
        navigate(PATH.MAIN);
    };
}

export function useMajorCategories(leanAIMetadata: LeanAIMetadata) {
    const { majorCategories } = leanAIMetadata;
    const reshapedMajorCategories = {} as {
        [key: string]: {
            [key: string]: string;
        } & MajorCategory;
    };

    Object.entries(majorCategories).forEach(([id, data]) => {
        reshapedMajorCategories[data.category] = { id, ...data };
    });

    return reshapedMajorCategories;
}
