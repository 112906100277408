import { Container,  } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import '@styles/pages/issuedSubjectDetailPage.scss';
import IssuedSubjectDetail from '@components/IssuedSubjectDetail';

export default function IssuedSubjectDetailPage() {
    const { id: _issuedSubjectId } = useParams();

    const navigate = useNavigate();
    function goBack() {
        navigate(-1);
    }

    return (
        <div id="issuedSubjectDetailPage" className="page-base">
            <Container className="go-back-btn-container no-print">
                <div className="go-back-btn" onClick={goBack}>
                    <span className="material-icons">arrow_back_ios</span>
                    <span>뒤로가기</span>
                </div>
            </Container>
            <Container className="subject-page-container">
                <IssuedSubjectDetail issuedSubjectId={Number(_issuedSubjectId)} />
            </Container>
        </div>
    )
}
