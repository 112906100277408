import { DeleteOutline } from '@mui/icons-material';
import { getIndexInKorean } from '@pages/pro12/helpers';

export default function ToughPointCard({
    toughPointDescription,
    overcomingMethodDescription,
    index,
    removeCard,
}: {
    toughPointDescription: string;
    overcomingMethodDescription: string;
    index: number;
    removeCard?: (index: number) => void;
}) {
    const indexInKorean = getIndexInKorean(index);

    return (
        <div className={'row align-items-center'}>
            <div className="col-11">
                {index === 0 && (
                    <p className="mb-1">
                        탐구 과정에서 어려웠던 지점은 다음과 같다.
                    </p>
                )}
                <p className="mb-0">
                    ({indexInKorean}),
                    <span className="text-primary">
                        {toughPointDescription}
                    </span>{' '}
                    이를 해결하기 위해{' '}
                    <span className="text-primary">
                        {overcomingMethodDescription}
                    </span>
                </p>
            </div>
            {removeCard && (
                <div className={'col-1'}>
                    <button
                        type={'button'}
                        className={'btn btn-outline btn-sm'}
                        onClick={() => removeCard(index)}
                    >
                        <DeleteOutline className={'text-danger'} />
                    </button>
                </div>
            )}
        </div>
    );
}
