import { useState } from 'react';
import clsx from 'clsx';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

export default function MajorSelectModal({
    modalShow,
    closeModal,
    onSelect,
    universityOptions,
    majorOptions,
}: {
    modalShow: boolean;
    closeModal: () => void;
    onSelect: (major: Major) => void;
    universityOptions: LeanAIMetadata['universities'];
    majorOptions: LeanAIMetadata['majors'];
}) {
    const [majorsOfSelectedUniversity, setMajorsOfSelectedUniversity] =
        useState<Major[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<Major | null>(null);

    const universitiesWithMajors = Object.entries(universityOptions).filter(
        ([id, _]) => {
            return getCorrespondingMajors(+id).length > 0;
        },
    );

    function getCorrespondingMajors(universityId: number) {
        const matchingMajors: Major[] = [];
        Object.entries(majorOptions).forEach(([majorId, major]) => {
            if (+major.university === universityId) {
                matchingMajors.push({ ...major, id: majorId });
            }
        });

        return matchingMajors;
    }

    function selectUniversity(id: number) {
        const majors = getCorrespondingMajors(id);

        if (majors.length > 0) {
            setMajorsOfSelectedUniversity(majors);
        }
    }

    function selectMajor(major: Major) {
        setSelectedMajor(major);
    }

    function selectAndClose() {
        if (selectedMajor === null) {
            return;
        }
        onSelect(selectedMajor);
        setSelectedMajor(null);
        closeModal();
    }
    return (
        <Modal show={modalShow} onHide={closeModal} centered>
            <button
                className="absolute top-5 right-8 w-fit material-icons text-purple"
                onClick={closeModal}
                type="button"
            >
                close
            </button>
            <div className="px-10 pt-20 pb-60 space-y-10">
                <h1 className="text-center text-3xl font-bold">
                    희망 대학/학과 선택
                </h1>
                <div className="space-y-2">
                    <p className="text-[#828282] text-lg font-medium">
                        희망 대학
                    </p>
                    <Select
                        onChange={(e) => {
                            selectUniversity(+e!.value);
                        }}
                        options={universitiesWithMajors.map(
                            ([id, nameObject]) => ({
                                value: id,
                                label: nameObject.name,
                            }),
                        )}
                    />
                </div>
                <div className="space-y-2">
                    <p className="text-[#828282] text-lg font-medium">
                        희망 학과
                    </p>
                    <Select
                        onChange={(e) => {
                            selectMajor(e!.value);
                        }}
                        options={majorsOfSelectedUniversity.map((major) => ({
                            value: major,
                            label: major.name,
                        }))}
                    />
                </div>
                <button
                    className={clsx(
                        'w-full text-xl font-bold rounded-lg py-3',
                        selectedMajor
                            ? 'bg-purple text-white'
                            : '!bg-[#DDD] !text-[#999]',
                    )}
                    onClick={() => {
                        selectAndClose();
                    }}
                    disabled={!selectedMajor}
                >
                    선택 완료
                </button>
            </div>
        </Modal>
    );
}
