import { client } from './client';

const NEW_MAJOR_CATEGORY_NAMES: Record<string, string> = {
    재활학: '재활치료',
    유아교육학: '유아교육',
    초등교육학: '초등교육',
    특수교육학: '특수교육',
    '반도체·세라믹공학': '반도체공학',
};

function patchMajorCategoriesWithNewName(leanAIMetadata: any) {
    Object.entries(leanAIMetadata.majorCategories).forEach(
        ([id, value]: [string, any]) => {
            value.category = getNewMajorCategoryName(value.category);
        },
    );

    Object.values(leanAIMetadata.majors).forEach((major: any) => {
        major.categories.forEach((categoryMap: any) => {
            const categoryObj = Object.values(categoryMap)[0] as any;
            categoryObj.category = getNewMajorCategoryName(
                categoryObj.category,
            );
        });
    });
}

function getNewMajorCategoryName(name: string) {
    return NEW_MAJOR_CATEGORY_NAMES[name] ?? name;
}

export async function callFetchLeanAIMetadata() {
    const { data } = await client.get('/static/lean_ai_metadata.json');

    patchMajorCategoriesWithNewName(data);

    return data;
}
