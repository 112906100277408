import { client } from './client';

export async function callDeleteKeyword({
    studentId,
    keyword,
}: {
    studentId: StudentId;
    keyword: string;
}) {
    await client.delete(
        `/students_app/supporters/keywords/`,

        { params: { clientStudentId: studentId, keywords: keyword } },
    );
}
