import { UseFormReturn } from 'react-hook-form';
import Step1 from '@pages/pro12/ResearchMainPage/Step1';
import Step2 from '@pages/pro12/ResearchMainPage/Step2';
import React from 'react';

export function TPOMDraft({
    setToughPointFormRef,
    setOvercomingMethodFormRef,
    validator,
}: {
    setToughPointFormRef: (form: UseFormReturn<ToughPointForm>) => void;
    setOvercomingMethodFormRef: (
        form: UseFormReturn<OvercomingMethodForm>,
    ) => void;
    validator: () => void;
}) {
    return (
        <>
            <Step1 setFormRef={setToughPointFormRef} />
            <Step2 setFormRef={setOvercomingMethodFormRef} />
            <div className={'row justify-content-center mt-3'}>
                <div className={'col-auto'}>
                    <button
                        type={'submit'}
                        onClick={() => validator()}
                        className={
                            'btn btn-outline-primary rounded-5 py-2 px-5'
                        }
                    >
                        선택 및 작성 완료
                    </button>
                </div>
            </div>
            <div className="col text-center mt-1">
                <span className="text-danger small">
                    버튼을 클릭해야 작성이 완료됩니다.
                </span>
            </div>
        </>
    );
}
