import { useState, useEffect } from 'react';
import { useAppSelector } from '@store';
import {
    callFetchMajorCategoryExploration,
    callCreateMajorCategoryExploration,
    callFetchMajorCategoryExplorationQuestionnaires,
} from '@client';

export function useMajorCategoryExplorationFacade() {
    const student = useAppSelector((state) => state.student);
    const [isLoaded, setIsLoaded] = useState(false);
    const [exploration, setExploration] = useState<Exploration | null>(null);
    const [questionnaires, setQuestionanires] = useState<Record<
        number,
        string[]
    > | null>(null);

    async function load() {
        try {
            const { results } = await callFetchMajorCategoryExploration(
                student.id,
            );
            if (results.length > 0) {
                setExploration(results[0]);
            }
        } finally {
            setIsLoaded(true);
        }
    }

    async function loadQuestionnaires() {
        const data = await callFetchMajorCategoryExplorationQuestionnaires();
        setQuestionanires(data);
    }

    async function create(studentId: number, majorCategoryIds: number[]) {
        const exploration = await callCreateMajorCategoryExploration(
            studentId,
            majorCategoryIds,
        );
        setExploration(exploration);
        setIsLoaded(true);
    }

    useEffect(() => {
        load();
        loadQuestionnaires();
    }, []);

    return {
        questionnaires,
        exploration,
        isLoaded,
        load,
        create,
    };
}
