import { client } from './client';

export async function callCreateKeyword({
    studentId,
    keywords,
}: {
    studentId: StudentId;
    keywords: string[];
}) {
    await client.post(
        `/students_app/supporters/keywords/`,
        {
            keywords,
        },
        { params: { clientStudentId: studentId } },
    );
}
