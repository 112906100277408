import { client } from './client';

export async function callFetchIssuedSubjectDetail({
    clientStudentId,
    issuedSubjectId,
}: {
    clientStudentId: StudentId;
    issuedSubjectId: IssuedSubjectId;
}) {
    const { data } = await client.get(
        `/students_app/supporters/issued-subjects/${issuedSubjectId}/`,
        {
            params: {
                clientStudentId,
            },
        },
    );
    return {
        ...data,
        issuedAt: new Date(data.issuedAt),
    };
}
