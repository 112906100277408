import TextRadio from '../../components/TextRadio';
import TextInputGathering from '../../components/TextInputGathering';
import { UseFormReturn } from 'react-hook-form';
import { RetrospectionForm } from '@pages/pro12/ResearchConclusionPage/types';

export default function CareerRelated({
    form,
}: {
    form: UseFormReturn<RetrospectionForm>;
}) {
    return (
        <>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="희망 진로가 사회에 기여하는 역할을 확인하였다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    희망 진로가 사회에 기여하는 역할을 확인하였다.
                </label>
                {form.watch('summary') ===
                    '희망 진로가 사회에 기여하는 역할을 확인하였다.' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                희망 진로가 사회에 어떻게 기여하나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1이(가) $2을(를) 확인하였다'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '희망 진로/학문',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '사회에 어떻게 기여함(기여할 수 있음)',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="희망 학과에 진학해서 공부·연구하고 싶은 지점을 발견하였다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    희망 학과에 진학해서 공부·연구하고 싶은 지점을 발견하였다.
                </label>
                {form.watch('summary') ===
                    '희망 학과에 진학해서 공부·연구하고 싶은 지점을 발견하였다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            어느 학과에 진학해서 어떤 부분을 연구하고
                            싶어졌나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1에 진학해서 $2에 대하여 공부·연구하고 싶다는 생각이 들었다'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '희망 학과',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder: '무엇',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="희망 진로의 인접 학과(학문)의 인접성을 확인하고, 해당 인접 학과(학문)를 탐색할 필요성을 느꼈다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    희망 진로의 인접 학과(학문)의 인접성을 확인하고, 해당 인접
                    학과(학문)를 탐색할 필요성을 느꼈다.
                </label>
                {form.watch('summary') ===
                    '희망 진로의 인접 학과(학문)의 인접성을 확인하고, 해당 인접 학과(학문)를 탐색할 필요성을 느꼈다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            무엇을 통해 희망 진로와 인접 학문(학문)의 인접성을
                            발견했나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1을(를) 통해 $2와(과) $3의 인접성을 확인할 수 있었으며, 이에 따라 $4에 관해서도 탐색해볼 필요성을 느꼈다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 탐구 내용',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '희망 진로·학과·학문',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $3: {
                                                        type: 'text',
                                                        placeholder:
                                                            '인접 진로·학과·학문',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $4: {
                                                        type: 'text',
                                                        placeholder:
                                                            '인접 진로·학과·학문',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="희망 진로의 전망을 구체적으로 알게 되었다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    희망 진로의 전망을 구체적으로 알게 되었다.
                </label>
                {form.watch('summary') ===
                    '희망 진로의 전망을 구체적으로 알게 되었다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            어떤 점을 통해 희망 진로의 전망을 확인했나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    ' $1이(가) $2에서 전망이 밝음을 확인하였다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '희망 진로·학과·학문',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder: '어떤 점',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="서로 다른 분야의 만남이 이루어낸 결과를 보고 융합적 연구의 필요성을 깨달았다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    서로 다른 분야의 만남이 이루어낸 결과를 보고 융합적 연구의
                    필요성을 깨달았다.
                </label>
                {form.watch('summary') ===
                    '서로 다른 분야의 만남이 이루어낸 결과를 보고 융합적 연구의 필요성을 깨달았다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            어떤 점에서 융합적 연구의 필요성을 깨달았나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1와(과) $2의 만남이 $3을(를) 이루어낸 것을 확인함으로써 융합적 연구의 필요성 및 중요성을 깨달았다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '희망 진로 분야',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '다른 분야',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $3: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떤 결과',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="기타"
                    {...form.register('summary')}
                />
                <label className="form-check-label">기타</label>
                {form.watch('summary') === '기타' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                내용을 자유롭게 작성해보세요.
                            </p>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={true}
                                readOnly
                            />
                            <label className="form-check-label w-75">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="직접 입력하기"
                                    onChange={(e) =>
                                        form.setValue(
                                            'description',
                                            e.target.value,
                                        )
                                    }
                                />
                            </label>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
