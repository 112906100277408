import { client } from './client';

export async function callFetchAssignment({
    studentId,
    assignmentId,
}: {
    studentId: StudentId;
    assignmentId: AssignmentId;
}) {
    const { data } = await client.get(
        `/assignment_app/assignments/${assignmentId}/`,
        {
            params: {
                clientStudentId: studentId,
            },
        },
    );
    return data;
}
