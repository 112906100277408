import lodash from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import {
    useAssignmentFacade,
    useTemporaryAssignmentReportFacade,
} from '@facades';
import { moment } from '@utils';
import { PATH, resolveRoute } from '../ProjectRoutes';

import '@styles/pages/assignmentFormPage.scss';

const motivationTypes = [
    { label: '교과 학습 (교과 → 전공)', value: false },
    { label: '전공 탐색 (전공 → 교과)', value: false },
    { label: '다른 과목 (간학문적 관점)', value: false },
];

const difficultyTypes = [
    { label: '정보 습득의 어려움 (정보 원천과 관련)', value: false },
    {
        label: '탐구 내용의 어려움 (지식 수준, 외국어 능력 등과 관련)',
        value: false,
    },
    {
        label: '탐구 방법의 어려움 (조사 및 분석 방법, 컴퓨터 활용 등과 관련)',
        value: false,
    },
    { label: '기타 :', value: false },
    { label: '', value: '' },
];

const overcomeTypes = [
    { label: '교과 선생님께 여쭤보기 또는 선배에게 조언 구하기', value: false },
    {
        label: '온라인 번역기 활용하기 (파파고나 플리토, 구글 번역기 등)',
        value: false,
    },
    {
        label: '이해하기 쉬운 대중서적, 청소년 잡지, 칼럼, 유튜브 영상 등',
        value: false,
    },
    { label: '공개 강의 듣기 (K-OCW, K-MOOC, TED 등)', value: false },
    { label: '기타 :', value: false },
    { label: '', value: '' },
];

const schoolSubjectRelatedTypes = [
    {
        label: '수업 시간에 이해가 잘 되지 않았던 부분을 이해하였다.',
        value: false,
    },
    { label: '학습이 더 필요한 부분을 확인하였다.', value: false },
    { label: '더 공부하고 싶은 부분을 발견하였다.', value: false },
    {
        label: '수업 시간에 배운 내용을 실제로 적용해 현상을 이해할 수 있게 되었다.',
        value: false,
    },
    { label: '교과 성적이 향상되었다.', value: false },
    { label: '기타 :', value: false },
    { label: '', value: '' },
];

const majorRelatedTypes = [
    { label: '희망 전공이 사회에 기여하는 역할을 확인하였다.', value: false },
    {
        label: '희망 학과에 진학해서 연구하고 싶은 지점을 발견하였다.',
        value: false,
    },
    {
        label: '희망 전공의 인접 학문을 확인하고, 인접 학과를 탐색할 필요성을 느꼈다.',
        value: false,
    },
    { label: '희망 전공의 전망을 구체적으로 알게 되었다.', value: false },
    { label: '융합적 연구의 필요성을 깨달았다.', value: false },
    { label: '기타 :', value: false },
    { label: '', value: '' },
];

const interdisciplinaryRelatedTypes = [
    {
        label: '다른 과목과의 연관성을 발견하여 함께 열심히 학습할 필요성을 느꼈다.',
        value: false,
    },
    {
        label: '다른 과목의 관점에서 같은 주제를 탐구해볼 가능성을 발견하였다.',
        value: false,
    },
    {
        label: '다른 과목에서 탐구한 주제를 관점에서 살핌으로써 현상을 보는 시야가 확대되었다.',
        value: false,
    },
    { label: '기타 :', value: false },
    { label: '', value: '' },
];

type UseAssignmentReportFormReturn = UseFormReturn<AssignmentReportForm, any>;

const arrayFields = {
    motivationTypes,
    difficultyTypes,
    overcomeTypes,
    schoolSubjectRelatedTypes,
    majorRelatedTypes,
    interdisciplinaryRelatedTypes,
};

export default function AssignmentReportFormPage() {
    const navigate = useNavigate();
    const assignmentFacade = useAssignmentFacade();
    const { assignmentId: _assignmentId } = useParams();
    const assignmentId = _assignmentId ? +_assignmentId : 0;
    const [assignment, setAssignment] = useState<Assignment>();
    const [isModifying, setIsModifying] = useState<boolean>(false);
    const temporaryAssignmentReportFacade =
        useTemporaryAssignmentReportFacade();

    const assignmentReportForm = useForm<AssignmentReportForm>({
        defaultValues: {
            ...arrayFields,
        },
    });

    useEffect(
        function initAssignment() {
            if (!assignmentId) {
                return;
            }

            (async () => {
                const assignment = await assignmentFacade.getAssignment(
                    assignmentId,
                );
                setAssignment(assignment);

                const reportFromRemote =
                    assignmentFacade.getReportFromAssignment(assignment);
                const tempReport =
                    temporaryAssignmentReportFacade.getReport(assignmentId);

                if (reportFromRemote) {
                    setIsModifying(true);
                    const tempIsCurrent =
                        tempReport &&
                        moment(tempReport.updatedAt).diff(
                            reportFromRemote.updatedAt,
                        ) > 0;

                    if (tempIsCurrent) {
                        assignmentReportForm.reset(tempReport.form);
                    } else {
                        assignmentReportForm.reset(
                            reportFromRemote.data as AssignmentReportForm,
                        );
                    }
                } else {
                    if (tempReport) {
                        assignmentReportForm.reset(tempReport.form);
                    }
                }
            })();
        },
        [assignmentId],
    );

    async function onSubmit() {
        if (!assignment) {
            return;
        }

        const data = assignmentReportForm.getValues() as any;

        // merge labels into data
        for (const [fieldName, defaultValue] of Object.entries(arrayFields)) {
            data[fieldName] = lodash.merge(defaultValue, data[fieldName]);
        }

        if (isModifying) {
            const assignmentReportId =
                assignmentFacade.getReportIdFromAssignment(assignment);
            await assignmentFacade.modifyAssignmentReport(
                assignmentReportId,
                assignmentReportForm.getValues(),
            );
        } else {
            await assignmentFacade.submitAssignmentReport(
                assignmentId,
                assignmentReportForm.getValues(),
            );
        }
        alert('제출완료!');
        navigate(
            resolveRoute(PATH.ASSIGNMENT_LIST__DEPRECATED, {
                grade: assignment.grade,
                semester: assignment.semester,
            }),
        );
    }

    return (
        <div className="page-base">
            <Container className="go-back-btn-container">
                <div className="go-back-btn" onClick={() => navigate(-1)}>
                    <span className="material-icons">arrow_back_ios</span>
                    <span> 뒤로가기</span>
                </div>
            </Container>
            <Container className="assignment-form-page-container">
                <div className="form-title">탐구활동 설계 과제</div>
                {assignment?.report?.feedback && (
                    <div className="form-container">
                        <div className="form-label">피드백</div>
                        <textarea
                            rows={4}
                            disabled
                            value={
                                (assignment.report.feedback
                                    .comprehensiveEvaluation as unknown as string) ??
                                '받은 피드백이 없습니다.'
                            }
                        />
                    </div>
                )}
                <form onSubmit={assignmentReportForm.handleSubmit(onSubmit)}>
                    <SubjectInfoSection form={assignmentReportForm} />
                    <div className="form-container">
                        <div className="form-section-title">1. 탐구 동기</div>
                        <MotivationSection form={assignmentReportForm} />
                    </div>
                    <div className="form-container">
                        <div className="form-section-title">2. 탐구 과정</div>
                        <ResearchProcessSection form={assignmentReportForm} />
                    </div>
                    <div className="form-container">
                        <div className="form-section-title">3. 탐구 결과</div>
                        <ResearchResultSection form={assignmentReportForm} />
                    </div>
                    <div className="form-container">
                        <div className="form-section-title">4. 자기평가서</div>
                        <div className="form-textarea-box">
                            <div className="description-box">
                                <div className="textarea-description-text">
                                    <p>동기</p>
                                    <p>(2줄 내외)</p>
                                </div>
                                <div className="textarea-description-text">
                                    <p>활동</p>
                                    <p>(5줄 내외)</p>
                                </div>
                                <div className="textarea-description-text">
                                    <p>배우고 느낀 점</p>
                                    <p>(2줄 내외)</p>
                                </div>
                            </div>
                            <textarea
                                {...assignmentReportForm.register(
                                    'selfAssessment',
                                )}
                                rows={9}
                            />
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    );
}

function SubjectInfoSection({ form }: { form: UseAssignmentReportFormReturn }) {
    return (
        <div className="form-container">
            <div className="form-label">탐구 주제</div>
            <input
                {...form.register('subject')}
                className="form-text-input"
                type="text"
                placeholder="탐구주제 입력"
            />
            <div className="form-label">과목명</div>
            <input
                {...form.register('relatedSchoolSubjectName')}
                className="form-text-input"
                type="text"
                placeholder="과목명 입력"
            />
            <div className="form-label">관련 단원</div>
            <input
                {...form.register('relatedSchoolSubjectUnitName')}
                className="form-text-input"
                type="text"
                placeholder="관련 단원 입력"
            />
            <div className="form-label">관련 성취기준 (or 학습목표)</div>
            <input
                {...form.register('relatedSchoolAchievement')}
                className="form-text-input"
                type="text"
                placeholder="관련 성취기준 입력"
            />
        </div>
    );
}

function MotivationSection({ form }: { form: UseAssignmentReportFormReturn }) {
    return (
        <>
            <div className="form-label">유형 (복수 선택 가능)</div>
            <div className="form-checkbox-group-horizontal row">
                {motivationTypes.map(({ label, value }, index) => (
                    <div
                        className="form-checkbox-set col-lg-3 col-sm-12"
                        key={index}
                    >
                        <input
                            {...form.register(`motivationTypes.${index}.value`)}
                            type="checkbox"
                            id="assignment-form-checkbox-1-1"
                        ></input>
                        <label htmlFor="assignment-form-checkbox-1-1">
                            {label}
                        </label>
                    </div>
                ))}
            </div>
            <div className="form-label">참조 자료</div>
            <div className="form-description-text">
                * 기사, 웹, 영상, 도서, 강의, 논문 등 (해당 주제에 관심을 갖게
                된 계기와 관련)
            </div>
            <input
                {...form.register('motivationReference')}
                className="form-text-input"
                type="text"
                placeholder="참조 자료 입력"
            />
            <div className="form-label">구체적 동기</div>
            <input
                {...form.register('motivationDescription')}
                className="form-text-input"
                type="text"
                placeholder="구체적 동기 입력"
            />
        </>
    );
}

function ResearchProcessSection({
    form,
}: {
    form: UseAssignmentReportFormReturn;
}) {
    return (
        <>
            <div className="form-label">활동 내용</div>
            <input
                {...form.register('processSummary')}
                className="form-text-input"
                type="text"
                placeholder="활동 내용 입력"
            />
            <InformationAcquisitionProcess form={form} />
            <DifficultyTypes form={form} />
            <OvercomeTypes form={form} />
        </>
    );
}

function ResearchResultSection({
    form,
}: {
    form: UseAssignmentReportFormReturn;
}) {
    return (
        <>
            <div className="form-section-title small">
                유형 (복수 선택 가능)
            </div>
            <div className="form-box-with-sideline">
                <SchoolSubjectRelatedTypes form={form} />
                <MajorRelatedTypes form={form} />
                <InterDisciplinaryTypes form={form} />
                <div className="form-section-title small">구체적 내용</div>
                <input
                    {...form.register('processDescription')}
                    className="form-text-input"
                    type="text"
                    placeholder="내용 입력"
                />
            </div>
        </>
    );
}

function InformationAcquisitionProcess({
    form,
}: {
    form: UseAssignmentReportFormReturn;
}) {
    return (
        <>
            <div className="form-section-title small">정보 획득 과정</div>
            <div className="form-box-with-sideline">
                <div className="form-label">참조 자료</div>
                <div className="form-description-text">
                    * 기사, 웹, 영상, 도서, 강의, 논문 등 (해당 주제에 관심을
                    갖게 된 계기와 관련)
                </div>
                <input
                    {...form.register('informationAcquisitionReference')}
                    className="form-text-input"
                    type="text"
                    placeholder="참조 자료 입력"
                />
                <div className="form-label">내용</div>
                <input
                    {...form.register('informationAcquisitionDescription')}
                    className="form-text-input"
                    type="text"
                    placeholder="내용 입력"
                />
            </div>
        </>
    );
}

function DifficultyTypes({ form }: { form: UseAssignmentReportFormReturn }) {
    return (
        <>
            <div className="form-section-title small">어려웠던 지점</div>
            <div className="form-box-with-sideline">
                <div className="form-label">유형 (복수 선택 가능)</div>
                <div className="form-checkbox-group-vertical">
                    <>
                        {difficultyTypes.map(({ label, value }, index) => (
                            <Fragment key={index}>
                                {index < difficultyTypes.length - 1 && (
                                    <div
                                        className="form-checkbox-set"
                                        key={index}
                                    >
                                        <input
                                            {...form.register(
                                                `difficultyTypes.${index}.value`,
                                            )}
                                            type="checkbox"
                                        ></input>
                                        <label>{label}</label>
                                    </div>
                                )}
                            </Fragment>
                        ))}

                        <input
                            {...form.register(
                                `difficultyTypes.${
                                    difficultyTypes.length - 1
                                }.value`,
                            )}
                            className="form-text-input short"
                            type="text"
                            placeholder="내용 입력"
                        />
                    </>
                    <div className="form-label">내용</div>
                    <input
                        {...form.register('difficultyDescription')}
                        className="form-text-input"
                        type="text"
                        placeholder="내용 입력"
                    />
                </div>
            </div>
        </>
    );
}

function OvercomeTypes({ form }: { form: UseAssignmentReportFormReturn }) {
    return (
        <>
            <div className="form-section-title small">극복 방법</div>
            <div className="form-box-with-sideline">
                <div className="form-label">유형 (복수 선택 가능)</div>
                <div className="form-checkbox-group-vertical">
                    {overcomeTypes.map(({ label, value }, index) => (
                        <Fragment key={index}>
                            {index < overcomeTypes.length - 1 && (
                                <div className="form-checkbox-set">
                                    <input
                                        {...form.register(
                                            `overcomeTypes.${index}.value`,
                                        )}
                                        type="checkbox"
                                    ></input>
                                    <label>{label}</label>
                                </div>
                            )}
                        </Fragment>
                    ))}
                    <input
                        {...form.register(
                            `overcomeTypes.${overcomeTypes.length - 1}.value`,
                        )}
                        className="form-text-input short"
                        type="text"
                        placeholder="내용 입력"
                    />
                </div>
                <div className="form-label">내용</div>
                <input
                    {...form.register('overcomeDescription')}
                    className="form-text-input"
                    type="text"
                    placeholder="내용 입력"
                />
            </div>
        </>
    );
}

function SchoolSubjectRelatedTypes({
    form,
}: {
    form: UseAssignmentReportFormReturn;
}) {
    return (
        <div className="form-checkbox-group-vertical">
            <div className="form-label">교과 학습 관련</div>
            {schoolSubjectRelatedTypes.map(({ label, value }, index) => (
                <Fragment key={index}>
                    {index < schoolSubjectRelatedTypes.length - 1 && (
                        <div className="form-checkbox-set">
                            <input
                                {...form.register(
                                    `schoolSubjectRelatedTypes.${index}.value`,
                                )}
                                type="checkbox"
                            ></input>
                            <label>{label}</label>
                        </div>
                    )}
                </Fragment>
            ))}
            <input
                {...form.register(
                    `schoolSubjectRelatedTypes.${
                        schoolSubjectRelatedTypes.length - 1
                    }.value`,
                )}
                className="form-text-input short"
                type="text"
                placeholder="내용 입력"
            />
        </div>
    );
}

function MajorRelatedTypes({ form }: { form: UseAssignmentReportFormReturn }) {
    return (
        <div className="form-checkbox-group-vertical">
            <div className="form-label">전공 탐색 관련</div>
            {majorRelatedTypes.map(({ label, value }, index) => (
                <Fragment key={index}>
                    {index < majorRelatedTypes.length - 1 && (
                        <div className="form-checkbox-set">
                            <input
                                {...form.register(
                                    `majorRelatedTypes.${index}.value`,
                                )}
                                key={label}
                                type="checkbox"
                            ></input>
                            <label key={`${label}-label`}>{label}</label>
                        </div>
                    )}
                </Fragment>
            ))}
            <input
                {...form.register(
                    `majorRelatedTypes.${majorRelatedTypes.length - 1}.value`,
                )}
                className="form-text-input short"
                type="text"
                placeholder="내용 입력"
            />
        </div>
    );
}

function InterDisciplinaryTypes({
    form,
}: {
    form: UseAssignmentReportFormReturn;
}) {
    return (
        <div className="form-checkbox-group-vertical">
            <div className="form-label">다른 과목 관련</div>
            {interdisciplinaryRelatedTypes.map(({ label, value }, index) => (
                <Fragment key={index}>
                    {index < interdisciplinaryRelatedTypes.length - 1 && (
                        <div className="form-checkbox-set">
                            <input
                                {...form.register(
                                    `interdisciplinaryRelatedTypes.${index}.value`,
                                )}
                                key={label}
                                type="checkbox"
                            ></input>
                            <label key={`${label}-label`}>{label}</label>
                        </div>
                    )}
                </Fragment>
            ))}
            <input
                {...form.register(
                    `interdisciplinaryRelatedTypes.${
                        interdisciplinaryRelatedTypes.length - 1
                    }.value`,
                )}
                className="form-text-input short"
                type="text"
                placeholder="내용 입력"
            />
        </div>
    );
}
