import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import TextInputGathering from '../components/TextInputGathering';
import TextRadio from '../components/TextRadio';
import { BorderBox } from '@pages/pro12/components';

export default function Step2({
    setFormRef,
    initialValue,
}: {
    setFormRef: (form: UseFormReturn<OvercomingMethodForm>) => void;
    initialValue?: OvercomingMethodForm;
}) {
    const form = useForm<OvercomingMethodForm>({
        defaultValues: initialValue ?? {},
    });

    useEffect(() => {
        setFormRef(form);
    }, []);

    return (
        <BorderBox variant={'danger'} className={'mt-2'}>
            <h4 className="fs-6 fw-semibold mb-3">[2단계] 극복한 방법</h4>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="교과 선생님께 여쭤보기 또는 선배에게 조언 구하기"
                    {...form.register('overcomingSummary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    교과 선생님께 여쭤보기 또는 선배에게 조언 구하기
                </label>
                {form.watch('overcomingSummary') ===
                    '교과 선생님께 여쭤보기 또는 선배에게 조언 구하기' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                누구로부터, 어떤 답변·조언을 받았나요? 문제
                                해결에 도움이 되었나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('overcomingDescription', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1로 부터 $2에 관한 $3을(를) 받았으며, 이를 바탕으로 $4을(를) $5 해결하였다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder: '누구',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder: '무엇',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $3: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떠한 답변·조언',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $4: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 문제',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $5: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떻게 해서',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="온라인 번역기 활용하기 (파파고나 플리토, 구글 번역기 등)"
                    {...form.register('overcomingSummary')}
                />
                <label
                    className="form-check-label d-flex align-items-center"
                    htmlFor="flexRadioDefault1"
                >
                    온라인 번역기 활용하기 (파파고나 플리토, 구글 번역기 등)
                </label>
                {form.watch('overcomingSummary') ===
                    '온라인 번역기 활용하기 (파파고나 플리토, 구글 번역기 등)' && (
                    <>
                        {' '}
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                무슨 번역기를 활용했나요? 결과는 충분히 도움이
                                되었나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('overcomingDescription', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1을(를) 활용하였으며, 이를 바탕으로 $2을(를) $3해결하였다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 변역기',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 문제',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $3: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떻게 해서',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="이해하기 쉬운 대중서적, 청소년 잡지, 칼럼, 유투브 영상 등 참고하기"
                    {...form.register('overcomingSummary')}
                />
                <label
                    className="form-check-label d-flex align-items-center"
                    htmlFor="flexRadioDefault1"
                >
                    이해하기 쉬운 대중서적, 청소년 잡지, 칼럼, 유투브 영상 등
                    참고하기
                </label>
                {form.watch('overcomingSummary') ===
                    '이해하기 쉬운 대중서적, 청소년 잡지, 칼럼, 유투브 영상 등 참고하기' && (
                    <>
                        {' '}
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                무슨 자료를 활용했나요? 문제를 해결하는 데 어떤
                                도움을 주었나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('overcomingDescription', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1을(를) 활용하였으며, 이를 바탕으로 $2을(를) $3해결하였다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 자료(명)',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 문제',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $3: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떻게 해서',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="공개 강의 듣기 (K-OCW, K-MOOC, TED 등)"
                    {...form.register('overcomingSummary')}
                />
                <label
                    className="form-check-label d-flex align-items-center"
                    htmlFor="flexRadioDefault1"
                >
                    공개 강의 듣기 (K-OCW, K-MOOC, TED 등)
                </label>
                {form.watch('overcomingSummary') ===
                    '공개 강의 듣기 (K-OCW, K-MOOC, TED 등)' && (
                    <>
                        {' '}
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                무슨 강의를 들었나요? 문제를 해결하는 데 어떤
                                도움을 주었나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('overcomingDescription', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1을(를) 찾아 들었으며, 이를 바탕으로 $2을(를) $3해결하였다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 강의',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 문제',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $3: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떻게 해서',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="기타"
                    {...form.register('overcomingSummary')}
                />
                <label className="form-check-label">기타</label>
                {form.watch('overcomingSummary') === '기타' && (
                    <>
                        {' '}
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                내용을 자유롭게 작성해보세요.
                            </p>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={true}
                                readOnly
                            />
                            <label className="form-check-label w-75">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="직접 입력하기"
                                    onChange={(e) =>
                                        form.setValue(
                                            'overcomingDescription',
                                            e.target.value,
                                        )
                                    }
                                />
                            </label>
                        </div>
                    </>
                )}
            </div>
        </BorderBox>
    );
}
