import { Modal } from 'react-bootstrap';
import 'src/assets/scss/components/mockSATGradeModal.scss';

function getNameDocumentsMap() {
    function importAll(r: any) {
        return r.keys().map(r);
    }

    const filePaths = importAll(require.context('src/assets/documents'));
    const fileMap: {
        [key: string]: {
            [key: string]: string;
        };
    } = {};
    filePaths.forEach((path: string) => {
        const regex = path.match(/[a-z]*\./);
        if (regex) {
            const splitted = path.split('.');
            const extension = splitted[splitted.length - 1];
            const key = regex[0];
            if (key in fileMap) {
                fileMap[key][extension] = path;
            } else {
                fileMap[key] = {
                    [extension]: path,
                };
            }
        }
    });
    return fileMap;
}

function getHashValue(code: string) {
    let hash = 0,
        index = 0;
    while (index < code.length) {
        hash = ((hash << 5) - hash + code.charCodeAt(index++)) << 0;
    }
    return hash;
}

export default function DownloadDocumentsModal({
    code,
    modalShow,
    closeModal,
}: {
    code: string;
    modalShow: boolean;
    closeModal: () => void;
}) {
    const nameDocumentMap = getNameDocumentsMap();
    const hashValue = getHashValue(code);
    const keys = Object.keys(nameDocumentMap);
    const index = hashValue % (keys.length - 1);
    const { hwp, docx } = nameDocumentMap[keys[index]];

    return (
        <Modal show={modalShow} onHide={closeModal} centered>
            <Modal.Header className="border-0 d-flex justify-content-end">
                <div
                    className="material-icons modal-close-button pointer"
                    onClick={closeModal}
                >
                    close
                </div>
            </Modal.Header>
            <Modal.Body>
                <a
                    href={hwp}
                    download=""
                    target="_blank"
                    className="d-flex bg-white border border-primary rounded-5 py-2 px-4 mx-auto mt-4 justify-content-center no-text-decoration"
                >
                    탐구보고서 양식 다운로드(.hwp)
                    <span className="material-icons ms-4 text-primary">
                        {' '}
                        download{' '}
                    </span>
                </a>
                <a
                    href={docx}
                    download=""
                    target="_blank"
                    className="d-flex bg-white border border-primary rounded-5 py-2 px-4 mx-auto mt-4 mb-5 justify-content-center no-text-decoration"
                >
                    발표 자료 양식 다운로드(.docx)
                    <span className="material-icons ms-4 text-primary">
                        {' '}
                        download{' '}
                    </span>
                </a>
            </Modal.Body>
        </Modal>
    );
}
