import '@styles/pages/pro12/assignmentListPage.scss';
import {useEffect, useState} from 'react';
import {generatePath, Link, useNavigate, useParams} from 'react-router-dom';

import {useAppSelector} from '@store';
import {
    useAssignmentFacade,
    useIssuedSubjectFacade,
    useLeanAIMetadataFacade,
} from '@facades';
import '@styles/pages/assignmentPage.scss';
import AssignmentRow from '@components/AssignmentRow';
import {PATH, resolveRoute} from '../../ProjectRoutes';

const gradeOptions = ['1', '2', '3'];
const semesterOptions = ['1', '2'];

function setRelatedAssignmentsToIssuedSubjects(
    issuedSubjects: IssuedSubjectSummary[],
    assignments: Assignment[],
) {
    return issuedSubjects.map((issuedSubject) => {
        issuedSubject.assignments = assignments
            .filter(
                (assignment) => issuedSubject.id === assignment.issuedSubjectId,
            )
            .sort((a, b) => a.id - b.id);
        return issuedSubject;
    });
}

function sortWithGivenIds(
    ids: number[],
    issuedSubjects: IssuedSubjectSummary[],
) {
    const indexed: {
        [k: number]: IssuedSubjectSummary;
    } = {};
    issuedSubjects.forEach((e) => (indexed[e.id] = e));
    return ids.sort((a, b) => b - a).map((id) => indexed[id]);
}

const MIN_ISSUED_AT = new Date('2022-12-10').getTime();

export default function AssignmentListPage() {
    const navigate = useNavigate();
    const student = useAppSelector((state) => state.student);
    const { grade = '1', semester = '1' } = useParams();
    const { listAssignmentIndexes, listAssignments } = useAssignmentFacade();
    const leanAIMetadata = useLeanAIMetadataFacade().get();
    const { getIssuedSubjects } = useIssuedSubjectFacade(leanAIMetadata);

    const defaultParams = {
        clientStudentId: student.id,
        student: student.id,
        isRequested: true,
        semester,
        grade,
    };

    const [issuedSubjects, setIssuedSubjects] = useState<
        IssuedSubjectSummary[]
    >([]);

    async function initAssignmentIndexes(
        params: any,
    ): Promise<Paginated<AssignmentIndex>> {
        return await listAssignmentIndexes(params);
    }

    async function initIssuedSubjects(
        params: any,
    ): Promise<Paginated<IssuedSubjectSummary>> {
        return getIssuedSubjects(params);
    }

    async function initAssignments(params: any): Promise<Assignment[]> {
        return await listAssignments(params);
    }

    async function initPage() {
        const { results: assignmentIndexes } = await initAssignmentIndexes(
            defaultParams,
        );
        if (assignmentIndexes.length > 0) {
            const issuedSubjectIds = assignmentIndexes.map(
                (a) => a.issuedSubjectId,
            );

            const { results: issuedSubjects } = await initIssuedSubjects({
                id: issuedSubjectIds.join(','),
                ...defaultParams,
            });

            const assignments = await initAssignments({
                issuedSubjectId: issuedSubjects
                    .map((i) => (i as IssuedSubjectSummary).id)
                    .join(','),
                clientStudentId: student.id,
            });

            const relationSetIssuedSubjects =
                setRelatedAssignmentsToIssuedSubjects(
                    issuedSubjects,
                    assignments,
                );

            const result = sortWithGivenIds(
                issuedSubjectIds,
                relationSetIssuedSubjects,
            );

            setIssuedSubjects(result);
        } else {
            setIssuedSubjects([]);
        }
    }

    function handleGradeSemesterButtonClick(grade: number, semester: number) {
        return navigate(
            resolveRoute(PATH.PRO12_ASSIGNMENT_LIST, {
                grade,
                semester,
            }),
        );
    }

    useEffect(
        function init() {
            initPage();
        },
        [grade, semester],
    );

    return (
        <div id="assignments-page">
            <div className="body-container">
                <div className="pt-[23px] text-[#777]">
                    <header>
                        <h2 className="d-flex items-center font-bold justify-center text-[38px]">
                            <span className="material-icons me-2 text-[45px]">
                                event_available
                            </span>
                            과제 관리
                        </h2>

                        <div className="pt-3 w-fit mx-auto">
                            <h5>
                                *양식은 작성을 마친 후 보고서 초안과 함께
                                다운로드 받을 수 있습니다.
                            </h5>
                            <h5>
                                *과제 초안을 통과(빨간 도장)해야 자기평가서
                                초안을 확인할 수 있습니다.
                            </h5>
                        </div>

                        <p className="text-end text-xs">
                            양식은 작성을 마친 후 보고서 초안과 함께 다운로드
                            받을 수 있습니다.
                        </p>
                    </header>
                </div>
                <div className="body-contents-box">
                    <div className="d-flex task-design-step-list px-2">
                        {gradeOptions.map((gradeOption) =>
                            semesterOptions.map((semesterOption) => (
                                <li
                                    key={`${gradeOption}${semesterOption}`}
                                    className="col"
                                >
                                    <a
                                        onClick={() =>
                                            handleGradeSemesterButtonClick(
                                                +gradeOption,
                                                +semesterOption,
                                            )
                                        }
                                        className={
                                            grade === gradeOption &&
                                            semester === semesterOption
                                                ? 'active'
                                                : ''
                                        }
                                    >
                                        {gradeOption}학년 {semesterOption}학기
                                    </a>
                                </li>
                            )),
                        )}
                    </div>

                    <div className="body-content">
                        {/* ASSIGNMENT-CARD */}
                        {issuedSubjects.map((issuedSubject) => (
                            <AssignmentRow
                                key={issuedSubject.id}
                                issuedSubject={issuedSubject}
                                onAssignmentReportUpdated={initPage}
                            />
                        ))}
                        {issuedSubjects.length === 0 && (
                            <div className="d-flex w-100 justify-content-center items-center h-100">
                                <div className="d-flex flex-column">
                                    <div className="self-center">
                                        과제 데이터가 없습니다.
                                    </div>
                                    <div>
                                        2022. 12. 12 이전 발급 주제에 대한
                                        과제는{' '}
                                        <Link
                                            to={generatePath(
                                                PATH.ASSIGNMENT_LIST__DEPRECATED,
                                                { grade, semester },
                                            )}
                                        >
                                            여기서 확인하실 수 있습니다.
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
