import '@styles/components/schoolRecordAgreement.scss';

export default function SchoolRecordAgreement() {
    return (
        <div className="school-record-agree-base">
            <div className="agree-container">
                <div className="agree-title">개인정보 수집 및 이용 동의</div>
                <div className="agree-text">
                    (주)잡쇼퍼는 "생기부 진단 및 분석" 서비스를 위하여
                    생활기록부에 포함되는 개인정보의 수집 및 이용목적, 보유기간,
                    동의 거부권 및 동의 거부 시 불이익에 관한 사항을
                    안내드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
                </div>
                <table className="agree-table">
                    <thead>
                        <td>수집 및 이용 목적</td>
                        <td>수집항목</td>
                        <td>보유기간</td>
                    </thead>
                    <tbody>
                        <td>생기부 진단 및 분석</td>
                        <td>
                            <p>[필수] 학생성명, 학교, 반, 번호</p>
                            <p>
                                ※ 주민등록번호, 주소, 성별, 가족상황, 담임성명은
                                수집되지 않습니다.
                            </p>
                        </td>
                        <td>
                            <p>회원 탈퇴 시 </p>
                            <p>삭제 요청 시</p>
                            <p>법정 의무 보유기간</p>
                        </td>
                    </tbody>
                </table>
                <div className="agree-text">
                    ※ 회원은 위 개인정보의 수집에 동의하지 않을 수 있습니다.
                    다만, 학쫑PRO의 생기부 진단 및 분석 서비스는 이용하실 수
                    없습니다. (위 개인정보 수집 및 이용에 동의하지 않는 경우
                    다른 서비스의 이용에는 지장이 없습니다.)
                </div>
            </div>

            <div className="agree-container">
                <div className="agree-title">민감정보 수집 및 이용 동의</div>
                <div className="agree-text">
                    (주)잡쇼퍼는 "생기부 진단 및 분석" 서비스를 위하여
                    생활기록부에 포함되는 민감정보의 수집 및 이용목적, 보유기간,
                    동의 거부권 및 동의 거부 시의 불이익에 관한 사항을
                    안내드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
                </div>
                <table className="agree-table">
                    <thead>
                        <td>수집 및 이용 목적</td>
                        <td>수집항목</td>
                        <td>보유기간</td>
                    </thead>
                    <tbody>
                        <td>생기부 진단 및 분석</td>
                        <td>
                            <p>[필수] 학생성명, 학교, 반, 번호</p>
                            <ul>
                                <li>
                                    학적사항 (중고교명, 입학, 졸업, 전학 등의
                                    기록)
                                </li>

                                <li>
                                    출결상황 (결석, 지각, 조퇴, 결과 등의 기록)
                                </li>
                                <li>
                                    수상경력 (수상명, 순위, 수상연월일,
                                    수여기관, 참가대상 및 인원)
                                </li>

                                <li>
                                    자격증 및 인증 취득상황 (자격증명, 내용,
                                    취득연월일, 발급기관 등)
                                </li>
                                <li>
                                    진로희망사항 (학년, 진로희망, 진로희망 사유)
                                </li>
                                <li>
                                    창의적 체험활동상황
                                    (자율/동아리/봉사/진로활동 시간, 특기사항
                                    등)
                                </li>
                                <li>
                                    교과학습발달사항 (학생이 이수한 교과, 과목,
                                    단위수, 원점수, 과목평균, 표준편차,
                                </li>
                                <li>성취도, 석차등급, 세부능력 및 특기사항)</li>
                                <li>
                                    독서활동상황 (관련 과목, 독서 시기, 도서명,
                                    저자)
                                </li>
                                <li>행동특성 및 종합의견</li>
                            </ul>
                        </td>
                        <td>
                            <p>회원 탈퇴 시 </p>
                            <p>삭제 요청 시</p>
                            <p>법정 의무 보유기간</p>
                        </td>
                    </tbody>
                </table>
                <div className="agree-text">
                    ※ 회원은 위 개인정보의 수집에 동의하지 않을 수 있습니다.
                    다만, 학쫑PRO의 생기부 진단 및 분석 서비스는 이용하실 수
                    없습니다. (위 개인정보 수집 및 이용에 동의하지 않는 경우
                    다른 서비스의 이용에는 지장이 없습니다.)
                </div>
            </div>
        </div>
    );
}
