import React, {useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

import 'bootstrap/dist/js/bootstrap.bundle';
import {useLeanAIMetadataFacade, useStudentFacade} from './facades';
import ProjectRoutes from './ProjectRoutes';
import {scrollToSection} from '@pages/pro12/helpers';

function isPro12Route(pathname: string) {
    return pathname.includes('pro12');
}

function App() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const studentFacade = useStudentFacade();

    useEffect(() => {
        if (searchParams.get('shouldLogout')) {
            studentFacade.logout();
        }
    }, [searchParams]);

    useEffect(() => {
        if (isPro12Route(location.pathname)) {
            scrollToSection('#A');
        }
    }, [location.pathname]);

    useEffect(() => {
        scrollToSection(location.hash);
    }, [location.pathname, location.hash]);

    const leanAIMetadata = useLeanAIMetadataFacade();
    useEffect(() => {
        if (leanAIMetadata.isReady) {
            return;
        }

        console.log('App.jsx: loading lean-ai metadata...');
        leanAIMetadata.ready().then(() => {
            console.log('App.jsx: lean-ai metadata ready.');

        });
    });

    return (
        <div className={isPro12Route(location.pathname) ? '' : 'legacy'}>
            {leanAIMetadata.isReady ? <ProjectRoutes /> : <></>}
        </div>
    );
}

export default App;
