import { useState } from 'react';

import closeImg from '@images/purple-close.png';

export default function Keywords({
    keywords,
    addKeyword,
    removeKeyword,
}: {
    keywords: string[];
    addKeyword: (keyword: string) => void;
    removeKeyword: (keyword: string) => void;
}) {
    const [keywordInput, setKeywordInput] = useState<string>('');

    function addAndClearKeyword() {
        if (keywordInput) {
            addKeyword(keywordInput);
            setKeywordInput('');
        }
    }

    return (
        <>
            {keywords.map((keyword) => (
                <div className="text-box square relative !pr-12" key={keyword}>
                    <span>{keyword}</span>

                    <button
                        className="absolute top-1/2 -translate-y-1/2 right-3 w-6 h-6 bg-transparent !p-0"
                        onClick={() => removeKeyword(keyword)}
                    >
                        <img src={closeImg} alt="삭제" />
                    </button>
                </div>
            ))}

            <div className="relative mb-[10px]">
                <input
                    className="bg-[#FAFAFA] !pr-12 !py-3 !border-none"
                    type="text"
                    placeholder="키워드 입력"
                    value={keywordInput}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            addAndClearKeyword();
                        }
                    }}
                    onChange={(e) => setKeywordInput(e.target.value)}
                />
                <button
                    className="absolute right-5 top-1/2 -translate-y-1/2 p-0 !bg-transparent text-purple !text-3xl"
                    onClick={addAndClearKeyword}
                >
                    +
                </button>
            </div>
        </>
    );
}
