import React from 'react';

export default function Bookmark({
    title,
    link,
    image,
    description,
}: {
    title: string;
    link: string;
    image?: string;
    description: string;
}) {
    if (image) {
        return (
            <a
                href={link}
                className={
                    'row border border-2 border-opacity-50 w-75 p-3 align-items-center '
                }
                target="_blank"
            >
                <div className={'col-3'}>
                    <div className={'row'}>
                        <img src={image} alt={'favicon'} />
                    </div>
                </div>
                <div className={'col-9 text-truncate'}>
                    <div className={'row'}>
                        <h5 className={'fs-semibold m-2'}>{title}</h5>
                    </div>
                    <div className={'row'}>
                        <small className={'row m-2'}>{description}</small>
                    </div>
                    <div className={'row'}>
                        <small className={'text-muted m-2'}>{link}</small>
                    </div>
                </div>
            </a>
        );
    } else {
        return (
            <a
                href={link}
                className={
                    'border border-2 border-opacity-50 w-50 p-3 align-items-center '
                }
                target="_blank"
            >
                <div className={'row'}>
                    <h5 className={'fs-semibold m-2'}>{title}</h5>
                </div>
                <div className={'row'}>
                    <small className={'row m-2'}>{description}</small>
                </div>
                <div className={'row text-truncate'}>
                    <small className={'text-muted m-2'}>{link}</small>
                </div>
            </a>
        );
    }
}
