import { client } from './client';

export async function callUploadSchoolRecord({
    studentId,
    grade,
    file,
}: {
    studentId: StudentId;
    grade: number;
    file: File;
}) {
    const data = new FormData();
    data.append('school_record', file);

    await client.post(`/students_app/supporters/grades/${grade}/upload-school-record/`, data, {
        params: { clientStudentId: studentId },
    });
}
