export const tournamentEntry = [
    '철학·윤리학',
    '화학·화학공학',
    '역사·고고학',
    '생명과학·생명공학',
    '종교학',
    '환경학·환경공학',
    '문화인류학',
    '식품학·식품공학',
    '미술사학',
    '농업학',
    '문헌정보학',
    '산림·작물·원예학',
    '문화콘텐츠학',
    '축산학',
    '심리학',
    '수산학',
    '언어학',
    '가정학·주거학',
    '국어국문·한국학',
    '의류·의상학',
    '독어독문·독일학',
    '전기공학',
    '노어노문·러시아학',
    '전자공학',
    '서어서문·스페인중남미학',
    '제어계측공학',
    '영어영문·영미학',
    '광학공학',
    '일어일문·일본학',
    '기계공학',
    '중어중문·중국학',
    '메카트로닉스공학',
    '불어불문·프랑스학',
    '자동차공학',
    '기타아시아어문·기타아시아학',
    '조선·해양공학',
    '기타유럽어문·기타유럽학',
    '항공·우주공학',
    '아프리카어문·아프리카학',
    '컴퓨터공학',
    '한문학',
    '정보·통신공학',
    '문예창작',
    '소프트웨어공학',
    '경영학',
    '게임공학',
    '경제학',
    '산업경영공학',
    '세무·회계학',
    '건축학·건축공학',
    '금융·보험학',
    '토목공학',
    '무역·유통학',
    '도시공학',
    '소비자·가정자원학',
    '교통·철도공학',
    '도시·지역·지리학',
    '조경학',
    '행정학',
    '소방·방재·안전공학',
    '법학',
    '신소재·재료공학',
    '사회학',
    '섬유·고분자공학',
    '사회복지학',
    '반도체공학',
    '아동가족학',
    '금속공학',
    '경찰행정학',
    '에너지공학',
    '군사·국방·안보학',
    '교육학',
    '정치외교학',
    '유아교육',
    '국제학',
    '초등교육',
    '언론·방송·매체학',
    '특수교육',
    '광고·홍보학',
    '의학',
    '관광학',
    '의공학',
    '수학',
    '약학',
    '통계학',
    '치의학',
    '물리학',
    '한의학',
    '천문학',
    '수의학',
    '기상학·대기과학',
    '간호학',
    '지구과학',
    '보건학',
    '해양학',
    '재활치료',
];
