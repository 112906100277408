import {
    callFetchStudentContext,
    callLogin,
    callMarkOnboarding,
} from '@client';
import { studentSlice, useAppDispatch, useAppSelector } from '@store';
import { FacadeError } from './facade-error';

class LoginFailed extends FacadeError {}

class LogoutFailed extends FacadeError {}

class OnBoardingFailed extends FacadeError {}

export function useStudentFacade() {
    const dispatch = useAppDispatch();
    const student = useAppSelector((state) => state.student);

    async function login(code: string, phoneNumber: string) {
        try {
            const studentData = await callLogin(code, phoneNumber);
            localStorage.clear();
            dispatch(
                studentSlice.actions.set({
                    ...studentData,
                }),
            );
        } catch (e) {
            throw new LoginFailed('Login failed');
        }
    }

    async function getIsActiveStudent(studentId: number): Promise<boolean> {
        const { isActive } = (await callFetchStudentContext(studentId)) as any;
        return isActive;
    }

    async function logout() {
        dispatch(studentSlice.actions.clear());
    }

    async function markOnBoard(studentId: number) {
        try {
            await callMarkOnboarding(studentId);
            localStorage.clear();
            dispatch(
                studentSlice.actions.set({
                    ...student,
                    isOnboarded: true,
                }),
            );
        } catch (e) {
            throw new OnBoardingFailed('On Boarding Failed');
        }
    }

    return {
        data: student,
        getIsActiveStudent,
        login,
        logout,
        markOnBoard,
        isLoggedIn: !!student.name,
    };
}
