import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Editor } from 'tinymce';

import ReferenceGroup from './ReferenceGroup';
import TinyMceTextEditor from '@components/TinyMceTextEditor';
import { ImageUploader } from './ImageUploader';

export default function TBRDraft({
    initialValue,
    onComplete,
}: {
    initialValue?: Partial<TBRForm>;
    onComplete: (data: TBRForm) => void;
}) {
    initialValue = initialValue ?? {};
    const form = useForm<TBRForm>({
        defaultValues: initialValue,
    });
    const [references, setReferences] = useState<Reference[]>(
        initialValue.references ?? [],
    );
    const editorRef = useRef<Editor | null>(null);

    const imageUploader = ImageUploader();

    function submitForm() {
        const theoreticalBackgroundDescription =
            editorRef.current?.getContent({ format: 'html' }) ?? '';
        form.setValue(
            'theoreticalBackgroundDescription',
            theoreticalBackgroundDescription,
        );
        form.setValue('references', references);
        onComplete(form.getValues());
    }

    return (
        <>
            <div className="row align-items-center mb-2">
                <div className="col-auto">
                    <h4 className="fs-5 fw-semibold mb-0">
                        이론적 배경 및 용어 :{' '}
                    </h4>
                </div>
                <div className="col">
                    <input
                        className="form-control fs-6"
                        type="text"
                        placeholder="이론명 혹은 용어명"
                        {...form.register('theoreticalBackground')}
                    />
                </div>
            </div>
            <div>
                <TinyMceTextEditor
                    editorRef={editorRef}
                    imageUploadHandler={imageUploader.upload}
                    initialValue={initialValue.theoreticalBackgroundDescription}
                />
            </div>
            <div className="row mt-2">
                <div className="col d-flex align-items-center mt-3">
                    <p className="d-inline-block mb-0 small">
                        ※ 이론적 배경 및 용어와 관련된 참고 자료가 있다면 작성해
                        보세요. 여러 개의 참고 자료를 추가할 수 있어요.
                    </p>
                    <a
                        className="py-1 px-4 text-decoration-none border border-danger rounded-4 fs-6 text-danger ms-2"
                        href="https://jobshopper.notion.site/5a983aa657ab4082b451542f7fa0433e"
                        target="_blank"
                    >
                        Tip! 자료 탐색방법 살펴보기
                    </a>
                </div>
            </div>
            <div className="row justify-content-center">
                <ReferenceGroup
                    initialValue={references}
                    onChange={(references) => setReferences(references)}
                />
            </div>
            <div className="row justify-content-end mt-2">
                <div className="col text-end my-auto">
                    <span className="text-danger small">
                        버튼을 클릭해야 작성이 완료됩니다.
                    </span>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-outline-primary rounded-4"
                        type="button"
                        onClick={() => submitForm()}
                    >
                        작성&수정 완료
                    </button>
                </div>
            </div>
        </>
    );
}
