import { useState } from 'react';
import { Col, Container, Row, ThemeProvider } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import SchoolRecordModal from '@components/SchoolRecordModal';
import SchoolRecordUploadGuide from '@components/SchoolRecordUploadGuide';
import SchoolRecordCard from '@components/SchoolRecordCard';

import '@styles/pages/schoolRecordPage.scss';
import { PATH } from '../ProjectRoutes';

export default function SchoolRecordPage() {
    const navigate = useNavigate();

    const gotoLandingPage = () => {
        navigate(PATH.LANDING);
    };

    // modal related;
    const [schoolRecordModalProps, setSchoolRecordModalProps] = useState<
        ModalProps<SchoolRecordModalProps>
    >({
        modalShow: false,
        data: null,
    });

    function openSchoolRecordModal(
        grade: number,
        uploadedSchoolRecord?: string,
    ) {
        setSchoolRecordModalProps({
            modalShow: true,
            data: { grade, uploadedSchoolRecord },
        });
    }

    function closeSchoolRecordModal() {
        setSchoolRecordModalProps({
            data: null,
            modalShow: false,
        });
    }

    return (
        <div id="schoolRecordPage" className="page-base">
            <Container className="go-back-btn-container">
                <div className="go-back-btn" onClick={gotoLandingPage}>
                    <span className="material-icons">arrow_back_ios</span>
                    <span> 뒤로가기</span>
                </div>
            </Container>
            <Container className="semester-report-page-container">
                <div className="content-container">
                    <div className="content-title-first">
                        <span className="main-title">생기부 관리</span>
                        <span>
                            ※분석을 원하는 학년에 생기부를 제출해주세요. (동일한
                            생기부 업로드)
                        </span>
                    </div>
                    <Row className="content-box">
                        {[1, 2, 3].map((grade) => (
                            <Col lg={4} md={4} sm={12}>
                                <SchoolRecordCard
                                    grade={grade}
                                    openSchoolRecordModal={
                                        openSchoolRecordModal
                                    }
                                />
                            </Col>
                        ))}
                    </Row>
                    <div className="content-title-second">
                        생기부 업로드 가이드
                    </div>
                    <div className="content-description d-lg-none">
                        {' '}
                        생기부 업로드 가이드는 PC를 기준으로 하고 있습니다.
                        <br></br>
                        원활한 생기부 제출을 위해 PC 사용을 권장합니다.
                    </div>
                    <div className="content-line"></div>
                    <SchoolRecordUploadGuide />
                </div>
            </Container>
            {
                <SchoolRecordModal
                    closeModal={closeSchoolRecordModal}
                    {...schoolRecordModalProps}
                />
            }
        </div>
    );
}
