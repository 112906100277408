import '@styles/components/assignmentCard.scss';

import React, { useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { moment } from '@utils';
import { AssignmentTypeValue } from '@constants';
import { PATH, resolveRoute } from '../ProjectRoutes';
import AssignmentModal from './AssignmentModal';
import { useAssignmentFacade } from '@facades';

interface AssignmentModalProps {
    modalShow: boolean;
    prevAssignmentType: string;
    currentAssignmentType: string;
    actionType: string;
    assignmentId: number;
    assignmentReportId: number | null;
}

const dateFormat = 'YYYY. MM. DD';
const dueDateFormat = 'YYYY. MM. DD a hh:mm';

const AssignmentTypeDisplay = {
    DESIGN_RESEARCH_ACTIVITY: '탐구 활동 설계',
    WRITE_RESEARCH_SUBJECT_FIRST_DRAFT: '탐구 과제 초안 작성',
    WRITE_RESEARCH_SUBJECT_FINAL_DRAFT: '탐구 과제 최종본 작성',
    SUBMIT_RESEARCH_SUBJECT_FINAL_DRAFT: '학교에 최종',
};

type AssignmentProgressStep = Partial<Assignment> & {
    display: string[];
};

export default function AssignmentCard({
    issuedSubject,
    onAssignmentReportUpdated,
}: {
    issuedSubject: IssuedSubjectSummary;
    onAssignmentReportUpdated: () => void;
}) {
    const navigate = useNavigate();

    // subject related;
    const backgroundFullName = issuedSubject.backgroundInEnglish
        ? `${issuedSubject.background}(${issuedSubject.backgroundInEnglish})`
        : issuedSubject.background;
    const issuedAt = moment(issuedSubject.issuedAt).format(dateFormat);

    // assignment related;
    const assignments = issuedSubject.assignments;
    const assignment = assignments[assignments.length - 1];
    const assignmentFacade = useAssignmentFacade();
    const dueDate = moment(assignment.dueDate).format(dueDateFormat);

    const isFinalStep =
        assignment.type === AssignmentTypeValue.SubmitResearchSubjectFinalDraft;
    const isBeforeSubmit =
        (assignment.isRequested && !assignment.isSubmitted) ||
        (isFinalStep && !assignment.isSubmitted);
    const isAfterSubmit =
        assignment.isRequested &&
        assignment.isSubmitted &&
        assignment.report &&
        !assignment.report.feedback;
    const isCompleted =
        (assignment.isRequested &&
            assignment.isSubmitted &&
            assignment.report) ||
        (isFinalStep && assignment.isSubmitted);
    const typeIndexedAssignments: TypeIndexedAssignment = {};
    assignments.forEach((assignment) => {
        typeIndexedAssignments[assignment.type] = assignment;
    });

    const assignmentProgressSteps: AssignmentProgressStep[] = [
        {
            display: ['탐구 활동', '설계 과제'],
            ...typeIndexedAssignments[
                AssignmentTypeValue.DesignResearchActivity
            ],
        },
        {
            display: ['탐구 과제', '초안'],
            ...typeIndexedAssignments[
                AssignmentTypeValue.WriteResearchSubjectFirstDraft
            ],
        },
        {
            display: ['탐구 과제', '최종본'],
            ...typeIndexedAssignments[
                AssignmentTypeValue.WriteResearchSubjectFinalDraft
            ],
        },
    ];

    // modal related;
    const initialModalProps = {
        modalShow: false,
        prevAssignmentType: '',
        currentAssignmentType: '',
        actionType: '',
        assignmentId: null,
        assignmentReportId: null,
    };

    const modalPropsByAssignmentType = {
        DESIGN_RESEARCH_ACTIVITY: initialModalProps,
        WRITE_RESEARCH_SUBJECT_FIRST_DRAFT: {
            modalShow: true,
            prevAssignmentType: '탐구활동 설계',
            currentAssignmentType: '탐구과제 초안',
        },
        WRITE_RESEARCH_SUBJECT_FINAL_DRAFT: {
            modalShow: true,
            prevAssignmentType: '탐구과제 초안',
            currentAssignmentType: '탐구과제 최종본',
        },
        SUBMIT_RESEARCH_SUBJECT_FINAL_DRAFT: initialModalProps,
    };

    const [assignmentModalProps, setAssignmentModalProps] =
        useState<AssignmentModalProps | null>(null);

    const openAssignmentModal = (
        actionType: string,
        assignmentType: string,
        assignmentId: number,
        assignmentReportId: number | null,
    ) => {
        // if (step.report) {
        //     assignmentReportId = step.report.id;
        // }

        setAssignmentModalProps({
            // @ts-ignore
            ...modalPropsByAssignmentType[assignmentType],
            actionType: actionType,
            assignmentId,
            assignmentReportId,
        });
    };

    function getFolderIconName(step: AssignmentProgressStep) {
        if (step.isSubmitted) {
            if (step.report && step.report.feedback) {
                return 'folder_special';
            } else {
                return 'drive_folder_upload';
            }
        }
        return 'folder_open';
    }

    function getFolderIconClass(step: AssignmentProgressStep) {
        if (step.isRequested || step.isSubmitted) {
            return 'after';
        }
        return 'before';
    }
    async function createFinalAssignmentReport(assignmentId: AssignmentId) {
        await assignmentFacade.completeAssignment(assignmentId);
        onAssignmentReportUpdated();
    }

    async function handleSubmitButtonClick() {
        if (assignment.type === AssignmentTypeValue.DesignResearchActivity) {
            return navigate(
                resolveRoute(PATH.ASSIGNMENT_REPORT_FORM__V1, {
                    assignmentId: assignment.id,
                }),
            );
        } else if (isFinalStep) {
            await createFinalAssignmentReport(assignment.id);
        } else {
            return openAssignmentModal(
                '제출',
                assignment.type,
                assignment.id,
                null,
            );
        }
    }

    function handleIconClick(
        assignmentStatus: string,
        step: AssignmentProgressStep,
    ) {
        if (!step.id || !step.report?.id) {
            return;
        }
        if (
            assignmentStatus === 'BEFORE_REQUESTED' ||
            assignmentStatus === 'BEFORE_SUBMITTED'
        ) {
            return;
        }

        if (step.display[0] === '탐구 활동') {
            return navigate(
                resolveRoute(PATH.ASSIGNMENT_REPORT_FORM__V1, {
                    assignmentId: step.id as number,
                }),
            );
        } else {
            if (step.display[1] === '초안') {
                return openAssignmentModal(
                    '수정',
                    AssignmentTypeValue.WriteResearchSubjectFirstDraft,
                    step.id,
                    step.report.id,
                );
            } else if (step.display[1] === '최종본') {
                return openAssignmentModal(
                    '수정',
                    AssignmentTypeValue.WriteResearchSubjectFinalDraft,
                    step.id,
                    step.report.id,
                );
            }
        }
    }

    function closeAssignmentModal() {
        if (!assignmentModalProps) {
            return;
        }

        setAssignmentModalProps({
            ...assignmentModalProps,
            modalShow: false,
        });
    }

    useEffect(() => {
        if (assignmentModalProps === null) {
            return;
        }

        if (!assignmentModalProps.modalShow) {
            onAssignmentReportUpdated();
        }
    }, [assignmentModalProps]);

    return (
        <Col lg={4} md={6} xs={12}>
            <Card className="assignment-card-base">
                <Card.Body className="card-container">
                    <div className="card-label">No. {assignment.id}</div>
                    <div className="card-label">주제 원리명</div>
                    <div className="card-title">{backgroundFullName}</div>
                    <div className="card-date">{issuedAt}</div>
                    <div className="card-label">세특 기입 과목</div>
                    <div className="card-item-box">
                        <div className="text-box square card-item">
                            {issuedSubject.appliedSchoolSubject
                                ? issuedSubject.appliedSchoolSubject.name
                                : '기타'}
                        </div>
                    </div>
                    <div className="card-label">과제 진척도</div>
                    <div className="card-status-box">
                        {assignmentProgressSteps.map((progressStep, index) => {
                            const determineStatus = (
                                progressStep: AssignmentProgressStep,
                            ) => {
                                return !progressStep.isRequested
                                    ? 'BEFORE_REQUESTED'
                                    : !progressStep.isSubmitted
                                    ? 'BEFORE_SUBMITTED'
                                    : !progressStep.report?.feedback
                                    ? 'BEFORE_FEEDBACK'
                                    : 'COMPLETED';
                            };

                            const currentStatus = determineStatus(progressStep);

                            return (
                                <React.Fragment key={index}>
                                    {index !== 0 &&
                                        (currentStatus ===
                                        'BEFORE_REQUESTED' ? (
                                            <div className="card-status-line before"></div>
                                        ) : currentStatus ===
                                              'BEFORE_SUBMITTED' ||
                                          currentStatus ===
                                              'BEFORE_FEEDBACK' ? (
                                            <div className="card-status-line pending"></div>
                                        ) : (
                                            <div className="card-status-line after"></div>
                                        ))}
                                    <div
                                        className="card-status-icon-box"
                                        onClick={() =>
                                            handleIconClick(
                                                currentStatus,
                                                progressStep,
                                            )
                                        }
                                    >
                                        <div
                                            className={`material-icons folder-icon ${getFolderIconClass(
                                                progressStep,
                                            )}`}
                                        >
                                            {getFolderIconName(progressStep)}
                                        </div>
                                        {progressStep.display.map(
                                            (displayChunk, innerIndex) => (
                                                <p
                                                    className="card-status-icon-text"
                                                    key={innerIndex}
                                                >
                                                    {displayChunk}
                                                </p>
                                            ),
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="card-bottom-button-box">
                        {isAfterSubmit && !isFinalStep && (
                            <div className="assignment-status">
                                과제 피드백 대기
                            </div>
                        )}
                        {isBeforeSubmit && (
                            <button
                                className="sub card-button"
                                onClick={handleSubmitButtonClick}
                            >
                                <p className="submit-button-text">
                                    {AssignmentTypeDisplay[assignment.type]}
                                    제출하기
                                </p>
                                {!isFinalStep ? (
                                    <p className="submit-button-date">
                                        {dueDate} 까지
                                    </p>
                                ) : (
                                    ''
                                )}
                            </button>
                        )}
                        {isFinalStep && isCompleted && (
                            <div className="assignment-status">
                                학교 최종 제출 완료
                            </div>
                        )}
                        <button
                            className="card-button"
                            onClick={() =>
                                navigate(
                                    resolveRoute(PATH.ISSUED_SUBJECT_DETAIL, {
                                        id: issuedSubject.id,
                                    }),
                                )
                            }
                        >
                            {' '}
                            주제 확인
                        </button>
                    </div>
                </Card.Body>
            </Card>
            {assignmentModalProps && (
                <AssignmentModal
                    closeModal={closeAssignmentModal}
                    {...assignmentModalProps}
                />
            )}
        </Col>
    );
}
