import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@store';
import { SectionTitle, SectionWrapper } from '@pages/pro12/components';
import { ColumnSingle } from '.';
import { formatDate, replaceStep } from '@pages/pro12/helpers';
import lodash from 'lodash';

export default function SectionA() {
    const location = useLocation();

    const data = useAppSelector(({ pro12 }) => pro12.researchOverviewForm);

    function getLink(section: string) {
        return replaceStep(location, 0, `#${section}`);
    }

    return (
        <SectionWrapper id={'A'}>
            <SectionTitle>A. 개요 검토</SectionTitle>
            <ColumnSingle title={'제목'} variant={'danger'} link={getLink('A')}>
                {data.presentationTitle}
            </ColumnSingle>
            <ColumnSingle
                title={'부제목'}
                variant={'danger'}
                link={getLink('A')}
            >
                {data.presentationSubtitle}
            </ColumnSingle>
            <ColumnSingle
                title={'탐구유형'}
                variant={'danger'}
                link={getLink('B')}
            >
                {data.presentationType}
            </ColumnSingle>
            <ColumnSingle
                title={'과목명'}
                variant={'danger'}
                link={getLink('C')}
            >
                {data.relatedSchoolSubject}
            </ColumnSingle>{' '}
            <ColumnSingle
                title={'관련 단원'}
                variant={'danger'}
                link={getLink('D')}
            >
                {data.relatedSchoolSubjectUnits.map((unit, index) => (
                    <div key={index}>{unit}</div>
                ))}
            </ColumnSingle>
            <ColumnSingle
                title={'관련 학습 요소'}
                variant={'danger'}
                link={getLink('E')}
            >
                {data.relatedLearningElements.map(
                    ({ learningElement }, index, arr) => (
                        <span className="me-1" key={index}>
                            {learningElement}
                            {index !== arr.length - 1 ? ',' : ''}
                        </span>
                    ),
                )}
            </ColumnSingle>{' '}
            <ColumnSingle
                title={'탐구 목차'}
                variant={'danger'}
                link={getLink('F')}
            >
                <ol className="ps-3 lh-base">
                    <li>서론</li>
                    <ol className="ps-3">
                        <li>탐구 동기 및 탐구 목적</li>
                        <li>탐구 문제</li>
                        <li>탐구 방법</li>
                    </ol>
                    <li>본론</li>
                    <ol className="ps-3">
                        {data.contents.map((content, index) => (
                            <li key={index}>{content.value}</li>
                        ))}
                    </ol>
                    <li>결론</li>
                    <ol className="ps-3">
                        <li>탐구 내용 정리</li>
                        <li>어려움과 극복</li>
                        <li>배우고 느낀 점</li>
                    </ol>
                </ol>
            </ColumnSingle>{' '}
            <ColumnSingle
                title={'제출일(발표일)'}
                variant={'danger'}
                link={getLink('G')}
            >
                {!lodash.isEmpty(data.dueDate) ? formatDate(data.dueDate) : ''}
            </ColumnSingle>{' '}
            <ColumnSingle
                title={'탐구기간'}
                variant={'danger'}
                link={getLink('H')}
            >
                {data.plannings.map((planning, index) => (
                    <div key={index}>
                        {planning.content},{' '}
                        {planning.startDate
                            ? formatDate(planning.startDate)
                            : ''}{' '}
                        ~ {planning.endDate ? formatDate(planning.endDate) : ''}
                    </div>
                ))}
            </ColumnSingle>
        </SectionWrapper>
    );
}
