import { Modal } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';

import { useAssignmentFacade } from '@facades';

import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import 'src/assets/scss/components/assignmentModal.scss';
import { 피드백도장가져오기 } from '@pages/pro12/helpers';

const Answers = ['상', '중', '하'];

function matchFileDownloadUrlScheme(url: string) {
    const currentScheme = window.location.protocol;
    if (currentScheme === 'https:') {
        return url.replace('http:', 'https:');
    }
    return url;
}

interface FileToUpload {
    name: string;
}

export default function AssignmentModal({
    modalShow,
    closeModal,
    prevAssignmentType,
    currentAssignmentType,
    actionType,
    assignmentId,
    assignmentReportId,
}: {
    modalShow: boolean;
    closeModal: () => void;
    prevAssignmentType: string;
    currentAssignmentType: string;
    actionType: string;
    assignmentId: number;
    assignmentReportId: number | null;
}) {
    const [fileToUpload, setFileToUpload] = useState<FileToUpload | null>();
    const [uploadedFile, setUploadedFile] = useState<string | null>();
    const [uploadedFileName, setUploadedFileName] = useState<string | null>();
    const [feedback, setFeedback] = useState<AssignmentReportFeedback | null>();
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [isSubmitCompleted, setIsSubmitCompleted] = useState<boolean>(false);

    const uploadInputElementRef = useRef<HTMLInputElement>(null);

    const assignmentFacade = useAssignmentFacade();

    const isInitialUpload = !!fileToUpload && !uploadedFile;
    const isReUpload = !!fileToUpload && !!uploadedFile;

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files) {
            setFileToUpload(e.target.files[0] as any);
        }
    };

    const handleSubmit = async () => {
        if (!fileToUpload) {
            return;
        }

        if (actionType === '수정' && assignmentReportId) {
            if (feedback?.stamp) {
                const report = await assignmentFacade.resubmitAssignmentReport(
                    assignmentReportId,
                    fileToUpload,
                );
            } else {
                const report = await assignmentFacade.modifyAssignmentReport(
                    assignmentReportId,
                    fileToUpload,
                );
            }
        } else if (actionType === '제출') {
            await assignmentFacade.submitAssignmentReport(
                assignmentId,
                fileToUpload,
            );
        }

        setIsSubmitCompleted(true);
    };

    const formatUploadedFileName = (fileURL: string) => {
        const splited = decodeURI(fileURL).split('/');
        return splited[splited.length - 1];
    };

    async function initAssignmentReportInfo() {
        if (!assignmentReportId) {
            clearFileModalProperty();
            return;
        }
        const assignmentReport = await assignmentFacade.getAssignmentReport(
            assignmentReportId,
        );
        setFeedback(assignmentReport.feedback);
        setUploadedFile(assignmentReport.file);
        setUploadedFileName(formatUploadedFileName(assignmentReport.file));
        setIsApproved(assignmentReport.isApproved);
    }

    function clearFileModalProperty() {
        setFileToUpload(undefined);
        setFeedback(null);
        setUploadedFile(null);
        setUploadedFileName(null);
    }

    function handleUploadButtonClick() {
        if (
            uploadInputElementRef === null ||
            uploadInputElementRef.current === null
        ) {
            return;
        }

        uploadInputElementRef.current.click();
    }

    function getAnswerClassName(
        question: string,
        answer: string,
        index: number,
    ) {
        if (feedback && feedback.questionnaires) {
            return `${
                feedback.questionnaires[question] === answer
                    ? 'purple-text'
                    : ''
            } ${index !== Answers.length - 1 ? 'right-bar' : ''}`;
        } else {
            return '';
        }
    }

    useEffect(() => {
        initAssignmentReportInfo();
        if (!modalShow) {
            clearFileModalProperty();
        }
    }, [modalShow]);

    return (
        <Modal
            show={modalShow}
            onHide={closeModal}
            className="assignment-modal-base"
            centered
        >
            <Modal.Header className="assignment-modal-header">
                <button
                    className="material-icons modal-close-button z-10"
                    onClick={closeModal}
                    type="button"
                >
                    close
                </button>
            </Modal.Header>
            {isSubmitCompleted ? (
                <>
                    <div className="pt-[77px] pb-[63px] text-[20px] text-[#777] text-center">
                        최종 과제물 제출이 완료되었습니다.
                        <br />
                        수고하셨습니다👏
                    </div>
                    <div className="text-center">
                        <button
                            onClick={closeModal}
                            className="w-[150px] h-[40px] rounded-[26px] text-[#777] text-[18px] border-2 border-[#777] mb-[8px]"
                        >
                            확인
                        </button>
                    </div>
                </>
            ) : (
                <Modal.Body className="assignment-modal-body">
                    {uploadedFile && (
                        <div>
                            <a
                                href={matchFileDownloadUrlScheme(uploadedFile)}
                                target="_blank"
                                className="file-download-btn"
                                rel="noreferrer"
                            >
                                {uploadedFileName}
                                <VerticalAlignBottomIcon className="fs-1 ml-[10px] text-[#FFADAD]" />
                            </a>
                        </div>
                    )}
                    {!isApproved && (
                        <div className="modal-input-file mt-5">
                            <input
                                type="file"
                                id="report-upload"
                                ref={uploadInputElementRef}
                                onChange={handleFileInputChange}
                            />
                            <button
                                onClick={handleUploadButtonClick}
                                className={`correction-btn ${
                                    uploadedFile ? 'text-[16px]' : 'text-[20px]'
                                }`}
                            >
                                {isReUpload
                                    ? decodeURI(fileToUpload.name)
                                    : uploadedFile
                                    ? '업로드 파일 수정하기'
                                    : '파일 업로드 하기'}
                                <UpgradeIcon className="fs-1 ml-[10px]" />
                            </button>

                            {isInitialUpload && (
                                <div>
                                    <a
                                        href={matchFileDownloadUrlScheme(
                                            fileToUpload.name,
                                        )}
                                        target="_blank"
                                        className="file-download-btn text-[16px]"
                                        rel="noreferrer"
                                    >
                                        {fileToUpload &&
                                            decodeURI(fileToUpload.name)}
                                        <VerticalAlignBottomIcon className="fs-1 ml-[10px] text-[#FFADAD]" />
                                    </a>
                                </div>
                            )}
                        </div>
                    )}

                    {feedback && (
                        <>
                            <div className="feedback-description">
                                피드백 확인
                            </div>
                            <div className="feedback-container">
                                <div className="feedback-contents">
                                    <div className="feedback-content">
                                        <div className="feedback-title">
                                            총평
                                        </div>
                                        <div className="row align-itmes-center">
                                            <div className="col-8">
                                                <textarea
                                                    className="h-100 input-text mt-1 py-2 w-100 bg-white"
                                                    defaultValue={
                                                        feedback.comprehensiveEvaluation
                                                    }
                                                    disabled
                                                />
                                            </div>
                                            <div className="col border rounded-2 ms-3 me-2 p-0">
                                                <div className="d-flex flex-column border-[#4521FF] border-[1px] rounded-[5px]">
                                                    {feedback.stamp && (
                                                        <>
                                                            <div className="h-75">
                                                                <img
                                                                    className="w-100 h-100"
                                                                    src={
                                                                        피드백도장가져오기(
                                                                            feedback
                                                                                .stamp
                                                                                .name,
                                                                            '사각',
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            {feedback.stamp
                                                                .type ===
                                                            'disapproval' ? (
                                                                <h5 className="text-center border-t-[1px] border-[#4521ff] py-1 w-100 fw-bold mb-0 text-purple text-[18px]">
                                                                    다시!
                                                                </h5>
                                                            ) : (
                                                                <h5 className="text-center border-t-[1px] border-[#4521ff] py-1 w-100 fw-bold mb-0 text-purple text-[18px]">
                                                                    패스!
                                                                </h5>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {!feedback && (
                        <button
                            onClick={handleSubmit}
                            className={`submit-btn ${
                                fileToUpload
                                    ? 'text-[#777] !border-[#FF6D6D] !mt-[20px] !mb-[33px]'
                                    : 'text-[#ddd] border-[#ddd]'
                            } `}
                        >
                            <span
                                className={`material-icons-outlined me-3 ${
                                    fileToUpload
                                        ? 'text-[#FF6D6D]'
                                        : 'text-[#ddd]'
                                }`}
                            >
                                check_circle
                            </span>
                            제출하기
                        </button>
                    )}
                </Modal.Body>
            )}
        </Modal>
    );
}
