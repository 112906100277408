import { client } from './client';

export async function callMarkOnboarding(studentId: StudentId) {
    const { data } = await client.patch(
        '/students_app/supporters/mark-onboarding/',
        null,
        { params: { clientStudentId: studentId } },
    );
    return data;
}
