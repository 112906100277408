import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { usePro12Context } from '@pages/pro12/Pro12Context';
import { pro12Slice, useAppDispatch } from '@store';

function TransitionWrapper({
    show,
    children,
    ...rest
}: {
    show: any;
    children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) {
    const transitionClasses = {
        enter: 'animate__animated',
        enterActive: 'animate__fadeIn',
        exit: 'animate__animated',
        exitActive: 'animate__fadeOut',
    };
    const ref = useRef(null);

    return (
        <CSSTransition
            nodeRef={ref}
            in={show}
            timeout={0}
            classNames={transitionClasses}
            unmountOnExit
        >
            <div ref={ref} {...rest}>
                {children}
            </div>
        </CSSTransition>
    );
}

export function _useOptionalField({
    defaultShow = true,
}: {
    defaultShow?: boolean;
} = {}) {
    const [shouldShow, setShouldShow] = useState(defaultShow);

    function render(
        wrapperProps: Partial<
            Omit<
                React.ComponentProps<typeof TransitionWrapper>,
                'show' | 'children'
            >
        > = {},
    ) {
        return (children: React.ReactNode) => {
            return (
                <TransitionWrapper show={shouldShow} {...wrapperProps}>
                    {children}
                </TransitionWrapper>
            );
        };
    }

    function show() {
        setShouldShow(true);
    }

    function hide() {
        setShouldShow(false);
    }

    return {
        render,
        show,
        hide,
        shouldShow,
    };
}

export function useOptionalField(
    ...params: Parameters<typeof _useOptionalField>
) {
    const { render, show, hide, shouldShow } = _useOptionalField(...params);

    function renderTogglingButton() {
        return shouldShow ? (
            <>
                <button
                    type={'button'}
                    className={'btn btn-outline-secondary rounded-5'}
                    onClick={() => hide()}
                >
                    건너뛰기
                </button>
            </>
        ) : (
            <>
                <button
                    type={'button'}
                    className={'btn btn-outline-primary rounded-5'}
                    onClick={() => show()}
                >
                    작성하기
                </button>
            </>
        );
    }

    return {
        render,
        renderTogglingButton,
    };
}

export function useBeforeTempSave(beforeTempSave: () => void) {
    const { currentStep, eventEmitter } = usePro12Context();
    const dispatch = useAppDispatch();

    function saveCurrentStep() {
        dispatch(pro12Slice.actions.setCurrentStep(currentStep));
    }

    useEffect(() => {
        function handle() {
            saveCurrentStep();
            beforeTempSave();
        }

        eventEmitter.on('before-temp-save', handle);
        return () => eventEmitter.off('before-temp-save', handle);
    }, [currentStep, eventEmitter, beforeTempSave]);
}
