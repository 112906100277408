import '@styles/pages/newStep3RenewalPage.scss';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { useAssignmentFacade } from '@facades';
import { step3페이지Url얻기 } from '@helpers';

import DownloadDocumentsModal from './components/DownloadDocumentsModal';

function 참고문헌({ 참고문헌 }) {
    if (참고문헌?.참고문헌종류 === '보도자료') {
        return (
            <>
                <div>{참고문헌?.참고문헌종류}</div>
                <div>
                    <span>{참고문헌?.저자명}. </span>
                    <span>({참고문헌?.발행일}). </span>
                    <span>{참고문헌?.제목}. </span>
                    <span className="italic">{참고문헌?.신문사명}</span>
                    <a
                        className="text-[#4397f7] underline decoration-2"
                        target="_blank"
                        href={참고문헌?.URL}
                        rel="noreferrer"
                    >
                        {참고문헌?.URL}
                    </a>
                </div>
            </>
        );
    } else if (참고문헌?.참고문헌종류 === '학술자료') {
        return (
            <>
                <div>{참고문헌?.참고문헌종류}</div>
                <div>
                    <span>{참고문헌?.저자명}. </span>
                    <span>({참고문헌?.발행연도}). </span>
                    <span>{참고문헌?.제목}. </span>
                    <span>{참고문헌?.간행물명}. </span>
                    <span className="italic">{참고문헌?.권}</span>
                    <span>({참고문헌?.호}).</span>
                    <span>{참고문헌?.수록면수}.</span>
                </div>
            </>
        );
    } else if (참고문헌?.참고문헌종류 === '영상자료') {
        return (
            <>
                <div>{참고문헌?.참고문헌종류}</div>
                <div>
                    <span>{참고문헌?.채널명}. </span>
                    <span>({참고문헌?.게시일}). </span>
                    <span className="italic">{참고문헌?.제목} </span>
                    <span>[{참고문헌?.영상}]</span>
                    <a
                        className="text-[#4397f7] underline decoration-2"
                        target="_blank"
                        href={참고문헌?.URL}
                        rel="noreferrer"
                    >
                        {참고문헌?.URL}
                    </a>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div>{참고문헌?.참고문헌종류}</div>
                <div>
                    <span>{참고문헌?.저자명}. </span>
                    <span>({참고문헌?.출판연도}). </span>
                    <span className="italic">{참고문헌?.제목}. </span>
                    <span>{참고문헌?.출판사}</span>
                </div>
            </>
        );
    }
}

function 피드백확인하기버튼({ 클릭시실행할함수 = () => {} }) {
    return (
        <button
            onClick={클릭시실행할함수}
            className="h-[29px] p-2 pl-3 pr-3 flex justify-between items-center text-white bg-purple rounded-lg ml-[15px]"
            type="button"
        >
            <span className="ml-3 mr-3">피드백 확인하기</span>
            <ThumbUpAltIcon className="text-purple bg-white p-[2px] rounded-full" />
        </button>
    );
}

function 다운로드모달사용() {
    const [속성, 속성변경] = useState({
        modalShow: false,
        data: null,
    });

    function 열기() {
        속성변경({
            ...속성,
            modalShow: true,
        });
    }

    function 닫기() {
        속성변경({
            ...속성,
            modalShow: false,
        });
    }

    return {
        속성,
        열기,
        닫기,
    };
}

export default function Step3ReviewPage() {
    const { assignmentId } = useParams();
    const [과제, 과제변경] = useState();

    const 과제제출데이터 = 과제?.report?.data.제출된데이터;

    const {
        속성: 다운로드모달속성,
        열기: 다운로드모달열기,
        닫기: 다운로드모달닫기,
    } = 다운로드모달사용();

    const [
        활성화된피드백데이터필드추적정보,
        활성화된피드백데이터필드추적정보변경,
    ] = useState(['서론']);

    const [피드백필드데이터, 피드백필드데이터변경] = useState([]);

    const { getAssignment } = useAssignmentFacade();

    function step3페이지로이동하기() {
        let page, step, subStep;
        page = `${활성화된피드백데이터필드추적정보[0]}페이지`;
        if(활성화된피드백데이터필드추적정보.length > 1) {
            subStep = `${활성화된피드백데이터필드추적정보[2]}`
            switch(활성화된피드백데이터필드추적정보[1]) {
                case '이론적배경목록': 
                    step = 1;
                case '탐구내용목록':
                    step = 2;
            }
        }

        
        window.location.href = step3페이지Url얻기({
            studentId: 과제.studentId,
            issuedSubjectId: 과제.issuedSubjectId,
            assignmentId: 과제.id,
            page,
            step, 
            subStep,
        });
    }

    function 현재활성화된피드백항목명얻기() {
        if (활성화된피드백데이터필드추적정보.length === 1) {
            return 활성화된피드백데이터필드추적정보[0];
        } else {
            const [대분류, 항목, 순서] = 활성화된피드백데이터필드추적정보;
            const 본론데이터 = 과제제출데이터?.본론데이터;
            const 피드백대상항목 = 본론데이터[항목];
            switch (항목) {
                case '이론적배경목록':
                    return 피드백대상항목[순서].이론명;
                case '탐구내용목록':
                    return 피드백대상항목[순서].목차;
            }
        }
    }

    function 피드백필드데이터얻기() {
        if (!과제?.report?.feedback) {
            return [];
        }

        let 필드데이터 = 과제.report.feedback;
        for (let 필드키 of 활성화된피드백데이터필드추적정보) {
            if (!필드데이터 || !필드데이터[필드키]) {
                return [];
            }

            필드데이터 = 필드데이터[필드키];
        }
        return 필드데이터;
    }

    function 피드백데이터활성화(...인자값목록) {
        활성화된피드백데이터필드추적정보변경(인자값목록);
    }

    useEffect(function 로드() {
        (async function () {
            const 과제데이터 = await getAssignment(assignmentId);
            과제변경(과제데이터);
        })();
    }, []);

    useEffect(function 피드백필드데이터초기화() {
        피드백필드데이터변경(피드백필드데이터얻기());
    }, [과제, 활성화된피드백데이터필드추적정보]);

    if (!과제제출데이터) {
        return <></>;
    }

    return (
        <div id="new-step3-renewal-page">
            <div className="flex justify-center pt-[27px] pb-[100px]">
                <div className="w-[858px] bg-white rounded-[20px] py-[46px] pr-[20px] pl-[42px] text-[18px]">
                    <header className="text-center color-[#333] pb-[103px]">
                        <h4 className="text-[24px] font-bold pb-[12px] mb-0">
                            {과제제출데이터.개요데이터.탐구제목}
                        </h4>
                        <h5 className="text-[24px]">
                            {과제제출데이터.개요데이터.탐구부제목
                                ? 과제제출데이터.개요데이터.탐구부제목
                                : '-'}
                        </h5>
                    </header>

                    <section className="pb-[104px]">
                        <header className="flex items-center pb-[49px]">
                            <h4 className="text-[24px] text-purple font-bold mb-0">
                                I. 서론
                            </h4>
                            <피드백확인하기버튼
                                클릭시실행할함수={() =>
                                    피드백데이터활성화('서론')
                                }
                            />
                        </header>

                        <article style={{ whiteSpace: 'pre-wrap' }}>
                            {과제제출데이터.서론데이터}
                        </article>
                    </section>

                    <section className="pb-[50px]">
                        <header className="pb-[39px]">
                            <h4 className="text-[24px] text-purple font-bold mb-0">
                                II. 본론
                            </h4>
                        </header>
                        <h5 className="text-purple text-[20px] pb-[40px] font-medium">
                            <span className="text-purple pe-3">1.</span> 이론적
                            배경
                        </h5>

                        {과제제출데이터.본론데이터.이론적배경목록.map(
                            (이론적배경, 인덱스) => (
                                <div key={인덱스} className="pb-[40px]">
                                    <div className="flex items-center pb-[10px]">
                                        <li className="font-medium">
                                            {이론적배경.이론명}
                                        </li>
                                        <피드백확인하기버튼
                                            클릭시실행할함수={() =>
                                                피드백데이터활성화(
                                                    '본론',
                                                    '이론적배경목록',
                                                    인덱스,
                                                )
                                            }
                                        />
                                    </div>
                                    <p className="mb-0 pb-[10px] px-2">
                                        {이론적배경.텍스트}
                                    </p>
                                    <div className="grid grid-cols-2">
                                        {이론적배경.이미지목록.map(
                                            (이미지링크, 인덱스) => (
                                                <img
                                                    key={인덱스}
                                                    src={이미지링크}
                                                    alt={이미지링크}
                                                />
                                            ),
                                        )}
                                    </div>
                                </div>
                            ),
                        )}

                        <h5 className="text-purple text-[20px] pb-[40px] font-medium">
                            <span className="pr-2">2.</span>탐구 내용
                        </h5>
                        {과제제출데이터.본론데이터.탐구내용목록.map(
                            (탐구내용, 인덱스) => (
                                <div key={인덱스} className="pb-[40px]">
                                    <div className="flex items-center pb-[10px]">
                                        <span className="font-medium">
                                            ({인덱스 + 1}){' '}
                                            {
                                                과제제출데이터.개요데이터
                                                    .탐구내용목록[인덱스]
                                            }
                                        </span>
                                        <피드백확인하기버튼
                                            클릭시실행할함수={() =>
                                                피드백데이터활성화(
                                                    '본론',
                                                    '탐구내용목록',
                                                    인덱스,
                                                )
                                            }
                                        />
                                    </div>
                                    <p className="mb-0 pb-[10px] px-2">
                                        {탐구내용.텍스트}
                                    </p>
                                    <div className="grid grid-cols-2">
                                        {탐구내용.이미지목록.map(
                                            (이미지링크, 인덱스) => (
                                                <img
                                                    key={인덱스}
                                                    src={이미지링크}
                                                    alt={이미지링크}
                                                />
                                            ),
                                        )}
                                    </div>
                                </div>
                            ),
                        )}
                    </section>

                    <section className="pb-[105px]">
                        <header className="flex items-center pb-[10px]">
                            <h4 className="text-[24px] text-purple font-bold mb-0">
                                III. 결론
                            </h4>
                            <피드백확인하기버튼
                                클릭시실행할함수={() =>
                                    피드백데이터활성화('결론')
                                }
                            />
                        </header>
                        <article style={{ whiteSpace: 'pre-wrap' }}>
                            {과제제출데이터.결론데이터}
                        </article>
                    </section>

                    <section className="lh-sm">
                        <header className="pb-[10px]">
                            <h4 className="text-[24px] text-purple font-bold mb-0">
                                참고문헌
                            </h4>
                        </header>
                        <article style={{ whiteSpace: 'pre-wrap' }}>
                            {과제제출데이터.참고문헌데이터}
                        </article>
                    </section>
                </div>

                <div className="w-[578px]">
                    <table>
                        <tbody>
                            <tr>
                                <td className="topic">과목명</td>
                                <td>{과제제출데이터.개요데이터.탐구과목}</td>
                            </tr>
                            <tr>
                                <td className="topic">탐구 유형</td>
                                <td>{과제제출데이터.개요데이터.탐구유형}</td>
                            </tr>
                            <tr>
                                <td className="topic">관련 단원</td>
                                <td>
                                    {과제제출데이터.개요데이터.관련단원목록.map(
                                        (단원, 인덱스) => {
                                            return (
                                                <span
                                                    key={`${단원}-${인덱스}`}
                                                    className="pr-2"
                                                >
                                                    {단원}
                                                </span>
                                            );
                                        },
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="topic">관련 학습요소</td>
                                <td>
                                    {과제제출데이터.개요데이터.관련학습요소목록.map(
                                        (학습요소, 인덱스) => {
                                            return (
                                                <div
                                                    key={`${학습요소}-${인덱스}`}
                                                >
                                                    {학습요소}
                                                </div>
                                            );
                                        },
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="topic">제출일</td>
                                <td>
                                    {과제제출데이터.개요데이터.발표일
                                        ? 과제제출데이터.개요데이터.발표일
                                        : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="p-[39px]">
                        <h4 className="mb-0 text-[20px] text-purple font-extrabold text-center pb-[14px]">
                            피드백 확인
                        </h4>

                        <p className="text-[20px] font-bold text-center pb-[14px]">
                            {현재활성화된피드백항목명얻기()}
                        </p>

                        <div className="bg-white w-[500px] rounded-[20px] px-[25px] py-[30px]">
                            <p className="leading-6 text-purple text-[14px] mb-0 pb-[37px]">
                                선생님께서 보완이 필요한 항목에 대해 피드백을
                                작성해 주셨어.
                                <br />
                                피드백을 확인하고 초안 수정을 해보자!
                                <br />
                                수정이 완료되면 꼭 "초안 다시 제출하기" 버튼을
                                눌러주세요.
                            </p>
                            <div className="feedback-checkbox-container leading-10 text-[18px] text-[#555] pb-[42px]">
                                {피드백필드데이터.map(
                                    ({ value, name, type }, index) =>
                                        type === 'checkbox' && (
                                            <div
                                                key={index}
                                                className="flex items-center"
                                            >
                                                <input
                                                    className="feedback-checkbox"
                                                    name="zz"
                                                    type="checkbox"
                                                    disabled
                                                    checked={value}
                                                />
                                                <span></span>
                                                <label
                                                    className={`ms-3
                                                        ${
                                                            value
                                                                ? 'text-purple'
                                                                : ''
                                                        }
                                                    `}
                                                >
                                                    {name}
                                                </label>
                                            </div>
                                        ),
                                )}
                            </div>
                            <div className="pb-[50px]">
                                <textarea
                                    defaultValue={
                                        피드백필드데이터[
                                            피드백필드데이터.length - 1
                                        ]?.value
                                    }
                                    className="text-purple bg-white resize-none w-full border-[1px] border-[#DDD] rounded-lg px-3 py-2.5 text-[16px]"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="text-center">
                            {/* 원장이 피드백 반려 시 노출 */}
                            {과제 && !과제.isApproved && (
                                <button
                                    onClick={step3페이지로이동하기}
                                    className="resubmit-btn !text-[16px]"
                                    type="button"
                                >
                                    초안 다시 제출하기
                                </button>
                            )}
                            <button
                                onClick={다운로드모달열기}
                                className="download-btn !text-[16px]"
                                type="button"
                            >
                                <VerticalAlignBottomIcon className="mr-4 !text-[2rem]" />
                                최종 과제 양식 다운로드
                            </button>
                            <DownloadDocumentsModal
                                code={String(과제.studentId)}
                                closeModal={다운로드모달닫기}
                                {...다운로드모달속성}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
