import { client } from './client';

export async function callUploadAssignmentReportFile({
    formData,
}: {
    formData: FormData;
}) {
    const { data: result } = await client.post(
        `/assignment_app/assignment-reports/uploads/`,
        formData,
        // {
        //     params: { clientStudentId: studentId },
        // },
    );
    return result;
}
