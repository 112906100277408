import React from 'react';

export default function SectionWrapper({
    id,
    children,
}: {
    id?: string;
    children: React.ReactNode;
}) {
    return (
        <section id={id} className={'row pt-5'}>
            {children}
        </section>
    );
}
