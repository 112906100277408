import lodash from 'lodash';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '@store';
import {
    BorderBox,
    SectionTitle,
    SectionWrapper,
} from '@pages/pro12/components';
import FormErrorMessage from '@components/FormErrorMessage';
import { ContentSummaryForm } from './types';

interface SummaryForm {
    summaries: ContentSummaryForm[];
}

export default function Summary({
    setCompletion,
    shouldShowValidationErrorMessage,
    setContentSummaryList,
}: {
    setContentSummaryList: (contentSummaryList: ContentSummaryForm[]) => void;
    setCompletion: (bool: boolean) => void;
    shouldShowValidationErrorMessage: boolean;
}) {
    const { researchContents, contentSummaryList } = useAppSelector(
        ({ pro12 }) => {
            const { contents: researchContents } = pro12.researchOverviewForm;
            const { researchContentSummaryList } = pro12;

            return {
                researchContents: pro12.researchOverviewForm.contents,
                contentSummaryList: lodash.isEmpty(researchContentSummaryList)
                    ? researchContents.map((content, index) => ({
                          content: content.value,
                          summary: '',
                      }))
                    : researchContentSummaryList,
            };
        },
    );
    const form = useForm<SummaryForm>({
        defaultValues: {
            summaries: contentSummaryList,
        },
    });

    const defaultCheckedBoxes: number[] = [];
    contentSummaryList.forEach(({ summary }, index) => {
        if (summary) {
            defaultCheckedBoxes.push(index);
        }
    });
    const [checkedBoxes, setCheckedBoxes] =
        useState<number[]>(defaultCheckedBoxes);

    function toggleCheckbox(index: number) {
        if (checkedBoxes.includes(index)) {
            setCheckedBoxes(checkedBoxes.filter((i) => i !== index));
        } else {
            setCheckedBoxes([...checkedBoxes, index]);
        }
    }

    function isChecked(index: number) {
        return checkedBoxes.includes(index);
    }

    const summaries = form.watch('summaries');
    useEffect(
        function propagate() {
            const toPropagate = checkedBoxes.map((index) => summaries[index]);
            setContentSummaryList(toPropagate);

            let completed = checkedBoxes.length > 0;
            checkedBoxes.forEach((summaryIndex) => {
                if (!summaries[summaryIndex].summary) {
                    completed = false;
                }
            });
            setCompletion(completed);
        },
        [JSON.stringify(summaries), checkedBoxes],
    );

    return (
        <SectionWrapper id={'A'}>
            <SectionTitle>A. 탐구 내용 정리</SectionTitle>
            {shouldShowValidationErrorMessage && (
                <FormErrorMessage
                    message={'탐구 내용을 정리하여 입력해주세요.'}
                />
            )}
            <p className="mb-0">
                탐구를 통해 무엇을 알게 되었나요? 알게 된 모든 내용을 정리해
                둡시다.
            </p>
            <div className="mt-2">
                <span className="d-flex text-primary align-items-center">
                    <span className="material-icons-outlined me-1 fs-3">
                        check_circle
                    </span>
                    <span>
                        탐구 문제를 모두 해결하였나요? ({checkedBoxes.length}/
                        {researchContents.length})
                    </span>
                </span>
            </div>
            <BorderBox variant={'danger'} className={'mt-2'}>
                {researchContents.map(({ value }, index) => (
                    <div className="form-check" key={index}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={isChecked(index)}
                            onChange={() => toggleCheckbox(index)}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                        >
                            <strong>탐구문제 {index + 1}. </strong> {value}
                        </label>
                        {isChecked(index) && (
                            <>
                                <div className="row">
                                    <p className="d-inline-block text-primary mt-2 mb-0">
                                        위의 탐구 문제를 해결하며 알게 된
                                        내용(핵심 내용)을 1~2문장으로 요약하여
                                        서술하세요.
                                    </p>
                                </div>
                                <input
                                    className="form-control m-2"
                                    type="text"
                                    placeholder="탐구한 내용을 요약해 주세요"
                                    {...form.register(
                                        `summaries.${index}.summary`,
                                    )}
                                />
                            </>
                        )}
                    </div>
                ))}
            </BorderBox>
        </SectionWrapper>
    );
}
