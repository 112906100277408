import lodash from 'lodash';

import { convertKeys } from './convert-keys';

import Moment from 'moment-timezone';

import 'moment/locale/ko';

function camelCase(s: string) {
    if (s.search(/[가-힣]/) !== -1) {
        return s;
    }
    return lodash.camelCase(s);
}

function snakeCase(s: string){
    if (typeof s === 'string' && s.search(/[가-힣]/) !== -1) {
        return s;
    }
    return lodash.snakeCase(s);
}

export function keysToCamel<T = any>(targetObject: T): T {
    return convertKeys<T>(camelCase, targetObject);
}

export function keysToSnake<T = any>(targetObject: T): T {
    return convertKeys<T>(snakeCase, targetObject);
}

export function objectFrom(
    queryString: string,
    transform: (obj: { [key: string]: string }) => any = (obj) => obj,
) {
    queryString = queryString.replace('?', '');
    const obj: { [key: string]: string } = {};
    queryString.split('&').forEach((attr) => {
        const [k, v] = attr.split('=');
        obj[k] = v;
    });
    return transform(obj);
}

Moment.locale('ko');

export function moment(...args: any) {
    return Moment(...args).tz('Asia/Seoul');
}

export function getSearchQueryFromParams(params: { [key: string]: any} = {}) : string {
    return Object.entries(params).reduce((pre, [key, value]) => {
        return `${pre}&${key}=${value}`;
    }, '');
};