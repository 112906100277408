import lodash from 'lodash';

import React, { useEffect, useRef, useState } from 'react';
import {
    SubmitErrorHandler,
    SubmitHandler,
    useFieldArray,
    useForm,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import FormErrorMessage from '@components/FormErrorMessage';
import { useRecommendationFacade } from '@facades';
import { pro12Slice, useAppDispatch, useAppSelector } from '@store';
import {
    BorderBox,
    CheckOptions,
    Navigator,
    SectionTitle,
    SectionWrapper,
    StringCheckOptions,
} from '../components';
import ContentInputGroup from './ContentInputGroup';
import Plannings from './Plannings';
import { usePro12Context } from '../Pro12Context';
import { useBeforeTempSave, useOptionalField } from '../hooks';
import { scrollToSection } from '@pages/pro12/helpers';

export default function ResearchOverviewPage() {
    const { issuedSubject, eventEmitter } = usePro12Context();
    const relatedSchoolSubjectsOfIssuedSubject =
        issuedSubject?.relatedSchoolSubjects ?? [];

    const [recommendedContents, setRecommendedContents] = useState<string[]>(
        [],
    );
    const { researchOverviewFormState } = useAppSelector(({ pro12 }) => ({
        researchOverviewFormState: pro12.researchOverviewForm,
    }));

    const dispatch = useAppDispatch();

    const form = useForm<ResearchOverviewForm>({
        defaultValues: researchOverviewFormState,
    });

    const skipSubtitle = useOptionalField();
    const skipPlannings = useOptionalField();

    const formRef = useRef<HTMLFormElement>(null);
    const {
        watch,
        setValue,
        register,
        control,
        getValues,
        formState: { errors, touchedFields },
        handleSubmit,
    } = form;
    const relatedSchoolSubjectWatch = watch('relatedSchoolSubject');

    const relatedLearningElementsFieldArray = useFieldArray({
        control: control,
        name: 'relatedLearningElements',
    });
    const { getTitleRecommendation, getSummaryRecommendation } =
        useRecommendationFacade();
    const [
        shouldShowValidationErrorMessageForFieldsNotRegistered,
        setShouldShowValidationErrorMessageForFieldsNotRegistered,
    ] = useState<boolean>(false);

    const [goToNextStep, setGoToNextStep] = useState(false);

    useEffect(
        function clearRelatedSchoolSubjectUnits() {
            if (touchedFields.relatedSchoolSubject) {
                setValue('relatedSchoolSubjectUnits', []);
            }
        },
        [touchedFields.relatedSchoolSubject, watch('relatedSchoolSubject')],
    );

    // RENDERING BUG!!!!!
    // user should click learning element checkbox twice to check it
    const learningElements = watch('relatedLearningElements', []);
    useEffect(
        function clearRelatedLearningElements() {
            const schoolSubjectUnits = watch('relatedSchoolSubjectUnits', []);
            const toSet = learningElements.filter((le) => {
                return schoolSubjectUnits.includes(le.schoolSubjectUnit);
            });
            if (!lodash.isEqual(toSet, learningElements)) {
                setValue(
                    'relatedLearningElements',
                    learningElements.filter((le) => {
                        return schoolSubjectUnits.includes(
                            le.schoolSubjectUnit,
                        );
                    }),
                );
            }
        },
        [learningElements, watch('relatedSchoolSubjectUnits')],
    );

    useEffect(() => {
        if (researchOverviewFormState) {
            Object.entries(researchOverviewFormState).forEach(
                ([key, value]) => {
                    setValue(key as keyof ResearchOverviewForm, value);
                },
            );
        }
    }, [researchOverviewFormState]);

    function storeForm() {
        dispatch(pro12Slice.actions.setResearchOverviewForm(getValues()));
    }

    useBeforeTempSave(storeForm);

    function getCurrentSchoolSubjectUnits() {
        const currentSchoolSubject = (
            relatedSchoolSubjectsOfIssuedSubject || []
        ).find(({ name }) => name === relatedSchoolSubjectWatch);

        if (currentSchoolSubject) {
            const units = currentSchoolSubject.units as SchoolSubjectUnit[];
            return units.length > 0
                ? units
                : [
                      {
                          name: '관련 단원이 없는 과목입니다.',
                          learningElements: [
                              '관련 학습요소가 없는 과목입니다.',
                          ],
                      },
                  ];
        } else {
            return [];
        }
    }

    function RelatedLearningElementsCheckOptions({
        schoolSubjectUnit,
    }: {
        schoolSubjectUnit: string;
    }) {
        const currentSchoolSubjectUnits = getCurrentSchoolSubjectUnits();
        if (currentSchoolSubjectUnits.length === 0) {
            return <></>;
        }
        const ssu = currentSchoolSubjectUnits.find(
            ({ name }) => name === schoolSubjectUnit,
        ) as SchoolSubjectUnit;

        function toggleRelatedLearningElement(
            learningElement: string,
            relatedSchoolSubject: string,
        ) {
            if (hasRelatedLearningElement(learningElement)) {
                relatedLearningElementsFieldArray.remove(
                    watch('relatedLearningElements', [])
                        .map(({ learningElement }) => learningElement)
                        .indexOf(learningElement),
                );
            } else {
                relatedLearningElementsFieldArray.append({
                    schoolSubjectUnit: relatedSchoolSubject,
                    learningElement: learningElement,
                });
            }
        }

        function hasRelatedLearningElement(relatedLearningElement: string) {
            return !!getValues('relatedLearningElements').find(
                ({ learningElement }) =>
                    learningElement === relatedLearningElement,
            );
        }

        function getInputProps(
            inputProps: React.InputHTMLAttributes<HTMLInputElement>,
            option: string,
            index: number,
        ) {
            return {
                ...inputProps,
                onChange: () => {
                    toggleRelatedLearningElement(option, ssu.name);
                },
                checked: hasRelatedLearningElement(option),
            };
        }

        return (
            <CheckOptions
                options={ssu.learningElements}
                getLabel={(option) => option}
                inputProps={{
                    className: 'form-check-input',
                    type: 'checkbox',
                }}
                getInputProps={getInputProps}
            />
        );
    }

    async function initPresentationTitleRecommendation() {
        if (!issuedSubject) {
            return;
        }

        const data = (await getTitleRecommendation(
            +issuedSubject.id,
        )) as string;
        form.clearErrors('presentationTitle');
        form.setValue('presentationTitle', data);
    }

    async function initContentsRecommendations() {
        if (!issuedSubject) {
            return;
        }

        const data = await getSummaryRecommendation(+issuedSubject.id);
        if (!Array.isArray(data)) {
            return;
        }
        // append new placeholder too
        setRecommendedContents([...data, '']);
    }

    const onFormValid: SubmitHandler<ResearchOverviewForm> = (data, e) => {
        e?.preventDefault();

        if (data.relatedLearningElements.length === 0) {
            setShouldShowValidationErrorMessageForFieldsNotRegistered(true);
            scrollToSection('#E');
            return;
        } else if (data.contents.filter(({ value }) => value).length === 0) {
            setShouldShowValidationErrorMessageForFieldsNotRegistered(true);
            scrollToSection('#F');
            return;
        }

        storeForm();
        setShouldShowValidationErrorMessageForFieldsNotRegistered(false);
        setGoToNextStep(true);
    };
    const onFormError: SubmitErrorHandler<ResearchOverviewForm> = (_, e) => {
        e?.preventDefault();
        setShouldShowValidationErrorMessageForFieldsNotRegistered(true);
    };

    const hasNoContents =
        watch('contents', []).filter(({ value }) => value).length === 0;

    function flushContents() {
        setRecommendedContents([]);
    }

    function cleanupEmptyFields() {
        setValue(
            'contents',
            watch('contents').filter(({ value }) => value),
        );
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit(onFormValid, onFormError)}>
            {/* A. 탐구 제목 */}
            <SectionWrapper id={'A'}>
                <SectionTitle>A. 탐구 제목</SectionTitle>
                <span className={'fs-6'}>
                    발급받은 탐구 주제를 바탕으로, 발표 혹은 보고서에 사용할
                    예상 제목을 달아보자. 필요 시 부제목을 달 수도 있어.
                </span>
                <div className={'row mt-3'}>
                    <BorderBox variant={'danger'}>
                        <div className={'row align-items-center'}>
                            <div className={'col-auto'}>
                                <label
                                    htmlFor={'presentationTitle'}
                                    className={'fw-semibold fs-5'}
                                >
                                    제목:
                                </label>
                            </div>
                            <div className={'col-6'}>
                                <input
                                    type={'text'}
                                    className={'form-control py-2'}
                                    placeholder={'제목을 작성해주세요.'}
                                    {...register('presentationTitle', {
                                        required: true,
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="presentationTitle"
                                    render={() => (
                                        <FormErrorMessage
                                            message={'제목을 입력해주세요.'}
                                        />
                                    )}
                                />
                            </div>
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    onClick={() =>
                                        initPresentationTitleRecommendation()
                                    }
                                    className={
                                        'btn btn-outline-primary rounded-5'
                                    }
                                >
                                    추천받기
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <span className="text-danger">
                                    AI 추천 결과는 작성에 참고하기 위한
                                    용도로서,
                                    <br />
                                    <span className="fw-bold">
                                        주제에 따라 적합도가 다르게 나올 수
                                        있습니다.
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={'row align-items-center mt-3'}>
                            <div className={'col-auto'}>
                                <label
                                    htmlFor={'presentationSubtitle'}
                                    className={'fw-semibold fs-5'}
                                >
                                    부제목:
                                </label>
                            </div>
                            {skipSubtitle.render({
                                className: 'col-6',
                            })(
                                <input
                                    type={'text'}
                                    className={'form-control py-2'}
                                    placeholder={
                                        '부제목을 작성해주세요. (선택)'
                                    }
                                    {...register('presentationSubtitle')}
                                />,
                            )}
                            <div className={'col-auto'}>
                                {skipSubtitle.renderTogglingButton()}
                            </div>
                        </div>
                    </BorderBox>
                </div>
            </SectionWrapper>

            {/* B. 탐구 유형 */}
            <SectionWrapper id={'B'}>
                <div className={'col-auto'}>
                    <SectionTitle>B. 탐구 유형</SectionTitle>
                </div>
                <div className={'col-auto'}>
                    <BorderBox variant={'danger'}>
                        <StringCheckOptions
                            options={['진로 연계', '교과 심화']}
                            fieldName={'presentationType'}
                            register={register}
                            inputProps={{
                                type: 'radio',
                                className: 'form-check-input',
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="presentationType"
                            render={() => (
                                <FormErrorMessage
                                    message={'탐구 유형을 선택해주세요.'}
                                />
                            )}
                        />
                    </BorderBox>
                </div>
            </SectionWrapper>

            {/* C. 과목명 */}
            <SectionWrapper id={'C'}>
                <div className={'col-auto'}>
                    <SectionTitle>C. 과목명</SectionTitle>
                </div>
                <div className={'col-auto'}>
                    <BorderBox variant={'danger'}>
                        {relatedSchoolSubjectsOfIssuedSubject ? (
                            <>
                                <StringCheckOptions
                                    options={relatedSchoolSubjectsOfIssuedSubject.map(
                                        ({ name }) => name,
                                    )}
                                    fieldName={'relatedSchoolSubject'}
                                    register={register}
                                    inputProps={{
                                        type: 'radio',
                                        className: 'form-check-input',
                                        onChange(e) {
                                            setValue(
                                                'relatedSchoolSubject',
                                                e.target.value,
                                            );
                                            setValue(
                                                'relatedSchoolSubjectUnits',
                                                [],
                                            );
                                            setValue(
                                                'relatedLearningElements',
                                                [],
                                            );
                                        },
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="relatedSchoolSubject"
                                    render={() => (
                                        <FormErrorMessage
                                            message={'과목을 선택해주세요.'}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            <p>선택 안함</p>
                        )}
                    </BorderBox>
                </div>
            </SectionWrapper>

            {/* D. 관련 단원 */}
            <SectionWrapper id={'D'}>
                <div className={'col-auto'}>
                    <SectionTitle>D. 관련 단원</SectionTitle>
                </div>
                <div className={'col-auto'}>
                    {relatedSchoolSubjectsOfIssuedSubject ? (
                        relatedSchoolSubjectWatch ? (
                            <BorderBox variant={'danger'}>
                                <StringCheckOptions
                                    options={getCurrentSchoolSubjectUnits().map(
                                        ({ name }) => name,
                                    )}
                                    fieldName={'relatedSchoolSubjectUnits'}
                                    register={register}
                                    inputProps={{
                                        type: 'checkbox',
                                        className: 'form-check-input',
                                    }}
                                    required={true}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="relatedSchoolSubjectUnits"
                                    render={() => (
                                        <FormErrorMessage
                                            message={'과목을 선택해 주세요.'}
                                        />
                                    )}
                                />
                            </BorderBox>
                        ) : (
                            <span>과목을 먼저 선택해주세요.</span>
                        )
                    ) : (
                        <div>
                            <p>선택 안 함</p>
                        </div>
                    )}
                </div>
            </SectionWrapper>

            {/* E. 관련 학습요소 */}
            <SectionWrapper id={'E'}>
                <div className={'col-auto'}>
                    <SectionTitle>E. 관련 학습요소</SectionTitle>
                </div>
                <div className={'col-auto'}>
                    {relatedSchoolSubjectsOfIssuedSubject ? (
                        form.watch('relatedSchoolSubjectUnits', []).length >
                        0 ? (
                            <BorderBox variant={'danger'}>
                                {form
                                    .watch('relatedSchoolSubjectUnits', [])
                                    .map((ssuName, index) => (
                                        <div
                                            key={ssuName}
                                            className={
                                                index === 0 ? '' : 'mt-3'
                                            }
                                        >
                                            <h5 className={'fw-semibold'}>
                                                {ssuName}
                                            </h5>
                                            <div className={'ps-4 mt-2'}>
                                                <RelatedLearningElementsCheckOptions
                                                    schoolSubjectUnit={ssuName}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                {shouldShowValidationErrorMessageForFieldsNotRegistered &&
                                    watch('relatedLearningElements', [])
                                        .length < 1 && (
                                        <p className={'text-danger mt-3 mb-0'}>
                                            관련 학습요소를 선택해주세요.
                                        </p>
                                    )}
                            </BorderBox>
                        ) : (
                            <span>관련 단원을 먼저 선택해주세요.</span>
                        )
                    ) : (
                        <div>
                            <p>선택 안 함</p>
                        </div>
                    )}
                </div>
            </SectionWrapper>

            {/* F. 탐구 목차 */}
            <SectionWrapper id={'F'}>
                <SectionTitle> F. 탐구 목차</SectionTitle>
                <div className={'row'}>
                    <BorderBox variant={'danger'}>
                        <h5 className={'fw-semibold'}>1. 서론</h5>
                        <div className={'ps-5 mt-3'}>
                            <h6 className={'mt-3'}>
                                1) 탐구 동기 및 탐구 목적
                            </h6>
                            <h6 className={'mt-3'}>2) 탐구 문제</h6>
                            <h6 className={'mt-3'}>3) 탐구 방법 </h6>
                        </div>
                        <h5 className={'fw-semibold mt-4'}>2. 본론</h5>
                        <div className={'ps-5 mt-3'}>
                            <h6 className={'mt-3'}>
                                1) 이론적 배경 및 용어 정리
                            </h6>
                            <div className={'row align-items-center mt-3'}>
                                <div className={'col-auto'}>
                                    <h6 className="mb-0">2) 탐구 내용</h6>
                                </div>
                                <div className={'col-auto px-0'}>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            initContentsRecommendations()
                                        }
                                        className={
                                            'btn btn-sm btn-outline-primary rounded-4'
                                        }
                                    >
                                        추천받기
                                    </button>
                                </div>
                                <div className={'col-auto'}>
                                    <span className="text-danger">
                                        AI 추천 결과는 작성에 참고하기 위한
                                        용도로서,{' '}
                                        <span className="fw-bold">
                                            주제에 따라 적합도가 다르게 나올 수
                                            있습니다.
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <ContentInputGroup
                                form={form}
                                contents={recommendedContents}
                                flushContents={() => flushContents()}
                            />
                            {shouldShowValidationErrorMessageForFieldsNotRegistered &&
                                hasNoContents && (
                                    <p className={'text-danger mt-1 mb-0 ps-5'}>
                                        탐구 내용을 적어주세요.
                                    </p>
                                )}

                            <h6 className={'mt-3'}>3) 어려움과 극복 </h6>
                        </div>
                        <h5 className={'fw-semibold mt-4'}>3. 결론</h5>
                        <div className={'ps-5 mt-3'}>
                            <h6 className={'mt-3'}>1) 탐구 내용 정리</h6>
                            <h6 className={'mt-3'}>2) 배우고 느낀 점</h6>
                        </div>
                    </BorderBox>
                </div>
            </SectionWrapper>

            {/* G. 제출일 또는 발표일 */}
            <SectionWrapper id={'G'}>
                <div className={'col-auto my-auto'}>
                    <SectionTitle>G. 제출일 또는 발표일</SectionTitle>
                </div>
                <div className={'col-auto'}>
                    <BorderBox variant={'danger py-2 px-5'}>
                        <input
                            className="form-control py-2"
                            type="date"
                            {...register('dueDate', { required: true })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="dueDate"
                            render={() => (
                                <FormErrorMessage
                                    message={
                                        '제출일 또는 발표일을 입력해주세요.'
                                    }
                                />
                            )}
                        />
                    </BorderBox>
                </div>
            </SectionWrapper>

            {/* H. 탐구기간 (선택) */}
            <SectionWrapper id={'H'}>
                <div className={'row align-items-center mb-3'}>
                    <div className={'col-auto'}>
                        <SectionTitle>H. 탐구기간 (선택)</SectionTitle>
                    </div>
                    <div className={'col-auto px-0'}>
                        {skipPlannings.renderTogglingButton()}
                    </div>
                </div>
                {skipPlannings.render({ className: 'row' })(
                    <BorderBox variant={'danger'}>
                        <Plannings form={form} />
                    </BorderBox>,
                )}
            </SectionWrapper>
            <Navigator
                onNextStepClick={() => {
                    cleanupEmptyFields();
                    formRef.current?.requestSubmit();
                }}
                goToNextStep={goToNextStep}
            />
        </form>
    );
}
