import React from 'react';
import { InfoOutlined } from '@mui/icons-material';

import Tooltip from './Tooltip';

export default function InfoTooltip({
    label,
    children,
}: {
    label: string;
    children: React.ReactNode;
}) {
    return (
        <Tooltip
            triggerNode={<InfoOutlined className={'ms-1 mb-1 text-primary'} />}
            label={<span>{label}</span>}
        >
            {children}
        </Tooltip>
    );
}
