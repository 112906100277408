import { Modal } from 'react-bootstrap';
import React from 'react';

import 'src/assets/scss/components/mockSATGradeModal.scss';

export default function MockSATGradeModal({
    modalShow,
    closeModal,
    data,
}: {
    modalShow: boolean;
    closeModal: () => void;
    data: Array<MockSATGrade> | null;
}) {
    return (
        <Modal
            show={modalShow}
            onHide={closeModal}
            className="mock-sat-grade-modal-base"
            centered
        >
            <Modal.Header className="mock-sat-grade-modal-header">
                <div
                    className="material-icons modal-close-button"
                    onClick={closeModal}
                >
                    close
                </div>
                <div className="modal-title">모의고사 등급 확인</div>
            </Modal.Header>
            <Modal.Body className="mock-sat-grade-modal-body">
                <table className="mock-sat-grade-info-container table">
                    <thead>
                        <tr>
                            <th>모의고사명</th>
                            <th>과목명</th>
                            <th>등급</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map(({ name, subjects }, i) => {
                                const rowSpan = subjects.length;
                                const names: Array<string> = [name];
                                const subjectNames: Array<string> = [];
                                const subjectScores: Array<number> = [];
                                subjects.forEach(
                                    ({ name: subjectName, score }, index) => {
                                        subjectNames.push(subjectName);
                                        subjectScores.push(score);

                                        if (index > 0) {
                                            names.push('');
                                        }
                                    },
                                );

                                return subjects.map((_, index) => (
                                    <tr key={`${i}:${index}`}>
                                        {index === 0 && (
                                            <td rowSpan={rowSpan}>{name}</td>
                                        )}
                                        <td>{subjectNames[index]}</td>
                                        <td>{subjectScores[index]}</td>
                                    </tr>
                                ));
                            })}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}
