import { ArrayPath, Path, useFieldArray, UseFormReturn } from 'react-hook-form';

type ExtractTypeFromArray<A> = A extends Array<infer R> ? R : unknown;

export default function useFieldArrayWithAddAndDeleteButton<
    T extends Record<string, any>,
    F extends keyof T,
>({
    form: { control, watch },
    name,
    validator = () => true,
}: {
    form: UseFormReturn<T>;
    name: F;
    validator?: (value: ExtractTypeFromArray<T[F]>) => boolean;
}) {
    const nameAsPath = name as unknown as Path<T>;
    const fieldArray = useFieldArray({
        control,
        name: name as unknown as ArrayPath<T>,
    });
    const values = watch(nameAsPath, [] as T[F]) as T[F];

    function isLast(index: number) {
        return index === fieldArray.fields.length - 1;
    }

    function hasMoreThanOne() {
        return fieldArray.fields.length > 1;
    }

    function isValid(index: number) {
        return validator(values[index]);
    }

    function showAddButton(index: number) {
        return isLast(index) && isValid(index);
    }

    function showDeleteButton(index: number) {
        return hasMoreThanOne() && isValid(index);
    }

    return {
        ...fieldArray,
        showAddButton,
        showDeleteButton,
        values,
    };
}
