import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import {
    useAssignmentFacade,
    useIssuedSubjectFacade,
    useLeanAIMetadataFacade,
} from '@facades';
import { pro12Slice, useAppDispatch, useAppSelector } from '@store';
import { EventEmitter, replaceStep } from './helpers';

export interface Pro12ContextValue {
    steps: {
        title: string;
        sections: {
            id: string;
            title: string;
        }[];
    }[];
    currentStep: number;
    setCurrentStep: (currentStep: number) => void;
    shouldShowRightBar: boolean;
    showRightBar: () => void;
    hideRightBar: () => void;
    nextStepPath: string | null;
    prevStepPath: string | null;
    leanAIMetadata: LeanAIMetadata;
    issuedSubject: IssuedSubjectDetail | null;
    assignment: Assignment | null;
    updateAssignmentReport: (data: any) => Promise<void>;
    createAssignmentReport: (data: any) => Promise<void>;
    eventEmitter: EventEmitter;
}

const Pro12Context = createContext<Pro12ContextValue>({} as Pro12ContextValue);

export default Pro12Context;

export const FIRST_STEP = 0;
export const LAST_STEP = 4;

const steps = [
    {
        title: '0. 개요',
        sections: [
            {
                id: 'A',
                title: 'A. 탐구 제목',
            },
            {
                id: 'B',
                title: 'B. 탐구 유형',
            },
            {
                id: 'C',
                title: 'C. 과목명',
            },
            {
                id: 'D',
                title: 'D. 관련 단원',
            },
            {
                id: 'E',
                title: 'E. 관련 학습요소',
            },
            {
                id: 'F',
                title: 'F. 탐구 목차',
            },
            {
                id: 'G',
                title: 'G. 제출일 또는 발표일',
            },
            {
                id: 'H',
                title: 'H. 탐구기간',
            },
        ],
    },
    {
        title: '1. 서론',
        sections: [
            {
                id: 'A',
                title: 'A. 탐구 동기 및 탐구 목적',
            },
            {
                id: 'B',
                title: 'B. 탐구 문제',
            },
            {
                id: 'C',
                title: 'C. 탐구 방법',
            },
        ],
    },
    {
        title: '2. 본론',
        sections: [
            {
                id: 'A',
                title: 'A. 이론적 배경 및 용어 정리',
            },
            {
                id: 'B',
                title: 'B. 탐구내용',
            },
            {
                id: 'C',
                title: 'C. 어려움과 극복',
            },
        ],
    },
    {
        title: '3. 결론',
        sections: [
            {
                id: 'A',
                title: 'A. 탐구 내용 정리',
            },
            {
                id: 'B',
                title: 'B. 배우고 느낀 점',
            },
        ],
    },
    {
        title: '4. 검토',
        sections: [],
    },
];

function getStep(pathname: string) {
    const step = pathname.split('/').at(-1) as string;
    return parseInt(step);
}

export function Pro12ContextProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const eventEmitter = useMemo(() => new EventEmitter(), []);

    const { assignmentId: assignmentIdInPro12Slice, lastEditedAt } =
        useAppSelector(({ pro12 }) => pro12);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [shouldShowRightBar, setShouldShowRightBar] = useState(false);
    function showRightBar() {
        setShouldShowRightBar(true);
    }

    function hideRightBar() {
        setShouldShowRightBar(false);
    }

    useEffect(() => {
        if (
            getStep(location.pathname) === 2 ||
            getStep(location.pathname) === 1
        ) {
            showRightBar();
        } else {
            hideRightBar();
        }
    }, [location.pathname]);

    const nextStepPath =
        currentStep < LAST_STEP ? replaceStep(location, currentStep + 1) : null;
    const prevStepPath =
        currentStep > FIRST_STEP
            ? replaceStep(location, currentStep - 1)
            : null;

    const [assignment, setAssignment] = useState<Assignment | null>(null);
    const { assignmentId } = useParams();

    const leanAIMetadataFacade = useLeanAIMetadataFacade();
    const leanAIMetadata = leanAIMetadataFacade.get();
    const issuedSubjectId = searchParams.get('issuedSubjectId');
    const [issuedSubject, setIssuedSubject] =
        useState<IssuedSubjectDetail | null>(null);
    const issuedSubjectFacade = useIssuedSubjectFacade(leanAIMetadata);
    const { getAssignment, submitAssignmentReport, modifyAssignmentReport } =
        useAssignmentFacade();

    async function updateAssignmentReport(data: any) {
        if (!assignment) {
            return;
        }

        const report = await modifyAssignmentReport(assignment.report.id, data);
        setAssignment({
            ...assignment,
            report,
        });
        return;
    }

    async function createAssignmentReport(data: any) {
        if (!assignment) {
            return;
        }

        const report = await submitAssignmentReport(assignment.id, data);
        setAssignment({
            ...assignment,
            report,
        });
        return report;
    }

    useEffect(() => {
        setCurrentStep(getStep(location.pathname));
    }, [location.pathname]);

    useEffect(() => {
        if (!issuedSubjectId || issuedSubject?.id === +issuedSubjectId) {
            return;
        }

        (async () => {
            const data = await issuedSubjectFacade.getIssuedSubjectDetail(
                +issuedSubjectId,
            );
            setIssuedSubject(data);
        })();
    }, [issuedSubjectId]);

    useEffect(() => {
        if (!assignmentId) {
            return;
        }

        const _assignmentId = +assignmentId;

        if (assignment?.id === _assignmentId) {
            return;
        }

        if (assignmentIdInPro12Slice !== _assignmentId) {
            dispatch(pro12Slice.actions.clear());
            dispatch(pro12Slice.actions.setAssignmentId(_assignmentId));
        }

        (async () => {
            const assignment = await getAssignment(_assignmentId);
            setAssignment(assignment);
            const reportData = assignment.report?.data;
            if (reportData && lastEditedAt < reportData.lastEditedAt) {
                dispatch(pro12Slice.actions.set(reportData));
            }
        })();
    }, [assignmentId, assignmentIdInPro12Slice]);

    return (
        <Pro12Context.Provider
            value={{
                steps,
                currentStep,
                setCurrentStep,
                nextStepPath,
                prevStepPath,
                issuedSubject,
                assignment,
                leanAIMetadata,
                updateAssignmentReport,
                createAssignmentReport,
                shouldShowRightBar,
                showRightBar,
                hideRightBar,
                eventEmitter,
            }}
        >
            {children}
        </Pro12Context.Provider>
    );
}

export function usePro12Context() {
    return useContext(Pro12Context);
}
