import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@store';
import {
    BorderBox,
    ReferenceCard,
    SectionTitle,
    SectionWrapper,
} from '@pages/pro12/components';
import { Column, ColumnSingle, ContentWithLink } from '.';
import { replaceStep } from '@pages/pro12/helpers';

export default function SectionB() {
    const location = useLocation();
    const { presentationTitle, contents: researchContents } = useAppSelector(
        ({ pro12 }) => pro12.researchOverviewForm,
    );
    const data = useAppSelector(({ pro12 }) => pro12.researchIntroductionForm);
    const application = `이에, ${presentationTitle}을(를) 주제로 선정하여 ${data.application}을(를) 실시하고자 한다.`;
    const motivationsAndApplication = [...data.motivations, application].join(
        ' ',
    );

    function getLink(section: string) {
        return replaceStep(location, 1, `#${section}`);
    }

    return (
        <SectionWrapper id={'B'}>
            <SectionTitle>B. 서론 검토</SectionTitle>
            <Column
                title={() => (
                    <h5 className="fw-bold text-center mb-0 fs-6">
                        탐구 동기 및<br />
                        탐구 목적
                    </h5>
                )}
                variant={'info'}
            >
                <ContentWithLink
                    className="border border-info rounded-2 p-2"
                    href={getLink('A')}
                >
                    <p className="mb-0">{motivationsAndApplication}</p>
                </ContentWithLink>
                {data.references.map((reference, index) => (
                    <div className="row mt-2" key={index}>
                        <BorderBox variant={'primary'}>
                            <ReferenceCard reference={reference} />
                        </BorderBox>
                    </div>
                ))}
            </Column>

            <ColumnSingle
                title={'탐구 문제'}
                variant={'info'}
                link={getLink('B')}
            >
                {researchContents.map((content, index) => (
                    <div key={index}>
                        <strong>탐구 문제 {index + 1}. </strong>
                        {`${data.objectives[index].objective}`}
                    </div>
                ))}
            </ColumnSingle>

            <Column title={'탐구 방법'} variant={'info'}>
                <ContentWithLink
                    className="border border-info rounded-2 p-2"
                    href={getLink('C')}
                >
                    (1) 연구유형: {data.types.join(', ')}
                </ContentWithLink>
                <ContentWithLink
                    className="border border-info rounded-2 p-2 mt-1"
                    href={getLink('C')}
                >
                    (2) 자료 수집 방법: {data.methods.join(', ')}
                </ContentWithLink>
            </Column>
        </SectionWrapper>
    );
}
