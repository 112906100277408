import { useState } from 'react';

import { callCompleteOrder, callGetOrderInfo } from '@client';

export function useOrderFacade() {
    const [order, setOrder] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    async function initOrder(orderId: OrderId) {
        setIsLoading(true);
        try {
            setOrder(await callGetOrderInfo(orderId));
        } finally {
            setIsLoading(false);
        }
    }

    async function completeOrder() {
        if (!order) {
            throw new Error('order must be initialized first.');
        }

        setIsLoading(true);
        try {
            await callCompleteOrder(order.id);
            setOrder({ ...order, status: 'COMPLETED' });
        } finally {
            setIsLoading(false);
        }
    }

    const isInitialized = !!order;
    const isCompleted = isInitialized && order.status === 'COMPLETED';

    return {
        isLoading,
        isInitialized,
        order,
        initOrder,
        completeOrder,
        isCompleted,
    };
}
