import { useState, useEffect } from 'react';

interface WithReferences {
    references: Reference[];
}

export default function useWithReferencesFormGroup<
    T extends WithReferences = WithReferences,
>(initialState: T[] = []) {
    const [forms, setForms] = useState<T[]>([]);

    function appendForm(form: T) {
        setForms((prev) => [...prev, form]);
    }

    function updateForm(index: number, form: T) {
        setForms((prev) =>
            prev.map((prevForm, i) => (i === index ? form : prevForm)),
        );
    }

    function removeForm(index: number) {
        setForms((prev) => prev.filter((_, i) => i !== index));
    }

    function removeReference(index: number, referenceIndex: number) {
        setForms((prev) =>
            prev.map((prevForm, i) =>
                i === index
                    ? {
                          ...prevForm,
                          references: prevForm.references.filter(
                              (_, i) => i !== referenceIndex,
                          ),
                      }
                    : prevForm,
            ),
        );
    }

    useEffect(function setInitialState() {
        setForms(initialState);
    }, []);

    return {
        forms,
        appendForm,
        updateForm,
        removeForm,
        removeReference,
    };
}
