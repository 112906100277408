import { useLeanAIMetadataFacade, useSemesterFacade } from '@facades';
import { useEffect, useState } from 'react';

export function useSemesterInfo(
    studentId: StudentId,
    grade: string,
    semester: string,
) {
    const leanAIMetadataFacade = useLeanAIMetadataFacade();
    const leanAIMetadata = leanAIMetadataFacade.get();
    const semesterFacade = useSemesterFacade(leanAIMetadata);

    const [semesterInfo, setSemesterInfo] = useState<SemesterInfo | void>();

    async function loadCurrentSemesterInfo() {
        const semesterInfo = await semesterFacade.getSemesterInfo({
            studentId,
            grade: +grade,
            semester: +semester,
        });
        setSemesterInfo(semesterInfo);
    }

    async function addTargetingMajor(major: Major) {
        await semesterFacade.addTargetingMajor({
            studentId,
            grade: +grade,
            semester: +semester,
            majorId: major.id,
        });
        await loadCurrentSemesterInfo();
    }

    async function removeTargetingMajor(majorId: MajorId) {
        await semesterFacade.removeTargetingMajor({
            studentId,
            grade: +grade,
            semester: +semester,
            majorId: majorId,
        });
        await loadCurrentSemesterInfo();
    }

    async function addKeyword(keyword: string) {
        await semesterFacade.addKeyword({
            studentId,
            grade: +grade,
            semester: +semester,
            keyword,
        });
        await loadCurrentSemesterInfo();
    }

    async function removeKeyword(keyword: string) {
        await semesterFacade.removeKeyword({
            studentId,
            keyword,
        });
        await loadCurrentSemesterInfo();
    }

    async function updateInterestedSchoolSubjects(
        schoolSubjectIds: SchoolSubjectId[],
    ) {
        await semesterFacade.updateInterestedSchoolSubjects({
            studentId,
            grade: +grade,
            semester: +semester,
            schoolSubjectIds,
        });
        await loadCurrentSemesterInfo();
    }

    async function removeInterestedSchoolSubject(
        schoolSubjectId: SchoolSubjectId,
    ) {
        await semesterFacade.removeInterestedSchoolSubject({
            studentId,
            grade: +grade,
            semester: +semester,
            schoolSubjectId: schoolSubjectId,
        });
        await loadCurrentSemesterInfo();
    }

    useEffect(
        function init() {
            if (!leanAIMetadataFacade.isReady) {
                return;
            }
            loadCurrentSemesterInfo();
        },
        [leanAIMetadataFacade.isReady, grade, semester],
    );

    return {
        semesterInfo,
        addTargetingMajor,
        removeTargetingMajor,
        addKeyword,
        removeKeyword,
        updateInterestedSchoolSubjects,
        removeInterestedSchoolSubject,
    };
}
