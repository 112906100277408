import { Round } from './round';
import { Participant } from './participant';
import { createTournamentRound } from './helpers';
import { InvalidRoundDataError } from './errors';

export class Tournament {
    rounds: Round[] = [];

    constructor(
        public participants: Participant[],
        private roundFactory: (
            tournament: Tournament,
        ) => Round = createTournamentRound,
    ) {}

    get isFinished() {
        return this.getCurrentRound().isFinished;
    }

    start() {
        this.createNextRound();
    }

    pick(target: number, roundNumber: number = this.getCurrentRound().number) {
        this.rounds[roundNumber - 1].pick(target);
    }

    unpick(roundNumber: number = this.getCurrentRound().number) {
        if (this.getCurrentRound().isNewlyCreated) {
            this.rounds.pop();
            roundNumber -= 1;
        }
        this.rounds[roundNumber - 1].unpick();
    }

    createNextRound(): Round {
        const round = this.roundFactory(this);
        this.rounds.push(round);
        return round;
    }

    getCurrentRound(): Round {
        let round = this.rounds[this.rounds.length - 1];
        if (round?.isFinished) {
            try {
                round = this.createNextRound();
            } catch (e) {
                if (e instanceof InvalidRoundDataError) {
                    return round;
                }
            }
        }
        return round;
    }
}
