import TextRadio from '../../components/TextRadio';
import TextInputGathering from '../../components/TextInputGathering';
import { UseFormReturn } from 'react-hook-form';
import { RetrospectionForm } from '@pages/pro12/ResearchConclusionPage/types';

export default function SchoolSubjectRelated({
    form,
}: {
    form: UseFormReturn<RetrospectionForm>;
}) {
    return (
        <>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="수업 시간에 이해가 잘 되지 않았던 부분을 이해하였다"
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    수업 시간에 이해가 잘 되지 않았던 부분을 이해하였다
                </label>
                {form.watch('summary') ===
                    '수업 시간에 이해가 잘 되지 않았던 부분을 이해하였다' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                어떤 부분이 잘 이해되지 않았었나요? 얼마나 잘
                                이해하게 되었나요?
                            </p>
                        </div>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '수업 시간에 $1이(가) 잘 이해되지 않았었는데, 본 탐구를 통해 $2을(를) 이해하게 되었다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '어떤 교과 내용',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '그 교과 내용의 무엇',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="학습(탐색)이 더 필요한 부분을 확인하였다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    학습(탐색)이 더 필요한 부분을 확인하였다.
                </label>
                {form.watch('summary') ===
                    '학습(탐색)이 더 필요한 부분을 확인하였다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            어떤 부분을 보완해야 할까요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1에 대한 이해를 심화하기 위하여 $2에 대하여 더 학습(탐색)할 필요성을 느꼈다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 교과 내용',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder: '무엇',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="더 공부하고 싶은 부분을 발견하였다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    더 공부하고 싶은 부분을 발견하였다.
                </label>
                {form.watch('summary') ===
                    '더 공부하고 싶은 부분을 발견하였다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            어떤 부분을 더 공부하고 싶나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '탐구를 통해 $1에 대하여 더 공부하고 싶어졌다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 교과 내용',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="수업 시간에 배운 내용을 실제로 적용해 현상·개념을 이해할 수 있게 되었다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    수업 시간에 배운 내용을 실제로 적용해 현상·개념을 이해할 수
                    있게 되었다.
                </label>
                {form.watch('summary') ===
                    '수업 시간에 배운 내용을 실제로 적용해 현상·개념을 이해할 수 있게 되었다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            어떤 부분을 더 공부하고 싶나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1을(를) 적용하여 $2을(를) 이해할 수 있게 되었다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 교과 내용',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder:
                                                            '무슨 현상-개념',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="교과 성적이 향상되었다."
                    {...form.register('summary')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    교과 성적이 향상되었다.
                </label>
                {form.watch('summary') === '교과 성적이 향상되었다.' && (
                    <>
                        <p className="step-description text-primary mt-1 mb-0">
                            어떤 과목의 성적이 얼마나 향상되었나요?
                        </p>
                        <TextRadio
                            setValue={(value) =>
                                form.setValue('description', value)
                            }
                            options={[
                                {
                                    render(setValue) {
                                        return (
                                            <TextInputGathering
                                                template={
                                                    '$1의 성적이 $2 향상되었다.'
                                                }
                                                inputProps={{
                                                    $1: {
                                                        type: 'text',
                                                        placeholder:
                                                            '관련 과목',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                    $2: {
                                                        type: 'text',
                                                        placeholder: '얼마나',
                                                        className:
                                                            'form-control form-control-sm d-inline-block rounded-3',
                                                    },
                                                }}
                                                onChange={(description) =>
                                                    setValue(description)
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    render(setValue) {
                                        return (
                                            <div className="col-7">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    onChange={(e) =>
                                                        setValue(e.target.value)
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    value="기타"
                    {...form.register('summary')}
                />
                <label className="form-check-label">기타</label>
                {form.watch('summary') === '기타' && (
                    <>
                        <div className="row">
                            <p className="d-inline-block text-primary mt-1 mb-0">
                                내용을 자유롭게 작성해보세요.
                            </p>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={true}
                                readOnly
                            />
                            <label className="form-check-label w-75">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="직접 입력하기"
                                    onChange={(e) =>
                                        form.setValue(
                                            'description',
                                            e.target.value,
                                        )
                                    }
                                />
                            </label>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
