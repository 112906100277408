import { useState } from 'react';
import MajorSelectModal from '@components/MajorSelectModal';

import closeImg from '@images/purple-close.png';

export default function TargetingMajors({
    majors,
    addTargetingMajor,
    removeTargetingMajor,
    universityData,
    majorData,
}: {
    majors: Major[];
    addTargetingMajor: (major: Major) => void;
    removeTargetingMajor: (id: MajorId) => void;
    universityData: LeanAIMetadata['universities'];
    majorData: LeanAIMetadata['majors'];
}) {
    const [showMajorSelectModal, setShowMajorSelectModal] =
        useState<boolean>(false);

    function openMajorSelectModal() {
        setShowMajorSelectModal(true);
    }

    function closeMajorSelectModal() {
        setShowMajorSelectModal(false);
    }

    return (
        <>
            <div className="content-box">
                {majors.map(({ name, university, id }, index) => (
                    <div className="text-box card" key={index}>
                        <button
                            className="absolute top-3 right-4 w-7 h-7 bg-transparent !p-0"
                            onClick={() => removeTargetingMajor(id)}
                        >
                            <img src={closeImg} alt="삭제" />
                        </button>

                        <p className="university-text">{university}</p>
                        <p className="major-text">{name}</p>
                    </div>
                ))}
                <button
                    className="!text-4xl bg-white text-purple border border-purple mb-[10px] !px-20"
                    onClick={openMajorSelectModal}
                >
                    +
                </button>
            </div>
            <MajorSelectModal
                closeModal={closeMajorSelectModal}
                modalShow={showMajorSelectModal}
                onSelect={addTargetingMajor}
                universityOptions={universityData ?? {}}
                majorOptions={majorData ?? {}}
            />
        </>
    );
}
