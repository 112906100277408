import '@styles/pages/majorCategoryExplorationTournament.scss';

import { useEffect, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useAppSelector } from '@store';
import { useMajorCategoryExplorationFacade } from '@facades';

import { PATH } from '../../ProjectRoutes';
import {
    TournamentComponent,
    ProgressRateBar,
    ExitModal,
    Loading,
} from './components/';
import { useTournament } from './components/tournament/useTournament';

const TOURNAMENT_TOTAL_LENGTH = 189;
const SPINNER_DURATION = 3000;
const MAX_TOURNAMENT_RESULT_LENGTH = 6;
const MAX_QUESTIONNAIRES_LENGTH = 4;

function getProcessCount(questionnaireLength: number) {
    return TOURNAMENT_TOTAL_LENGTH + questionnaireLength + 2;
}

export default function ExplorationPage() {
    const student = useAppSelector((state) => state.student);
    const navigate = useNavigate();
    const majorCategoryExplorationFacade = useMajorCategoryExplorationFacade();

    const [isCompleted, setIsCompleted] = useState(false);

    const {
        tournament,
        pick: pickTournamentSet,
        unpick: unpickTournamentSet,
    } = useTournament({
        onPicked: increaseProgressCount,
        onCompleted: handleTournamentCompleted,
    });

    const [progressCount, setProgressCount] = useState(0);
    const [maxProgressCount, setMaxProgressCount] = useState(
        getProcessCount(0),
    );
    const [exitModalProps, setExitModalProps] = useState<ModalProps<null>>({
        modalShow: false,
        data: null,
    });

    function unpick() {
        if (progressCount === 0) {
            return;
        }

        setProgressCount((v) => v - 1);
        unpickTournamentSet();
    }

    async function handleTournamentCompleted(majorCategoryIds: number[]) {
        await majorCategoryExplorationFacade.create(
            student.id,
            majorCategoryIds,
        );

        setIsCompleted(true);

        setTimeout(() => {
            navigate({
                pathname: PATH.MAJOR_CATEGORY_EXPLORATION_RESULT_PREVIEW,
                search: createSearchParams({
                    majorCategoryIds: majorCategoryIds.join(','),
                }).toString(),
            });
        }, SPINNER_DURATION);
    }

    function increaseProgressCount() {
        setProgressCount((v) => v + 1);
    }

    function openExitModal() {
        setExitModalProps({ ...exitModalProps, modalShow: true });
    }

    function closeExitModal() {
        setExitModalProps({ ...exitModalProps, modalShow: false });
    }

    function handleExit() {
        closeExitModal();
        navigateToInitializorPage();
    }

    function navigateToInitializorPage() {
        navigate(PATH.MAJOR_CATEGORY_EXPLORATION_INITIALIZOR);
    }

    function getMaxProgressCount(tournamentResultLength: number) {
        setMaxProgressCount(
            getProcessCount(tournamentResultLength * MAX_QUESTIONNAIRES_LENGTH),
        );
    }

    useEffect(
        function guardAlreadyExplorationCompletedUser() {
            if (
                majorCategoryExplorationFacade.isLoaded &&
                majorCategoryExplorationFacade.exploration
            ) {
                alert('이미 완료된 계열 검사 결과가 존재합니다.');
                navigate(PATH.MAJOR_CATEGORY_EXPLORATION_RESULT);
            }
        },
        [majorCategoryExplorationFacade.isLoaded],
    );

    useEffect(
        function initializeMaxProgressCount() {
            if (majorCategoryExplorationFacade.questionnaires) {
                getMaxProgressCount(MAX_TOURNAMENT_RESULT_LENGTH);
            }
        },
        [majorCategoryExplorationFacade.questionnaires],
    );

    return (
        <>
            {isCompleted ? (
                <Loading />
            ) : (
                <div className="body-wrapper">
                    <div className="mx-auto body-container">
                        <ProgressRateBar
                            total={maxProgressCount}
                            current={progressCount}
                        />
                    </div>
                    <section className="mx-auto body-container-large">
                        <div className="go-back-btn-container container absolute-go-back-btn">
                            <div
                                onClick={unpick}
                                className="go-back-btn text-black-50"
                            >
                                <span className="material-icons me-2">
                                    arrow_back
                                </span>
                                <span>이전 문항으로</span>
                            </div>
                        </div>
                        <div>
                            {tournament && (
                                <TournamentComponent
                                    tournament={tournament}
                                    pick={pickTournamentSet}
                                />
                            )}
                        </div>
                    </section>
                    <div className="mt-5 pt-5 text-center">
                        <button
                            onClick={openExitModal}
                            className="back-btn border-0 bg-transparent text-purple fw-bold fs-4"
                            type="button"
                        >
                            나가기
                        </button>
                    </div>
                    <ExitModal
                        {...exitModalProps}
                        closeModal={closeExitModal}
                        onExited={handleExit}
                    />
                </div>
            )}
        </>
    );
}
