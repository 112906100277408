import '@styles/pages/pro12/sideToolBars.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import IssuedSubjectDetail from '@components/IssuedSubjectDetail';
import Pro12Context from '@pages/pro12/Pro12Context';
import { researchReferenceTypes } from '../constants';
import { Portal, ResearchRecommendationList } from '.';

interface ResearchReferenceTypeValue {
    name: string;
    type: string;
}

function useOffCanvas({
    onHide = () => {},
    onShow = () => {},
}: {
    onHide?: () => void;
    onShow?: () => void;
} = {}) {
    const ref = useRef<HTMLDivElement>(null);
    const [isShown, setShown] = useState(false);

    useEffect(
        function addOffCanvasEventListeners() {
            const targetElement = ref.current;
            if (targetElement === null) {
                return;
            }

            function onHideHandler() {
                setShown(false);
                onHide();
            }

            function onShowHandler() {
                setShown(true);
                onShow();
            }

            const eventNameHandlerMap = {
                'hide.bs.offcanvas': onHideHandler,
                'show.bs.offcanvas': onShowHandler,
            } as {
                [key: string]: () => void;
            };

            Object.entries(eventNameHandlerMap).forEach(
                ([eventName, handler]) =>
                    targetElement.addEventListener(eventName, handler),
            );

            return function removeOffCanvasEventListeneres() {
                Object.entries(eventNameHandlerMap).forEach(
                    ([eventName, handler]) =>
                        targetElement.removeEventListener(eventName, handler),
                );
            };
        },
        [ref],
    );

    return {
        ref,
        isShown,
    };
}

function LeftBar({ children }: { children: React.ReactNode }) {
    const id = 'subject-display';
    const { ref, isShown } = useOffCanvas();

    function ToggleButton() {
        return (
            <button
                className={`btn btn-primary subject-display-button ${
                    isShown ? 'after' : 'before'
                }`}
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target={`#${id}`}
                aria-controls={id}
            >
                <span className="material-icons-outlined mb-2 icon">notes</span>
                <p>주제</p>
                <p>확인하기</p>
            </button>
        );
    }

    return (
        <div className="left-bar">
            {!isShown && <ToggleButton />}
            <div
                className="offcanvas offcanvas-start subject-display-wrapper"
                id={id}
                ref={ref}
                tabIndex={-1}
            >
                <div className="offcanvas-body p-0">{children}</div>
                {isShown && <ToggleButton />}
            </div>
        </div>
    );
}

function RightBar({ issuedSubjectId }: { issuedSubjectId: IssuedSubjectId }) {
    const id = 'research-references-display';
    const [typeValue, setSelectedTypeValue] =
        useState<ResearchReferenceTypeValue>(researchReferenceTypes[0]);
    const { ref, isShown } = useOffCanvas();

    function isActive(name: string) {
        return typeValue.name === name && isShown;
    }

    function handleResearchReferenceTypeButtonClick(
        typeValue: ResearchReferenceTypeValue,
    ) {
        setSelectedTypeValue(typeValue);
    }

    function ToggleButtonGroup() {
        return (
            <div className={`${isShown ? 'after' : 'before'} button-wrapper`}>
                {
                    <button
                        className={`close-icon-wrapper ${
                            isShown ? 'visible' : 'invisible'
                        }`}
                        data-bs-toggle="offcanvas"
                        data-bs-target={`#${id}`}
                        aria-controls={id}
                    >
                        <div className="close-icon material-icons-outlined">
                            arrow_circle_right
                        </div>
                    </button>
                }
                {researchReferenceTypes.map(
                    (typeValue: ResearchReferenceTypeValue, index) => (
                        <button
                            key={index}
                            onClick={() =>
                                handleResearchReferenceTypeButtonClick(
                                    typeValue,
                                )
                            }
                            className={`btn border-danger research-references-display-button ${
                                isActive(typeValue.name) ? 'active' : ''
                            }`}
                            data-bs-toggle={isShown ? '' : 'offcanvas'}
                            data-bs-target={isShown ? '' : `#${id}`}
                            aria-controls={isShown ? '' : id}
                            type="button"
                        >
                            <span>{typeValue.name}</span>
                        </button>
                    ),
                )}
            </div>
        );
    }

    return (
        <div className="right-bar">
            {!isShown && <ToggleButtonGroup />}
            <div
                id={id}
                ref={ref}
                className="offcanvas offcanvas-end border-danger research-references-display-wrapper"
                tabIndex={-1}
            >
                {isShown && <ToggleButtonGroup />}
                <div className="offcanvas-body">
                    <ResearchRecommendationList
                        type={typeValue.type}
                        issuedSubjectId={issuedSubjectId}
                    />
                </div>
            </div>
        </div>
    );
}

export default function SideToolbars() {
    const { shouldShowRightBar } = useContext(Pro12Context);
    const [searchParams] = useSearchParams();
    const issuedSubjectId = searchParams.get('issuedSubjectId');
    return (
        <Portal>
            {issuedSubjectId && (
                <div id="side-tool-bar-wrapper">
                    <LeftBar>
                        <IssuedSubjectDetail
                            issuedSubjectId={+issuedSubjectId}
                        />
                    </LeftBar>
                    {shouldShowRightBar && (
                        <RightBar issuedSubjectId={+issuedSubjectId} />
                    )}
                </div>
            )}
        </Portal>
    );
}
