import { useEffect, useState } from 'react';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';

import { useAssignmentFacade } from '@facades';

export function useAssignmentSummary() {
    const { getAssignmentProgressingCounts } = useAssignmentFacade();

    const [summary, setSummary] = useState<AssignmentProgressingSummary>({
        beforeRequest: 0,
        beforeSubmit: 0,
        afterSubmit: 0,
        completed: 0,
    });

    async function init(params: any) {
        const summary = await getAssignmentProgressingCounts(params);
        setSummary(summary);
    }

    return {
        init,
        summary,
    };
}

export function useEtcRadio<TFieldValues extends FieldValues>({
    fieldName,
    form,
    otherOptions = [],
    isEtcChecked,
}: {
    fieldName: string;
    form: UseFormReturn<TFieldValues>;
    otherOptions?: string[];
    isEtcChecked?: (value: string) => boolean;
}) {
    const [isChecked, setIsChecked] = useState(false);
    const [etcValue, setEtcValue] = useState<
        PathValue<TFieldValues, FieldPath<TFieldValues>>
    >('' as PathValue<TFieldValues, FieldPath<TFieldValues>>);
    const [options, setOptions] = useState<
        PathValue<TFieldValues, FieldPath<TFieldValues>>[]
    >(otherOptions as PathValue<TFieldValues, FieldPath<TFieldValues>>[]);
    const fieldNameAsPath = fieldName as FieldPath<TFieldValues>;
    const fieldWatch = form.watch(fieldNameAsPath, options[0]);

    useEffect(() => {});
    function check() {
        setIsChecked(true);
        form.setValue(fieldNameAsPath, etcValue);
    }

    function setValue(value: PathValue<TFieldValues, FieldPath<TFieldValues>>) {
        setEtcValue(value);
        form.setValue(fieldNameAsPath, value);
    }

    function registerRadio() {
        return {
            checked: isChecked,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                check();
            },
        };
    }

    function registerInput() {
        return {
            value: etcValue as string,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(
                    e.target.value as PathValue<
                        TFieldValues,
                        FieldPath<TFieldValues>
                    >,
                );
            },
        };
    }

    useEffect(
        function setInitialOption() {
            form.setValue(fieldNameAsPath, options[0]);
            setEtcValue('' as PathValue<TFieldValues, FieldPath<TFieldValues>>);
        },
        [options],
    );

    useEffect(
        function checkIfEtc() {
            if (fieldWatch && options.includes(fieldWatch)) {
                setIsChecked(false);
            }
        },
        [options, fieldWatch, setIsChecked],
    );

    useEffect(() => {
        if (isEtcChecked) {
            setIsChecked(isEtcChecked(etcValue));
        }
    }, [fieldWatch]);

    return {
        registerRadio,
        registerInput,
        isChecked,
        check,
        setValue,
        setOptions,
    };
}

export function useEtcCheckbox({
    fieldName,
    otherOptions = [],
    form,
}: {
    fieldName: string;
    otherOptions?: string[];
    form: UseFormReturn<ResearchIntroductionForm>;
}) {
    const [isChecked, setIsChecked] = useState(false);
    const [etcValue, setEtcValue] = useState('');
    const [options, setOptions] = useState(otherOptions || []);
    const fieldNameAsPath = fieldName as Path<ResearchIntroductionForm>;
    const fieldWatch = (form.watch(fieldNameAsPath) || []) as string[];

    function getCheckedNormalOptions() {
        return fieldWatch.filter((value) => options.includes(value));
    }

    function toggle() {
        const original = getCheckedNormalOptions();

        if (!isChecked) {
            form.setValue(fieldNameAsPath, [...original, etcValue]);
            setIsChecked(true);
        } else {
            form.setValue(fieldNameAsPath, original);
            setIsChecked(false);
        }
    }

    function setValue(value: string) {
        setEtcValue(value);
        if (isChecked) {
            form.setValue(fieldNameAsPath, [
                ...getCheckedNormalOptions(),
                value,
            ]);
        }
    }

    function registerCheckbox() {
        return {
            checked: isChecked,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                toggle();
            },
        };
    }

    function registerInput() {
        return {
            value: etcValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
            },
        };
    }

    return {
        registerCheckbox,
        registerInput,
        isChecked,
        setValue,
        setOptions,
    };
}
