import React from 'react';

export default function SectionTitle({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <h3 className="section-title fs-5 fw-bold mb-2 d-flex align-items-center">
            {children}
        </h3>
    );
}
