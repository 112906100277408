import React from 'react';

export default function BorderBox({
    children,
    variant,
    ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    variant: string;
}) {
    rest.className = `border border-${variant} rounded-4 p-4 ${
        rest.className ?? ''
    }`;
    return <div {...rest}>{children}</div>;
}
