import { Modal } from 'react-bootstrap';
import { zip } from 'lodash';

function 템플릿문장변환하기(내용목록, 템플릿) {
    let 문장 = 템플릿;
    내용목록.forEach((내용) => {
        문장 = 문장.replace(/\$\d/, 내용);
    });
    return 문장;
}

function 탐구동기문장으로만들기({ 탐구계기, 탐구범주 }) {
    return `${탐구계기[0]} ${탐구계기[1]} ${탐구범주[0]} ${탐구범주[1]} ${탐구범주[2]}`;
}

export default function 자기평가서모달({ 모달열림, 모달닫기, 보고서 }) {
    const 데이터 = 보고서.data;
    const 탐구동기목록 = 데이터.서론데이터.탐구동기목록;
    const 탐구내용목록 = 데이터.본론데이터.탐구내용목록;
    const 어려움과극복목록 = 데이터.결론데이터.어려움과극복목록;
    const 배우고느낀점목록 = 데이터.결론데이터.배우고느낀점목록;

    const 탐구동기문장목록 = 탐구동기목록.map(탐구동기문장으로만들기);
    const 탐구내용문장목록 = 탐구내용목록.map(({ 정리 }) => 정리);
    const 어려웠던지점문장목록 = 어려움과극복목록.map(({ 어려웠던지점 }) => {
        if (보고서.version === 3) {
            return 템플릿문장변환하기(
                어려웠던지점.내용값목록,
                어려웠던지점.템플릿,
            );
        } else {
            return 어려웠던지점.내용;
        }
    });
    const 극복한방법문장목록 = 어려움과극복목록.map(({ 극복한방법 }) => {
        if (보고서.version === 3) {
            return 템플릿문장변환하기(극복한방법.내용값목록, 극복한방법.템플릿);
        } else {
            return 극복한방법.내용;
        }
    });
    const 배우고느낀점문장목록 = 배우고느낀점목록.map((e) => {
        if (보고서.version === 3) {
            return 템플릿문장변환하기(e.내용값목록, e.템플릿);
        } else {
            return e.내용;
        }
    });

    return (
        <Modal show={모달열림} onHide={모달닫기} centered size="lg">
            <header className="py-5 text-center">
                <h1 className="flex items-center justify-center text-3xl font-bold pb-3">
                    <span className="bg-[#e1dbff] mt-3 pb-3 h-4 flex items-center mr-3.5 text-purple">
                        Bonus!
                    </span>
                    <span>자기평가서</span>
                </h1>
                <h2 className="text-lg text-[#777]">
                    선생님께 제출할 자기평가서가 필요한가요?
                    <br />
                    미루지 말아요, 여기 초안이 있잖아요? 한 방에 끝내봅시다!
                    <br />
                    (아래 초안을 복사/수정하여 사용하세요.)
                </h2>
            </header>

            <div className="bg-white p-4 rounded-lg border border-purple mb-8 space-y-5 text-lg w-2/3 mx-auto">
                <div className="relative">
                    <p className="absolute -left-40 w-28 text-purple text-end font-bold">
                        탐구동기
                    </p>
                    <p>{탐구동기문장목록.join(' ')}</p>
                </div>
                <div className="relative">
                    <p className="absolute -left-40 w-28 text-purple text-end font-bold">
                        배운내용정리
                    </p>
                    <p>{탐구내용문장목록.join(' ')}</p>
                </div>
                <div className="relative">
                    <p className="absolute -left-40 w-28 text-purple text-end font-bold">
                        어려움과극복
                    </p>
                    <p>
                        {zip(어려웠던지점문장목록, 극복한방법문장목록)
                            .flat()
                            .join(' ')}
                    </p>
                </div>
                <div className="relative">
                    <p className="absolute -left-40 w-28 text-purple text-end font-bold">
                        배우고느낀점
                    </p>
                    <p>{배우고느낀점문장목록.join(' ')}</p>
                </div>
            </div>

            <footer className="text-center pb-5">
                <button
                    className="bg-purple text-white rounded-xl px-40 py-4"
                    onClick={모달닫기}
                    type="button"
                >
                    확인 완료
                </button>
            </footer>
        </Modal>
    );
}
