import { UseFormReturn } from 'react-hook-form';
import {
    LearningPointForm,
    RetrospectionForm,
} from '@pages/pro12/ResearchConclusionPage/types';
import { BorderBox } from '@pages/pro12/components';
import Step1 from '@pages/pro12/ResearchConclusionPage/Step1';
import Step2 from '@pages/pro12/ResearchConclusionPage/Step2';
import React from 'react';

export function LPRDraft({
    setLearningPointFormRef,
    setRetrospectionFormRef,
    currentLearningPoint,
    insertHandler,
}: {
    setLearningPointFormRef: (form: UseFormReturn<LearningPointForm>) => void;
    setRetrospectionFormRef: (form: UseFormReturn<RetrospectionForm>) => void;
    currentLearningPoint: LearningPointForm['value'];
    insertHandler: () => void;
}) {
    return (
        <>
            <BorderBox variant={'danger'} className={'mt-4'}>
                <Step1
                    setFormRef={setLearningPointFormRef}
                    value={currentLearningPoint}
                />
            </BorderBox>

            {!!currentLearningPoint && (
                <BorderBox variant={'danger'} className={'mt-2'}>
                    <Step2
                        setFormRef={setRetrospectionFormRef}
                        currentLearningPoint={currentLearningPoint}
                    />
                </BorderBox>
            )}
            <div className={'row justify-content-center mt-3'}>
                <div className={'col-auto'}>
                    <button
                        type={'submit'}
                        onClick={() => insertHandler()}
                        className={
                            'btn btn-outline-primary rounded-5 py-2 px-5'
                        }
                    >
                        선택 및 작성 완료
                    </button>
                </div>
            </div>
            <div className="col text-center mt-1">
                <span className="text-danger small">
                    버튼을 클릭해야 작성이 완료됩니다.
                </span>
            </div>
        </>
    );
}
