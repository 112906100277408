import { useContext, useEffect, useState } from 'react';

import { pro12Slice, useAppDispatch, useAppSelector } from '@store';
import TBRCard from './TBRCard';
import TBRDraft from './TBRDraft';
import useWithReferencesFormGroup from './useWithReferencesFormGroup';
import { BorderBox, SectionTitle, SectionWrapper } from '../components';
import FormErrorMessage from '@components/FormErrorMessage';
import Pro12Context from '../Pro12Context';

export default function SectionA({
    setCompletion,
    shouldShowValidationErrorMessage,
}: {
    setCompletion: (bool: boolean) => void;
    shouldShowValidationErrorMessage: boolean;
}) {
    const { issuedSubject } = useContext(Pro12Context);

    const initialState = useAppSelector(
        ({ pro12 }) => pro12.researchTheoreticalBackgroundAndReferencesForms,
    );
    const { forms, appendForm, updateForm, removeForm, removeReference } =
        useWithReferencesFormGroup<TBRForm>(initialState);

    function getInitialForm() {
        const background = issuedSubject?.subject?.background;
        if (forms.length > 0 || !background) {
            return {
                theoreticalBackground: '',
                theoreticalBackgroundDescription: '',
            };
        } else {
            return {
                theoreticalBackground: `${background.name} (${background.englishName})`,
                theoreticalBackgroundDescription: background.content,
            };
        }
    }

    const dispatch = useAppDispatch();

    const [isWorkingOnNewForm, setIsWorkingOnNewForm] = useState(false);
    const [editingFormIndex, setEditingFormIndex] = useState(-1);

    function resetEditingFormIndex() {
        setEditingFormIndex(-1);
    }

    useEffect(
        function storeState() {
            dispatch(
                pro12Slice.actions.setTheoreticalBackgroundAndReferencesForms(
                    forms,
                ),
            );
        },
        [forms],
    );

    useEffect(
        function complete() {
            setCompletion(forms.length > 0);
        },
        [forms],
    );

    const [isInvalidFormSubmitted, setIsInvalidFormSubmitted] =
        useState<boolean>(false);
    function isValidDraft(draft: TBRForm) {
        return (
            draft.theoreticalBackground?.length > 0 &&
            draft.theoreticalBackgroundDescription?.length > 0
        );
    }
    function validateAndUpdate(index: number, draft: TBRForm) {
        if (isValidDraft(draft)) {
            updateForm(index, draft);
            resetEditingFormIndex();
            setIsInvalidFormSubmitted(false);
        } else {
            setIsInvalidFormSubmitted(true);
        }
    }

    function validateAndAppend(draft: TBRForm) {
        if (isValidDraft(draft)) {
            appendForm(draft);
            setIsWorkingOnNewForm(false);
            setIsInvalidFormSubmitted(false);
        } else {
            setIsInvalidFormSubmitted(true);
        }
    }

    return (
        <SectionWrapper id={'A'}>
            <SectionTitle>A. 이론적 배경 및 용어 정리</SectionTitle>
            {(shouldShowValidationErrorMessage || isInvalidFormSubmitted) && (
                <FormErrorMessage
                    message={'이론적 배경과 용어를 정리하여 입력해주세요.'}
                />
            )}
            <p className="my-1">
                탐구에서 다루는 주요 개념을 정의해 보자. 같은 용어일지라도
                학자나 분야에 따라 정의가 다양할 수 있으니 폭넓게 탐색해보아야
                해. <br />
                이론적 배경을 작성하는 과정에서 추가적으로 이해해야 하는
                개념이나 용어가 있다면 그 또한 정리해 두자.
            </p>
            {forms.map((value, index) => (
                <BorderBox variant="danger" key={index} className="mt-2">
                    <div className="row justify-content-center">
                        {editingFormIndex === index ? (
                            <TBRDraft
                                initialValue={value}
                                onComplete={(draft) => {
                                    validateAndUpdate(index, draft);
                                }}
                            />
                        ) : (
                            <TBRCard
                                onEditButtonClick={() =>
                                    setEditingFormIndex(index)
                                }
                                onDeleteButtonClick={() => removeForm(index)}
                                onReferenceRemoveButtonClick={(refIndex) =>
                                    removeReference(index, refIndex)
                                }
                                value={value}
                            />
                        )}
                    </div>
                </BorderBox>
            ))}
            {forms.length > 0 &&
                editingFormIndex === -1 &&
                !isWorkingOnNewForm && (
                    <div className="row mt-3 justify-content-center">
                        <div className="col-auto">
                            <button
                                className="btn btn-outline-danger rounded-5 fw-bold py-2 px-4"
                                type="button"
                                onClick={() => setIsWorkingOnNewForm(true)}
                            >
                                이론적 배경 및 용어 추가하기
                            </button>
                        </div>
                    </div>
                )}
            {((forms.length === 0 && issuedSubject) || isWorkingOnNewForm) && (
                <BorderBox variant="danger" className="mt-2">
                    <TBRDraft
                        initialValue={getInitialForm()}
                        onComplete={(draft) => {
                            validateAndAppend(draft);
                        }}
                    />
                </BorderBox>
            )}
        </SectionWrapper>
    );
}
