export const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

export const REDUX_LOCAL_STORAGE =
    process.env.REACT_APP_REDUX_LOCAL_STORAGE || 'hakzzong-supporter';

export const STEP3_FRONTEND_URL =
    process.env.REACT_APP_STEP3_FRONTEND_URL || 'http://localhost:3000';

export const NEW_HZPRO_URL =
    process.env.REACT_APP_NEW_HZPRO_URL || 'http://localhost:3000';
