import { useState } from 'react';
import { Container } from 'react-bootstrap';
import onboardingImage1 from '@images/pro12-onboarding-1.png';
import onboardingImage2 from '@images/pro12-onboarding-2.png';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@store';
import { useStudentFacade } from '@facades';
import { PATH, resolveRoute } from '../../ProjectRoutes';
import { getSearchQueryFromParams } from '@utils';

export default function OnboardingPage() {
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const studentFacade = useStudentFacade();
    const { assignmentId } = useParams();
    const [searchParams] = useSearchParams();
    const issuedSubjectId = searchParams.get('issuedSubjectId');
    const grade = searchParams.get('grade');
    const semester = searchParams.get('semester');

    const LAST_STEP = 3;

    const student = useAppSelector((state) => state.student);

    function goToAssignmentReportActivityPage({
        assignmentId,
        issuedSubjectId,
    }: {
        assignmentId: AssignmentId;
        issuedSubjectId: IssuedSubjectId;
    }) {
        return navigate({
            pathname:
                resolveRoute(PATH.PRO12_ASSIGNMENT_REPORT, {
                    assignmentId,
                    index: 0,
                }) + '/0',
            search: getSearchQueryFromParams({
                issuedSubjectId,
            }),
        });
    }
    async function startAssignmentReport() {
        if (assignmentId && issuedSubjectId) {
            await studentFacade.markOnBoard(student.id);
            goToAssignmentReportActivityPage({
                assignmentId: +assignmentId,
                issuedSubjectId: +issuedSubjectId,
            });
        }
    }

    function backToPrevious() {
        if (currentStep === 0 && grade && semester) {
            return navigate(
                resolveRoute(PATH.PRO12_ASSIGNMENT_LIST, {
                    grade,
                    semester,
                }),
            );
        } else {
            setCurrentStep(currentStep - 1);
        }
    }
    let CurrentComponent: React.ReactNode;
    switch (currentStep) {
        case 0:
            CurrentComponent = (
                <FirstOnboardingComponent onNext={() => setCurrentStep(1)} />
            );
            break;

        case 1:
            CurrentComponent = (
                <SecondOnboardingComponent onNext={() => setCurrentStep(2)} />
            );
            break;
        case 2:
            CurrentComponent = (
                <ThirdOnboardingComponent onNext={() => setCurrentStep(3)} />
            );
            break;

        case 3:
            CurrentComponent = (
                <LastOnboardingComponent
                    startAssignmentReport={startAssignmentReport}
                />
            );
            break;
        default:
            break;
    }

    return assignmentId && issuedSubjectId && CurrentComponent ? (
        <WithPreviousButtonOnBoardingComponent
            isOnboarded={student.isOnboarded}
            isLastStep={currentStep === LAST_STEP}
            backToPrevious={backToPrevious}
            goToAssignmentReportActivity={() =>
                goToAssignmentReportActivityPage({
                    assignmentId: +assignmentId,
                    issuedSubjectId: +issuedSubjectId,
                })
            }
        >
            {CurrentComponent}
        </WithPreviousButtonOnBoardingComponent>
    ) : (
        <></>
    );
}

function WithPreviousButtonOnBoardingComponent({
    children,
    isOnboarded,
    isLastStep,
    backToPrevious,
    goToAssignmentReportActivity,
}: {
    children: React.ReactNode;
    isOnboarded: boolean;
    isLastStep: boolean;
    backToPrevious: () => void;
    goToAssignmentReportActivity: () => void;
}) {
    return (
        <Container className="h-screen">
            <div className="flex justify-between py-12">
                <div
                    className="flex items-center text-xl text-[#8EC6FF] cursor-pointer"
                    onClick={backToPrevious}
                >
                    <span className="material-icons">arrow_back_ios</span>
                    뒤로가기
                </div>
                {isOnboarded && !isLastStep && (
                    <div>
                        <button
                            className="bg-white border-2 border-[#FFADAD] text-[#FFADAD] py-2 px-3 rounded-3xl no-underline"
                            type={'button'}
                            onClick={goToAssignmentReportActivity}
                        >
                            이미 알고 있어요. 바로 작성할래요
                        </button>
                    </div>
                )}
            </div>
            {children}
        </Container>
    );
}
function FirstOnboardingComponent({ onNext }: { onNext: () => void }) {
    return (
        <div className="text-center">
            <div className="text-[#8EC6FF] pt-20">
                <h1>탐구의 바다에 들어오신 것을 환영합니다!</h1>
            </div>
            <div className="py-20 text-xl">
                <div>흥미로운 주제를 발급받았군요!</div>
                <div>탐구 주제가 어렵다고요? 걱정하지 마세요.</div>
                <div>
                    나의 지성을 뽐낼 고퀄리티 탐구 활동, 학쫑이 도와줄게요.
                </div>
            </div>
            <div className="py-20">
                <button
                    className="py-2 px-12 bg-white border-2 border-[#8EC6FF] text-[#8EC6FF] rounded-3xl font-bold text-xl"
                    type="button"
                    onClick={onNext}
                >
                    다음으로
                </button>
            </div>
            <div className="space-x-3">
                <span className="text-[#50A3FF]">
                    <FiberManualRecordIcon />
                </span>
                <span className="text-[#B3D6FF]">
                    <FiberManualRecordIcon />
                </span>
                <span className="text-[#B3D6FF]">
                    <FiberManualRecordIcon />
                </span>
            </div>
        </div>
    );
}
function SecondOnboardingComponent({ onNext }: { onNext: () => void }) {
    const [isChecked, setIsChecked] = useState(false);
    return (
        <div className="text-center">
            <div className="text-[#8EC6FF] pt-20">
                <h2>탐구활동을 쉽게, 편하게, 빠르게 도와줄게요!</h2>
            </div>
            <div className="py-10 text-xl">
                <div>발표와 보고서, 어떻게 시작할지 막막할 때가 많죠?</div>
                <div>
                    <strong>AI의 도움</strong>과 <strong>최소한의 입력</strong>
                    만으로,
                </div>
                <div>서론-본론-결론을 갖춘 체계적인 초안이 완성됩니다.</div>
            </div>
            <div className="pt-16 text-gray-500 text-lg">
                <div>학쫑 AI가 제목, 목차, 참고자료를 추천해줍니다.</div>
                <img
                    className="w-1/4 mx-auto pt-4"
                    src={onboardingImage1}
                    alt="onboarding"
                />
            </div>
            <div>
                <label className="flex items-center text-lg justify-center">
                    <input
                        className="mr-3"
                        type="checkbox"
                        onChange={() => setIsChecked((pre) => !pre)}
                    />
                    아하, 이해했어요
                </label>
            </div>
            <div className="py-10">
                <button
                    className={`py-2 px-12 bg-white border-2 rounded-3xl font-bold text-xl ${
                        isChecked
                            ? 'border-[#8EC6FF] text-[#8EC6FF]'
                            : 'border-[#8A8A8A] text-[#8A8A8A]'
                    }`}
                    type="button"
                    disabled={!isChecked}
                    onClick={onNext}
                >
                    다음으로
                </button>
            </div>
            <div className="space-x-3">
                <span className="text-[#50A3FF]">
                    <FiberManualRecordIcon />
                </span>
                <span className="text-[#50A3FF]">
                    <FiberManualRecordIcon />
                </span>
                <span className="text-[#B3D6FF]">
                    <FiberManualRecordIcon />
                </span>
            </div>
        </div>
    );
}
function ThirdOnboardingComponent({ onNext }: { onNext: () => void }) {
    const [isChecked, setIsChecked] = useState(false);
    return (
        <div className="text-center">
            <div className="text-[#8EC6FF] pt-20">
                <h2>과제 설계는 좋은 세특으로 향하는 지름길!</h2>
            </div>
            <div className="py-4 text-xl">
                <div>'탐구 동기'부터 '배우고 느낀 점'까지,</div>
                <div>
                    <strong>좋은 세특</strong>에 필요한 항목은 모두 담았어요!
                </div>
                <div>
                    선생님께 어필할 수 있는 작은 부분 하나도, 놓치지 말아요.
                </div>
            </div>
            <div className="pt-16 text-gray-500 text-lg">
                <div>문장 만들기, 참 어렵죠?</div>
                <div>문장은 학쫑이 만들어 줄게요, 빈 부분만 채워 보세요!</div>
                <img
                    className="w-1/3 mx-auto pt-4"
                    src={onboardingImage2}
                    alt="onboarding"
                />
            </div>
            <div className="pt-5">
                <label className="flex items-center text-lg justify-center">
                    <input
                        className="mr-3"
                        type="checkbox"
                        onChange={() => setIsChecked((pre) => !pre)}
                    />
                    아하, 이해했어요
                </label>
            </div>
            <div className="py-10">
                <button
                    className={`py-2 px-12 bg-white border-2 rounded-3xl font-bold text-xl ${
                        isChecked
                            ? 'border-[#8EC6FF] text-[#8EC6FF]'
                            : 'border-[#8A8A8A] text-[#8A8A8A]'
                    }`}
                    type="button"
                    disabled={!isChecked}
                    onClick={onNext}
                >
                    다음으로
                </button>
            </div>
            <div className="space-x-3">
                <span className="text-[#50A3FF]">
                    <FiberManualRecordIcon />
                </span>
                <span className="text-[#50A3FF]">
                    <FiberManualRecordIcon />
                </span>
                <span className="text-[#50A3FF]">
                    <FiberManualRecordIcon />
                </span>
            </div>
        </div>
    );
}
function LastOnboardingComponent({
    startAssignmentReport,
}: {
    startAssignmentReport: () => void;
}) {
    return (
        <div className="text-center">
            <div className="text-[#8EC6FF] pt-20">
                <h2>그럼 지금부터 과제 설계를 시작해 볼까요?</h2>
            </div>
            <div className="pt-40">
                <button
                    className="py-2 px-4 bg-white border-2 rounded-3xl font-bold text-xl border-[#8EC6FF] text-[#8EC6FF] no-underline"
                    onClick={startAssignmentReport}
                >
                    과제 설계 시작하기
                </button>
            </div>
        </div>
    );
}
