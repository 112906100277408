import {
    temporaryAssignmentReportFormsSlice,
    useAppDispatch,
    useAppSelector,
} from '@store';

export function useTemporaryAssignmentReportFacade() {
    const dispatch = useAppDispatch();
    const tempReportForms = useAppSelector(
        (state) => state.temporaryAssignmentReportForms,
    );

    function getReport(assignmentId: AssignmentId) {
        const report = tempReportForms.find(
            (reportForm) => reportForm.assignmentId === assignmentId,
        );
        if (report) {
            return { ...report };
        }
    }

    function saveReport(
        assignmentId: AssignmentId,
        data: AssignmentReportForm,
    ) {
        dispatch(
            temporaryAssignmentReportFormsSlice.actions.save({
                assignmentId,
                form: data,
            }),
        );
    }

    return {
        getReport,
        saveReport,
    };
}
