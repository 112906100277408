// @ts-nocheck

import React from 'react';

import 가보자고 from '@images/stamps/가보자고.png';
import 감동심해 from '@images/stamps/감동심해.png';
import 단디해라 from '@images/stamps/단디해라.png';
import 쉽살재빙 from '@images/stamps/쉽살재빙.png';
import 이건다시 from '@images/stamps/이건다시.png';
import 이건인정 from '@images/stamps/이건인정.png';
import 이걸해냄 from '@images/stamps/이걸해냄.png';
import 짜짜박박 from '@images/stamps/짜짜박박.png';
import 쪼끔애쉽 from '@images/stamps/쪼끔애쉽.png';
import 쫌더힘내 from '@images/stamps/쫌더힘내.png';
import 찢었다능 from '@images/stamps/찢었다능.png';
import 하면된다 from '@images/stamps/하면된다.png';

const stampNameSrcMap: {
    [key: string]: string;
} = {
    가보자고: 가보자고,
    감동심해: 감동심해,
    단디해라: 단디해라,
    쉽살재빙: 쉽살재빙,
    이건다시: 이건다시,
    이건인정: 이건인정,
    이걸해냄: 이걸해냄,
    짜짜박박: 짜짜박박,
    쪼끔애쉽: 쪼끔애쉽,
    쫌더힘내: 쫌더힘내,
    찢었다능: 찢었다능,
    하면된다: 하면된다,
};

const feedbackDetailKeys = [
    { name: '개요', key: 'overview' },
    { name: '서론', key: 'introduction' },
    { name: '본론', key: 'main' },
    { name: '결론', key: 'conclusion' },
];

const feedbackTypes = [
    '주제 관련성',
    '정확성·타당성',
    '난이도',
    '내용부실',
    '자료·출처',
    '기타',
];

export default function FeedbackDisplay({
    assignmentReport,
    onSubmit,
}: {
    assignmentReport: AssignmentReport;
    onSubmit: () => void;
}) {
    const contentNames = assignmentReport.data.researchContentSummaryList.map(
        ({ content }) => content,
    );
    function getSortedEntriesOfFeedbackDetail(key) {
        const entries = Object.entries(assignmentReport.feedback[key]);
        return entries.sort();
    }

    function isContent(key) {
        return key.match(' 탐구내용 - ');
    }

    return (
        <div className="w-100 right-nav-container mt-5">
            <div className="right-nav">
                <h4 className="text-center text-purple fw-bold">피드백 확인</h4>
                <div className="checklist-container border bg-white rounded-4 p-3">
                    <p className="text-purple">
                        선생님께서 보완이 필요한 항목을 선택하고 피드백을 작성해
                        주셨어.
                        <br />
                        피드백을 확인하며 과제 설계를 수정해 보자!
                    </p>
                    {feedbackDetailKeys.map((feedbackDetail, index) => {
                        return (
                            <div
                                key={feedbackDetail.key}
                                className="checklist mb-4"
                            >
                                <div className="row text-purple">
                                    <h5>{feedbackDetail.name}</h5>
                                </div>
                                {
                                    // @ts-ignore
                                    getSortedEntriesOfFeedbackDetail(
                                        feedbackDetail.key,
                                    ).map(([key, value], innerIndex) => {
                                        if (isContent(key)) {
                                            const isRelatedContent =
                                                contentNames
                                                    .map((name) => {
                                                        return key.match(name);
                                                    })
                                                    .some((x) => x);
                                            if (!isRelatedContent) {
                                                return null;
                                            }
                                        }
                                        return (
                                            <React.Fragment key={key}>
                                                <div className="row">
                                                    <div className="form-check px-3">
                                                        <input
                                                            disabled
                                                            type="checkbox"
                                                            className="form-input-check me-2"
                                                        />
                                                        <label>{key}</label>
                                                    </div>
                                                </div>
                                                {value.checked && (
                                                    <div className="container mx-3">
                                                        <div className="row">
                                                            {feedbackTypes.map(
                                                                (
                                                                    feedbackType,
                                                                    index,
                                                                ) => (
                                                                    // @ts-ignore
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="form-check col-4 px-3 mt-1"
                                                                    >
                                                                        <input
                                                                            disabled
                                                                            type="checkbox"
                                                                            className="form-input-check me-1"
                                                                            checked={Boolean(
                                                                                value.types.find(
                                                                                    (
                                                                                        e,
                                                                                    ) =>
                                                                                        e ===
                                                                                        feedbackType,
                                                                                ),
                                                                            )}
                                                                        />
                                                                        <label>
                                                                            {
                                                                                feedbackType
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                        <div className="col mx-2">
                                                            <textarea
                                                                resize="false"
                                                                disabled
                                                                className="container mt-2 text-purple"
                                                                defaultValue={
                                                                    value.comment
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </div>
                        );
                    })}
                    <div className="row text-purple">
                        <h5 className="mt-4">총평</h5>
                    </div>
                    <div className="row align-items-center">
                        <div className={'col-8 ms-3'}>
                            <textarea
                                className={'w-100'}
                                rows="7"
                                disabled
                                defaultValue={
                                    assignmentReport.feedback
                                        ?.comprehensiveEvaluation
                                }
                            />
                        </div>
                        <div className={'col border rounded-2'}>
                            <div className={'d-flex flex-column'}>
                                <div className="h-75">
                                    <img
                                        src={
                                            stampNameSrcMap[
                                                assignmentReport.feedback?.stamp
                                                    .name
                                            ]
                                        }
                                        alt="stamp"
                                        className="w-100 h-100"
                                    />
                                </div>
                                <div className="p-0 border-top w-100">
                                    {assignmentReport.feedback?.stamp.type ===
                                    'disapproval' ? (
                                        <h5 className="text-center fw-bold">
                                            다시!
                                        </h5>
                                    ) : (
                                        <h5 className="text-center fw-bold">
                                            통과!
                                        </h5>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!assignmentReport.isApproved && (
                    <>
                        <button
                            onClick={onSubmit}
                            className="border border-danger rounded-5 bg-white text-danger px-5 py-2 fw-bold mt-2 mx-auto d-flex"
                        >
                            <span className="material-icons-outlined me-2">
                                {' '}
                                check_circle{' '}
                            </span>
                            수정완료
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}
