import { client } from './client';

export async function callSubmitAssignmentReport({
    studentId,
    assignmentId,
    report,
    file,
}: {
    studentId: StudentId;
    assignmentId: AssignmentId;
    report?: { [key: string]: any };
    file?: File;
}) {
    if (!file) {
        const response = await client.post(
            '/assignment_app/assignment-reports/',
            {
                assignment: assignmentId,
                data: report,
            },
            {
                params: { clientStudentId: studentId },
            },
        );
        return response.data;
    } else {
        const data = new FormData();
        data.append('assignment', assignmentId.toString());
        data.append('file', file);

        const response = await client.post('/assignment_app/assignment-reports/', data, {
            params: { clientStudentId: studentId },
        });
        return response.data;
    }
}
