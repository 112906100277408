import React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@mui/icons-material';

export { default as SectionA } from './SectionA';
export { default as SectionB } from './SectionB';
export { default as SectionC } from './SectionC';
export { default as SectionD } from './SectionD';
export { default as SelfChecklist } from './SelfChecklist';
export { default as FeedbackDisplay } from './FeedbackDisplay';

export function Column({
    title,
    variant,
    children,
}: {
    title: React.ReactNode | (() => React.ReactNode);
    variant: string;
    children: React.ReactNode;
}) {
    return (
        <div className={`row p-0 mt-3`}>
            <div
                className={`col-2 d-flex justify-content-center align-items-center bg-${variant} p-2 rounded-2`}
            >
                {typeof title === 'function' ? (
                    title()
                ) : (
                    <h5 className={'fw-bold mb-0 fs-6'}>{title}</h5>
                )}
            </div>
            <div className={'col ps-4'}>{children}</div>
        </div>
    );
}

export function ContentWithLink({
    children,
    href,
    ...divProps
}: {
    children: React.ReactNode;
    href: string;
} & React.HTMLAttributes<HTMLDivElement>) {
    divProps.className = `row align-items-center ${divProps.className ?? ''}`;

    return (
        <div {...divProps}>
            <div className="col">{children}</div>
            <div className="col-auto">
                <Link to={href}>
                    <span className={'pe-1 text-primary'}>
                        <EditOutlined />
                    </span>
                </Link>
            </div>
        </div>
    );
}

export function ColumnSingle({
    children,
    variant,
    link,
    ...rest
}: {
    link?: string;
} & React.ComponentProps<typeof Column>) {
    return (
        <Column variant={variant} {...rest}>
            <ContentWithLink
                className={`border border-${variant} rounded-2 p-2`}
                href={link ?? ''}
            >
                {children}
            </ContentWithLink>
        </Column>
    );
}
