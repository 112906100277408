import { useState } from 'react';
import { callUploadAssignmentReportFile } from '@client';

export function ImageUploader() {
    const [uploadInfo, setUploadInfo] = useState<Record<any, any>>({});

    const upload: any = async function (blobInfo: any, progress: any) {
        let formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        try {
            const { id, file } = await callUploadAssignmentReportFile({
                formData,
            });
            setUploadInfo((prevInfo) => ({
                ...prevInfo,
                [file]: id,
            }));
            return file;
        } catch (err: any) {
            alert('HTTP Error: ' + err.message);
        }
    };

    return {
        uploadInfo,
        setUploadInfo,
        upload,
    };
}
