import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useGradeFacade } from '@facades';
import { PATH, resolveRoute } from '../ProjectRoutes';

export default function SchoolRecordCard({
    grade,
    openSchoolRecordModal,
}: {
    grade: number;
    openSchoolRecordModal: (
        grade: number,
        uploadedSchoolRecord?: string,
    ) => void;
}) {
    const navigate = useNavigate();
    const gradeFacade = useGradeFacade();
    const [uploadedSchoolRecord, setUploadedSchoolRecord] = useState<
        string | null
    >();
    const [proposedProposal, setProposedProposal] =
        useState<SchoolRecordAnalysisProposal | null>();

    function gotoAnalysisPage() {
        navigate(
            resolveRoute(PATH.SCHOOL_RECORD_ANALYSIS_RESULT, {
                grade,
            }),
        );
    }

    useEffect(function loadUploadedSchoolRecord() {
        (async function () {
            const result = await gradeFacade.retrieveSchoolRecord({
                grade,
            });

            if (result.schoolRecord) {
                setUploadedSchoolRecord(result.schoolRecord);
            }
        })();
    });

    useEffect(function loadProposedProposal() {
        (async function () {
            const proposal =
                await gradeFacade.getProposedSchoolRecordAnalysisProposal(
                    grade,
                );
            setProposedProposal(proposal);
        })();
    }, []);

    return (
        <Card className="record-card-container">
            <Card.Body className="record-card">
                <div className="card-title">{grade}학년 생기부</div>
                {uploadedSchoolRecord ? (
                    <>
                        {!proposedProposal && (
                            <>
                                <div className="card-text-box">
                                    생기부 분석중
                                </div>
                                <button
                                    className="card-btn sub"
                                    onClick={() =>
                                        openSchoolRecordModal(
                                            grade,
                                            uploadedSchoolRecord,
                                        )
                                    }
                                >
                                    생기부 수정
                                </button>
                            </>
                        )}
                    </>
                ) : (
                    <button
                        className="card-btn sub"
                        onClick={() => openSchoolRecordModal(grade)}
                    >
                        생기부 제출
                    </button>
                )}

                {proposedProposal && (
                    <button className="card-btn" onClick={gotoAnalysisPage}>
                        생기부 분석지 확인
                    </button>
                )}
            </Card.Body>
        </Card>
    );
}
