import '@styles/pages/majorCategoryExplorationTournament.scss';

import { Tournament, RoundTypes } from './core';
import { BinaryMatchRound } from './BinaryMatchRound';
import { QuestionnaireMatchRound } from './QuestionnaireMatchRound';

export * from './useTournament';

export default function TournamentComponent({
    tournament,
    pick,
}: {
    tournament: Tournament;
    pick: (v: number) => void;
}) {
    const round = tournament.getCurrentRound();
    return (
        round.type === RoundTypes.Questionnaire ? (
            <QuestionnaireMatchRound pick={pick} round={round} />
        ) : (
            <BinaryMatchRound pick={pick} round={round} />
        )
    );
}
