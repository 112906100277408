import lodash from 'lodash';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Container, Dropdown, Row } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
    useIssuedSubjectFacade,
    useLeanAIMetadataFacade,
    useMajorCategoryExplorationFacade,
} from '@facades';
import IssuedSubjectCard from '@components/IssuedSubjectCard';
import { useAppSelector } from '@store';

import '@styles/pages/mainPage.scss';
import { PATH, resolveRoute } from '../ProjectRoutes';
import { useAssignmentSummary } from './hooks';
import SchoolGradeModal from '@components/SchoolGradeModal';
import MockSATGradeModal from '@components/MockSATGradeModal';
import TargetingMajors from '@components/TargetingMajors';
import Keywords from '@components/Keywords';
import { useSemesterInfo } from '../hooks/useSemesterInfo';
import InterestedSchoolSubjects from '@components/InterestedSchoolSubjects';
import { A01_URL } from '@constants';

export default function MainPage() {
    const student = useAppSelector((state) => state.student);
    const leanAIMetadataFacade = useLeanAIMetadataFacade();
    const leanAIMetadata = leanAIMetadataFacade.get();

    const subjectsFacade = useIssuedSubjectFacade(leanAIMetadata);

    const { grade = '1', semester = '1' } = useParams();

    const {
        semesterInfo: currentSemesterInfo,
        addTargetingMajor,
        removeTargetingMajor,
        addKeyword,
        removeKeyword,
        updateInterestedSchoolSubjects,
        removeInterestedSchoolSubject,
    } = useSemesterInfo(student.id, grade, semester);

    // 계열선택검사
    const { exploration } = useMajorCategoryExplorationFacade();
    function navigateToMajorCategoryExplorationResultPage() {
        navigate(PATH.MAJOR_CATEGORY_EXPLORATION_RESULT);
    }

    const [currentSubjects, setCurrentSubjects] = useState<
        IssuedSubjectSummary[]
    >([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();

    const gotoLandingPage = () => {
        navigate(PATH.LANDING);
    };

    // summary related
    const { summary, init: initSummary } = useAssignmentSummary();

    useEffect(
        function loadSubjects() {
            if (!leanAIMetadataFacade.isReady) {
                return;
            }

            (async () => {
                const { results: issuedSubjects } =
                    await subjectsFacade.getIssuedSubjects({
                        grade: +grade,
                        semester: +semester,
                        page: currentPage,
                    });
                setCurrentSubjects(issuedSubjects);
            })();

            (async () => {
                await initSummary({
                    clientStudentId: student.id,
                    student: student.id,
                    grade: +grade,
                    semester: +semester,
                });
            })();
        },
        [leanAIMetadataFacade.isReady, grade, semester, currentPage],
    );

    // tab related;
    function isCurrent(targetGrade: number, targetSemester: number) {
        return +grade === targetGrade && +semester === targetSemester;
    }

    function changeSemester(grade: number, semester: number) {
        navigate(
            generatePath(PATH.MAIN, {
                grade: String(grade),
                semester: String(semester),
            }),
        );
    }

    // modal related;
    const [schoolGradeModalProps, setSchoolGradeModalProps] = useState<
        ModalProps<Array<SchoolGrade>>
    >({
        modalShow: false,
        data: null,
    });

    const [mockSATGradeModalProps, setMockSATGradeModalProps] = useState<
        ModalProps<Array<MockSATGrade>>
    >({
        modalShow: false,
        data: null,
    });

    useEffect(() => {
        if (!currentSemesterInfo) {
            return;
        }

        setSchoolGradeModalProps((current) => ({
            ...current,
            data: currentSemesterInfo.schoolGrades,
        }));

        setMockSATGradeModalProps((current) => ({
            ...current,
            data: currentSemesterInfo.mockSATGrades,
        }));
    }, [currentSemesterInfo]);

    function openSchoolGradeModal() {
        setSchoolGradeModalProps({
            ...schoolGradeModalProps,
            modalShow: true,
        });
    }

    function closeSchoolGradeModal() {
        setSchoolGradeModalProps({
            ...schoolGradeModalProps,
            modalShow: false,
        });
    }

    function openMockSATGradeModal() {
        setMockSATGradeModalProps({
            ...mockSATGradeModalProps,
            modalShow: true,
        });
    }

    function closeMockSATGradeModal() {
        setMockSATGradeModalProps({
            ...mockSATGradeModalProps,
            modalShow: false,
        });
    }

    function navigateA01Page() {
        const url = new URL('/a01', A01_URL);
        url.search = `?studentName=${student.name}&schoolGrade=${grade}&studentId=${student.id}`;
        window.open(url.toString());
    }

    return (
        <div className="page-base" id="mainPage">
            <Container className="go-back-btn-container">
                <div className="go-back-btn" onClick={gotoLandingPage}>
                    <span className="material-icons">arrow_back_ios</span>
                    <span> 뒤로가기</span>
                </div>
            </Container>
            <Container className="main-page-container">
                <div className="semester-tab-container d-none d-sm-none d-lg-flex">
                    {generateSemesterTabs(isCurrent, changeSemester)}
                </div>

                <Dropdown className="d-sm-block d-lg-none d-xl-none">
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                        {`${grade}학년 ${semester}학기`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {generateSemesterDropdown(isCurrent, changeSemester)}
                    </Dropdown.Menu>
                </Dropdown>

                {currentSemesterInfo ? (
                    <div className="content-container">
                        <div
                            className="content-apple d-lg-none"
                            style={{ height: '80px' }}
                        ></div>
                        <div className="flex justify-between">
                            <div className="content-title">학생 정보</div>
                            <div className="flex items-center space-x-3">
                                <div>
                                    <button
                                        className={clsx(
                                            '!text-lg w-44 font-bold rounded-lg',
                                            exploration
                                                ? 'bg-purple text-white'
                                                : '!bg-[#DDD] !text-[#999]',
                                        )}
                                        onClick={
                                            navigateToMajorCategoryExplorationResultPage
                                        }
                                        disabled={!exploration}
                                        type="button"
                                    >
                                        계열선택결과 확인
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="!text-lg w-44 bg-purple text-white font-bold rounded-lg"
                                        onClick={openSchoolGradeModal}
                                    >
                                        내신 등급 확인
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="!text-lg w-44 bg-purple text-white font-bold rounded-lg"
                                        onClick={openMockSATGradeModal}
                                    >
                                        모의고사 등급 확인
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="!text-lg w-44 bg-purple text-white font-bold rounded-lg"
                                        onClick={navigateA01Page}
                                    >
                                        수시 배치표
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="content-label">희망 대학/학과</div>
                        <div className="content-box">
                            <TargetingMajors
                                majors={currentSemesterInfo.targetingMajors}
                                addTargetingMajor={addTargetingMajor}
                                removeTargetingMajor={removeTargetingMajor}
                                universityData={leanAIMetadata.universities}
                                majorData={leanAIMetadata.majors}
                            />
                        </div>
                        <div className="content-label">
                            희망학과 소계열 목록
                        </div>
                        <div className="content-box">
                            {currentSemesterInfo.interestedMajorCategories.map(
                                (category) => (
                                    <div
                                        className="text-box pill"
                                        key={category}
                                    >
                                        {category}
                                    </div>
                                ),
                            )}
                        </div>
                        <div className="content-label">주요 생기부 키워드</div>
                        <div className="content-box">
                            <Keywords
                                keywords={currentSemesterInfo.keywords}
                                addKeyword={addKeyword}
                                removeKeyword={removeKeyword}
                            />
                        </div>
                        <div className="content-label">주요 수강 과목</div>
                        <div className="content-box">
                            <InterestedSchoolSubjects
                                interestedSchoolSubjects={
                                    currentSemesterInfo.interestedSchoolSubjects
                                }
                                schoolSubjectData={
                                    leanAIMetadata.schoolSubjects
                                }
                                updateInterestedSchoolSubjects={
                                    updateInterestedSchoolSubjects
                                }
                                removeInterestedSchoolSubject={
                                    removeInterestedSchoolSubject
                                }
                            />
                        </div>
                        <div className="content-title">세특 관리</div>
                        <div className="content-box">
                            <Container>
                                <Row>
                                    {currentSubjects.map((issuedSubject) => (
                                        <IssuedSubjectCard
                                            key={issuedSubject.id}
                                            issuedSubject={issuedSubject}
                                        />
                                    ))}
                                </Row>
                            </Container>
                            {/*<Pagination />*/}
                        </div>
                        <div className="content-title">과제 관리</div>
                        <div className="content-box">
                            <Row className="manage-assignment-box justify-content-md-center">
                                <div className="col col-lg-2">
                                    <span className="assignment-status-text">
                                        진행 중 과제
                                    </span>
                                    <span className="assignment-status-number">
                                        : {summary.beforeSubmit}
                                    </span>
                                </div>
                                <div className="col-md-auto">
                                    <span className="assignment-status-text">
                                        대기 중 과제
                                    </span>
                                    <span className="assignment-status-number">
                                        : {summary.afterSubmit}
                                    </span>
                                </div>
                                <div className="col col-lg-2">
                                    <span className="assignment-status-text">
                                        완료 된 과제
                                    </span>
                                    <span className="assignment-status-number">
                                        : {summary.completed}
                                    </span>
                                </div>
                            </Row>
                            <button
                                className="manage-assignment-btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                        resolveRoute(
                                            PATH.PRO12_ASSIGNMENT_LIST,
                                            {
                                                grade,
                                                semester,
                                            },
                                        ),
                                    );
                                }}
                            >
                                과제 관리
                            </button>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </Container>
            <SchoolGradeModal
                closeModal={closeSchoolGradeModal}
                {...schoolGradeModalProps}
            />
            <MockSATGradeModal
                closeModal={closeMockSATGradeModal}
                {...mockSATGradeModalProps}
            />
        </div>
    );
}

function generateSemesterTabs(
    isActive: (grade: number, semester: number) => boolean,
    onSelection: (grade: number, semester: number) => void,
) {
    const grades = [1, 2, 3];
    const semesters = [1, 2];

    return grades.map((grade) => {
        return semesters.map((semester) => {
            return (
                <div
                    key={`${grade}-${semester}`}
                    className={`semester-tab ${
                        isActive(grade, semester) ? 'active-semester-btn' : ''
                    }`}
                    onClick={() => onSelection(grade, semester)}
                >
                    {grade}학년 {semester}학기
                </div>
            );
        });
    });
}

function generateSemesterDropdown(
    isActive: (grade: number, semester: number) => boolean,
    onSelection: (grade: number, semester: number) => void,
) {
    const grades = [1, 2, 3];
    const semesters = [1, 2];

    return lodash.flatten(
        grades.map((grade) => {
            return semesters.map((semester) => {
                return (
                    <Dropdown.Item
                        key={`${grade}-${semester}`}
                        onClick={() => onSelection(grade, semester)}
                    >
                        {grade}학년 {semester}학기
                    </Dropdown.Item>
                );
            });
        }),
    );
}
