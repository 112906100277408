import { every, shuffle } from 'lodash';

import { Round, RoundTypes } from './round';
import { Set } from './set';
import {
    CategoryParticipant,
    Participant,
    QuestionnaireParticipant,
} from './participant';
import { InvalidRoundDataError } from './errors';
import { Tournament } from './tournament';

export const FIRST_ROUND = 1;
export const LOSER_MATCH_ROUNDS = [5, 8, 11];
export const WINNER_MATCH_ROUNDS = [3, 6, 9, 12];
export const LOSER_WINNER_MIXED_MATCH_ROUNDS = [4, 7, 10, 13];
export const FULL_LEAGUE_ROUND = 14;
export const PLAYOFF_ROUND = 15;

const ROUND_NUMBERS_FOR_IMAGE = [1, 2, 3, 6, 9, 12];
const ROUND_NUMBERS_FOR_RECOMMENDED_BOOK = [4, 5, 7, 8];
const ROUND_NUMBERS_FOR_RELATED_MAJOR = [10, 11, 13];
const DEFAULT_SCORES = [0, 1, 2, 3, 4];

function createMatchRoundChecker(targetRounds: number[]) {
    return function (rounds: Round[]) {
        const currentRound = rounds.length + 1;
        return targetRounds.indexOf(currentRound) > -1;
    };
}

function isLoserMatchRound(rounds: Round[]) {
    return createMatchRoundChecker(LOSER_MATCH_ROUNDS)(rounds);
}

function isWinnerMatchRound(rounds: Round[]) {
    return createMatchRoundChecker(WINNER_MATCH_ROUNDS)(rounds);
}

function isLoserWinnerMixedMatchRound(rounds: Round[]) {
    return createMatchRoundChecker(LOSER_WINNER_MIXED_MATCH_ROUNDS)(rounds);
}

function isFullLeagueRound(rounds: Round[]) {
    return rounds.length + 1 === FULL_LEAGUE_ROUND;
}

function isFirstRound(rounds: Round[]) {
    return rounds.length + 1 === FIRST_ROUND;
}

function isSecondRound(rounds: Round[]) {
    return rounds.length + 1 === 2;
}

function findRoundByNumber(rounds: Round[], number: number) {
    const round = rounds.find((r) => r.number === number);
    if (!round) {
        throw new InvalidRoundDataError();
    }

    return round;
}

function isPlayoffRound(rounds: Round[]) {
    return rounds.length + 1 === PLAYOFF_ROUND;
}

export function circularShift(participants: Participant[]) {
    const shifted = participants.slice();
    const participant = shifted.shift();
    if (participant) {
        shifted.push(participant);
    }
    return shifted;
}

function getScoredCategories(sets: Set[]) {
    const map = {} as {
        [key: number]: CategoryParticipant;
    };
    sets.forEach((pair) => {
        pair.participants.forEach((participant) => {
            map[participant.id] = participant as CategoryParticipant;
        });
    });

    sets.forEach((pair) => {
        if (pair.winner) {
            map[pair.winner.id].score += 1;
        }
    });
    return Object.values(map);
}

export function getPlayoffWinnersFromWinnerSets(sets: Set[]) {
    const categoryIdMap = getScoredCategories(sets);
    if (every(categoryIdMap.map(({ score }) => score === 1))) {
        return categoryIdMap;
    } else {
        return categoryIdMap.filter(({ score }) => score > 0);
    }
}

export function getPlayoffWinnersFromLoserSets(sets: Set[]) {
    const categoryIdMap = getScoredCategories(sets);
    if (every(categoryIdMap.map(({ score }) => score === 1))) {
        return categoryIdMap;
    } else {
        return categoryIdMap.filter(({ score }) => score > 1);
    }
}

function getSets(tournament: Tournament) {
    const participants = tournament.participants;
    const rounds = tournament.rounds;
    const currentRoundNumber = rounds.length + 1;
    let sets = [] as Set[];

    if (isFirstRound(rounds)) {
        sets = Set.zip(participants);
    } else if (isSecondRound(rounds)) {
        const lastRoundNumber = currentRoundNumber - 1;
        const lastRound = findRoundByNumber(rounds, lastRoundNumber);
        const losers = lastRound.getLosers();
        sets = Set.zip(losers);
    } else if (isLoserMatchRound(rounds)) {
        const lastRoundNumber = currentRoundNumber - 1;
        const lastRound = findRoundByNumber(rounds, lastRoundNumber);
        const winners = lastRound.getWinners();
        sets = Set.zip(winners);
    } else if (isWinnerMatchRound(rounds)) {
        const lastRoundNumber =
            currentRoundNumber === 3 ? 1 : currentRoundNumber - 3;
        const lastRound = findRoundByNumber(rounds, lastRoundNumber);
        const winners = lastRound.getWinners();
        sets = Set.zip(winners);
    } else if (isLoserWinnerMixedMatchRound(rounds)) {
        const lastLoserRoundNumber = currentRoundNumber - 2;
        const loserRound = findRoundByNumber(rounds, lastLoserRoundNumber);
        const lastWinnerRoundNumber = currentRoundNumber - 1;
        const winnerRound = findRoundByNumber(rounds, lastWinnerRoundNumber);

        const loserRoundWinners = loserRound.getWinners();
        const winnerRoundLosers = winnerRound.getLosers();

        sets = Set.reduce(
            loserRoundWinners,
            winnerRoundLosers.slice().reverse(),
        );
    } else if (isFullLeagueRound(rounds)) {
        const winnerRound = findRoundByNumber(rounds, 12);
        const winnerRoundWinners = winnerRound.getWinners();
        const circularShiftedWinnerRoundWinners =
            circularShift(winnerRoundWinners);

        const loserRound = findRoundByNumber(rounds, 13);
        const loserRoundWinners = loserRound.getWinners();
        const circularShiftedLoserRoundLosers =
            circularShift(loserRoundWinners);

        sets = [
            ...Set.reduce(
                winnerRoundWinners,
                circularShiftedWinnerRoundWinners,
            ),
            ...Set.reduce(loserRoundWinners, circularShiftedLoserRoundLosers),
        ];
    } else if (isPlayoffRound(rounds)) {
        const lastRoundSets = findRoundByNumber(
            rounds,
            FULL_LEAGUE_ROUND,
        ).getSets();

        const participants = [
            ...getPlayoffWinnersFromWinnerSets(lastRoundSets.slice(0, 3)),
            ...getPlayoffWinnersFromLoserSets(lastRoundSets.slice(3, 6)),
        ];

        sets = initializeRandomQuestionnaireSets(participants);
    } else {
        throw new InvalidRoundDataError();
    }

    return sets;
}

function initializeRandomQuestionnaireSets(
    participants: CategoryParticipant[],
) {
    let questionnaireId = -1;
    const result = participants.reduce(
        (previousCategoryParticipant, currentCategoryParticipant, index) => {
            questionnaireId += 1;
            return previousCategoryParticipant.concat(
                currentCategoryParticipant.questionnaires.map(
                    (questionnaire) =>
                        new Set(
                            DEFAULT_SCORES.map((score) => {
                                questionnaireId += 1;
                                return new QuestionnaireParticipant(
                                    questionnaireId,
                                    currentCategoryParticipant,
                                    questionnaire,
                                    score,
                                );
                            }),
                        ),
                ),
            );
        },
        [] as Set[],
    );

    return shuffle(result);
}

function getRoundType(number: number) {
    if (ROUND_NUMBERS_FOR_IMAGE.indexOf(number) > -1) {
        return RoundTypes.Image;
    }

    if (ROUND_NUMBERS_FOR_RECOMMENDED_BOOK.indexOf(number) > -1) {
        return RoundTypes.RecommendedBook;
    }

    if (ROUND_NUMBERS_FOR_RELATED_MAJOR.indexOf(number) > -1) {
        return RoundTypes.RelatedMajor;
    }

    if (number === FULL_LEAGUE_ROUND) {
        return RoundTypes.WordCloud;
    }

    if (number === PLAYOFF_ROUND) {
        return RoundTypes.Questionnaire;
    }
}

export function createTournamentRound(tournament: Tournament): Round {
    const currentRoundNumber = tournament.rounds.length + 1;
    const sets = getSets(tournament);
    return new Round(
        currentRoundNumber,
        sets,
        getRoundType(currentRoundNumber),
    );
}

class TournamentResultSort {
    constructor(
        private a: Record<string, number>,
        private b: Record<string, number>,
    ) {}

    get isSameScore() {
        return this.scoreDelta === 0;
    }

    get isSameRoundPriority() {
        return this.roundPriorityDelta === 0;
    }

    get randomDelta() {
        return Math.random() - Math.random();
    }

    get isSamePickedCount() {
        return this.pickedCountDelta === 0;
    }

    get roundPriorityDelta() {
        if (!this.a.roundPriority || !this.b.roundPriority) {
            throw new InvalidRoundDataError();
        }

        return this.b.roundPriority - this.a.roundPriority;
    }

    get scoreDelta() {
        return this.b.score - this.a.score;
    }

    get pickedCountDelta() {
        return this.b.pickedCount - this.a.pickedCount;
    }
}

function getRoundPriority(tournament: Tournament, categoryId: number) {
    let round = findRoundByNumber(tournament.rounds, FULL_LEAGUE_ROUND);
    if (!round) {
        throw new InvalidRoundDataError();
    }

    let indexOfWinningRound = 0;
    for (let set of round.getSets()) {
        if (set.winner && set.winner.id === categoryId) {
            break;
        }
        indexOfWinningRound += 1;
    }
    return indexOfWinningRound > 2 ? 1 : 2;
}

export function getTournamentResult(tournament: Tournament) {
    const result = {} as {
        [key: number]: {
            score: number;
            roundPriority: number;
            pickedCount: number;
        };
    };

    const lastRound = tournament.rounds[tournament.rounds.length - 1];
    lastRound.getSets().forEach((set) => {
        const winner = set.winner as QuestionnaireParticipant;
        if (winner && winner.category.id in result) {
            result[winner.category.id].score += winner.score;
        } else {
            result[winner.category.id] = {
                score: winner.score,
                roundPriority: getRoundPriority(tournament, winner.category.id),
                pickedCount: tournament.rounds
                    .filter((round) => round.number === FULL_LEAGUE_ROUND)
                    .reduce((acc, cur) => {
                        return (
                            acc +
                            cur
                                .getWinners()
                                .filter((winner) => winner.id === winner.id)
                                .length
                        );
                    }, 0),
            };
        }
    });

    return Object.entries(result)
        .map(([id, { roundPriority, score }]) => ({
            id: +id,
            roundPriority,
            score,
        }))
        .sort((a, b) => {
            const sort = new TournamentResultSort(a, b);
            if (sort.isSameScore) {
                if (sort.isSameRoundPriority) {
                    if (sort.isSamePickedCount) {
                        return sort.randomDelta;
                    } else {
                        return sort.pickedCountDelta;
                    }
                } else {
                    return sort.roundPriorityDelta;
                }
            } else {
                return sort.scoreDelta;
            }
        })
        .map((e) => e.id)
        .slice(0, 3);
}
