export enum AssignmentType {
    PRE_DRAFT = 'PRE_DRAFT',
    DRAFT = 'DRAFT',
    FINAL = 'FINAL',
}

export enum FirstCode {
    U = 'U',
    D = 'D',
    F = 'F',
}

export enum SecondCode {
    U = 'U',
    O = 'O',
    F = 'F',
}

export enum ThirdCode {
    U = 'U',
    A = 'A',
    D = 'D',
    M = 'M',
}

export const RecommendationType = {
    ExtractTitle: 'EXTRACT_TITLE',
    ListUpSummaries: 'LIST_UP_SUMMARIES',
};

export const ResearchReferencesType = {
    AdvancedResearchReferences: 'ADVANCED_RESEARCH_REFERENCES',
    NewsResearchReferences: 'NEWS_RESEARCH_REFERENCES',
    VideoResearchReferences: 'VIDEO_RESEARCH_REFERENCES',
};

export enum QUANTITY_RANK {
    WORST = 'worst',
    BAD = 'bad',
    GOOD = 'good',
    EXCELLENT = 'excellent',
    BEST = 'best',
}

export enum QUALITY_RANK {
    F = 'f',
    A = 'a',
    B = 'b',
    C = 'c',
}
