import { Participant } from './participant';

export class Set {
    constructor(
        public participants: Participant[],
        public winner: Participant | null = null,
        public losers: Participant[] = [],
    ) {}

    static zip(pairs: Participant[]) {
        const zippedSets = [] as Set[];
        pairs.forEach((participant, index) => {
            if (index % 2 === 0) {
                zippedSets.push(new Set([participant, pairs[index + 1]]));
            }
        });
        return zippedSets;
    }

    static reduce(participants1: Participant[], participants2: Participant[]) {
        return participants1.reduce((reducedSets, participant, i) => {
            const otherParticipant = participants2[i];
            reducedSets.push(new Set([participant, otherParticipant]));
            return reducedSets;
        }, [] as Set[]);
    }

    setWinner(v: number) {
        this.winner = this.participants[v];
        this.losers = this.participants.filter((e, i) => i !== v);
    }

    initialize() {
        this.winner = null;
        this.losers = [];
    }
}
