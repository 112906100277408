import React, { useEffect, useState } from 'react';

import ReferenceDraft from './ReferenceDraft';
import ReferenceCard from './ReferenceCard';
import { callGetWebPageMetadata } from '@client';

export default function ReferenceGroup({
    initialValue,
    onChange,
    renderNewReferenceButton,
    renderReferenceDraft,
    renderReferenceCard,
}: {
    initialValue: Reference[];
    onChange: (references: Reference[]) => void;
    renderNewReferenceButton?: (
        props: React.HTMLAttributes<HTMLButtonElement>,
    ) => React.ReactNode;
    renderReferenceDraft?: (
        props: Parameters<typeof ReferenceDraft>[0],
    ) => React.ReactNode;
    renderReferenceCard?: (
        props: Parameters<typeof ReferenceCard>[0],
        index: number,
    ) => React.ReactNode;
}) {
    if (!renderReferenceDraft) {
        renderReferenceDraft = (props) => <ReferenceDraft {...props} />;
    }

    if (!renderReferenceCard) {
        renderReferenceCard = (props, index) => (
            <ReferenceCard key={index} {...props} />
        );
    }

    if (!renderNewReferenceButton) {
        renderNewReferenceButton = (onClick) => (
            <button
                className="literature-tip-btn row m-auto px-4 py-1"
                type="button"
                onClick={() => setIsWorkingOnNewReference(true)}
            >
                참고 자료 추가하기
            </button>
        );
    }

    const [references, setReferences] = useState<Reference[]>(
        initialValue ?? [],
    );
    const [isWorkingOnNewReference, setIsWorkingOnNewReference] =
        useState(false);

    async function generateWebPageBookMark(link: string) {
        return await callGetWebPageMetadata({ url: link });
    }

    async function appendReference(reference: Reference) {
        if (reference.link) {
            reference.bookmark = await generateWebPageBookMark(reference.link);
        }
        setReferences((prev) => [...prev, reference]);
        setIsWorkingOnNewReference(false);
    }

    function removeReference(index: number) {
        setReferences((prev) => prev.filter((_, i) => i !== index));
    }

    const hasNoReference = references.length === 0;

    useEffect(
        function syncReferences() {
            onChange(references);
        },
        [references],
    );

    return (
        <>
            {references.map((reference, index) =>
                renderReferenceCard!(
                    {
                        reference,
                        removeReference: () => removeReference(index),
                    },
                    index,
                ),
            )}
            {!hasNoReference &&
                !isWorkingOnNewReference &&
                renderNewReferenceButton({
                    onClick: () => setIsWorkingOnNewReference(true),
                })}

            {(hasNoReference || isWorkingOnNewReference) &&
                renderReferenceDraft({
                    onComplete: async (reference) =>
                        await appendReference(reference),
                })}
        </>
    );
}
