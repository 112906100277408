import { useAppSelector } from '@store';
import {
    callFetchIssuedSubjectDetail,
    callFetchIssuedSubjects,
    callGenerateIssuedSubjectPdf,
} from '@client';

export function useIssuedSubjectFacade(leanAIMetadata: LeanAIMetadata) {
    const student = useAppSelector((state) => state.student);

    async function getIssuedSubjects(params: {
        grade: number;
        semester: number;
        page: number;
    }): Promise<{ count: number; results: IssuedSubjectSummary[] }> {
        const { results: rawSubjects, count } = await callFetchIssuedSubjects({
            clientStudentId: student.id,
            ...params,
        });

        return {
            count,
            results: translateRawIssuedSubjects(rawSubjects),
        };

        function translateRawIssuedSubjects(rawIssuedSubjects: any) {
            return rawIssuedSubjects.map(
                ({ appliedSchoolSubjectId, background, ...rest }: any) => {
                    return {
                        ...rest,
                        background: background?.name,
                        backgroundInEnglish: background?.englishName,
                        appliedSchoolSubject:
                            leanAIMetadata.schoolSubjects[
                                appliedSchoolSubjectId
                            ],
                    };
                },
            );
        }
    }

    async function getIssuedSubjectDetail(issuedSubjectId: SubjectId) {
        const subject = await callFetchIssuedSubjectDetail({
            clientStudentId: student.id,
            issuedSubjectId,
        });
        return subject;
    }

    async function generateIssuedSubjectPdf(issuedSubjectId: SubjectId) {
        const data = await callGenerateIssuedSubjectPdf({
            clientStudentId: student.id,
            issuedSubjectId,
        });
        return data;
    }

    return {
        getIssuedSubjects,
        getIssuedSubjectDetail,
        generateIssuedSubjectPdf,
    };
}
