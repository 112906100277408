export default function SmallRoundedButton({
    children,
    variant,
    ...rest
}: {
    children: React.ReactNode;
    variant: 'primary' | 'danger';
} & React.HTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            type="button"
            className={`btn btn-sm btn-outline-${variant} rounded-circle p-1`}
            {...rest}
        >
            {children}
        </button>
    );
}
