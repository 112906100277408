import { client } from './client';

export async function callCreateMajorCategoryExploration(
    studentId: number,
    majorCategoryIds: number[],
) {
    const { data } = await client.post(
        '/students_app/supporters/major-category-explorations/',
        {
            student: studentId,
            majorCategoryIds,
        },
    );
    return data;
}
