import lodash from 'lodash';

import {
    Container,
    ContentWrapper,
    LeftNav,
    LeftNavContainer,
    SideToolbars,
    Toolbar,
} from './components';
import { usePro12Context } from './Pro12Context';

export default function Pro12Layout({
    children,
}: {
    children: React.ReactNode;
}) {
    const { steps, currentStep } = usePro12Context();

    const sections = steps[currentStep].sections ?? [];

    return (
        <Container style={{ minHeight: '85vh' }}>
            <Toolbar />
            <SideToolbars />

            {!lodash.isEmpty(sections) && (
                <LeftNavContainer>
                    <LeftNav sections={sections} />
                </LeftNavContainer>
            )}

            <ContentWrapper>{children}</ContentWrapper>
        </Container>
    );
}
