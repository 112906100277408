export const applicationOptions = [
    '주제 발표',
    '보고서 작성',
    '토론',
    '동아리 활동',
    '포스터 발표',
    '페임랩(TED)',
    '영상 제작',
    '동아리 활동',
];

export const methodOptions = [
    '문헌 연구법',
    '실험법',
    '질문지법',
    '면접법',
    '관찰법',
];

export const motivationPart0Options = [
    '교과 학습으로부터',
    '진로 탐색으로부터',
    '타과목 학습으로부터',
    '일상 생활로부터',
    '기타',
];

export const motivationPart3Options = [
    '에 대하여 알게 되었다.',
    '을(를) 발견하였다.',
    '을(를) 확인하였다.',
    '이(가) 궁금해졌다.',
    '이(가) 재미있었다.',
    '이(가) 흥미로웠다.',
    '에 흥미가 생겼다.',
    '에 관심을 가지게 되었다.',
    '에 대하여 호기심이 생겼다.',
    '에 대하여 구체적으로 알아보고 싶어졌다.',
    '에 대하여 탐색할 필요성을 느꼈다.',
];

export const typeOptions = [
    '조사연구(survey research)',
    '실험연구(experimental research)',
    '관찰연구(observational study)',
    '문화기술지연구(ethnographic study)',
    '사례연구(case study)',
    '역사연구(historical research)',
];
