import { Modal } from 'react-bootstrap';

export function ExitModal({
    modalShow,
    closeModal,
    onExited,
}: {
    modalShow: boolean;
    closeModal: () => void;
    onExited: () => void;
}) {
    function exitAgreed() {
        onExited();
    }

    return (
        <>
            <Modal
                show={modalShow}
                onHide={closeModal}
                className="school-record-modal-base"
                centered
                size="lg"
            >
                <Modal.Header className="school-record-modal-header">
                    <div
                        className="material-icons modal-close-button"
                        onClick={closeModal}
                    >
                        close
                    </div>
                    <div className="modal-title text-center text-black-50 mb-0">
                        지금 나가시면 처음부터 새로 시작해야 합니다.
                        <br />
                        나가시겠습니까?
                    </div>
                </Modal.Header>
                <Modal.Body className="school-record-modal-body d-flex justify-content-center">
                    <button
                        onClick={exitAgreed}
                        className="text-purple border-0 bg-white fw-semibold fs-4 rounded-3 py-2 px-4 me-5 shadow-btn"
                        type="button"
                    >
                        네, 나갈게요.
                    </button>
                    <button
                        onClick={closeModal}
                        className="text-white border-0 bg-purple fw-semibold fs-4 rounded-3 py-2 px-4"
                        type="button"
                    >
                        아니요, 계속할래요.
                    </button>
                </Modal.Body>
            </Modal>
        </>
    );
}
