import { NEW_HZPRO_URL, STEP3_FRONTEND_URL } from '@constants/config';

interface CommonProps {
    studentId: number;
    issuedSubjectId: number;
    assignmentId: number;
}

interface NewVersionProps extends CommonProps {
    version: 230901;
}

interface OldVersionProps extends CommonProps {
    page?: string;
    step?: number | null;
    subStep?: number | null;
}

type Props = NewVersionProps | OldVersionProps;

export function step3페이지Url얻기(props: Props) {
    let url: URL;

    if ('version' in props && props.version.toString() === '230901') {
        url = new URL('/step3/onboarding/1', NEW_HZPRO_URL);
        url.searchParams.set('entering', 'true');
    } else {
        url = new URL('step3/온보딩/0', STEP3_FRONTEND_URL);
    }

    Object.entries(props).forEach(([k, v]) => {
        if (!v) {
            return;
        }
        url.searchParams.set(k, String(v));
    });

    url.searchParams.set('_', new Date().getTime().toString());
    return String(url);
}
