import React from 'react';

export default function LeftNavContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div className={'col-2 '}>
            <div className={'position-sticky'} style={{ top: '1rem' }}>
                {children}
            </div>
        </div>
    );
}
