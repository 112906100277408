import { UseFormRegister } from 'react-hook-form';
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { FormState } from 'react-hook-form/dist/types/form';
import FormErrorMessage from '@components/FormErrorMessage';

export default function ObjectiveInputGroup({
    researchContents,
    register,
    formState,
}: {
    researchContents: ResearchOverviewForm['contents'];
    register: UseFormRegister<ResearchIntroductionForm>;
    formState: FormState<ResearchIntroductionForm>;
}) {
    return (
        <>
            {researchContents.map(({ value }, index) => (
                <div className={'row mb-2 align-items-center'} key={index}>
                    <div className={'col-auto pe-0'}>
                        <h5 className={'fs-6 fw-bold mb-0'}>
                            탐구 문제 {index + 1}.
                        </h5>
                    </div>
                    <div className={'col-auto'}>
                        <span className={'fs-6 badge bg-warning'}>{value}</span>
                    </div>
                    <div className={'col'}>
                        <input
                            className="form-control rounded-3 py-1"
                            type="text"
                            placeholder="탐구 문제를 서술하세요."
                            {...register(`objectives.${index}.objective`, {
                                required: true,
                                minLength: 1,
                            })}
                        />
                        <ErrorMessage
                            errors={formState.errors}
                            name={`objectives.${index}.objective`}
                            render={() => (
                                <FormErrorMessage
                                    message={'탐구 문제를 입력해주세요.'}
                                />
                            )}
                        />
                    </div>
                </div>
            ))}
        </>
    );
}
