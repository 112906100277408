import React, { useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useAppSelector } from '@store';
import { LearningPointForm, RetrospectionForm } from './types';
import Card from './Card';
import {
    BorderBox,
    SectionTitle,
    SectionWrapper,
} from '@pages/pro12/components';
import { LPRDraft } from './LPRDraft';
import FormErrorMessage from '@components/FormErrorMessage';
import { descriptionBlankMark } from '@pages/pro12/ResearchMainPage/constants';

export default function LearningPointAndRetrospection({
    setCompletion,
    shouldShowValidationErrorMessage,
    setRetrospectionList,
}: {
    setRetrospectionList: (retrospectionList: RetrospectionForm[]) => void;
    setCompletion: (bool: boolean) => void;
    shouldShowValidationErrorMessage: boolean;
}) {
    const { motivations, retrospectionList, presentationTitle, application } =
        useAppSelector(({ pro12 }) => {
            return {
                motivations:
                    pro12.researchIntroductionForm.motivations ||
                    '이러저러한 걸 해보고자 한다~',
                retrospectionList: pro12.researchRetrospectionList,
                presentationTitle: pro12.researchOverviewForm.presentationTitle,
                application: pro12.researchIntroductionForm.application,
            };
        });

    const [currentLearningPoint, setCurrentLearningPoint] = useState('');

    const [isInvalidFormSubmitted, setIsInvalidFormSubmitted] =
        useState<boolean>(false);

    const learningPointFormRef = useRef<UseFormReturn<LearningPointForm>>();
    const retrospectionFormRef = useRef<UseFormReturn<RetrospectionForm>>();

    function setLearningPointFormRef(ref: UseFormReturn<LearningPointForm>) {
        learningPointFormRef.current = ref;
        if (ref.getValues('value')) {
            setCurrentLearningPoint(ref.getValues('value'));
        }
    }

    function setRetrospectionFormRef(ref: UseFormReturn<RetrospectionForm>) {
        retrospectionFormRef.current = ref;
    }

    const [cards, setCards] = useState<RetrospectionForm[]>(
        retrospectionList ?? [],
    );
    const [shouldOpenDraft, setShouldOpenDraft] = useState(false);

    function insertTempCard() {
        const learningPointForm =
            learningPointFormRef.current as UseFormReturn<LearningPointForm>;
        const retrospectionForm =
            retrospectionFormRef.current as UseFormReturn<RetrospectionForm>;

        setCards((prev) => [...prev, retrospectionForm.getValues()]);
        setShouldOpenDraft(false);

        learningPointForm.reset();
        retrospectionForm.reset();
    }

    function removeCard(index: number) {
        setCards((prev) => prev.filter((_, i) => i !== index));
    }

    const hasNoCard = cards.length === 0;

    function isValid() {
        const learningPointForm =
            learningPointFormRef.current as UseFormReturn<LearningPointForm>;
        const retrospectionForm =
            retrospectionFormRef.current as UseFormReturn<RetrospectionForm>;

        const learningPoint = learningPointForm.getValues();
        const retrospection = retrospectionForm?.getValues();

        function isValidDescription(description: string) {
            return (
                description.length > 0 &&
                !description.includes(descriptionBlankMark)
            );
        }
        return (
            learningPoint.value?.length > 0 &&
            retrospection?.summary?.length > 0 &&
            isValidDescription(retrospection?.description)
        );
    }

    function validateAndInsert() {
        if (isValid()) {
            insertTempCard();
            setIsInvalidFormSubmitted(false);
        } else {
            setIsInvalidFormSubmitted(true);
        }
    }

    useEffect(
        function propagate() {
            setCompletion(cards.length > 0);
            setRetrospectionList(cards);
        },
        [cards],
    );

    return (
        <SectionWrapper id={'B'}>
            <SectionTitle>B. 배우고 느낀 점</SectionTitle>
            {(shouldShowValidationErrorMessage || isInvalidFormSubmitted) && (
                <FormErrorMessage message={'배우고 느낀 점을 입력해주세요.'} />
            )}
            <p className="lh-sm mb-0">
                활동을 통해 성장했을 때 네게 진정으로 의미 있는 과제탐구라 할 수
                있겠지?
                <br />
                배우고 느낀 점은 앞서 서술한 탐구 동기와도 연결지을 수 있어.
                과제탐구 과정을 통해 배우고 느낀 점을 정리해 보자.
            </p>

            <BorderBox variant={'primary'} className={'my-3'}>
                <div className="row align-items-center">
                    <div className="col-2 text-primary fw-semibold text-center">
                        <p className="mb-0">작성한 탐구동기</p>
                    </div>
                    <div className="col-10 ps-0">
                        <p className="mb-0">
                            {`${motivations} 이에, ${presentationTitle}을(를) 주제로 선정하여 ${application}을(를) 실시하고자 한다.`}
                        </p>
                    </div>
                </div>
            </BorderBox>

            {hasNoCard && (
                <LPRDraft
                    setLearningPointFormRef={setLearningPointFormRef}
                    setRetrospectionFormRef={setRetrospectionFormRef}
                    currentLearningPoint={currentLearningPoint}
                    insertHandler={validateAndInsert}
                />
            )}
            {cards.map(({ description }, index) => (
                <Card
                    key={index}
                    retrospectionDescription={description}
                    index={index}
                    removeCard={removeCard}
                />
            ))}
            {!hasNoCard && shouldOpenDraft && (
                <LPRDraft
                    setLearningPointFormRef={setLearningPointFormRef}
                    setRetrospectionFormRef={setRetrospectionFormRef}
                    currentLearningPoint={currentLearningPoint}
                    insertHandler={validateAndInsert}
                />
            )}
            {!hasNoCard && !shouldOpenDraft && (
                <div className="row mt-2 justify-content-center">
                    <div className="col-auto">
                        <button
                            className="btn btn-outline-danger rounded-4"
                            type="button"
                            onClick={() => setShouldOpenDraft(true)}
                        >
                            배우고 느낀 점 추가하기
                        </button>
                    </div>
                </div>
            )}
        </SectionWrapper>
    );
}
