import { ArrowRightAltOutlined } from '@mui/icons-material';
import { useForm } from 'react-hook-form';

import { useEtcRadio } from '@pages/hooks';
import { referenceTypeOptions } from '../constants';

export default function ReferenceDraft({
    onComplete,
}: {
    onComplete: (data: Reference) => void;
}) {
    const form = useForm<Reference>();
    const typeEtcRadio = useEtcRadio<Reference>({
        form,
        fieldName: 'type',
        otherOptions: referenceTypeOptions,
    });

    return (
        <div className={'row'}>
            <div className={'row mb-3'}>
                <div className={''}>
                    <div className={'row align-items-center'}>
                        {referenceTypeOptions.map((value, index) => (
                            <div className={'col-auto'} key={index}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value={value}
                                        {...form.register('type')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={value}
                                    >
                                        {value}
                                    </label>
                                </div>
                            </div>
                        ))}
                        <div className={'col-auto'}>
                            <div className={'form-check'}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value=""
                                    {...typeEtcRadio.registerRadio()}
                                />
                                <label className="form-check-label w-100">
                                    <input
                                        className="form-control form-control-sm w-100"
                                        type="text"
                                        placeholder="기타 활동 (직접 입력)"
                                        {...typeEtcRadio.registerInput()}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-auto'}>
                    <h5 className={'fs-6 fw-semibold'}>URL :</h5>
                </div>
                <div className={'col'}>
                    <input
                        className="form-control w-100"
                        type="text"
                        placeholder="URL을 입력해 주세요."
                        {...form.register('link', {
                            required: false,
                        })}
                    />
                </div>
            </div>
            <div className={'row align-items-center mt-3'}>
                <div className={'col-auto'}>
                    <h5 className={'fs-6 fw-semibold'}>내용(요약) :</h5>
                </div>
                <div className={'col'}>
                    <input
                        className="form-control w-100"
                        type="text"
                        placeholder="어떤 내용인가요? 간략히 기입하세요."
                        {...form.register('content', {
                            required: false,
                        })}
                    />
                </div>
            </div>
            <div className={'row align-items-center mt-3'}>
                <div className={'col-auto'}>
                    <h5 className={'fs-6 fw-semibold'}>발췌 내용(인용) :</h5>
                </div>
                <div className={'col'}>
                    <input
                        className="form-control w-100"
                        type="text"
                        placeholder="발췌 내용을 입력하세요."
                        {...form.register('quote', {
                            required: false,
                        })}
                    />
                </div>
            </div>
            <div className={'row align-items-center mt-3'}>
                <div className={'col-auto'}>
                    <h5 className={'fs-6 fw-semibold'}>발췌 구간 (서적) :</h5>
                </div>
                <div className={'col-auto'}>
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('startPageForBook', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>쪽</span>
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('startLineForBook', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>줄</span>
                    <ArrowRightAltOutlined className={'ms-2'} />
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('endPageForBook', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>쪽</span>
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('endLineForBook', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>줄</span>
                </div>
            </div>
            <div className={'row align-items-center mt-3'}>
                <div className={'col-auto'}>
                    <h5 className={'fs-6 fw-semibold'}>발췌 구간 (영상) :</h5>
                </div>
                <div className={'col-auto'}>
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('startMinuteForVideo', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>분</span>
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('startSecondForVideo', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>초</span>
                    <ArrowRightAltOutlined className={'ms-2'} />
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('endMinuteForVideo', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>분</span>
                    <input
                        className="form-control form-control-sm d-inline-block ms-2 rounded-3"
                        style={{ width: '70px' }}
                        type="number"
                        {...form.register('endSecondForVideo', {
                            required: false,
                        })}
                    />
                    <span className={'ms-2'}>초</span>
                </div>
            </div>
            <div className={'row align-items-center mt-3'}>
                <div className={'col-auto'}>
                    <h5 className={'fs-6 fw-semibold'}>참고문헌 정리 :</h5>
                </div>
                <div className={'col'}>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="참고문헌을 정해진 양식에 맞게 정리하세요."
                        {...form.register('summary', {
                            required: false,
                        })}
                    />
                </div>
                <div className={'col-auto'}>
                    <a
                        className="btn btn-outline-danger rounded-4"
                        href="https://libguide.snu.ac.kr/c.php?g=321609&p=2151776"
                        target="_blank"
                    >
                        참고문헌 작성 Tip!
                    </a>
                </div>
            </div>
            <div className={'row justify-content-center mt-3'}>
                <div className={'col-auto'}>
                    <button
                        type={'button'}
                        onClick={() => onComplete(form.getValues())}
                        className={'btn btn-outline-primary rounded-4'}
                    >
                        참고 자료 작성완료
                    </button>
                </div>
            </div>
        </div>
    );
}
