import {
    callPatchSemester,
    callCreateKeyword,
    callDeleteKeyword,
    callFetchSemesterInfo,
} from '@client';

export function useSemesterFacade(leanAIMetadata: LeanAIMetadata) {
    async function getSemesterInfo({
        studentId,
        grade,
        semester,
    }: {
        studentId: StudentId;
        grade: number;
        semester: number;
    }) {
        const data = await callFetchSemesterInfo({
            studentId,
            grade,
            semester,
        });
        return translateRawAndMergeMajorsSemester(leanAIMetadata, data);
    }

    function translateRawAndMergeMajorsSemester(
        leanAIMetadata: LeanAIMetadata,
        data: any,
    ): SemesterInfo {
        const { targetingMajorIds, interestedSchoolSubjectIds, ...rest } = data;
        const interestedMajorCategories = new Set();
        const targetingMajors = targetingMajorIds.map((majorId: MajorId) => {
            const {
                name,
                university: universityId,
                categories: rawCategories,
            } = leanAIMetadata.majors[majorId.toString()];

            // rawCategories: [{ [categoryId]: { category: string; parent: string } }]
            (rawCategories as any).forEach((rawCategoryValue: any) => {
                Object.values(rawCategoryValue).forEach((content: any) => {
                    interestedMajorCategories.add(content.category);
                });
            });

            return {
                id: majorId,
                name,
                university: leanAIMetadata.universities[universityId].name,
            };
        });
        const interestedSchoolSubjects = interestedSchoolSubjectIds.map(
            (schoolSubjectId: SchoolSubjectId) => ({
                id: schoolSubjectId,
                name: leanAIMetadata.schoolSubjects[schoolSubjectId].name,
            }),
        );

        return {
            targetingMajors,
            interestedSchoolSubjects,
            interestedMajorCategories: [...interestedMajorCategories.values()],
            ...rest,
        };
    }

    async function getSemesterInfoWithNotMergedMajor({
        studentId,
        grade,
        semester,
    }: {
        studentId: StudentId;
        grade: number;
        semester: number;
    }) {
        const data = await callFetchSemesterInfo({
            studentId,
            grade,
            semester,
        });
        return translateRawSemester(leanAIMetadata, data);
    }

    function translateRawSemester(
        leanAIMetadata: LeanAIMetadata,
        data: any,
    ): SemesterInfo {
        const { targetingMajorIds, interestedSchoolSubjectIds, ...rest } = data;
        const targetingMajors = targetingMajorIds.map((majorId: MajorId) => {
            const {
                name,
                university: universityId,
                categories: rawCategories,
            } = leanAIMetadata.majors[majorId.toString()];

            const majorCategories: string[] = [];
            // rawCategories: [{ [categoryId]: { category: string; parent: string } }]
            (rawCategories as any).forEach((rawCategoryValue: any) => {
                Object.values(rawCategoryValue).forEach((content: any) => {
                    majorCategories.push(content.category);
                });
            });

            return {
                name,
                university: leanAIMetadata.universities[universityId].name,
                majorCategories,
            };
        });

        return {
            targetingMajors,
            ...rest,
        };
    }

    async function addTargetingMajor({
        studentId,
        majorId,
        grade,
        semester,
    }: {
        studentId: StudentId;
        majorId: MajorId;
        grade: number;
        semester: number;
    }) {
        const semesterInfo = await callFetchSemesterInfo({
            studentId,
            grade,
            semester,
        });
        const majorIds = [...semesterInfo.targetingMajorIds, +majorId];

        await callPatchSemester({
            studentId,
            grade,
            semester,
            fieldName: 'targetingMajorIds',
            data: majorIds,
        });
    }

    async function removeTargetingMajor({
        studentId,
        majorId,
        grade,
        semester,
    }: {
        studentId: StudentId;
        majorId: MajorId;
        grade: number;
        semester: number;
    }) {
        const semesterInfo = await callFetchSemesterInfo({
            studentId,
            grade,
            semester,
        });
        const majorIds = semesterInfo.targetingMajorIds.filter(
            (id: number) => id !== +majorId,
        );

        await callPatchSemester({
            studentId,
            grade,
            semester,
            fieldName: 'targetingMajorIds',
            data: majorIds,
        });
    }

    async function addKeyword({
        studentId,
        keyword,
        grade,
        semester,
    }: {
        studentId: StudentId;
        keyword: string;
        grade: number;
        semester: number;
    }) {
        const semesterInfo = await callFetchSemesterInfo({
            studentId,
            grade,
            semester,
        });
        const keywords = [...semesterInfo.keywords, keyword];
        await callCreateKeyword({
            studentId,
            keywords,
        });
    }

    async function removeKeyword({
        studentId,
        keyword,
    }: {
        studentId: StudentId;
        keyword: string;
    }) {
        await callDeleteKeyword({
            studentId,
            keyword,
        });
    }

    async function updateInterestedSchoolSubjects({
        studentId,
        schoolSubjectIds,
        grade,
        semester,
    }: {
        studentId: StudentId;
        schoolSubjectIds: SchoolSubjectId[];
        grade: number;
        semester: number;
    }) {
        await callPatchSemester({
            studentId,
            grade,
            semester,
            fieldName: 'interestedSchoolSubjectIds',
            data: schoolSubjectIds,
        });
    }

    async function removeInterestedSchoolSubject({
        studentId,
        schoolSubjectId,
        grade,
        semester,
    }: {
        studentId: StudentId;
        schoolSubjectId: SchoolSubjectId;
        grade: number;
        semester: number;
    }) {
        const semesterInfo = await callFetchSemesterInfo({
            studentId,
            grade,
            semester,
        });
        const schoolSubjectIds = semesterInfo.interestedSchoolSubjectIds.filter(
            (id: number) => id !== schoolSubjectId,
        );
        await callPatchSemester({
            studentId,
            grade,
            semester,
            fieldName: 'interestedSchoolSubjectIds',
            data: schoolSubjectIds,
        });
    }

    return {
        getSemesterInfo,
        getSemesterInfoWithNotMergedMajor,
        addTargetingMajor,
        removeTargetingMajor,
        addKeyword,
        removeKeyword,
        updateInterestedSchoolSubjects,
        removeInterestedSchoolSubject,
    };
}
