import _ from 'lodash';

import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';

function transposeSchoolSubjects(schoolSubjectArray: SchoolSubject[][]) {
    const numRows = schoolSubjectArray.length;
    const numCols = _.max(schoolSubjectArray.map((e) => e.length))!;

    const transposedMatrix: SchoolSubject[][] = new Array(numCols)
        .fill([])
        .map(() => []);

    for (let row = 0; row < numRows; row++) {
        for (let col = 0; col < numCols; col++) {
            transposedMatrix[col][row] = schoolSubjectArray[row][col];
        }
    }

    return transposedMatrix;
}
export default function SchoolSubjectSelectModal({
    modalShow,
    closeModal,
    onSelect,
    interestedSchoolSubjectIds,
    schoolSubjectData,
}: {
    modalShow: boolean;
    closeModal: () => void;
    onSelect: (selectedSubjectIds: SchoolSubjectId[]) => void;
    interestedSchoolSubjectIds: SchoolSubjectId[];
    schoolSubjectData: LeanAIMetadata['schoolSubjects'];
}) {
    const flattenedSchoolSubjects = Object.entries(schoolSubjectData).map(
        ([id, schoolSubject]) => ({
            ...schoolSubject,
            id: +id,
        }),
    );

    const schoolSubjectsGroupedByCategory = _.groupBy(
        flattenedSchoolSubjects,
        'category',
    );
    const categories = Object.keys(schoolSubjectsGroupedByCategory);
    const schoolSubjectArray = Object.values(schoolSubjectsGroupedByCategory);
    const reshapedSchoolSubjects = transposeSchoolSubjects(schoolSubjectArray);

    const [selectedSchoolSubjectIds, setSelectedSchoolSubjectIds] = useState<
        SchoolSubjectId[]
    >(interestedSchoolSubjectIds);

    useEffect(
        function syncInterestedSchoolSubjectIds() {
            setSelectedSchoolSubjectIds(interestedSchoolSubjectIds);
        },
        [interestedSchoolSubjectIds],
    );

    function toggleSubject(subject: SchoolSubject) {
        if (selectedSchoolSubjectIds.includes(subject.id)) {
            setSelectedSchoolSubjectIds(
                selectedSchoolSubjectIds.filter((e) => e !== subject.id),
            );
        } else {
            setSelectedSchoolSubjectIds([
                ...selectedSchoolSubjectIds,
                subject.id,
            ]);
        }
    }
    function clearAndClose() {
        setSelectedSchoolSubjectIds(interestedSchoolSubjectIds);
        closeModal();
    }

    function selectAndClose() {
        onSelect(selectedSchoolSubjectIds);
        setSelectedSchoolSubjectIds([]);
        closeModal();
    }

    return (
        <Modal
            show={modalShow}
            onHide={closeModal}
            dialogClassName="max-w-[800px]"
        >
            <button
                className="absolute top-5 right-8 w-fit material-icons text-purple"
                onClick={clearAndClose}
                type="button"
            >
                close
            </button>
            <div className="px-10 py-16">
                <h1 className="text-center text-3xl font-bold pb-12">
                    과목 선택
                </h1>
                <table>
                    <thead>
                        <tr>
                            {categories.map((category) => (
                                <th
                                    className="text-center text-xl text-[#A191ED] font-bold py-3"
                                    key={category}
                                >
                                    {category}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {reshapedSchoolSubjects.map((row, index) => (
                            <tr key={index}>
                                {row.map((subject, _index) => (
                                    <td
                                        className="text-center text-xl py-3"
                                        key={`${index}-${_index}`}
                                    >
                                        {subject && (
                                            <button
                                                className={
                                                    selectedSchoolSubjectIds.includes(
                                                        subject.id,
                                                    )
                                                        ? '!text-purple font-bold'
                                                        : ''
                                                }
                                                onClick={() =>
                                                    toggleSubject(subject)
                                                }
                                            >
                                                {subject.name}
                                            </button>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="text-center pt-20">
                    <button
                        className="text-xl font-bold rounded-lg py-3 px-40 bg-purple text-white"
                        onClick={selectAndClose}
                    >
                        선택 완료
                    </button>
                </div>
            </div>
        </Modal>
    );
}
