import { client } from './client';

export async function callFetchAssignmentIndexes(params: any) {
    const response = await client.get(
        '/assignment_app/assignments/prefetch-list/',
        {
            params,
        },
    );
    return response.data;
}
