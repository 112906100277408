import { useForm } from 'react-hook-form';
import { AutoAwesome } from '@mui/icons-material';

interface SelfChecklistForm {
    aboutResearchSubject: string[];
    aboutResearchContentsAndStructure: string[];
    aboutPresentation: string[];
    comments: string;
}

function getInitialState() {
    return {
        aboutResearchSubject: [],
        aboutResearchContentsAndStructure: [],
        aboutPresentation: [],
        comments: '',
    };
}

const options = {
    aboutResearchSubject: [
        '과목과 관련있는 주제인가요?',
        '나의 진로와 연계된 주제인가요?',
    ],
    aboutResearchContentsAndStructure: [
        '제목이 주제를 잘 드러내고 있나요?',
        '서론-본론-결론의 짜임새를 갖추고 있나요?',
        '목차를 통해 소주제를 확인할 수 있나요?',
        '주제/소주제를 벗어나는 내용은 없나요?',
        '참고자료를 충분히 탐색하였나요? (3개 이상)',
        '참고자료가 주제/소주제와 긴밀히 연관되나요?',
        '참고자료의 내용을 잘 이해하고 있나요?',
        '참고자료의 출처가 명확한가요?',
        '모든 항목을 빠짐없이 작성하였나요?',
    ],
    aboutPresentation: [
        '탐구한 내용을 독자/청중이 무리 없이 이해할 수 있나요?',
        '맞춤법, 띄어쓰기 등 문법적 오류는 없나요?',
    ],
};

export default function SelfChecklist({
    initialState,
    onSubmit = () => {},
}: {
    initialState?: SelfChecklistForm;
    onSubmit?: (data: SelfChecklistForm) => void;
}) {
    const { watch, register, handleSubmit } = useForm<SelfChecklistForm>({
        defaultValues: initialState ?? getInitialState(),
    });

    return (
        <div className="w-100 right-nav-container mt-5">
            <form className="right-nav" onSubmit={handleSubmit(onSubmit)}>
                <h4 className="text-center fw-bold text-secondary">
                    Self-Checklist
                </h4>
                <div className="checklist-container border bg-white rounded-4 p-3">
                    <p className="small text-black-50">
                        ※작성한 탐구 설계에 대해 각 항목을 체크해보세요.
                    </p>
                    <div className="checklist">
                        <div className="row mb-1">
                            <span>
                                <b>[주제]</b>
                                <span className="text-danger fw-bolder ms-1">
                                    {watch('aboutResearchSubject').length}/2
                                </span>
                            </span>
                        </div>
                        {options.aboutResearchSubject.map((option, index) => (
                            <div className="row" key={index}>
                                <div className="form-check d-flex">
                                    <input
                                        type="checkbox"
                                        className="form-input-check me-2"
                                        {...register('aboutResearchSubject')}
                                        value={option}
                                    />
                                    <label htmlFor={option}>{option}</label>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="checklist mt-3">
                        <div className="row mb-1">
                            <span>
                                <b>[내용 및 구성]</b>
                                <span className="text-danger fw-bolder ms-1">
                                    {
                                        watch(
                                            'aboutResearchContentsAndStructure',
                                        ).length
                                    }
                                    /9
                                </span>
                            </span>
                        </div>
                        {options.aboutResearchContentsAndStructure.map(
                            (option, index) => (
                                <div className="row" key={index}>
                                    <div className="form-check d-flex">
                                        <input
                                            type="checkbox"
                                            className="form-input-check me-2"
                                            {...register(
                                                'aboutResearchContentsAndStructure',
                                            )}
                                            value={option}
                                        />
                                        <label htmlFor={option}>{option}</label>
                                    </div>
                                </div>
                            ),
                        )}
                    </div>

                    <div className="checklist mt-3">
                        <div className="row mb-1">
                            <span>
                                <b>[표현]</b>
                                <span className="text-danger fw-bolder ms-1">
                                    {watch('aboutPresentation').length}/2
                                </span>
                            </span>
                        </div>
                        {options.aboutPresentation.map((option, index) => (
                            <div className="row" key={index}>
                                <div className="form-check d-flex">
                                    <input
                                        type="checkbox"
                                        className="form-input-check me-2"
                                        {...register('aboutPresentation')}
                                        value={option}
                                    />
                                    <label htmlFor={option}>{option}</label>
                                </div>
                            </div>
                        ))}
                    </div>

                    <h5 className="mt-4">선생님께 하고 싶은 말이 있나요?</h5>
                    {/* <input
                        className="border rounded-2 w-100 p-2"
                        type="text"
                        placeholder="조언을 구하고 싶은 점 등을 작성해 보세요."
                        {...register('comments')}
                    /> */}
                    <textarea
                        className="border rounded-2 w-100 p-2"
                        id=""
                        rows={3}
                        {...register('comments')}
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="border border-danger rounded-5 bg-white text-danger px-5 py-2 fw-bold mt-2 mx-auto d-flex"
                >
                    <AutoAwesome className="me-2" />
                    제출하기
                </button>
            </form>
        </div>
    );
}
