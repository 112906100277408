import { EditOutlined, DeleteOutline } from '@mui/icons-material';

import { ReferenceCard, BorderBox } from '../components';

export default function RCRCard({
    value,
    index,
    onEditButtonClick,
    onReferenceRemoveButtonClick,
}: {
    value: RCRForm;
    index: number;
    onEditButtonClick: () => void;
    onReferenceRemoveButtonClick: (index: number) => void;
}) {
    return (
        <>
            <div className="row align-items-center">
                <div className="col-auto">
                    <h5 className="fs-5 fw-semibold">
                        ({index + 1}) {value.researchContent}
                    </h5>
                </div>
                <div className="col text-end">
                    <button
                        type={'button'}
                        className={'btn btn-outline btn-sm'}
                        onClick={() => onEditButtonClick()}
                    >
                        <EditOutlined className={'text-danger'} />
                    </button>
                </div>
            </div>
            <div className="row mt-2">
                <BorderBox variant="grey">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: value.researchContentDescription,
                        }}
                    ></div>
                </BorderBox>
            </div>
            {value.references.map((reference, index) => (
                <div className="row mt-2" key={index}>
                    <BorderBox variant="primary">
                        <ReferenceCard
                            key={index}
                            reference={reference}
                            removeReference={() =>
                                onReferenceRemoveButtonClick(index)
                            }
                        />
                    </BorderBox>
                </div>
            ))}
        </>
    );
}
