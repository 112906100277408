import '@styles/components/footer.scss';

export default function Footer() {
    return (
        <div className="footer-container no-print">
            <p>(주)잡쇼퍼 | 대표: 권재우, 권기원 | 전화번호 : 02-6951-1510</p>
            <p>개인정보보호책임자 : 권기원 ceo@jobshopper.co.kr</p>
            <p>통신판매업신고번호 : 2019-서울성북-0908</p>
            <p>서울특별시 관악구 봉천로 545, 2층 201호 (주)잡쇼퍼</p>
            <p>사업자등록번호: 315-87-00617</p>
            <p>대표이메일 : ceo@jobshopper.co.kr</p>
            <p>© 2020. Hakzzong. All Rights Reserved.</p>
        </div>
    );
}
