import { generatePath, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useAppSelector } from '@store';
import { callHasA01Record } from '@client';
import clsx from 'clsx';
import { useLeanAIMetadataFacade, useSemesterFacade } from '@facades';

import '@styles/pages/landingPage.scss';

import anlyzeImg from "@images/purple-analyze.png";
import briefImg from '@images/brief.svg';
import tableImg from '@images/table.svg';
import documentsImg from '@images/product-documents.svg';
import chatImg from '@images/chat-svgrepo-com.svg';
import questionnaireImg from '@images/questionnaire.svg';
import { PATH } from '../ProjectRoutes';
import { HelpOutlined } from '@mui/icons-material';
import { A01_URL, NEW_HZ_FRONTEND_URL } from '@constants';

function Tooltip({
    label,
    children,
    triggerNode,
}: {
    label?: React.ReactNode;
    children: React.ReactNode;
    triggerNode: React.ReactElement;
}) {
    return (
        <>
            <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="right"
                overlay={
                    <Popover style={{ maxWidth: 500 }}>
                        <Popover.Body>{children}</Popover.Body>
                    </Popover>
                }
                defaultShow={false}
                delay={{ show: 0, hide: 1000 }}
            >
                {triggerNode}
            </OverlayTrigger>
        </>
    );
}

let toDeprecatedRoute = false;
export default function LandingPage() {
    const student = useAppSelector((state) => state.student);
    const leanAIMetadataFacade = useLeanAIMetadataFacade();
    const leanAIMetadata = leanAIMetadataFacade.get();
    const semesterFacade = useSemesterFacade(leanAIMetadata);

    const { id, grade, code, phoneNumber } = student;
    const [hasA01Record, setHasA01Record] = useState(false);
    const currentMonth = new Date().getMonth() + 1;
    const currentSemester = currentMonth > 2 && currentMonth < 9 ? 1 : 2;

    const [semesters, setSemesters] = useState<SemesterInfo[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        callHasA01Record(id).then((hasRecord) => setHasA01Record(hasRecord));
    }, [id]);

    function gotoMajorCategoryExplorationTournamentPage() {
        navigate(PATH.MAJOR_CATEGORY_EXPLORATION_INITIALIZOR);
    }

    function gotoSchoolRecordPage() {
        navigate(PATH.SCHOOL_RECORD);
    }

    function goToNewHzPage() {
        const consultationRecords = ['1', '2', '3'].map((grade) => {
            const majors = semesters
                .filter((semester) => semester.grade === grade)
                .reduce((p, c) => {
                    return p.concat(c.targetingMajors);
                }, [] as any[]);
            const [major] = majors;

            return {
                grade,
                university: major?.university ?? '',
                major: major?.name ?? '',
                majorCategories: (major?.majorCategories || []).map(
                    (c: any) => c?.category ?? '',
                ),
            };
        });

        const data = {
            student: {
                name: student?.name ?? '',
                grade: student?.grade ?? '',
                school: student?.school ?? '',
                검사학년: grade,
            },
            institution: {
                name: student.cramSchool,
            },
            consultationRecords,
        };


        const url = new URL(
            'hzhw/school-report-cards',
            NEW_HZ_FRONTEND_URL,
        );
        const encoded = encodeURI(JSON.stringify(data));
        url.search = `?data=${encoded}&studentId=${student.code}`;
        window.location.href = url.toString();
    }

    function goToAssignmentListPage(e: React.MouseEvent) {
        if (toDeprecatedRoute) {
            toDeprecatedRoute = false;
            return;
        }
        navigate(
            generatePath(PATH.PRO12_ASSIGNMENT_LIST, {
                grade: String(grade),
                semester: String(currentSemester),
            }),
        );
    }

    function goToAssignmentListPageDeprecated() {
        toDeprecatedRoute = true;
        navigate(
            generatePath(PATH.ASSIGNMENT_LIST__DEPRECATED, {
                grade: String(grade),
                semester: String(currentSemester),
            }),
        );
    }

    function gotoMainPage() {
        navigate(
            generatePath(PATH.MAIN, {
                grade: String(grade),
                semester: String(currentSemester),
            }),
        );
    }

    function getA01Url(): string {
        if (!hasA01Record) {
            return '#';
        }

        const url = new URL('/a01/result/details', A01_URL);
        url.searchParams.set('hzsStudentId', id.toString());
        url.searchParams.set('hzsStudentCode', code);
        url.searchParams.set('hzsStudentPhoneNumber', phoneNumber);
        return url.toString();
    }

    useEffect(
        function loadCurrentSemesterInfo() {
            if (!leanAIMetadataFacade.isReady) {
                return;
            }
            (async () => {
                const fetched = await Promise.all(
                    ['1:1', '1:2', '2:1', '2:2', '3:1', '3:2'].map((t) => {
                        const [grade, semester] = t.split(':');
                        return semesterFacade.getSemesterInfoWithNotMergedMajor(
                            {
                                studentId: student.id,
                                grade: +grade,
                                semester: +semester,
                            },
                        );
                    }),
                );

                setSemesters(fetched);
            })();
        },
        [leanAIMetadataFacade.isReady],
    );

    return (
        <div className="page-base" id="landingPage">
            <div className="landing-page-container">
                <div className="landing-page-image d-none d-sm-block"></div>
                <div className="mobile-landing-page-image d-block d-sm-none"></div>
                <div className="button-group">
                    <div className="d-flex justify-content-center pb-5">
                        <button
                            disabled={!hasA01Record}
                            style={{
                                opacity: hasA01Record ? 1 : 0.5,
                            }}
                            type="button"
                        >
                            <a
                                href={getA01Url()}
                                onClick={(e) => {
                                    if (!hasA01Record) {
                                        e.preventDefault();
                                    }
                                }}
                                className={clsx(
                                    !hasA01Record && 'cursor-default',
                                )}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="button-image"
                                    src={tableImg}
                                    alt="HAKZZONG"
                                />
                                <p>수시배치표 확인</p>
                                {!hasA01Record && (
                                    <small className="text-[0.65rem] text-center text-red-500">
                                        ⚠️ 수시배치표 검사 진행 후 확인하실 수
                                        있습니다.
                                    </small>
                                )}
                            </a>
                        </button>
                        <button onClick={gotoSchoolRecordPage}>
                            <img
                                className="button-image"
                                src={briefImg}
                                alt="HAKZZONG"
                            />
                            <p>생기부 관리</p>
                        </button>

                        <button onClick={goToNewHzPage}>
                            <img
                                className="button-image"
                                src={anlyzeImg}
                                alt="HAKZZONG"
                            />
                            <p>AI 생기부 관리 BETA</p>
                        </button>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button onClick={goToAssignmentListPage}>
                            <img
                                className="button-image"
                                src={documentsImg}
                                alt="HAKZZONG"
                            />
                            <p>
                                과제 관리{' '}
                                <Tooltip
                                    triggerNode={
                                        <HelpOutlined
                                            className={'text-danger mb-1'}
                                        />
                                    }
                                >
                                    <a
                                        href={'#'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            goToAssignmentListPageDeprecated();
                                        }}
                                    >
                                        혹시 2022. 12. 12 이전 과제를 찾고
                                        계신가요?
                                    </a>
                                </Tooltip>
                            </p>
                        </button>
                        <button onClick={gotoMainPage}>
                            <img
                                className="button-image"
                                src={chatImg}
                                alt="HAKZZONG"
                            />
                            <p>학기별 관리</p>
                        </button>
                        <button
                            onClick={gotoMajorCategoryExplorationTournamentPage}
                        >
                            <img
                                className="button-image ms-3"
                                src={questionnaireImg}
                                alt="HAKZZONG"
                            />
                            <p>계열 검사</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
