import '@styles/components/schoolRecordUploadGuide.scss';

import uploadGuideImg1 from '@images/upload-guide-1.png';
import uploadGuideImg2 from '@images/upload-guide-2.png';
import uploadGuideImg3 from '@images/upload-guide-3.png';
import uploadGuideImg4 from '@images/upload-guide-4.png';
import uploadGuideImg5 from '@images/upload-guide-5.png';
import uploadGuideImg6 from '@images/upload-guide-6.png';
import uploadGuideImg7 from '@images/upload-guide-7.png';
import uploadGuideImg8 from '@images/upload-guide-8.png';
import uploadGuideImg9 from '@images/upload-guide-9.png';
import { Col, Container, Row } from 'react-bootstrap';

export default function SchoolRecordUploadGuide() {
    return (
        <Container className="semester-report-upload-guide-container">
            <Row className="upload-guide-step-container flex-md-row flex-column-reverse">
                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <div className="upload-guide-title-box">
                            <div className="upload-guide-step-title">
                                [Step 1] 나이스플러스 로그인
                            </div>
                        </div>
                        <ul className="upload-guide-text">
                            <li>
                                나이스플러스 사이트(
                                <a
                                    href="https://www.neisplus.kr"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.neisplus.kr
                                </a>
                                )에 접속하시어 회원가입 후 로그인을 해주세요.
                            </li>
                            <li>
                                크롬 접속을 권장하며, 이용 전 모든 보안프로그램
                                설치 및 팝업
                                <br />
                                허용을 해주세요.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img
                        src={uploadGuideImg1}
                        alt="HAKZZONG"
                        className="justify-content-md-start"
                    />
                </Col>
            </Row>
            <Row className="upload-guide-step-container flex-md-row flex-column-reverse">
                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <div className="upload-guide-title-box">
                            <div className="upload-guide-step-title">
                                [Step 2] '학교생활기록' 클릭
                            </div>
                        </div>
                        <ul className="upload-guide-text">
                            <li>
                                나이스플러스에 로그인 하신 후 상단 좌측의
                                '학교생활기록' 버튼을 <br />
                                클릭해주세요.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg2} alt="HAKZZONG" />
                </Col>
                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <ul className="upload-guide-text">
                            <li>
                                학교생활기록 체크박스가 모두 선택되어 있는지
                                확인해주신 후, <br />
                                페이지 우측 상단의 점 3개(설정)를 클릭해주세요.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg3} alt="HAKZZONG" />
                </Col>
            </Row>
            <Row className="upload-guide-step-container flex-md-row flex-column-reverse">
                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <div className="upload-guide-title-box">
                            <div className="upload-guide-step-title">
                                [Step 3] 생활기록부 저장
                            </div>
                        </div>
                        <ul className="upload-guide-text">
                            <li>
                                '도구 더보기' -&gt; '페이지를 다른 이름으로
                                저장'을 클릭해주시면
                                <br /> 저장 팝업창이 뜹니다.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg4} alt="HAKZZONG" />
                </Col>
                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <ul className="upload-guide-text">
                            <li>
                                파일이름을 영문으로 설정해주시고, 파일형식은
                                '웹페이지, 전부' 혹은 '웹페이지 완료'로
                                설정해주세요.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg5} alt="HAKZZONG" />
                </Col>
            </Row>
            <Row className="upload-guide-step-container flex-md-row flex-column-reverse">
                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <div className="upload-guide-title-box">
                            <div className="upload-guide-step-title">
                                [Step 4] 학쫑 생기부 업로드
                            </div>
                        </div>
                        <ul className="upload-guide-text">
                            <li>
                                <a href="https://학쫑학원.com">학쫑학원.com</a>
                                에 접속하시어 로그인 하신 후, '생기부 관리'
                                버튼을
                                <br /> 클릭해주세요.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg6} alt="HAKZZONG" />
                </Col>

                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <ul className="upload-guide-text">
                            <li>
                                본인 학년에 맞춰 '생기부 제출' 버튼을
                                클릭해주세요.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg7} alt="HAKZZONG" />
                </Col>

                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <ul className="upload-guide-text">
                            <li>'HTML 파일 업로드' 버튼을 눌러주세요.</li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg8} alt="HAKZZONG" />
                </Col>

                <Col lg={6} md={7} sm={12} xs={12}>
                    <div className="upload-guide-content">
                        <ul className="upload-guide-text">
                            <li>
                                개인정보 및 민감정보 수집 이용에 동의해주신 후,
                                '제출하기' 버튼을 클릭해주시면 제출 완료!
                            </li>
                            <li>
                                원장님께서 학생의 생기부를 진단해주실 때까지
                                기다려주세요.
                                <br /> 진단이 완료되면 학생의 문자로
                                안내해드립니다.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col lg={6} md={5} sm={12} xs={12}>
                    <img src={uploadGuideImg9} alt="HAKZZONG" />
                </Col>
            </Row>
        </Container>
    );
}
