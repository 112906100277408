import { client } from './client';

export async function callFetchAssignmentProgressingCounts(params: any) {
    const response = await client.get(
        '/assignment_app/assignments/progress-counts/',
        {
            params,
        },
    );
    return response.data;
}
