import { DeleteOutline } from '@mui/icons-material';
import { BorderBox } from '@pages/pro12/components';
import { getIndexInKorean } from '../helpers';

export default function Card({
    retrospectionDescription,
    index,
    removeCard,
}: {
    retrospectionDescription: string;
    index: number;
    removeCard: (index: number) => void;
}) {
    return (
        <BorderBox variant="danger" className="mb-2 p-4" key={index}>
            <div className="row align-items-center">
                <div className="col-11">
                    {index === 0 && (
                        <p className={'mb-2'}>
                            본 과제탐구를 통해 배우고 느낀 점을 정리하면 다음과
                            같다.
                        </p>
                    )}
                    <p className="text-primary">
                        <span className="text-black">
                            ({getIndexInKorean(index)}, ){' '}
                        </span>
                        {retrospectionDescription}
                    </p>
                </div>
                <div className="col-1">
                    <button
                        type={'button'}
                        className={'btn btn-outline btn-sm'}
                        onClick={() => removeCard(index)}
                    >
                        <DeleteOutline className={'text-danger'} />
                    </button>
                </div>
            </div>
        </BorderBox>
    );
}
