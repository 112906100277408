import {
    Navigate,
    Outlet,
    RouteObject,
    useRoutes,
    useSearchParams,
} from 'react-router-dom';

import Footer from '@components/Footer';
import Header from '@components/Header';
import { useStudentFacade } from '@facades';
import AssignmentPage from '@pages/AssignmentPage';
import AssignmentReportFormPage from '@pages/AssignmentReportFormPage';
import IssuedSubjectDetailPage from '@pages/IssuedSubjectDetailPage';
import LandingPage from '@pages/LandingPage';
import LoginPage from '@pages/LoginPage';
import MainPage from '@pages/MainPage';
import PaymentPage from '@pages/PaymentPage';
import SchoolRecordAnalysisResultPage from '@pages/SchoolRecordAnalysisResultPage';
import SchoolRecordPage from '@pages/SchoolRecordPage';
import AssignmentListPage from '@pages/pro12/AssignmentListPage';
import OnboardingPage from '@pages/pro12/OnBoardingPage';
import ResearchOverviewPage from '@pages/pro12/ResearchOverviewPage';
import ResearchIntroductionPage from '@pages/pro12/ResearchIntroductionPage';
import ResearchMainPage from '@pages/pro12/ResearchMainPage';
import ResearchConclusionPage from '@pages/pro12/ResearchConclusionPage';
import ResearchReviewPage from '@pages/pro12/ResearchReviewPage';
import Pro12Layout from '@pages/pro12/Pro12Layout';
import MajorCategoryExplorationTournamentPage from '@pages/majorCategoryExploration/ExplorationPage';
import MajorCategoryExplorationInitializorPage from '@pages/majorCategoryExploration/InitializorPage';
import MajorCategoryExplorationResultPage from '@pages/majorCategoryExploration/ResultPage';
import MajorCategoryExplorationResultPreviewPage from '@pages/majorCategoryExploration/ResultPreviewPage';
import { Pro12ContextProvider } from '@pages/pro12/Pro12Context';
import Step3ReviewPage from '@pages/pro12/Step3ReviewPage';

export const PATH = {
    LOGIN: '/',
    MAIN: '/main/:grade/:semester',
    LANDING: '/intro',
    SCHOOL_RECORD: '/school-records',
    SCHOOL_RECORD_ANALYSIS_RESULT: '/school-records/result/:grade',
    ISSUED_SUBJECT_DETAIL: '/issued-subjects/:id',
    PAYMENT: '/payment/:orderId',
    FALLBACK: '*',

    PRO12_ASSIGNMENT_LIST: '/pro12/assignments/:grade/:semester',
    PRO12_ASSIGNMENT_REPORT_STEP3_REVIEW: '/pro12/assignments/:assignmentId/report/step3',
    PRO12_ASSIGNMENT_REPORT: '/pro12/assignments/:assignmentId/report/v2',
    ASSIGNMENT_REPORT_FORM__V1: '/dep/assignments/:assignmentId/report/v1',
    PRO12_ON_BOARD: '/pro12/assignments/:assignmentId/on-board',

    // deprecated routes
    ASSIGNMENT_LIST__DEPRECATED: '/dep/assignments/:grade/:semester',

    MAJOR_CATEGORY_EXPLORATION: '/major-category-exploration',
    MAJOR_CATEGORY_EXPLORATION_INITIALIZOR: '/major-category-exploration/init',
    MAJOR_CATEGORY_EXPLORATION_RESULT: '/major-category-exploration/result',
    MAJOR_CATEGORY_EXPLORATION_RESULT_PREVIEW:
        '/major-category-exploration/result-preview',
};

const withHeaderAndFooter = (children: React.ReactNode) => (
    <>
        <Header />
        {children}
        <Footer />
    </>
);

export function resolveRoute(
    path: string,
    params: { [key: string]: string | number } = {},
) {
    let resolved = path;
    Object.entries(params).forEach(([key, value]) => {
        resolved = resolved.replace(`:${key}`, value.toString());
    });
    return resolved;
}

function Redirect({
    to,
    preserveSearchParams = true,
}: {
    to: string;
    preserveSearchParams?: boolean;
}) {
    const [searchParams] = useSearchParams();
    const search = searchParams.toString();
    const searchParam = search ? `?${search}` : '';
    return (
        <Navigate
            to={`${to}${preserveSearchParams ? searchParam : ''}`}
            replace
        />
    );
}

export default function ProjectRoutes() {
    let routes: RouteObject[] = [
        {
            path: PATH.PAYMENT,
            element: <PaymentPage />,
        },
    ];
    const studentFacade = useStudentFacade();
    if (studentFacade.isLoggedIn) {
        routes = [
            ...routes,
            {
                path: '/',
                element: <Navigate to={PATH.LANDING} replace={true} />,
                index: true,
            },
            {
                path: PATH.LANDING,
                element: withHeaderAndFooter(<LandingPage />),
            },
            {
                path: PATH.MAIN,
                element: withHeaderAndFooter(<MainPage />),
            },
            {
                path: PATH.SCHOOL_RECORD,
                element: withHeaderAndFooter(<SchoolRecordPage />),
            },
            {
                path: PATH.SCHOOL_RECORD_ANALYSIS_RESULT,
                element: withHeaderAndFooter(
                    <SchoolRecordAnalysisResultPage />,
                ),
            },
            {
                path: PATH.ISSUED_SUBJECT_DETAIL,
                element: withHeaderAndFooter(<IssuedSubjectDetailPage />),
            },
            {
                path: PATH.MAJOR_CATEGORY_EXPLORATION_INITIALIZOR,
                element: withHeaderAndFooter(
                    <MajorCategoryExplorationInitializorPage />,
                ),
            },
            {
                path: PATH.MAJOR_CATEGORY_EXPLORATION,
                element: withHeaderAndFooter(
                    <MajorCategoryExplorationTournamentPage />,
                ),
            },
            {
                path: PATH.MAJOR_CATEGORY_EXPLORATION_RESULT,
                element: withHeaderAndFooter(
                    <MajorCategoryExplorationResultPage />,
                ),
            },
            {
                path: PATH.MAJOR_CATEGORY_EXPLORATION_RESULT_PREVIEW,
                element: withHeaderAndFooter(
                    <MajorCategoryExplorationResultPreviewPage />,
                ),
            },
            // pro12
            {
                path: '',
                element: <Outlet />,
                children: [
                    {
                        path: PATH.PRO12_ON_BOARD,
                        element: withHeaderAndFooter(<OnboardingPage />),
                    },
                    {
                        path: PATH.PRO12_ASSIGNMENT_LIST,
                        element: withHeaderAndFooter(<AssignmentListPage />),
                    },
                    // step3
                    {
                        path: PATH.PRO12_ASSIGNMENT_REPORT_STEP3_REVIEW,
                        element: withHeaderAndFooter(
                            <Step3ReviewPage />
                        ),
                    },
                    {
                        path: PATH.PRO12_ASSIGNMENT_REPORT,
                        element: withHeaderAndFooter(
                            <Pro12ContextProvider>
                                <Pro12Layout>
                                    <Outlet />
                                </Pro12Layout>
                            </Pro12ContextProvider>,
                        ),
                        children: [
                            {
                                path: '0',
                                element: <ResearchOverviewPage />,
                            },
                            {
                                path: '1',
                                element: <ResearchIntroductionPage />,
                            },
                            {
                                path: '2',
                                element: <ResearchMainPage />,
                            },
                            {
                                path: '3',
                                element: <ResearchConclusionPage />,
                            },
                            {
                                path: '4',
                                element: <ResearchReviewPage />,
                            },
                        ],
                    },
                ],
            },

            // routes to be deprecated
            {
                path: PATH.ASSIGNMENT_LIST__DEPRECATED,
                element: withHeaderAndFooter(<AssignmentPage />),
            },
            {
                path: PATH.ASSIGNMENT_REPORT_FORM__V1,
                element: withHeaderAndFooter(<AssignmentReportFormPage />),
            },
            {
                path: PATH.FALLBACK,
                element: <Navigate to={PATH.LANDING} replace={true} />,
            },
            {
                path: PATH.LOGIN,
                element: <LoginPage />,
            },
        ];
    } else {
        routes = [
            ...routes,
            {
                path: PATH.LOGIN,
                element: <LoginPage />,
                index: true,
            },
            {
                path: PATH.FALLBACK,
                element: <Navigate to={PATH.LOGIN} replace={true} />,
            },
        ];
    }
    return useRoutes(routes);
}
