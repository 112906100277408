import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ContentSummaryForm,
    RetrospectionForm,
} from '@pages/pro12/ResearchConclusionPage/types';

export interface ResearchContentAndReferencesFormState
    extends ResearchContentAndReferencesForm {
    isComplete: boolean;
}

export interface ResearchToughPointOvercomingMethodSet {
    toughPoint: string;
    overcomingMethod: string;
}

function getInitialState() {
    return {
        assignmentId: -1,
        currentStep: 0,
        isResubmitted: false,
        isSubmitted: false,
        isCompleted: false,
        researchOverviewForm: {
            relatedSchoolSubjectUnits:
                [] as ResearchOverviewForm['relatedSchoolSubjectUnits'],
            relatedLearningElements:
                [] as ResearchOverviewForm['relatedLearningElements'],
            contents: [{ value: '' }] as ResearchOverviewForm['contents'],
            plannings: [{ content: '' }] as ResearchOverviewForm['plannings'],
            dueDate: new Date(),
        } as ResearchOverviewForm,
        researchIntroductionForm: {
            application: '' as ResearchIntroductionForm['application'],
            methods: [] as ResearchIntroductionForm['methods'],
            motivationDraft: [] as ResearchIntroductionForm['motivationDraft'],
            objectives: [
                { objective: '' },
            ] as ResearchIntroductionForm['objectives'],
            references: [] as ResearchIntroductionForm['references'],
            types: [] as ResearchIntroductionForm['types'],
            motivations: [] as ResearchIntroductionForm['motivations'],
        } as ResearchIntroductionForm,
        researchTheoreticalBackgroundAndReferencesForms:
            [] as TheoreticalBackgroundAndReferencesForm[],
        researchContentAndReferencesForms:
            [] as Array<ResearchContentAndReferencesFormState>,
        researchToughPointOvercomingMethodSets:
            [] as ResearchToughPointOvercomingMethodSet[],
        researchContentSummaryList: [] as ContentSummaryForm[],
        researchRetrospectionList: [] as RetrospectionForm[],
        lastEditedAt: 0,
    };
}

export const pro12Slice = createSlice({
    name: 'pro12',
    initialState: getInitialState(),
    reducers: {
        setAssignmentId(state, action: PayloadAction<number>) {
            return {
                ...state,
                assignmentId: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setCurrentStep(state, action: PayloadAction<number>) {
            return {
                ...state,
                currentStep: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setResearchOverviewForm(
            state,
            action: PayloadAction<ResearchOverviewForm>,
        ) {
            return {
                ...state,
                researchOverviewForm: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setResearchIntroductionForm(
            state,
            action: PayloadAction<ResearchIntroductionForm>,
        ) {
            return {
                ...state,
                researchIntroductionForm: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setTheoreticalBackgroundAndReferencesForms(
            state,
            action: PayloadAction<TheoreticalBackgroundAndReferencesForm[]>,
        ) {
            return {
                ...state,
                researchTheoreticalBackgroundAndReferencesForms: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setResearchContentAndReferencesForms(
            state,
            action: PayloadAction<ResearchContentAndReferencesFormState[]>,
        ) {
            return {
                ...state,
                researchContentAndReferencesForms: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setResearchToughPointOvercomingMethodSets(
            state,
            action: PayloadAction<ResearchToughPointOvercomingMethodSet[]>,
        ) {
            return {
                ...state,
                researchToughPointOvercomingMethodSets: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setResearchContentSummaryList(
            state,
            action: PayloadAction<ContentSummaryForm[]>,
        ) {
            return {
                ...state,
                researchContentSummaryList: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        setResearchRetrospectionList(
            state,
            action: PayloadAction<RetrospectionForm[]>,
        ) {
            return {
                ...state,
                researchRetrospectionList: action.payload,
                lastEditedAt: new Date().getTime(),
            };
        },
        clear(state) {
            return { ...getInitialState() };
        },
        set(state, action: PayloadAction<ReturnType<typeof getInitialState>>) {
            return { ...action.payload, lastEditedAt: new Date().getTime() };
        },
    },
});

export default pro12Slice.reducer;
