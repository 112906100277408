import { client } from './client';

export async function callFetchIssuedSubjects(params: any) {
    const { data } = await client.get(
        `/students_app/supporters/semesters/${params.grade}-${params.semester}/subjects/`,
        {
            params,
        },
    );

    const subjects = data.results.map(({ issuedAt, ...rest }: any) => ({
        ...rest,
        issuedAt: new Date(issuedAt),
    }));

    return { count: data.count, results: subjects };
}
