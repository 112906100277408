import axios from 'axios';

export async function callHasA01Record(
    studentId: number | string,
): Promise<boolean> {
    const url = new URL(
        '/api/a01/students',
        process.env.REACT_APP_NEW_HZPRO_URL,
    );
    const { data } = await axios.get(url.toString());
    return data.includes(+studentId);
}
