export const referenceTypeOptions = [
    '보도자료',
    '학술자료',
    '영상자료',
    '도서자료',
];

export const ResearchReferenceListType = {
    AdvancedResearchReferences: 'ADVANCED_RESEARCH_REFERENCES',
    NewsResearchReferences: 'NEWS_RESEARCH_REFERENCES',
    VideoResearchReferences: 'VIDEO_RESEARCH_REFERENCES',    
}

export const researchReferenceTypes = [
    {
        name: '학술자료 추천',
        type: ResearchReferenceListType.AdvancedResearchReferences,
    },
    {
        name: '보도자료 추천',
        type: ResearchReferenceListType.NewsResearchReferences,
    },
    {
        name: '영상자료 추천',
        type: ResearchReferenceListType.VideoResearchReferences
    }
]
