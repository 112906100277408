import { useLocation } from 'react-router-dom';

import { SectionTitle, SectionWrapper } from '@pages/pro12/components';
import { useAppSelector } from '@store';
import { Column, ContentWithLink } from '.';
import { getIndexInKorean, replaceStep } from '@pages/pro12/helpers';

export default function SectionD() {
    const location = useLocation();

    const { contentSummaryList, retrospectionList } = useAppSelector(
        ({ pro12 }) => ({
            contentSummaryList: pro12.researchContentSummaryList,
            retrospectionList: pro12.researchRetrospectionList,
        }),
    );

    function getLink(section: string) {
        return replaceStep(location, 3, `#${section}`);
    }

    function retrospection() {
        const sentences = retrospectionList.map(
            ({ description }, index) =>
                `(${getIndexInKorean(index)},) ${description}`,
        );
        return sentences.join(' ');
    }

    return (
        <SectionWrapper id={'D'}>
            <SectionTitle>D. 결론 검토</SectionTitle>
            <Column
                title={() => (
                    <h5 className="fw-bold text-center fs-6 mb-0">
                        탐구 내용 정리
                    </h5>
                )}
                variant={'info'}
            >
                <ContentWithLink
                    className={'border border-info rounded-2 p-2'}
                    href={getLink('C')}
                >
                    {contentSummaryList.map(({ content, summary }, index) => (
                        <div key={index} className="row align-items-center">
                            <div className="col-auto">
                                <span className={'fw-semibold'}>
                                    {`탐구문제${index + 1}. ${content}:`}{' '}
                                </span>
                            </div>
                            <div className="col-auto">
                                <span>{summary}</span>
                            </div>
                        </div>
                    ))}
                </ContentWithLink>
            </Column>
            <Column
                title={() => (
                    <h5 className="fw-bold text-center mb-0 fs-6">
                        배우고 느낀 점
                    </h5>
                )}
                variant={'info'}
            >
                <ContentWithLink
                    className={'border border-info rounded-2 p-2'}
                    href={getLink('C')}
                >
                    <div>
                        {`본 과제탐구를 통해 배우고 느낀 점을 정리하면 다음과 같다. ${retrospection()}`}
                    </div>
                </ContentWithLink>
            </Column>
        </SectionWrapper>
    );
}
