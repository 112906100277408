import React, { Fragment, useEffect, useState } from 'react';
import { descriptionBlankMark } from '@pages/pro12/ResearchMainPage/constants';

export default function TextInputGathering({
    template,
    inputProps,
    onChange,
}: {
    template: string;
    inputProps: Record<string, Record<string, string>>;
    onChange: (value: string) => void;
}) {
    const inputPlaceholders = template.match(/\$\d+/g) || [];
    const texts = template.split(/\$\d+/g);
    const [inputs, setInputs] = useState<Record<string, unknown>>({});

    function setInput(inputPlaceholder: string, value: unknown) {
        setInputs((prev) => ({ ...prev, [inputPlaceholder]: value }));
    }

    useEffect(() => {
        function gatherInput() {
            let finalText = template;
            inputPlaceholders.forEach((inputPlaceholder) => {
                finalText = finalText.replace(
                    inputPlaceholder,
                    (inputs[inputPlaceholder] ||
                        descriptionBlankMark) as string,
                );
            });
            return finalText;
        }

        onChange(gatherInput());
    }, [inputs]);

    return (
        <>
            {texts.map((text, index) => {
                const inputPlaceholder = inputPlaceholders?.[index];
                return (
                    <Fragment key={`${text}-${index}`}>
                        {text.length > 0 && (
                            <div className="col-auto pe-1">
                                <span>{text}</span>
                            </div>
                        )}
                        <div className="col-2 pe-0">
                            {inputPlaceholder &&
                                inputPlaceholder.length > 0 && (
                                    <input
                                        {...inputProps[inputPlaceholder]}
                                        onChange={(e) =>
                                            setInput(
                                                inputPlaceholder,
                                                e.target.value,
                                            )
                                        }
                                    />
                                )}
                        </div>
                    </Fragment>
                );
            })}
        </>
    );
}
