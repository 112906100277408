import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useStudentFacade } from '@facades';

import '@styles/pages/loginPage.scss';
import { Container } from 'react-bootstrap';
import { useEffect } from 'react';

interface LoginForm {
    studentCode: string;
    password: string;
}

export default function LoginPage() {
    const navigate = useNavigate();
    const studentFacade = useStudentFacade();
    const [searchParams, setSearchParams] = useSearchParams();

    const loginForm = useForm<LoginForm>();

    async function login(data: LoginForm) {
        const { studentCode, password } = data;
        try {
            await studentFacade.login(studentCode, password);
            navigate('/intro');
        } catch (error) {
            alert('로그인에 실패했습니다.');
        }
    }

    // handle redirects
    useEffect(() => {
        const studentCode = searchParams.get('hzsStudentCode');
        const password = searchParams.get('hzsStudentPhoneNumber');
        if (studentCode && password) {
            login({ studentCode, password });
        }
    }, [searchParams]);

    return (
        <div className="page-base" id="loginPage">
            <Container className="login-page-container">
                <div className="login-form-title">로그인</div>
                <form onSubmit={loginForm.handleSubmit(login)}>
                    <input
                        {...loginForm.register('studentCode')}
                        className="login-input-text"
                        type="text"
                        placeholder="학생 코드 입력"
                    />
                    <input
                        {...loginForm.register('password')}
                        className="login-input-text"
                        type="text"
                        placeholder="연락처 입력 (숫자만)"
                    />
                    <button type="submit" className="max-width">
                        로그인
                    </button>
                </form>
            </Container>
        </div>
    );
}
