import '@styles/components/assignmentCard.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getSearchQueryFromParams, moment } from '@utils';
import { AssignmentTypeValue } from '@constants';
import { FIRST_STEP, LAST_STEP } from '@pages/pro12/Pro12Context';
import tracker from '@tracker';
import { step3페이지Url얻기 } from '@helpers';

import { PATH, resolveRoute } from '../ProjectRoutes';
import AssignmentModal from './AssignmentModal';
import 자기평가서모달 from './자기평가서모달';
import { 피드백도장가져오기 } from '@pages/pro12/helpers';

interface AssignmentModalProps {
    modalShow: boolean;
    prevAssignmentType: string;
    currentAssignmentType: string;
    actionType: string;
    assignmentId: number;
    assignmentReportId: number | null;
}

const dateFormat = 'YYYY. MM. DD';

function getFormattedDueDate(assignment: Partial<Assignment>) {
    if (!assignment.dueDate) {
        return '';
    }
    return moment(assignment.dueDate).format('YYYY. MM. DD');
}

const modalPropsByAssignmentType = {
    DESIGN_RESEARCH_ACTIVITY: {
        modalShow: false,
        prevAssignmentType: '',
        currentAssignmentType: '',
        actionType: '',
        assignmentId: null,
        assignmentReportId: null,
    },
    WRITE_RESEARCH_SUBJECT_FINAL_DRAFT: {
        modalShow: true,
        prevAssignmentType: '탐구과제 초안',
        currentAssignmentType: '탐구과제 최종본',
    },
};

const {
    DesignResearchActivity: 과제설계단계,
    WriteResearchSubjectFinalDraft: 최종과제단계,
} = AssignmentTypeValue;

function 과제단계표시(과제타입: AssignmentType) {
    switch (과제타입) {
        case 과제설계단계:
            return '과제 초안';
        case 최종과제단계:
            return '최종 과제';
        default:
            return '';
    }
}

function 과제정보얻기(발급된주제: IssuedSubjectSummary) {
    return {
        탐구주제명: 발급된주제.backgroundInEnglish
            ? `${발급된주제.background}(${발급된주제.backgroundInEnglish})`
            : 발급된주제.background,
        발급일: moment(발급된주제.issuedAt).format(dateFormat),
        과목명: 발급된주제.appliedSchoolSubject?.name || '',
        학생Id: 발급된주제.studentId,
        id: 발급된주제.id,
    };
}

function 모달사용({ 과제레포트변경시 }: { 과제레포트변경시: () => void }) {
    const [속성, 속성변경] = useState<AssignmentModalProps | null>(null);

    function 닫기() {
        if (!속성) {
            return;
        }

        속성변경({
            ...속성,
            modalShow: false,
        });
    }

    function 열기(과제: Assignment) {
        return 속성변경({
            // @ts-ignore
            ...modalPropsByAssignmentType[과제.type],
            actionType: 과제.report ? '수정' : '제출',
            assignmentReportId: 과제.report ? 과제.report.id : null,
            // @ts-ignore
            assignmentType: 과제.type,
            assignmentId: 과제.id,
        });
    }

    useEffect(() => {
        if (속성 === null) {
            return;
        }

        if (!속성.modalShow) {
            과제레포트변경시();
        }
    }, [속성]);

    return {
        속성,
        닫기,
        열기,
    };
}

function 재제출여부(과제: Assignment) {
    if (!과제.report?.data) {
        return false;
    }

    if (typeof 과제.report.data === 'string') {
        return false;
    }

    if (과제.report?.version === 1) {
        return false;
    } else {
        return 과제.report.data.isResubmitted;
    }
}

export default function AssignmentRow({
    issuedSubject,
    onAssignmentReportUpdated,
}: {
    issuedSubject: IssuedSubjectSummary;
    onAssignmentReportUpdated: () => void;
}) {
    const 과제노출순서 = [과제설계단계, 최종과제단계];
    const 과제목록 = issuedSubject.assignments;
    const { 탐구주제명, 발급일, 과목명 } = 과제정보얻기(issuedSubject);
    const navigate = useNavigate();
    const {
        속성: 과제제출모달속성,
        열기: 과제제출모달열기,
        닫기: 과제제출모달닫기,
    } = 모달사용({
        과제레포트변경시: onAssignmentReportUpdated,
    });

    function getReportData(과제: Assignment): any {
        return 과제?.report?.data ?? {};
    }

    function 버젼1버튼핸들러(과제: Assignment) {
        return function 버젼1과제설계페이지가기() {
            return navigate(
                resolveRoute(PATH.ASSIGNMENT_REPORT_FORM__V1, {
                    assignmentId: 과제.id,
                }),
            );
        };
    }

    function 버젼2버튼핸들러(과제: Assignment) {
        if (과제.type === AssignmentTypeValue.DesignResearchActivity) {
            return function 과제설계페이지가기() {
                if (!과제.report) {
                    return navigate({
                        pathname: resolveRoute(PATH.PRO12_ON_BOARD, {
                            assignmentId: 과제.id,
                        }),
                        search: getSearchQueryFromParams({
                            issuedSubjectId: 과제.issuedSubjectId,
                            grade: 과제.grade,
                            semester: 과제.semester,
                        }),
                    });
                }

                const reportData = getReportData(과제);
                let currentStepToNavigate = 과제.isSubmitted
                    ? LAST_STEP
                    : reportData.currentStep ?? FIRST_STEP;

                return navigate({
                    pathname:
                        resolveRoute(PATH.PRO12_ASSIGNMENT_REPORT, {
                            assignmentId: 과제.id,
                            index: 0,
                        }) + `/${currentStepToNavigate}`,
                    search: getSearchQueryFromParams({
                        issuedSubjectId: 과제.issuedSubjectId,
                    }),
                });
            };
        } else {
            return function () {
                return 과제제출모달열기(과제);
            };
        }
    }

    function 버젼3버튼핸들러(과제: Assignment) {
        if (과제.type === AssignmentTypeValue.DesignResearchActivity) {
            return function () {
                if (과제.report?.feedback?.isCompleted) {
                    return navigate({
                        pathname: resolveRoute(
                            PATH.PRO12_ASSIGNMENT_REPORT_STEP3_REVIEW,
                            {
                                assignmentId: 과제.id,
                            },
                        ),
                        search: getSearchQueryFromParams({
                            issuedSubjectId: 과제.issuedSubjectId,
                        }),
                    });
                } else {
                    const url = new URL(
                        step3페이지Url얻기({
                            studentId: issuedSubject.studentId,
                            issuedSubjectId: issuedSubject.id,
                            assignmentId: 과제.id,
                        }),
                    );
                    window.location.href = url.toString();
                }
            };
        } else {
            return function () {
                return 과제제출모달열기(과제);
            };
        }
    }

    function 버전230901핸들러(과제: Assignment) {
        if (과제.type === AssignmentTypeValue.DesignResearchActivity) {
            return function () {
                const url = new URL(
                    step3페이지Url얻기({
                        studentId: issuedSubject.studentId,
                        issuedSubjectId: issuedSubject.id,
                        assignmentId: 과제.id,
                        version: 230901,
                    }),
                );

                const lastPath = (과제.report?.data as any)?.lastPath;
                if (lastPath) {
                    url.pathname = lastPath;
                }

                window.location.href = url.toString();
            };
        } else {
            return function () {
                return 과제제출모달열기(과제);
            };
        }
    }

    function 과제버튼클릭핸들러얻기(과제: Assignment) {
        switch (과제.report?.version) {
            case 1:
                return 버젼1버튼핸들러(과제);
            case 2:
                return 버젼2버튼핸들러(과제);
            case 3:
                return 버젼3버튼핸들러(과제);
            default:
                return 버전230901핸들러(과제);
        }
    }

    function 화살표표시여부(index: number) {
        return index < 1;
    }

    const [자기평가서모달Props, set자기평가서모달Props] = useState({
        열림: false,
        보고서: null,
    });

    function 자기평가서모달열기(보고서: any) {
        set자기평가서모달Props({
            열림: true,
            보고서,
        });
    }

    function 자기평가서모달닫기() {
        set자기평가서모달Props({
            열림: false,
            보고서: null,
        });
    }

    return (
        <div className="row mt-5">
            <div className="row border rounded-4 py-3 m-0 shadow-[0_2px_3px_0_rgba(0,0,0,0.3)] min-h-[140px]">
                <div className="col-3 lh-lg me-4 border-end border-3 border-danger border-opacity-25 ps-2">
                    <div className="row">
                        <div className="col-4 pe-0">주제 발급일</div>
                        <div className="col-8 ps-0">{발급일}</div>
                    </div>
                    <div className="row lh-base">
                        <div className="col-4 pe-0">탐구 주제</div>
                        <div className="col-8 ps-0">
                            <strong>{탐구주제명}</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 pe-0">과목</div>
                        <div className="col-8 ps-0">{과목명}</div>
                    </div>
                </div>
                <div className="col">
                    <div className="flex justify-between">
                        {과제노출순서.map((단계, 순서) => (
                            <React.Fragment key={순서}>
                                <div>
                                    <div className="text-center font-medium w-[150px]">
                                        {과제단계표시(단계)}
                                    </div>
                                    <div className="flex">
                                        <과제단계별컴포넌트
                                            순서={순서}
                                            단계={단계}
                                            과제목록={과제목록}
                                            자기평가서모달열기={
                                                자기평가서모달열기
                                            }
                                            과제버튼클릭핸들러얻기={
                                                과제버튼클릭핸들러얻기
                                            }
                                        />
                                    </div>
                                </div>
                                {화살표표시여부(순서) && (
                                    <div className="m-auto text-[50px] text-[#FFECEC] opacity-50 material-icons-outlined">
                                        arrow_forward
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            {과제제출모달속성?.modalShow && (
                <AssignmentModal
                    closeModal={과제제출모달닫기}
                    {...과제제출모달속성}
                />
            )}
            {자기평가서모달Props.보고서 && (
                <자기평가서모달
                    모달열림={자기평가서모달Props.열림}
                    모달닫기={자기평가서모달닫기}
                    보고서={자기평가서모달Props.보고서}
                />
            )}
        </div>
    );
}

function 과제단계별버튼({
    과제,
    버튼클릭처리하기,
}: {
    과제: Assignment;
    버튼클릭처리하기: () => void;
}) {
    switch (과제.type) {
        case 과제설계단계:
            if (!과제.isRequested) {
                return (
                    <button className="w-100 h-[45px] mt-1 border rounded-3 ">
                        <span className="text-[#DDD]">발급 준비 중</span>
                    </button>
                );
            } else if (!과제.isSubmitted) {
                return (
                    <button
                        type="button"
                        onClick={버튼클릭처리하기}
                        className="w-[150px] h-[45px] flex border-[1px] border-[#FFADAD] rounded-[11px] text-[#555] p-1 justify-center items-center mt-[10px]"
                    >
                        <div className="col-3 text-[#777] material-icons-outlined fs-1">
                            drive_file_rename_outline
                        </div>
                        <div className="row col text-center">
                            <div className="text-base px-0 font-semibold text-[#555]">
                                작성하기
                            </div>
                            <div className="text-xs text-[#FF6D6D]">
                                {getFormattedDueDate(과제)}
                                까지
                            </div>
                        </div>
                    </button>
                );
            } else if (!과제.isApproved && 재제출여부(과제)) {
                return (
                    <button
                        type="button"
                        onClick={버튼클릭처리하기}
                        className="w-[150px] h-[45px] flex border-[1px] border-[#8FC5FF] rounded-[11px] text-[#555] p-1 justify-center items-center mt-[10px]"
                    >
                        <div className="col-3 text-[#777] material-icons-outlined fs-1">
                            description
                        </div>
                        <div className="col text-center">
                            <div className="text-base px-0 font-semibold text-[#555]">
                                피드백 대기중
                                {재제출여부(과제) && '(재)'}
                            </div>
                            <div className="text-xs text-[#777] px-0">
                                👈 작성 내용 확인하기
                            </div>
                        </div>
                    </button>
                );
            } else if (
                !과제.isCompleted ||
                (!과제.isApproved && 재제출여부(과제))
            ) {
                return (
                    <button
                        type="button"
                        onClick={버튼클릭처리하기}
                        className="w-[150px] h-[45px] flex border-[1px] border-[#8FC5FF] rounded-[11px] text-[#555] p-1 justify-center items-center mt-[10px]"
                    >
                        <div className="col-3 text-[#777] material-icons-outlined fs-1">
                            description
                        </div>
                        <div className="col text-center">
                            <div className="text-base px-0 font-semibold text-[#555]">
                                피드백 대기중
                                {재제출여부(과제) && '(재)'}
                            </div>
                            <div className="text-xs text-[#777] px-0">
                                👈 작성 내용 확인하기
                            </div>
                        </div>
                    </button>
                );
            } else {
                return (
                    <button
                        onClick={버튼클릭처리하기}
                        className="w-[150px] h-[45px] flex border-[1px] border-[#8FC5FF] rounded-[11px] text-[#555] p-1 justify-center items-center mt-[10px]"
                    >
                        <div className="col-3 text-[#777] material-icons-outlined fs-1">
                            sms
                        </div>
                        <div className="row col text-center">
                            <div className="text-base px-0 font-semibold text-[#555]">
                                피드백 완료
                            </div>
                            <div className="text-xs text-[#777] px-0">
                                👈 피드백 확인하기
                                {재제출여부(과제) && '(재)'}
                            </div>
                        </div>
                    </button>
                );
            }
        case 최종과제단계:
            if (!과제.isSubmitted) {
                return (
                    <button
                        onClick={버튼클릭처리하기}
                        className="w-[150px] h-[45px] flex border-[1px] border-[#FFADAD] rounded-[11px] text-[#555] p-1 justify-center items-center mt-[10px]"
                    >
                        <div className="col-3 rotate-45 text-[#777] material-icons-outlined fs-1">
                            attach_file
                        </div>
                        <div className="row col text-center">
                            <div className="text-base px-0 font-semibold text-[#555]">
                                제출하기
                            </div>
                            <div className="text-xs text-[#FF6D6D]">
                                {getFormattedDueDate(과제)}
                                까지
                            </div>
                        </div>
                    </button>
                );
            } else if (!과제.isCompleted) {
                return (
                    <button
                        onClick={버튼클릭처리하기}
                        className="w-[150px] h-[45px] flex border-[1px] border-[#8FC5FF] rounded-[11px] text-[#555] p-1 justify-center items-center mt-[10px]"
                    >
                        <div className="col-3 text-[#777] material-icons-outlined fs-1">
                            description
                        </div>
                        <div className="col text-center">
                            <div className="text-base px-0 font-semibold text-[#555]">
                                총평 대기중
                            </div>
                            <div className="text-xs text-[#777] px-0">
                                👈 제출 파일 확인하기
                            </div>
                        </div>
                    </button>
                );
            } else {
                return (
                    <button
                        onClick={버튼클릭처리하기}
                        className="w-[150px] h-[45px] flex border-[1px] border-[#8FC5FF] rounded-[11px] text-[#555] p-1 justify-center items-center mt-[10px]"
                    >
                        <div className="col-3 text-[#777] material-icons-outlined fs-1">
                            sms
                        </div>
                        <div className="row col text-center">
                            <div className="text-base px-0 font-semibold text-[#555]">
                                총평 완료
                            </div>
                            <div className="text-xs text-[#777] px-0">
                                👈 제출 파일 확인하기
                                {재제출여부(과제) && '(재)'}
                            </div>
                        </div>
                    </button>
                );
            }
        default:
            return <></>;
    }
}

function 과제단계별컴포넌트({
    순서,
    단계,
    과제목록,
    자기평가서모달열기,
    과제버튼클릭핸들러얻기,
}: {
    순서: number;
    단계: AssignmentType;
    과제목록: Assignment[];
    자기평가서모달열기: (보고서: any) => void;
    과제버튼클릭핸들러얻기: (과제: Assignment) => () => void;
}): JSX.Element {
    const 과제 = 과제목록.find(({ type }) => type === 단계);
    if (!과제) {
        return (
            <button className="w-[150px] h-[45px] mt-[10px] border rounded-[11px]"></button>
        );
    }
    const 과제버젼 = 과제.report?.version || 0;
    const 버튼클릭처리하기 = 과제버튼클릭핸들러얻기(과제);
    const 피드백도장 = 과제목록?.[0].report?.feedback?.stamp;
    const 과제승인여부 = 피드백도장?.type === 'approval';

    return (
        <>
            <div className="flex flex-column w-100">
                <과제단계별버튼
                    과제={과제}
                    버튼클릭처리하기={버튼클릭처리하기}
                />
                {과제버젼 > 2 && 순서 === 0 && (
                    <button
                        className={`${
                            과제승인여부 ? 'border-[#8FC5FF]' : 'text-[#DDD]'
                        } mt-3 !border px-8 py-2 rounded-xl`}
                        onClick={() =>
                            과제승인여부 &&
                            자기평가서모달열기(과제목록[0].report)
                        }
                        type="button"
                        disabled={!과제승인여부}
                    >
                        자기평가서 초안
                    </button>
                )}
            </div>
            {과제버젼 > 2 && 단계 === 과제설계단계 && 피드백도장 && (
                <img
                    className="w-[65px] h-[65px]"
                    src={피드백도장가져오기(피드백도장.name)}
                    alt={피드백도장.name}
                />
            )}
        </>
    );
}
