import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
    ArrowCircleLeftOutlined,
    ArrowCircleRightOutlined,
} from '@mui/icons-material';

import Pro12Context from '../Pro12Context';

const A = styled.a`
    text-decoration: none;
    font-size: 1.3rem;
`;

export default function Navigator({
    goToNextStep = false,
    onNextStepClick,
    onPreviousStepClick,
}: {
    goToNextStep?: boolean;
    onNextStepClick?: (next: () => void) => void;
    onPreviousStepClick?: (prev: () => void) => void;
}) {
    const navigate = useNavigate();

    const { prevStepPath, nextStepPath } = useContext(Pro12Context);

    useEffect(() => {
        if (goToNextStep) {
            next();
        }
    }, [goToNextStep]);

    function next() {
        if (nextStepPath) {
            navigate(nextStepPath);
        }
    }

    function prev() {
        if (prevStepPath) {
            navigate(prevStepPath);
        }
    }

    async function handleNextStepClick(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();

        if (onNextStepClick) {
            onNextStepClick(next);
        } else {
            next();
        }
    }

    async function handlePreviousStepClick(
        e: React.MouseEvent<HTMLAnchorElement>,
    ) {
        e.preventDefault();

        if (onPreviousStepClick) {
            onPreviousStepClick(prev);
        } else {
            prev();
        }
    }

    return (
        <div className="row p-5">
            <div className="col-auto">
                {prevStepPath && (
                    <A
                        href="#"
                        className="d-flex align-items-center text-secondary"
                        onClick={(e) => handlePreviousStepClick(e)}
                    >
                        <ArrowCircleLeftOutlined
                            className="me-1 fs-1"
                            fontSize={'inherit'}
                        />
                        이전 단계로
                    </A>
                )}
            </div>
            <div className="col-auto ms-auto">
                {nextStepPath && (
                    <A
                        href="#"
                        className="d-flex align-items-center text-primary"
                        onClick={(e) => handleNextStepClick(e)}
                    >
                        다음 단계로
                        <ArrowCircleRightOutlined
                            className="ms-1 fs-1"
                            fontSize={'inherit'}
                        />
                    </A>
                )}
            </div>
        </div>
    );
}
