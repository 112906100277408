import { RecommendationType } from '@constants/enums';
import { callGetRecommendation } from '@client';

export function useRecommendationFacade() {
    async function getTitleRecommendation(
        issuedSubjectId: IssuedSubjectId,
    ): Promise<string | string[]> {
        return await callGetRecommendation({
            issuedSubjectId,
            type: RecommendationType.ExtractTitle,
        });
    }
    async function getSummaryRecommendation(
        issuedSubjectId: IssuedSubjectId,
    ): Promise<string | string[]> {
        return await callGetRecommendation({
            issuedSubjectId,
            type: RecommendationType.ListUpSummaries,
        });
    }
    return {
        getTitleRecommendation,
        getSummaryRecommendation,
    };
}
