import { client } from './client';

export async function callPatchSemester({
    studentId,
    grade,
    semester,
    fieldName,
    data,
}: {
    studentId: StudentId;
    grade: number;
    semester: number;
    fieldName: string;
    data: any;
}) {
    const body: Record<string, any> = {};
    body[fieldName] = data;
    await client.patch(
        `/students_app/supporters/semesters/${grade}-${semester}/`,
        body,
        { params: { clientStudentId: studentId } },
    );
}
