import { useEffect } from 'react';
import { useResearchReferencesFacade } from '@facades';
import { ResearchReferenceListType } from '../constants';
import { moment } from '@utils';

export default function ResearchRecommendationList({
    type,
    issuedSubjectId,
}: {
    type: string;
    issuedSubjectId: IssuedSubjectId;
}) {
    const {
        initAll,
        advancedResearchReferences,
        newsResearchReferences,
        videoResearchReferences,
        errors,
    } = useResearchReferencesFacade();

    useEffect(function init() {
        initAll(issuedSubjectId);
    }, []);

    return (
        <div id="research-recommendation-list-wrapper">
            {type === ResearchReferenceListType.AdvancedResearchReferences && (
                <div className="advanced-research-reference-list">
                    <div className={'col-auto text-center'}>
                        <span className="text-danger">
                            AI 추천 결과는 작성에 참고하기 위한 용도로서,
                            <br />
                            <span className="fw-bold">
                                주제에 따라 적합도가 다르게 나올 수 있습니다.
                            </span>
                        </span>
                    </div>
                    {errors.ADVANCED_RESEARCH_REFERENCES && (
                        <div className="row mt-3 d-flex justify-content-center text-danger">
                            추천 자료가 없습니다.
                        </div>
                    )}
                    {advancedResearchReferences.map((data, index) => (
                        <div
                            key={index}
                            className="border rounded-2 py-1 px-2 mt-3"
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="small text-secondary">
                                    학술논문
                                </div>
                                <a
                                    className="d-flex px-3 py-0 bg-danger small text-white rounded-3 border border-white text-decoration-none align-items-center"
                                    href={data.link}
                                    target="_blank"
                                >
                                    <div className="material-icons-outlined me-2">
                                        all_inclusive
                                    </div>
                                    <div>원문보기</div>
                                </a>
                            </div>
                            <div className="mt-1">
                                <span>
                                    <span className="text-danger">
                                        [
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: data.title,
                                            }}
                                        ></span>
                                        ],
                                    </span>
                                    &nbsp;
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data.description,
                                        }}
                                    ></span>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {type === ResearchReferenceListType.NewsResearchReferences && (
                <div className="news-research-reference-list">
                    <div className={'col-auto text-center'}>
                        <span className="text-danger">
                            AI 추천 결과는 작성에 참고하기 위한 용도로서,
                            <br />
                            <span className="fw-bold">
                                주제에 따라 적합도가 다르게 나올 수 있습니다.
                            </span>
                        </span>
                    </div>
                    {errors.NewsResearchReferences && (
                        <div className="row mt-3 d-flex justify-content-center text-danger">
                            추천 자료가 없습니다.
                        </div>
                    )}
                    {newsResearchReferences.map((data, index) => (
                        <div
                            key={index}
                            className="border rounded-2 py-1 px-2 mt-3"
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="small text-secondary">
                                    보도자료
                                </div>
                                <a
                                    className="d-flex px-3 py-0 bg-danger small text-white rounded-3 border border-white text-decoration-none align-items-center"
                                    href={data.link}
                                    target="_blank"
                                >
                                    <div className="material-icons-outlined me-2">
                                        all_inclusive
                                    </div>
                                    <div>원문보기</div>
                                </a>
                            </div>
                            <div className="mt-1">
                                <span>
                                    <span className="text-danger">
                                        [
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: data.title,
                                            }}
                                        ></span>
                                        ]
                                    </span>
                                    ,[
                                    {moment(data.publishedDate).format(
                                        'YYYY-MM-DD',
                                    )}
                                    ]
                                </span>
                                , <span>{data.source}</span>
                            </div>
                            <div
                                className="small text-secondary mt-1"
                                dangerouslySetInnerHTML={{
                                    __html: data.description,
                                }}
                            ></div>
                        </div>
                    ))}
                </div>
            )}
            {type === ResearchReferenceListType.VideoResearchReferences && (
                <div className="video-research-reference-list">
                    <div className={'col-auto text-center'}>
                        <span className="text-danger">
                            AI 추천 결과는 작성에 참고하기 위한 용도로서,
                            <br />
                            <span className="fw-bold">
                                주제에 따라 적합도가 다르게 나올 수 있습니다.
                            </span>
                        </span>
                    </div>
                    {errors.VideoResearchReferences && (
                        <div className="row mt-3 d-flex justify-content-center text-danger">
                            추천 자료가 없습니다.
                        </div>
                    )}
                    {videoResearchReferences.map((data, index) => (
                        <div
                            key={index}
                            className="border rounded-2 py-1 px-2 mt-3"
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="small text-secondary">
                                    영상자료
                                </div>
                                <a
                                    className="d-flex px-3 py-0 bg-danger small text-white rounded-3 border border-white text-decoration-none align-items-center"
                                    href={`https://www.youtube.com/watch?v=${data.id}`}
                                    target="_blank"
                                >
                                    <div className="material-icons-outlined me-2">
                                        all_inclusive
                                    </div>
                                    <div>원문보기</div>
                                </a>
                            </div>
                            <a
                                className="row mx-2 mt-1 justify-content-center"
                                href={`https://www.youtube.com/watch?v=${data.id}`}
                                target="_blank"
                            >
                                <img src={data.thumbnail} />
                            </a>
                            <div className="mt-1">
                                <span>
                                    <span className="text-danger">
                                        [
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: data.title,
                                            }}
                                        ></span>
                                        ]
                                    </span>
                                    , [
                                    <a href={data.channelLink} target="_blank">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: data.channelTitle,
                                            }}
                                        ></span>
                                    </a>
                                    ]
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
