import { client } from './client';

export async function callFetchMajorCategoryExploration(studentId: StudentId) {
    const { data } = await client.get(
        '/students_app/supporters/major-category-explorations/',
        {
            params: {
                clientStudentId: studentId,
            },
        },
    );
    return data;
}
