import React from 'react';
import {
    ReferenceGroup as ReferenceGroup_,
    ReferenceCard,
    ReferenceDraft,
    BorderBox,
} from '../components';

export default function ReferenceGroup(
    props: React.ComponentProps<typeof ReferenceGroup_>,
) {
    return (
        <ReferenceGroup_
            {...props}
            renderReferenceDraft={(props) => (
                <div className="row mt-2 p-3">
                    <BorderBox variant="primary">
                        <ReferenceDraft {...props} />
                    </BorderBox>
                </div>
            )}
            renderReferenceCard={(props) => (
                <div className="row mt-2 p-3">
                    <BorderBox variant="primary">
                        <ReferenceCard {...props} />
                    </BorderBox>
                </div>
            )}
            renderNewReferenceButton={(props) => (
                <div className="row justify-content-center mt-2">
                    <div className="col-auto">
                        <button
                            className="btn btn-outline-danger rounded-4"
                            {...props}
                        >
                            참고 자료 추가하기
                        </button>
                    </div>
                </div>
            )}
        />
    );
}
