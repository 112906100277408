import { Round, CategoryParticipant } from './core';
import { getParticipantImage } from './helpers';

export function BinaryMatchRound({
    pick,
    round,
}: {
    pick: (v: number) => void;
    round: Round;
}) {
    const set = round.getCurrentSet();
    const participants = set.participants as CategoryParticipant[];

    function getImageSrc(participant: CategoryParticipant) {
        return getParticipantImage({
            type: round.type,
            categoryName: participant.category,
        });
    }

    return (
        <article className="mt-5 text-center">
            <header className="row align-items-center justify-content-between pb-5">
                <div className="col text-center">
                    <h1 className="fw-bold">
                        두 가지 중 더 선호하는 것을 고르세요.
                    </h1>
                </div>
            </header>
            <div className="d-flex">
                {set &&
                    participants.map((participant, index) => (
                        <div
                            key={index}
                            onClick={() => pick(index)}
                            className="justify-content-center mx-auto"
                        >
                            <div className="bg-white rounded-5 p-0 select-option m-auto">
                                <img src={getImageSrc(participant)} />
                            </div>
                        </div>
                    ))}
            </div>
        </article>
    );
}
