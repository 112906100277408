import '@styles/pages/majorCategoryExplorationTournament.scss';
import { useAppSelector } from '@store';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../ProjectRoutes';
import { useMajorCategoryQueryParams } from './hooks';

export default function ResultPreviewPage() {
    const student = useAppSelector((state) => state.student);
    const { majorCategories } = useMajorCategoryQueryParams();
    const navigate = useNavigate();

    function handleButtonClick() {
        navigate(PATH.MAJOR_CATEGORY_EXPLORATION_RESULT);
    }

    return (
        <>
            <div className="body-wrapper">
                <div className="mx-auto">
                    <header className="py-5 text-center">
                        <h4 className="fw-bold">
                            {student.name} 학생의 검사 결과는 아래와 같습니다.
                        </h4>
                    </header>

                    <section className="body-container-large mx-auto bg-white rounded-4 p-5 mx-5">
                        <div className="row justify-content-center text-center py-5">
                            {majorCategories.map((majorCategory, index) => (
                                <div
                                    key={index}
                                    className="test-result-card border-purple border rounded-4 py-4 mx-3"
                                >
                                    <div className="mb-2">
                                        <span className="text-black-50">
                                            {index + 1}순위
                                        </span>
                                    </div>
                                    <div>
                                        <h4>{majorCategory.category}</h4>
                                        <h4 className="text-secondary">계열</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-center text-black-50 mt-5 pt-5">
                            <span>
                                더 자세한 결과를 보고 싶다면, 아래 버튼을
                                클릭하세요.
                            </span>
                        </div>
                        <div className="text-center py-5">
                            <button
                                onClick={handleButtonClick}
                                className="text-white border-0 py-3 px-5 rounded-3 bg-purple"
                                type="button"
                            >
                                검사 결과 확인하기
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
