import { useEffect, useState } from 'react';
import Latex from 'react-latex';
import { useIssuedSubjectFacade, useLeanAIMetadataFacade } from '@facades';
import { useAppSelector } from '@store';

import lodash from 'lodash';
import 'src/assets/scss/components/issuedSubjectDetail.scss';
import { Col, Row, Spinner } from 'react-bootstrap';

export default function IssuedSubjectDetail({
    issuedSubjectId,
}: {
    issuedSubjectId: IssuedSubjectId;
}) {
    const student = useAppSelector((state) => state.student);
    const leanAIFacade = useLeanAIMetadataFacade();
    const leanAIMetadata = leanAIFacade.get();
    const subjectFacade = useIssuedSubjectFacade(leanAIMetadata);
    const [issuedSubject, setIssuedSubject] =
        useState<IssuedSubjectDetail | void>();
    const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);

    async function downloadPdf() {
        window.print();
    }

    useEffect(
        function initIssuedSubject() {
            if (!issuedSubjectId || !leanAIFacade.isReady) {
                return;
            }

            (async () => {
                const data = await subjectFacade.getIssuedSubjectDetail(
                    issuedSubjectId,
                );
                setIssuedSubject(data);
            })();
        },
        [issuedSubjectId, leanAIFacade.isReady],
    );

    return issuedSubject ? (
        <div id="issued-subject-detail">
            <div className="top-button-container no-print">
                <button
                    className="download-pdf-button top-button"
                    onClick={downloadPdf}
                >
                    {isPdfLoading ? (
                        <Spinner animation="border" variant="light" />
                    ) : (
                        <span>프린트하기</span>
                    )}
                </button>
            </div>
            <div className="subject-page-title">주제 확인</div>
            <Row className="student-info-container">
                <Col lg={1}></Col>
                <Col xs={12} lg={2}>
                    <Row>
                        <Col className="student-info-title" xs={6} lg={12}>
                            학생 이름
                        </Col>
                        <Col
                            className="student-info-description"
                            xs={6}
                            lg={12}
                        >
                            {student.name}
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={2}>
                    <Row>
                        <Col className="student-info-title" xs={6} lg={12}>
                            학생 학교
                        </Col>
                        <Col
                            className="student-info-description"
                            xs={6}
                            lg={12}
                        >
                            {student.school}
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={2}>
                    <Row>
                        <Col className="student-info-title" xs={6} lg={12}>
                            학생 학년
                        </Col>
                        <Col
                            className="student-info-description"
                            xs={6}
                            lg={12}
                        >
                            {student.grade}
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={2}>
                    <Row>
                        <Col className="student-info-title" xs={6} lg={12}>
                            담당자 이름
                        </Col>
                        <Col
                            className="student-info-description"
                            xs={6}
                            lg={12}
                        >
                            {student.consultantName}
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={2}>
                    <Row>
                        <Col className="student-info-title" xs={6} lg={12}>
                            주제 발급 일자
                        </Col>
                        <Col
                            className="student-info-description"
                            xs={6}
                            lg={12}
                        >
                            {formatDate(issuedSubject.issuedAt)}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="divider-main"></div>
            <div className="content-container">
                <div className="content-section-title">
                    연계 과목 및 학습요소
                </div>
                <div className="content-section-description">
                    <p>
                        주제를 과세특으로 활용 가능한 과목
                        <br />각 과목별 주제와 연관되어 있는 단원과 학습요소
                    </p>
                </div>

                <div className="divider-sub"></div>

                <table className="content-table small">
                    <thead>
                        <tr>
                            <th>과목명</th>
                            <th>단원명</th>
                            <th>학습요소</th>
                        </tr>
                    </thead>
                    <tbody>
                        {issuedSubject.relatedSchoolSubjects.map(
                            (schoolSubject) => (
                                <tr key={schoolSubject.id}>
                                    <td>{schoolSubject.name}</td>
                                    <td>
                                        {schoolSubject.units.map(
                                            (schoolSubjectUnit, index) => (
                                                <div key={index}>
                                                    {schoolSubjectUnit.name}
                                                </div>
                                            ),
                                        )}
                                    </td>
                                    <td>
                                        {lodash
                                            .flatten(
                                                lodash.map(
                                                    schoolSubject.units,
                                                    'learningElements',
                                                ),
                                            )
                                            .map((learningElement, index) => (
                                                <div key={index}>
                                                    {learningElement}
                                                </div>
                                            ))}
                                    </td>
                                </tr>
                            ),
                        )}
                    </tbody>
                </table>
            </div>
            <div className="divider-main"></div>
            <div className="content-container">
                <div className="content-section-title">주제 요약 키워드</div>
                <div className="content-section-description">
                    <p>
                        주제와 관련된 키워드로 탐구를 주체적으로 해결하기 위해
                        필요한 검색 도구
                        <br />
                        키워드를 바탕으로 검색해보며 주제를 탐구 가능
                    </p>
                </div>

                <div className="divider-sub"></div>

                <div className="content-box">
                    {issuedSubject.subject.keywords.map((keyword, index) => (
                        <div
                            className="text-box pill rounded-5 py-1 px-3 mx-2"
                            key={index}
                        >
                            {keyword}
                        </div>
                    ))}
                </div>
            </div>
            <div className="divider-main"></div>
            <div className="content-container">
                <div className="content-section-title">탐구 배경 지식</div>
                <div className="content-section-description">
                    <p>
                        교과 내용, 전공 지식, 일반 상식을 아우르는 지식
                        <br />
                        보고서 작성 시 이론적 배경이나 연구방법, 분석의 틀 등을
                        마련하기 위해 참조
                        <br />
                        배경지식에 기반하여 탐구의 방향을 구체적으로 설정 가능
                    </p>
                </div>

                <div className="divider-sub"></div>

                <table className="concept-container">
                    <tbody>
                        <tr>
                            <td>원리명</td>
                            <td>{issuedSubject.subject.background.name}</td>
                        </tr>
                        <tr>
                            <td>원어명</td>
                            <td>
                                {issuedSubject.subject.background.englishName}
                            </td>
                        </tr>
                        <tr>
                            <td>원리내용</td>
                            <td>{issuedSubject.subject.background.content}</td>
                        </tr>
                        <tr>
                            <td>유사개념</td>
                            <td>
                                {issuedSubject.subject.background
                                    .relatedConcepts &&
                                    issuedSubject.subject.background.relatedConcepts.map(
                                        (concept, index) => (
                                            <span
                                                className="related-concept-item"
                                                key={index}
                                            >
                                                {concept}
                                            </span>
                                        ),
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="divider-main"></div>
            <div className="content-container">
                <div className="content-section-title">탐구 내용</div>
                <div className="content-section-description">
                    <p>탐구의 방법과 탐구 주제 제시</p>
                </div>

                <div className="divider-sub"></div>

                <table className="concept-container">
                    <tbody>
                        <tr>
                            <td>탐구내용</td>
                            <td>
                                <Latex displayMode={true}>
                                    {issuedSubject.subject.content}
                                </Latex>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="divider-main"></div>
            <div className="content-container">
                <div className="content-section-title">관련 도서</div>
                <div className="content-section-description">
                    <p>
                        관련 도서를 사용하여 탐구의 동기 작성 가능
                        <br />
                        독서를 통해 과세특을 자율활동, 동아리활동, 진로활동과
                        연계 가능
                        <br />
                        다른 과목과의 탐구활동으로도 적용 가능
                    </p>
                </div>

                <div className="divider-sub"></div>

                <Row className="book-container">
                    {issuedSubject.subject.recommendedBooks.map((book, index) =>
                        book ? (
                            <Col
                                key={index}
                                className="book-information"
                                xs={12}
                                lg={6}
                            >
                                <Row>
                                    <Col xs={4} lg={4}>
                                        <img
                                            className="book-img"
                                            src={book.cover}
                                            alt="book"
                                        />
                                    </Col>
                                    <Col xs={8} lg={8}>
                                        <div>{book.title}</div>
                                        <div>{book.author}</div>
                                        <div>{book.publisher}</div>
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            ''
                        ),
                    )}
                </Row>
            </div>
            <div className="divider-main"></div>

            <div className="copyright-text">
                본 상품의 저작권은 (주)잡쇼퍼에 있으며, 무단으로 전재, 복사 혹은
                배포하는 경우 저작권법에 의거해 법적 조치에 처할 수 있습니다.
            </div>
        </div>
    ) : (
        <></>
    );
}

function formatDate(d: Date) {
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(0);
    const date = d.getDate();
    return `${year}. ${month}. ${date}`;
}
