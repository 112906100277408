import { client } from './client';

export async function callFetchSemesterInfo({
    studentId,
    grade,
    semester,
}: {
    studentId: StudentId;
    grade: number;
    semester: number;
}) {
    const { data } = await client.get(
        `/students_app/supporters/semesters/${grade}-${semester}/`,
        {
            params: {
                clientStudentId: studentId,
            },
        },
    );

    data.mockSATGrades = data.mockSatGrades;
    delete data.mockSatGrades;

    return data;
}
