import { useEffect, useState, useCallback } from 'react';

import { tournamentEntry } from '@constants/major-category-tournament-entry';
import {
    useLeanAIMetadataFacade,
    useMajorCategoryExplorationFacade,
} from '@facades';

import { useMajorCategories } from '../hooks';
import { CategoryParticipant, Tournament, getTournamentResult } from './core';

export function useTournament({
    onPicked,
    onCompleted,
}: {
    onPicked: () => void;
    onCompleted: (majorCategoryIds: number[]) => void;
}) {
    const leanAIMetadataFacade = useLeanAIMetadataFacade();
    const leanAIMetadata = leanAIMetadataFacade.get();
    const majorCategories = useMajorCategories(leanAIMetadata);
    const { questionnaires } = useMajorCategoryExplorationFacade();

    const [tournament, setTournament] = useState<Tournament | null>(null);

    const pick = useCallback(
        (v: number) => {
            if (!tournament) {
                return;
            }

            tournament.pick(v);
            onPicked();
            if (tournament.isFinished) {
                onCompleted(getTournamentResult(tournament));
            } else {
                setTournament(tournament);
            }
        },
        [tournament],
    );

    const unpick = useCallback(() => {
        if (!tournament) {
            return;
        }

        tournament.unpick();
        setTournament(tournament);
    }, [tournament]);

    function getParticipants(questionnaires: Record<number, string[]>) {
        return tournamentEntry.map((categoryName) => {
            const category = majorCategories[categoryName];
            return new CategoryParticipant(
                +category.id,
                category.category,
                questionnaires[+category.id],
            );
        });
    }

    useEffect(
        function initializeTournament() {
            if (leanAIMetadata && questionnaires) {
                const participants = getParticipants(questionnaires);
                const newTournament = new Tournament(participants);
                newTournament.start();

                setTournament(newTournament);
            }
        },
        [leanAIMetadata, questionnaires],
    );

    return {
        tournament,
        setTournament,
        pick,
        unpick,
    };
}
