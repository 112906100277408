import { useState, useEffect } from 'react';

export function ProgressRateBar({
    total,
    current,
}: {
    total: number;
    current: number;
}) {
    const [currentRate, setCurrentRate] = useState(0);

    function getProcessRate() {
        return (current / total) * 100;
    }

    useEffect(() => {
        setCurrentRate(getProcessRate());
    }, [current]);

    return (
        <header className="pt-5">
            <div className="text-center">
                <span className="text-purple fs-5">{Math.ceil(currentRate)}%</span>
            </div>
            <div className="w-100 pt-3 progress-bar">
                <div className="wrapper">
                    <div className="bar-container purple" style={{ width: `${currentRate}%` }}/>
                    <div className="bar-container grey" />
                </div>
            </div>
        </header>
    );
}
