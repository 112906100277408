import styled from 'styled-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const List = styled.ul`
    list-style: disc;
    width: 80%;

    li {
        margin-bottom: 0.25rem;
    }
`;

const ITEMS = [
    {
        fieldName: 'concept',
        description: '개념, 사건 등',
        placeholder: '(예) 양자 개념, 인공지능, 강화도 조약, 보스턴 차 사건',
    },
    {
        fieldName: 'rule',
        description: '원리, 규범, 규칙, 방법, 기술 등',
        placeholder:
            '(예) 질량 보존 법칙, 표준어 규정, 축구 경기 규칙, 하수 처리 방법, 자율 주행 기술',
    },
    {
        fieldName: 'character',
        description: '인물, 관계 등',
        placeholder: '(예) 링컨, 영조와 사도세자, 한일 관계의 전망',
    },
    {
        fieldName: 'era',
        description: '시대, 역사, 흐름 등',
        placeholder: '(예) 중세 봉건 시대, 1970년대 한국 경제, 서양 미술사',
    },
    {
        fieldName: 'region',
        description: '지역, 국가 등',
        placeholder: '(예) 제주 특산물, 베트남 전쟁, 북유럽의 복지 제도',
    },
    {
        fieldName: 'statistic',
        description: '통계, 기록 등',
        placeholder: '(예) 인구 통계, 세계 기록, 환율 변동',
    },
    {
        fieldName: 'combination',
        description: '조합',
        placeholder:
            '(예) 임진왜란 시기에 의병이 어떻게 활약했는지, 최근 10년간 부동산 가격이 얼마나 상승했는지',
    },
    {
        fieldName: 'etc',
        description: '기타',
        placeholder: '자유롭게 작성해 보세요.',
    },
];

interface InterestingPoints {
    concept: string;
    rule: string;
    character: string;
    era: string;
    region: string;
    statistic: string;
    combination: string;
    etc: string;
}

function Item({
    currentItem,
    setCurrentItem,
    propagate,
    form,
    fieldName,
    description,
    placeholder,
}: any) {
    return (
        <div className="input-group mb-3 d-flex align-items-center">
            <input
                className="me-1"
                type="radio"
                checked={currentItem === fieldName}
                onChange={() => {
                    setCurrentItem(fieldName);
                    propagate(fieldName);
                }}
            />
            <div className="col-3">
                <span
                    className="input-group-text bg-body border-0"
                    id="inputGroup-sizing-default"
                >
                    {description}
                </span>
            </div>
            <input
                type="text"
                className="form-control rounded-2"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                placeholder={placeholder}
                disabled={currentItem !== fieldName}
                {...form.register(fieldName, {
                    onChange() {
                        if (currentItem === fieldName) {
                            propagate(fieldName);
                        }
                    },
                })}
            />
        </div>
    );
}

export default function InterestingPoint({
    setMotivationPart2,
}: {
    setMotivationPart2: (value: string) => void;
}) {
    const form = useForm<InterestingPoints>();
    const [currentItem, setCurrentItem] = useState<keyof InterestingPoints>(
        ITEMS[0].fieldName as keyof InterestingPoints,
    );

    function propagate(fieldName: keyof InterestingPoints): void {
        setMotivationPart2(form.watch(fieldName));
    }

    const fixedItemProps = {
        currentItem,
        setCurrentItem,
        propagate,
        form,
    };

    return (
        <List className={'ps-4 w-100'}>
            {ITEMS.map((itemProps, index) => (
                <Item key={index} {...fixedItemProps} {...itemProps} />
            ))}
        </List>
    );
}
