import { client } from './client';

export async function callFetchAnalysisProposalDiagnosis({
    studentId,
    proposalId,
}: {
    studentId: StudentId;
    proposalId: number;
}) {
    const { data } = await client.get(
        `/students_app/supporters/proposals/${proposalId}/diagnosis/`,
        {
            params: {
                clientStudentId: studentId,
            },
        },
    );
    return data;
}
