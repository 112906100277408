import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { useOrderFacade } from '@facades';
import onlineEduImg from '@images/online-education.jpg';
import '@styles/pages/paymentPage.scss';

declare global {
    interface Window {
        IMP: any;
    }
}

export default function PaymentPage() {
    const { orderId } = useParams();
    const { order, initOrder, completeOrder, isCompleted, isInitialized } =
        useOrderFacade();
    const [searchParams, setSearchParams] = useSearchParams();
    const [hideBottomBox, setHideBottomBox] = useState<boolean>(true);
    const impSuccess = searchParams.get('imp_success');

    useEffect(
        function initializeOrder() {
            if (!orderId) {
                alert('잘못된 접근입니다.');
                return;
            }

            initOrder(orderId);
        },
        [orderId],
    );

    async function pay() {
        if (!order) {
            return;
        }

        const { IMP } = window;

        const data = {
            pg: 'html5_inicis',
            pay_method: 'card',
            merchant_uid: order.id,
            name: '학쫑학원 생활기록부 관리',
            amount: order.finalPrice,
            buyer_name: order.student.name,
            buyer_tel: order.student.contact,
        };

        if (isMobile) {
            // @ts-ignore
            data['m_redirect_url'] = window.location.href;
        }

        async function callback({
            success,
            error_msg,
            merchant_uid,
        }: {
            success: boolean;
            error_msg: string;
            merchant_uid: string;
        }) {
            if (success) {
                await completeOrder();
            } else {
                alert(`결제 실패: ${error_msg}`);
            }
        }

        IMP.request_pay(data, callback);
    }

    function handleScroll() {
        const currentScroll = document.documentElement.scrollTop;
        if (currentScroll > 600 && hideBottomBox) {
            setHideBottomBox(false);
        } else if (currentScroll <= 600 && !hideBottomBox) {
            setHideBottomBox(true);
        }
    }

    function scrollToTop() {
        document.documentElement.scrollTo(0, 0);
    }

    useEffect(
        function completeOrderForMobile() {
            if (!impSuccess || !isInitialized) {
                return;
            }
            completeOrder();
        },
        [impSuccess, isInitialized],
    );

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [window.document.documentElement.scrollTop]);

    return (
        <div id="paymentPage" className="payment-page">
            <div className="page-title">결제 페이지</div>
            {order ? (
                <>
                    <div className="payment-container">
                        <div className="content-title">결제정보</div>
                        <div className="content-with-img">
                            <img src={onlineEduImg} alt="학쫑학원" />
                            <div>
                                <div className="content-line">
                                    <span className="content-label emphasize">
                                        상품명
                                    </span>
                                    <span>{order.product.name}</span>
                                </div>
                                <div className="content-line">
                                    <span className="content-label emphasize">
                                        상품설명
                                    </span>
                                    <span>{order.product.description}</span>
                                </div>
                            </div>
                        </div>
                        <div className="content-divider" />
                        <div className="content-line space-between">
                            <span className="content-label">정상가</span>
                            <span>
                                {order.product.price.toLocaleString()}원
                            </span>
                        </div>
                        <div className="content-line space-between red">
                            <span className="content-label red">할인 금액</span>
                            <span>
                                -
                                {(
                                    order.product.price -
                                    order.product.finalPrice
                                ).toLocaleString()}
                                원
                            </span>
                        </div>
                        <div className="content-divider gray" />
                        <div className="content-line space-between emphasize">
                            <span>최종 금액</span>
                            <span>
                                {order.product.finalPrice.toLocaleString()}원
                            </span>
                        </div>
                    </div>

                    <div className="bottom-btn-group">
                        {!isCompleted && (
                            <>
                                <button className="order-btn" onClick={pay}>
                                    결제하기
                                </button>
                                <div className="btn-description">
                                    *결제가 완료되면 생기부 등록 안내 문자가
                                    전송됩니다.
                                </div>
                            </>
                        )}
                        {isCompleted && (
                            <div className="complete-message">
                                결제가 완료되었습니다
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="error-message">잠시만 기다려주세요...</div>
            )}

            <div className="service-info">
                <div>학쫑 학원에 대해 궁금하신가요?</div>
                <div className="info-main-text">아래로 스크롤!</div>
                <div className="material-icons">keyboard_double_arrow_down</div>
            </div>

            <div className="bottom-floating-box" hidden={hideBottomBox}>
                <button className="order-btn" onClick={scrollToTop}>
                    지금 결제하기
                </button>
                <div className="btn-description">
                    클릭 시, 결제 페이지로 이동
                </div>
            </div>
            <div className="payment-banner banner-img-1" />
            <div className="payment-banner banner-img-2" />
            <div className="payment-banner banner-img-4" />
            <div className="payment-banner banner-img-3" />
        </div>
    );
}
