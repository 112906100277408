import { client } from './client';

export async function callFetchSchoolRecord({
    studentId,
    grade,
}: {
    studentId: StudentId;
    grade: number;
}) {
    const { data } = await client.get(
        `/students_app/supporters/grades/${grade}/school-records/`,
        {
            params: {
                clientStudentId: studentId,
            },
        },
    );
    return data;
}
