import { useState } from 'react';
import { Col, Container, Nav, Navbar } from 'react-bootstrap';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import { useNavigate } from 'react-router-dom';

import { useStudentFacade } from '@facades';
import { moment } from '@utils';
import { Moment } from 'moment-timezone';
import { PATH } from '../ProjectRoutes';

import '@styles/components/header.scss';

const getSerivceUsageDate = (now?: Moment) => {
    const FIRST_SEMESTER_START_MONTH = 2;
    const SECOND_SEMESTER_START_MONTH = 8;

    now = now ? now : moment();
    const currentYear = now.year();
    const currentMonth = now.month();

    let from = moment(),
        to = moment();

    if (currentMonth < FIRST_SEMESTER_START_MONTH) {
        from.set('year', currentYear - 1);
        from.set('month', SECOND_SEMESTER_START_MONTH);
        to.set('month', FIRST_SEMESTER_START_MONTH - 1);
    } else if (currentMonth >= SECOND_SEMESTER_START_MONTH) {
        to.set('year', currentYear + 1);
        to.set('month', FIRST_SEMESTER_START_MONTH - 1);
    } else {
        from.set('month', FIRST_SEMESTER_START_MONTH);
        to.set('month', SECOND_SEMESTER_START_MONTH - 1);
    }
    return {
        from: from.startOf('month'),
        to: to.endOf('month'),
    };
};

export default function Header() {
    const navigate = useNavigate();

    const { from, to } = getSerivceUsageDate();
    const usageDateTo = to.format('YYYY.MM.DD');

    const studentFacade = useStudentFacade();

    async function logout() {
        await studentFacade.logout();
        navigate(PATH.LOGIN);
    }

    function navigateToLandingPage() {
        navigate(PATH.LANDING);
    }

    return (
        <>
            <Navbar className="header-base no-print" expand="lg" bg="white">
                {studentFacade.isLoggedIn ? (
                    <>
                        <Container fluid>
                            <Col xxl={4} xl={4} lg={4}>
                                <Navbar.Brand className="pointer" onClick={navigateToLandingPage}>
                                    {studentFacade.data.cramSchool}
                                </Navbar.Brand>
                            </Col>
                            <Col xxl={4} xl={4} lg={4}>
                                <Nav>
                                    <div className="header-usage-period toggle d-none d-lg-block d-xl-block d-xxl-block">
                                        만료 기한 {usageDateTo}
                                    </div>
                                </Nav>
                            </Col>
                            <Col xxl={3} xl={3} lg={3}>
                                <Nav>
                                    <div className="header-unique-code toggle d-none d-lg-block d-xl-block d-xxl-block">
                                        고유코드 {studentFacade.data.code}
                                    </div>
                                </Nav>
                            </Col>
                            <Col xxl={1} xl={1} lg={1}>
                                <Nav>
                                    <div
                                        className="header-logout-btn toggle d-none d-lg-block d-xl-block d-xxl-block"
                                        onClick={logout}
                                    >
                                        로그아웃
                                    </div>
                                </Nav>
                            </Col>
                            <Col lg={8} className="header-toggle-btn">
                                <NavbarToggle
                                    className="menu-btn d-lg-none"
                                    aria-controls="header-menu-container"
                                />
                            </Col>
                        </Container>

                        <Col>
                            <NavbarCollapse
                                id="header-menu-container"
                                className="d-lg-none"
                            >
                                <Nav className="header-menu d-lg-none">
                                    <div className="header-unique-code-toggle">
                                        고유코드 {studentFacade.data.code}
                                    </div>
                                    <div>만료 기한 {usageDateTo}</div>
                                    <div
                                        className="header-logout-btn"
                                        onClick={logout}
                                    >
                                        로그아웃
                                    </div>
                                </Nav>
                            </NavbarCollapse>
                        </Col>
                    </>
                ) : (
                    ''
                )}
            </Navbar>
        </>
    );
}
