import { useLayoutEffect, useState } from 'react';

const activeSectionClassName = 'fw-bold text-danger';
const inactiveSectionClassName = 'text-black opacity-50';
export default function LeftNav({
    sections,
}: {
    sections: {
        id: string;
        title: string;
    }[];
}) {
    const [currentSessionId, setCurrentSectionId] = useState('A');

    function gotoSection(id: string) {
        const scrollTarget = document.getElementById(id);
        if (scrollTarget) {
            scrollTarget.scrollIntoView({ behavior: 'smooth' });
            setCurrentSectionId(id);
        }
    }

    useLayoutEffect(
        function trackCurrentSection() {
            function getCurrentSection() {
                const firstSection = document.getElementById(sections[0].id);
                if (window.scrollY <= (firstSection?.offsetTop ?? 0)) {
                    return sections[0];
                } else {
                    return sections.find((section) => {
                        const element = document.getElementById(section.id);
                        if (element) {
                            const sectionTop = element.offsetTop;
                            const sectionBottom =
                                sectionTop + element.offsetHeight;
                            return (
                                window.scrollY >= sectionTop &&
                                window.scrollY <= sectionBottom - 30
                            );
                        } else {
                            return false;
                        }
                    });
                }
            }

            let isScrolling = false;
            function handleScroll() {
                if (!isScrolling && window.scrollY < window.innerHeight) {
                    setTimeout(() => {
                        const currentSection = getCurrentSection();
                        if (currentSection) {
                            setCurrentSectionId(currentSection.id);
                        }
                        isScrolling = false;
                    }, 50);
                }
                isScrolling = true;
            }

            document.addEventListener('scroll', handleScroll);

            return () => {
                document.removeEventListener('scroll', handleScroll);
            };
        },
        [sections],
    );

    return (
        <div className={'bg-white border rounded-4 p-3'}>
            {sections.map(({ title, id }, index) => (
                <div className={`row ${index !== 0 ? 'mt-3' : ''}`} key={index}>
                    <a
                        href={'#'}
                        className={`small pe-0 text-decoration-none ${
                            id === currentSessionId
                                ? activeSectionClassName
                                : inactiveSectionClassName
                        }`}
                        onClick={(e) => {
                            e.preventDefault();
                            gotoSection(id);
                        }}
                    >
                        {title}
                    </a>
                </div>
            ))}
        </div>
    );
}
