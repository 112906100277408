import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = [] as SchoolRecordAnalysisCode[];

export const schoolRecordAnalysisCodesSlice = createSlice({
    name: 'schoolRecordAnalysisCodes',
    initialState,
    reducers: {
        set(state, { payload }: PayloadAction<SchoolRecordAnalysisCode>) {
            const isExisting = state.find(
                (analysisCode) => analysisCode.grade === payload.grade,
            );
            if (!isExisting) {
                state.push(payload);
            }
        },
        clear(state) {
            return [];
        },
    },
});

export default schoolRecordAnalysisCodesSlice.reducer;
