import React, { useEffect, useState } from 'react';
import { Navigator } from '../components';
import SectionA from './SectionA';
import SectionB from './SectionB';
import SectionC from './SectionC';
import { scrollToSection } from '@pages/pro12/helpers';

export default function ResearchMainPage() {
    const [
        shouldShowValidationErrorMessage,
        setShouldShowValidationErrorMessage,
    ] = useState<boolean>(false);
    const [isSectionACompleted, setIsSectionACompleted] =
        useState<boolean>(false);
    const [isSectionBCompleted, setIsSectionBCompleted] =
        useState<boolean>(false);
    const [isSectionCCompleted, setIsSectionCCompleted] =
        useState<boolean>(false);

    const [shouldFocus, setShouldFocus] = useState<boolean>(false);

    const isValid =
        isSectionACompleted && isSectionBCompleted && isSectionCCompleted;

    useEffect(
        function focusOnError() {
            let scroll: () => void;
            if (shouldFocus) {
                if (!isSectionACompleted) {
                    scroll = () => scrollToSection('#A');
                } else if (!isSectionBCompleted) {
                    scroll = () => scrollToSection('#B');
                } else {
                    scroll = () => scrollToSection('#C');
                }

                if (scroll) {
                    setTimeout(scroll, 100);
                }

                setShouldFocus(false);
            }
        },
        [shouldFocus],
    );

    return (
        <>
            <SectionA
                setCompletion={setIsSectionACompleted}
                shouldShowValidationErrorMessage={
                    shouldShowValidationErrorMessage && !isSectionACompleted
                }
            />

            <SectionB
                setCompletion={setIsSectionBCompleted}
                shouldShowValidationErrorMessage={
                    shouldShowValidationErrorMessage && !isSectionBCompleted
                }
            />
            <SectionC
                setCompletion={setIsSectionCCompleted}
                shouldShowValidationErrorMessage={
                    shouldShowValidationErrorMessage && !isSectionCCompleted
                }
            />
            <Navigator
                onNextStepClick={(next) => {
                    if (isValid) {
                        next();
                    } else {
                        setShouldFocus(true);
                        setShouldShowValidationErrorMessage(true);
                    }
                }}
            />
        </>
    );
}
