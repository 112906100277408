import { every } from 'lodash';

import { Set } from './set';
import { Participant } from './participant';

export const RoundTypes = {
    WordCloud: 'WORD_CLOUD',
    RelatedMajor: 'RELATED_MAJOR',
    Image: 'IMAGE',
    RecommendedBook: 'RECOMMENDED_BOOK',
    Questionnaire: 'QUESTIONNAIRE',
} as const;

export type RoundType = typeof RoundTypes[keyof typeof RoundTypes];

export class Round {
    constructor(
        public number: number,
        private sets: Set[],
        public type: RoundType = RoundTypes.Image,
    ) {}

    get isFinished() {
        return every(this.getSets().map((e) => !!e.winner));
    }

    get isNewlyCreated() {
        return every(
            this.getSets().map((e) => !e.winner && e.losers.length === 0),
        );
    }

    pick(v: number) {
        this.getCurrentSet().setWinner(v);
    }

    unpick() {
        this.getLastResolvedSet().initialize();
    }

    getSets() {
        return this.sets;
    }

    getLastResolvedSet() {
        for (let index = this.sets.length - 1; index > -1; index--) {
            const pair = this.sets[index];
            if (pair.winner) {
                return pair;
            }
        }
        return this.sets[0];
    }

    getCurrentSet() {
        for (let index = 0; index < this.sets.length; index++) {
            const pair = this.sets[index];
            if (!pair.winner) {
                return pair;
            }
        }
        return this.sets[0];
    }

    getWinners() {
        return (
            this.getSets().reduce((p, c) => {
                if (c.winner) {
                    p.push(c.winner);
                }
                return p;
            }, [] as Participant[]) || []
        );
    }

    getLosers() {
        return (
            this.getSets().reduce((p, c) => {
                return p.concat(c.losers);
            }, [] as Participant[]) || []
        );
    }

    get [Symbol.toStringTag]() {
        return `${this.number}`;
    }
}
