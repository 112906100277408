import lodash from 'lodash';

type Convert = (key: string) => string;

export function convertKeys<T = any>(convert: Convert, targetObject: T): T {
    if (lodash.isArray(targetObject)) {
        return targetObject.map((e) => convertKeys(convert, e)) as unknown as T;
    } else if (lodash.isObject(targetObject)) {
        const convertedObject: any = {};
        Object.keys(targetObject).forEach((k) => {
            convertedObject[convert(k)] = convertKeys(
                convert,
                (targetObject as any)[k],
            );
        });
        return convertedObject as unknown as T;
    }
    return targetObject;
}
