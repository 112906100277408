import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as Student;

export const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        set(state, { payload }: PayloadAction<Student>) {
            return { ...payload };
        },
        clear(state) {
            return { ...initialState };
        },
    },
});

export default studentSlice.reducer;
