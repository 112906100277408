import { client } from './client';

export async function callPatchAssignmentReport({
    studentId,
    assignmentReportId,
    report,
    file,
}: {
    studentId: StudentId;
    assignmentReportId: AssignmentReportId;
    report?: { [key: string]: any };
    file?: File;
}) {
    if (!file) {
        const response = await client.patch(
            `/assignment_app/assignment-reports/${assignmentReportId}/`,
            {
                data: report,
            },
            {
                params: { clientStudentId: studentId },
            },
        );
        return response.data;
    } else {
        const data = new FormData();
        data.append('file', file);

        const response = await client.patch(
            `/assignment_app/assignment-reports/${assignmentReportId}/`,
            data,
            {
                params: { clientStudentId: studentId },
            },
        );
        return response.data;
    }
}
