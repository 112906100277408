import React from 'react';
import reactDom from 'react-dom';

export default function Portal({ children }: { children: React.ReactNode }) {
    const element = document.getElementById('portal');
    if (!element) {
        return <></>;
    }
    return reactDom.createPortal(children, element);
}
