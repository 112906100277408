import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { RetrospectionForm } from './types';
import SchoolSubjectRelated from '@pages/pro12/ResearchConclusionPage/components/SchoolSubjectRelated';
import CareerRelated from '@pages/pro12/ResearchConclusionPage/components/CareerRelated';
import EtcSchoolSubjectRelated from '@pages/pro12/ResearchConclusionPage/components/EtcSchoolSubjectRelated';

export default function Step2({
    setFormRef,
    currentLearningPoint,
}: {
    currentLearningPoint: string;
    setFormRef: (form: UseFormReturn<RetrospectionForm>) => void;
}) {
    const form = useForm<RetrospectionForm>();

    useEffect(() => {
        setFormRef(form);
    }, []);

    return (
        <>
            <h4 className="fs-6 fw-semibold mb-3">[2단계] 문장 완성하기</h4>
            {currentLearningPoint === '교과 학습과 관련하여' && (
                <SchoolSubjectRelated form={form} />
            )}
            {currentLearningPoint === '진로 탐색과 관련하여' && (
                <CareerRelated form={form} />
            )}
            {currentLearningPoint === '다른 과목 학습과 관련하여' && (
                <EtcSchoolSubjectRelated form={form} />
            )}
        </>
    );
}
