import leftBottom from '@images/record-diagnosis/left-bottom.jpg';
import leftMiddle from '@images/record-diagnosis/left-middle.jpg';
import leftTop from '@images/record-diagnosis/left-top.jpg';
import rightBottom from '@images/record-diagnosis/right-bottom.jpg';
import rightMiddle from '@images/record-diagnosis/right-middle.jpg';
import rightTop from '@images/record-diagnosis/right-top.jpg';
import centerBottom from '@images/record-diagnosis/center-bottom.jpg';
import centerMiddle from '@images/record-diagnosis/center-middle.jpg';
import centerTop from '@images/record-diagnosis/center-top.jpg';
import React from 'react';

export default function SchoolRecordAnalysisDiagnosisImageCard({
    position,
}: {
    position: string;
}) {
    let path: string;
    switch (position) {
        case 'leftTop':
            path = leftTop;
            break;
        case 'leftMiddle':
            path = leftMiddle;
            break;
        case 'leftBottom':
            path = leftBottom;
            break;
        case 'centerTop':
            path = centerTop;
            break;
        case 'centerMiddle':
            path = centerMiddle;
            break;
        case 'centerBottom':
            path = centerBottom;
            break;
        case 'rightTop':
            path = rightTop;
            break;
        case 'rightMiddle':
            path = rightMiddle;
            break;
        case 'rightBottom':
            path = rightBottom;
            break;
        default:
            return <></>;
    }
    return (
        <div className="col bg-info">
            <div
                className={
                    'row bg-body justify-content-center align-items-center'
                }
            >
                <img className={'w-50 h-50'} src={path} />
            </div>
        </div>
    );
}
