import { client } from './client';

export async function callFetchAssignments(params: any) {
    const response = await client.get(
        '/assignment_app/assignments/',
        {
            params,
        },
    );
    return response.data;
}
