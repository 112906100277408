import {
    callFetchAnalysisProposalDiagnosis,
    callFetchHakzzongSchoolRecord,
    callFetchSchoolRecord,
    callFetchSchoolRecordAnalysisProposal,
    callUploadSchoolRecord,
} from '@client';
import { useAppSelector } from '@store';

export function useGradeFacade() {
    const student = useAppSelector((state) => state.student);

    async function uploadSchoolRecord({
        schoolRecord,
        grade,
        studentId,
    }: {
        schoolRecord: File;
        grade: number;
        studentId: StudentId;
    }) {
        return await callUploadSchoolRecord({
            studentId,
            grade,
            file: schoolRecord,
        });
    }

    async function retrieveSchoolRecord({ grade }: { grade: number }) {
        return await callFetchSchoolRecord({ grade, studentId: student.id });
    }

    async function getSchoolRecordAnalysisProposal(grade: number) {
        return await callFetchSchoolRecordAnalysisProposal({
            studentId: student.id,
            grade,
        });
    }

    async function getHakzzongSchoolRecord(grade: number) {
        return await callFetchHakzzongSchoolRecord({
            studentId: student.id,
            grade,
        });
    }

    async function getAnalysisProposalDiagnosis(proposalId: number) {
        return await callFetchAnalysisProposalDiagnosis({
            studentId: student.id,
            proposalId,
        });
    }

    return {
        uploadSchoolRecord,
        retrieveSchoolRecord,
        getProposedSchoolRecordAnalysisProposal:
            getSchoolRecordAnalysisProposal,
        getAnalysisProposalDiagnosis,
    };
}
