import React, { useEffect, useRef, useState } from 'react';

import {
    pro12Slice,
    ResearchToughPointOvercomingMethodSet,
    useAppDispatch,
    useAppSelector,
} from '@store';
import {
    BorderBox,
    SectionTitle,
    SectionWrapper,
    ToughPointCard,
} from '../components';
import FormErrorMessage from '@components/FormErrorMessage';
import { descriptionBlankMark } from '@pages/pro12/ResearchMainPage/constants';
import { UseFormReturn } from 'react-hook-form';
import { TPOMDraft } from '@pages/pro12/ResearchMainPage/TPOMDraft';

export default function SectionC({
    setCompletion,
    shouldShowValidationErrorMessage,
}: {
    setCompletion: (bool: boolean) => void;
    shouldShowValidationErrorMessage: boolean;
}) {
    const toughPointOvercomingMethodSets = useAppSelector(
        ({ pro12 }) => pro12.researchToughPointOvercomingMethodSets || [],
    );
    const dispatch = useAppDispatch();
    const [cards, setCards] = useState<
        ResearchToughPointOvercomingMethodSet[]
    >(toughPointOvercomingMethodSets);

    const toughPointFormRef = useRef<UseFormReturn<ToughPointForm>>();
    const overcomingMethodFormRef =
        useRef<UseFormReturn<OvercomingMethodForm>>();

    const [isInvalidFormSubmitted, setIsInvalidFormSubmitted] =
        useState<boolean>(false);

    function setToughPointFormRef(ref: UseFormReturn<ToughPointForm>) {
        toughPointFormRef.current = ref;
    }

    function setOvercomingMethodFormRef(
        ref: UseFormReturn<OvercomingMethodForm>,
    ) {
        overcomingMethodFormRef.current = ref;
    }

    const [shouldOpenDraft, setShouldOpenDraft] = useState(false);

    function insertTempCard() {
        const toughPointForm =
            toughPointFormRef.current as UseFormReturn<ToughPointForm>;
        const overcomingMethodForm =
            overcomingMethodFormRef.current as UseFormReturn<OvercomingMethodForm>;

        setCards((prev) => [
            ...prev,
            {
                toughPoint: toughPointForm.getValues().toughPointDescription,
                overcomingMethod:
                    overcomingMethodForm.getValues().overcomingDescription,
            },
        ]);
        setShouldOpenDraft(false);

        toughPointForm.reset();
        overcomingMethodForm.reset();
    }

    function removeCard(index: number) {
        setCards((prev) => prev.filter((_, i) => i !== index));
    }

    const hasNoCard = cards.length === 0;

    function isValid() {
        const toughPointForm =
            toughPointFormRef.current as UseFormReturn<ToughPointForm>;
        const overcomingMethodForm =
            overcomingMethodFormRef.current as UseFormReturn<OvercomingMethodForm>;

        const toughPoint = toughPointForm.getValues();
        const overcomingMethod = overcomingMethodForm.getValues();

        function isValidDescription(description: string) {
            return (
                description.length > 0 &&
                !description.includes(descriptionBlankMark)
            );
        }
        return (
            toughPoint.toughPointSummary?.length > 0 &&
            isValidDescription(toughPoint.toughPointDescription) &&
            overcomingMethod.overcomingSummary?.length > 0 &&
            isValidDescription(overcomingMethod.overcomingDescription)
        );
    }

    function validateAndInsert() {
        if (isValid()) {
            insertTempCard();
            setIsInvalidFormSubmitted(false);
        } else {
            setIsInvalidFormSubmitted(true);
        }
    }

    useEffect(
        function storeCards() {
            dispatch(
                pro12Slice.actions.setResearchToughPointOvercomingMethodSets(
                    cards,
                ),
            );
        },
        [cards],
    );

    useEffect(
        function complete() {
            setCompletion(cards.length > 0);
        },
        [cards],
    );

    return (
        <SectionWrapper id={'C'}>
            <SectionTitle>C. 어려움과 극복</SectionTitle>
            {(shouldShowValidationErrorMessage || isInvalidFormSubmitted) && (
                <FormErrorMessage
                    message={'어려웠던 지점과 극복한 방법을 입력해주세요.'}
                />
            )}
            {cards.map(({ toughPoint, overcomingMethod }, index) => (
                <BorderBox variant="danger" className="mb-2" key={index}>
                    <ToughPointCard
                        toughPointDescription={toughPoint}
                        overcomingMethodDescription={overcomingMethod}
                        index={index}
                        removeCard={removeCard}
                    />
                </BorderBox>
            ))}
            {hasNoCard && (
                <TPOMDraft
                    setToughPointFormRef={setToughPointFormRef}
                    setOvercomingMethodFormRef={setOvercomingMethodFormRef}
                    validator={validateAndInsert}
                />
            )}
            {!hasNoCard &&
                (shouldOpenDraft ? (
                    <TPOMDraft
                        setToughPointFormRef={setToughPointFormRef}
                        setOvercomingMethodFormRef={setOvercomingMethodFormRef}
                        validator={validateAndInsert}
                    />
                ) : (
                    <div className="row mt-3 justify-content-center">
                        <div className="col-auto">
                            <button
                                className="btn btn-outline-danger rounded-5 fw-bold py-2 px-4"
                                type="button"
                                onClick={() => setShouldOpenDraft(true)}
                            >
                                어려움과 극복 추가하기
                            </button>
                        </div>
                    </div>
                ))}
        </SectionWrapper>
    );
}
