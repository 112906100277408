import { useState } from 'react';
import SchoolSubjectSelectModal from '@components/SchoolSubjectsSelectModal';

import closeImg from '@images/purple-close.png';

export default function InterestedSchoolSubjects({
    interestedSchoolSubjects,
    schoolSubjectData,
    updateInterestedSchoolSubjects,
    removeInterestedSchoolSubject,
}: {
    interestedSchoolSubjects: SchoolSubject[];
    schoolSubjectData: LeanAIMetadata['schoolSubjects'];
    updateInterestedSchoolSubjects: (schoolSubject: SchoolSubjectId[]) => void;
    removeInterestedSchoolSubject: (schoolSubject: SchoolSubjectId) => void;
}) {
    const [showModal, setShowModal] = useState<boolean>(false);

    function openModal() {
        setShowModal(true);
    }
    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            {interestedSchoolSubjects.map(({ id, name }) => (
                <div className="text-box square relative !pr-12" key={id}>
                    <button
                        className="absolute top-1/2 -translate-y-1/2 right-3 w-6 h-6 bg-transparent !p-0"
                        onClick={() => removeInterestedSchoolSubject(id)}
                    >
                        <img src={closeImg} alt="삭제" />
                    </button>

                    <span>{name}</span>
                </div>
            ))}
            <button
                className="bg-white text-purple border border-purple py-0 !px-10 mb-[10px]"
                onClick={openModal}
                type="button"
            >
                {' '}
                +
            </button>
            <SchoolSubjectSelectModal
                modalShow={showModal}
                closeModal={closeModal}
                onSelect={updateInterestedSchoolSubjects}
                interestedSchoolSubjectIds={interestedSchoolSubjects.map(
                    (e) => e.id,
                )}
                schoolSubjectData={schoolSubjectData}
            />
        </>
    );
}
