import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { REDUX_LOCAL_STORAGE } from '@constants/config';
import {
    hakzzongRecordAnalysisCodes,
    leanAIMetadata,
    pro12,
    student,
    temporaryAssignmentReportForms,
} from './slices';

const rootReducer = combineReducers({
    pro12,
    student,
    leanAIMetadata,
    temporaryAssignmentReportForms,
    hakzzongRecordAnalysisCodes,
});

const persistConfig = {
    storage,
    key: REDUX_LOCAL_STORAGE,
    blacklist: ['leanAIMetadata', 'pro12'],
};

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedRootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
    devTools: true,
});

export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
