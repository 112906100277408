import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const QUEUE_SIZE_LIMIT = 10;

const initialState = [] as TemporaryAssignmentReportForm[];

export const temporaryAssignmentReportFormsSlice = createSlice({
    name: 'temporaryAssignmentReportForms',
    initialState,
    reducers: {
        save(
            state,
            {
                payload,
            }: PayloadAction<Omit<TemporaryAssignmentReportForm, 'updatedAt'>>,
        ) {
            while (state.length >= QUEUE_SIZE_LIMIT) {
                state.shift();
            }

            const record = { ...payload, updatedAt: new Date() };
            const existingRecord = state.find(
                (record) => record.assignmentId === payload.assignmentId,
            );

            if (existingRecord) {
                state[state.indexOf(existingRecord)] = record;
            } else {
                state.push(record);
            }
        },
        clear(state) {
            return [];
        },
    },
});

export default temporaryAssignmentReportFormsSlice.reducer;
