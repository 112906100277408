import Tracker from '@openreplay/tracker';

const tracker = new Tracker({
    projectKey: process.env.REACT_APP_OPENREPLAY_PROJECT_KEY || '',
    ingestPoint: process.env.REACT_APP_OPENREPLAY_INGEST_POINT || '',
    __DISABLE_SECURE_MODE: true,
    defaultInputMode: 0,
    obscureInputDates: false,
    obscureInputEmails: false,
    obscureTextEmails: false,
    obscureTextDates: false,
    obscureInputNumbers: false,
    obscureTextNumbers: false,
    network: {
        capturePayload: true,
        sanitizer: (payload) => payload,
    },
});
const sessionHash =
    new URLSearchParams(window.location.search).get('sessionHash') ?? undefined;
tracker.start({
    sessionHash,
});

export default tracker;
