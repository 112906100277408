import '@styles/pages/majorCategoryExplorationTournament.scss';
import { QuestionnaireParticipant, Round } from './core';

const answers = ['매우 싫다', '싫다', '보통이다', '좋다', '매우 좋다'];

export function QuestionnaireMatchRound({
    round,
    pick,
}: {
    round: Round;
    pick: (v: number) => void;
}) {
    const set = round.getCurrentSet();
    const participants = set.participants as QuestionnaireParticipant[];

    return (
        <article className="mt-5 text-center">
            <div className="row align-items-center justify-content-between pb-5">
                <div className="col text-center">
                    <h1 className="fw-bold">
                        문항을 읽고, 해당되는 답을 선택하세요.
                    </h1>
                </div>
            </div>
            {(
                <div className="border border-purple py-4 rounded-3 bg-white w-75 mx-auto body-container">
                    <span className="fw-semibold fs-4">
                        {participants[0].questionnaire}
                    </span>
                </div>
            )}
            <div className="d-flex justify-content-center my-5">
                {participants.map((participant, index) => (
                    <button
                        key={index}
                        onClick={() => pick(index)}
                        className="answer-btn bg-light-purple mx-3 text-purple rounded-2 border-0"
                    >
                        {answers[index]}
                    </button>
                ))}
            </div>
        </article>
    );
}
