import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AddRounded, Delete } from '@mui/icons-material';

import useFieldArrayWithAddAndDeleteButton from './useFieldArrayWithAddAndDeleteButton';
import SmallRoundedButton from './SmallRoundedButton';

function Draggable({
    id,
    handleDrag,
    handleDrop,
    children,
    ...divProps
}: {
    id: string;
    handleDrag: (e: React.DragEvent<HTMLDivElement>) => void;
    handleDrop: (e: React.DragEvent<HTMLDivElement>) => void;
    children: React.ReactNode;
} & Omit<
    React.HTMLAttributes<HTMLDivElement>,
    'id' | 'draggable' | 'onDragStart' | 'onDrop' | 'onDragOver'
>) {
    return (
        <div
            id={id}
            draggable={true}
            onDragStart={handleDrag}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            {...divProps}
        >
            {children}
        </div>
    );
}

export default function ContentInputGroup({
    form,
    contents,
    flushContents,
}: {
    form: UseFormReturn<ResearchOverviewForm>;
    contents: string[];
    flushContents: () => void;
}) {
    const {
        values,
        fields,
        showAddButton,
        showDeleteButton,
        append,
        remove,
        swap,
    } = useFieldArrayWithAddAndDeleteButton({
        form,
        name: 'contents',
        validator: (content) => !!content?.value,
    });

    const [currentDragged, setCurrentDragged] = React.useState<string | null>(
        null,
    );

    useEffect(() => {
        if (values.length === 0) {
            append({ value: '' });
        }
    }, [values]);

    useEffect(
        function syncContents() {
            if (contents.length === 0) {
                return;
            }

            if (values.length > 0 && !values[values.length - 1].value) {
                remove(values.length - 1);
            }

            append(contents.map((content) => ({ value: content })));
            flushContents();
        },
        [values, contents],
    );

    function dragRow(e: React.DragEvent<HTMLDivElement>) {
        setCurrentDragged(e.currentTarget.id);
    }

    function swapRows(e: React.DragEvent<HTMLDivElement>) {
        const targetId = e.currentTarget.id;
        if (currentDragged !== null) {
            const currentDraggedIndex = fields.findIndex(
                (field) => field.id === currentDragged,
            );
            const targetIndex = fields.findIndex(
                (field) => field.id === targetId,
            );
            swap(currentDraggedIndex, targetIndex);
            setCurrentDragged(null);
        }
    }

    return (
        <>
            {fields.map((field, index) => (
                <Draggable
                    id={field.id}
                    className={'row ps-5 align-items-center mt-2'}
                    key={index}
                    handleDrag={(e) => dragRow(e)}
                    handleDrop={(e) => swapRows(e)}
                >
                    <div className={'col-8 d-flex align-items-center'}>
                        <span className="me-2">({index + 1}) </span>
                        <input
                            type={'text'}
                            className={
                                'form-control form-control-sm d-inline-block py-1 fs-6 rounded-3'
                            }
                            {...form.register(`contents.${index}.value`, {
                                required: true,
                            })}
                            placeholder="탐구 내용을 작성해주세요."
                        />
                    </div>
                    <div className={'col-auto'}>
                        <>
                            {showAddButton(index) ? (
                                <SmallRoundedButton
                                    variant={'primary'}
                                    onClick={() => append({ value: '' })}
                                    key={index}
                                >
                                    <AddRounded />
                                </SmallRoundedButton>
                            ) : (
                                showDeleteButton(index) && (
                                    <SmallRoundedButton
                                        variant={'danger'}
                                        onClick={() => remove(index)}
                                        key={index}
                                    >
                                        <Delete />
                                    </SmallRoundedButton>
                                )
                            )}
                        </>
                    </div>
                </Draggable>
            ))}
        </>
    );
}
