import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { LearningPointForm } from './types';
import { learningPointOptions } from './constants';

export default function Step1({
    value,
    setFormRef,
}: {
    value: string;
    setFormRef: (form: UseFormReturn<LearningPointForm>) => void;
}) {
    const form = useForm<LearningPointForm>({
        defaultValues: { value },
    });

    useEffect(() => {
        setFormRef(form);
    }, [form.watch('value')]);

    return (
        <>
            <h4 className="fs-6 fw-semibold mb-3">[1단계] 유형 선택</h4>
            {learningPointOptions.map((option) => (
                <div className="form-check" key={option}>
                    <input
                        className="form-check-input"
                        type="radio"
                        value={option}
                        {...form.register('value')}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                    >
                        {option}
                    </label>
                </div>
            ))}
        </>
    );
}
