import { callFetchLeanAIMetadata } from '@client';
import { leanAIMetadataSlice, useAppDispatch, useAppSelector } from '@store';
import lodash from 'lodash';

let isGettingReady = false;
export function useLeanAIMetadataFacade() {
    const dispatch = useAppDispatch();
    let temporalLeanAIMetadata: LeanAIMetadata;
    const leanAIMetadata = useAppSelector((state) => state.leanAIMetadata);
    const isReady = !lodash.isEmpty(leanAIMetadata);
    const get = () => (isReady ? leanAIMetadata : temporalLeanAIMetadata);

    async function ready() {
        if (isReady || isGettingReady) {
            return;
        }

        isGettingReady = true;
        const data = await callFetchLeanAIMetadata();
        temporalLeanAIMetadata = data;
        dispatch(leanAIMetadataSlice.actions.set(data));
        isGettingReady = false;
    }

    return {
        isReady,
        ready,
        get,
    };
}
