import React from 'react';

export default function ContentWrapper({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div className={'col px-5 pb-5 bg-white rounded-4 border'}>
            {children}
        </div>
    );
}
