import { UseFormReturn } from 'react-hook-form';

import useFieldArrayWithAddAndDeleteButton from './useFieldArrayWithAddAndDeleteButton';
import { AddRounded, Delete } from '@mui/icons-material';
import React, { useEffect } from 'react';
import SmallRoundedButton from '@pages/pro12/ResearchOverviewPage/SmallRoundedButton';

function newPlanning() {
    return {
        content: '',
    };
}

export default function Plannings({
    form,
}: {
    form: UseFormReturn<ResearchOverviewForm>;
}) {
    const { values, showAddButton, showDeleteButton, append, remove } =
        useFieldArrayWithAddAndDeleteButton({
            form,
            name: 'plannings',
            validator: (value) =>
                !!(value.content && value.startDate && value.endDate),
        });

    useEffect(() => {
        if (values.length === 0) {
            append(newPlanning());
        }
    }, [values]);

    return (
        <>
            <div className="row text-center">
                <div className="col-4">
                    <strong className="text-center fw-bold fs-5">내용</strong>
                </div>
                <div className="col-3">
                    <strong className="text-center fw-bold fs-5">시작일</strong>
                </div>
                <div className="col-3">
                    <strong className="text-center fw-bold fs-5">종료일</strong>
                </div>
                <div className="col-1"></div>
            </div>
            {values.map((_, index) => (
                <div className="row mt-2 align-items-center" key={index}>
                    <div className="col-4">
                        <input
                            className="form-control text-center py-2"
                            type="text"
                            placeholder={
                                'ex) 자료 수집, 보고서 작성, 발표자료 제작'
                            }
                            {...form.register(`plannings.${index}.content`, {
                                required: false,
                            })}
                        />
                    </div>
                    <div className="col-3">
                        <input
                            className="form-control text-center py-2"
                            type="date"
                            {...form.register(`plannings.${index}.startDate`, {
                                required: false,
                            })}
                        />
                    </div>
                    <div className="col-3">
                        <input
                            className="form-control text-center py-2"
                            type="date"
                            {...form.register(`plannings.${index}.endDate`, {
                                required: false,
                            })}
                        />
                    </div>
                    <div className="col-1 d-flex">
                        {showAddButton(index) ? (
                            <SmallRoundedButton
                                key={index}
                                variant={'primary'}
                                onClick={() => append(newPlanning())}
                            >
                                <AddRounded />
                            </SmallRoundedButton>
                        ) : (
                            showDeleteButton(index) && (
                                <SmallRoundedButton
                                    variant={'danger'}
                                    onClick={() => remove(index)}
                                    key={index}
                                >
                                    <Delete />
                                </SmallRoundedButton>
                            )
                        )}
                    </div>
                </div>
            ))}
        </>
    );
}
