import React, { useEffect, useRef, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { BackspaceOutlined } from '@mui/icons-material';
import { ErrorMessage } from '@hookform/error-message';

import { pro12Slice, useAppDispatch, useAppSelector } from '@store';
import { useEtcCheckbox, useEtcRadio } from '@pages/hooks';
import FormErrorMessage from '@components/FormErrorMessage';
import {
    BorderBox,
    Navigator,
    ReferenceCard,
    ReferenceDraft,
    ReferenceGroup,
    SectionTitle,
    SectionWrapper,
    StringCheckOptions,
    SubSection,
} from '../components';
import {
    applicationOptions,
    methodOptions,
    motivationPart0Options,
    motivationPart3Options,
    typeOptions,
} from './constants';
import ObjectiveInputGroup from './ObjectiveInputGroup';
import InfoTooltip from './InfoTooltip';
import InterestingPoint from '@pages/pro12/ResearchIntroductionPage/InterestingPoint';
import { useBeforeTempSave, useOptionalField } from '@pages/pro12/hooks';
import { scrollToSection } from '@pages/pro12/helpers';
import Tooltip from './Tooltip';

const researchTypeTooltips: Record<string, React.ReactNode> = {
    '조사연구(survey research)': (
        <span>
            연구 집단(표본)에 속한 개인들에게 면접이나 설문지를 통해 일련의
            질문을 함으로써 해당 집단의 행동, 태도, 특성, 관심 등을 조사하는
            연구 유형.
        </span>
    ),
    '실험연구(experimental research)': (
        <span>
            인과관계에 관한 가설을 검증할 목적으로, 통제된 조건하에 수행되는
            연구 유형.
        </span>
    ),
    '관찰연구(observational study)': (
        <p className={'mb-0'}>
            관찰 대상의 특성을 연구자가 직접 관찰하여 분석하는 연구 유형.
            <br />
            관찰 방법에 따라 통제적/비통제적 관찰, 자연/조작적 관찰, 참여/비참여
            관찰 등으로 구분된다.
        </p>
    ),
    '문화기술지연구(ethnographic study)': (
        <p className={'mb-0'}>
            문화기술지에서 사용하는 참여관찰 방식이나 정보제공자 면접방식을
            동원하여 한 문화를 서술하는 연구 유형.
            <br />
            현지인 혹은 관찰대상의 관점에서 그들의 생활방식을 이해하는 데 목표를
            둠.
        </p>
    ),
    '사례연구(case study)': (
        <p className={'mb-0'}>
            단일/소수 사례에 대하여 심층적인 자료를 수집하여 탐색하는 연구 유형.
            <br />
            대상 사례의 모든 측면(전개 과정, 특성, 문제점과 원인, 해결방안)을
            면밀하게 분석함.
        </p>
    ),
    '역사연구(historical research)': (
        <p className={'mb-0'}>
            역사적 자료(고문서, 예술작품 등)를 통해 사회현상을 분석하는 연구
            유형.
            <br />
            오래 전의 사회현상을 분석할 때 적합함.
        </p>
    ),
};

const researchMethodTooltips: Record<string, React.ReactNode> = {
    '문헌 연구법': <span>기존의 문헌자료를 통해 정보를 수집하는 방법</span>,
    실험법: (
        <span>
            연구 대상을 실험 집단과 통제 집단으로 나누어 다른 조건들을 통제한
            후, 하나의 변수가 실험 집단에 어떤 영향을 끼치는지 측정하는 방법
        </span>
    ),
    질문지법: (
        <span>
            질문지(설문지)에 응답자가 답을 기입하게 함으로써 자료를 수집하는
            방법
        </span>
    ),
    면접법: <span>응답자와의 1:1 대화를 통해 필요한 자료를 수집하는 방법</span>,
    관찰법: (
        <p className={'mb-0'}>
            조사 대상자를 현장에서 관찰하여 자료를 수집하는 방법.
            <br />
            연구자가 집단 구성원이 되어 관찰하는 ‘참여관찰’과, 연구자가 제3자의
            입장에서 관찰하는 ‘비참여관찰’로 나뉜다.
        </p>
    ),
};

function getInitialMotivationDraft() {
    return ['교과 학습으로부터', '', '', ''];
}

export default function ResearchIntroductionPage() {
    const {
        relatedSchoolSubject,
        relatedSchoolSubjectUnits,
        relatedLearningElements,
        presentationTitle,
        contents: researchContents,
    } = useAppSelector(({ pro12 }) => pro12.researchOverviewForm);
    const researchIntroductionForm = useAppSelector(
        ({ pro12 }) => pro12.researchIntroductionForm,
    );
    const dispatch = useAppDispatch();

    const [isMotivationDraftOpen, setIsMotivationDraftOpen] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    const form = useForm<ResearchIntroductionForm>({
        defaultValues: {
            motivationDraft: getInitialMotivationDraft(),
            types: [],
            methods: [],
            motivations: [],
            ...(researchIntroductionForm as any),
        },
    });
    const {
        watch,
        register,
        setValue,
        resetField,
        getValues,
        formState,
        handleSubmit,
        setFocus,
        setError,
        clearErrors,
    } = form;

    const skipTypes = useOptionalField();
    const skipMethods = useOptionalField();

    const motivationDraftWatch = watch('motivationDraft');
    const motivationDraftPart0EtcRadio = useEtcRadio<ResearchIntroductionForm>({
        fieldName: 'motivationDraft.0',
        otherOptions: motivationPart0Options,
        form,
    });

    const defaultMotivationPart1Option = `${relatedSchoolSubject} ${relatedSchoolSubjectUnits
        .map((u) => `<${u}>`)
        .join(', ')}에서 ${relatedLearningElements
        .map(({ learningElement }) => learningElement)
        .join(', ')}에 관해 학습하면서,`;

    const motivationDraftPart1EtcRadio = useEtcRadio<ResearchIntroductionForm>({
        fieldName: 'motivationDraft.1',
        otherOptions: [defaultMotivationPart1Option],
        form,
    });

    const motivationDraftPart3EtcRadio = useEtcRadio<ResearchIntroductionForm>({
        fieldName: 'motivationDraft.3',
        otherOptions: motivationPart3Options,
        form,
    });

    const applicationEtcRadio = useEtcRadio({
        fieldName: 'application',
        otherOptions: applicationOptions,
        form,
    });

    const typesEtcCheckbox = useEtcCheckbox({
        fieldName: 'types',
        otherOptions: typeOptions,
        form,
    });

    const methodsEtcCheckbox = useEtcCheckbox({
        fieldName: 'methods',
        otherOptions: methodOptions,
        form,
    });

    const applicationWatch = watch('application');

    const [
        shouldShowValidationErrorMessage,
        setShouldShowValidationErrorMessage,
    ] = useState(false);

    const [goToNextStep, setGoToNextStep] = useState(false);

    function isMotivationDraftComplete() {
        return !motivationDraftWatch.slice(1).find((value) => !value);
    }

    function resetMotivationDraft() {
        resetField('motivationDraft');
        setIsMotivationDraftOpen(true);
    }

    function completeMotivationDraft() {
        function makeMotivationSentence() {
            return [
                motivationDraftWatch[1],
                [motivationDraftWatch[2], motivationDraftWatch[3]].join(''),
            ].join(' ');
        }
        setValue('motivations', [
            ...watch('motivations'),
            makeMotivationSentence(),
        ]);
        resetMotivationDraft();
        setIsMotivationDraftOpen(false);
    }

    function removeMotivation(index: number) {
        setValue(
            'motivations',
            watch('motivations').filter((_, i) => i !== index),
        );
    }

    useEffect(
        function initObjectivesWithSameNumberOfRowsAsResearchContents() {
            if (watch('objectives')) {
                return;
            }
            if (researchContents.length > 0) {
                setValue(
                    'objectives',
                    researchContents.map(({ value }) => ({
                        keyword: value,
                        objective: '',
                    })),
                );
            }
        },
        [researchContents],
    );

    useEffect(
        function initMotivationDraft() {
            if (watch('motivations').length === 0) {
                setIsMotivationDraftOpen(true);
            }
        },
        [watch('motivations')],
    );

    useEffect(
        function clearMotivationValidationErrorMessage() {
            if (
                watch('motivations').length === 1 &&
                shouldShowValidationErrorMessage
            ) {
                setShouldShowValidationErrorMessage(false);
            }
        },
        [watch('motivations')],
    );

    useEffect(() => {
        if (watch('motivations').length > 0) {
            return;
        }

        if (watch('motivationDraft.2')) {
            clearErrors('motivationDraft.2');
        } else if (formState.isSubmitted) {
            setError('motivationDraft.2', {
                type: 'manual',
            });
            setFocus('motivationDraft.1');
        }
    }, [
        watch('motivations'),
        watch('motivationDraft.2'),
        formState.isSubmitted,
    ]);

    function storeForm() {
        dispatch(pro12Slice.actions.setResearchIntroductionForm(watch()));
    }

    useBeforeTempSave(storeForm);

    const onFormValid: SubmitHandler<ResearchIntroductionForm> = (_, e) => {
        e?.preventDefault();

        const isValid = watch('motivations', []).length > 0;

        if (isValid) {
            storeForm();
            setShouldShowValidationErrorMessage(false);
            setGoToNextStep(true);
        } else {
            setShouldShowValidationErrorMessage(true);
        }
    };

    const onFormError: SubmitErrorHandler<ResearchIntroductionForm> = (
        _,
        e,
    ) => {
        e?.preventDefault();
        setShouldShowValidationErrorMessage(true);

        if (watch('motivations', []).length === 0) {
            setTimeout(() => scrollToSection('#A'), 100);
        }
    };

    function shouldShowMotivationValidationErrorMessage() {
        return (
            shouldShowValidationErrorMessage &&
            watch('motivations')?.length === 0
        );
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit(onFormValid, onFormError)}>
            {/* A. 탐구 동기 및 탐구 목적 */}
            <SectionWrapper id={'A'}>
                <SectionTitle>A. 탐구 동기 및 탐구 목적</SectionTitle>
                <span className={'fs-6'}>
                    동기 문장은 반드시 하나일 필요는 없어. 여러개의 동기 문장을
                    생성하고 연결지어 탐구 동기를 생성해 보자.
                    <Tooltip
                        triggerNode={
                            <span className="badge bg-danger rounded-3 fs-6 ms-2 py-2 px-2">
                                예시
                            </span>
                        }
                    >
                        <>
                            <span>(예)</span>
                            <ol className="ps-4 mb-0">
                                <li>
                                    경제 관련 뉴스 기사를 찾아 읽다가, 한국지리
                                    시간에 배운 개념인 ‘젠트리 피케이션’이
                                    등장해서 유심히 읽어보았다.
                                </li>
                                <li>
                                    친구들과 자주 놀러가는 강남이나 홍대 주변도
                                    젠트리피케이션으로 요새 가게가 많이 빠지고
                                    있다던데, 사람들의 경제 생활에
                                    젠트리피케이션이 어떤 영향을 미치는지에
                                    대하여 구체적으로 알아보고 싶어졌다.
                                </li>
                            </ol>
                        </>
                    </Tooltip>
                    <a
                        className="d-inline-block text-decoration-none text-danger border-danger border rounded-5 ms-3 px-3 py-1"
                        href="https://jobshopper.notion.site/78081c1346df48579bf0d4bf0205b832"
                        target="_blank"
                        rel="noreferrer"
                    >
                        탐구 동기 작성 Tip!
                    </a>
                </span>
                {/* A-1. Key 문장 작성 */}
                <SubSection title={'A-1. Key 문장 작성'}>
                    {shouldShowMotivationValidationErrorMessage() && (
                        <FormErrorMessage
                            message={
                                '관심 Point 작성하고 작성 완료를 클릭해주세요.'
                            }
                        />
                    )}
                    {watch('motivations').map((motivation, index) => (
                        <SubSection key={index}>
                            <BorderBox variant={'danger mb-1 w-100 mx-0 my-0'}>
                                <div className={'row align-items-center'}>
                                    <div
                                        className={
                                            'col-11 d-flex align-items-center'
                                        }
                                    >
                                        {motivation}
                                    </div>
                                    <div className={'col-1'}>
                                        <button
                                            type={'button'}
                                            className={
                                                'btn btn-outlined text-end'
                                            }
                                            onClick={() =>
                                                removeMotivation(index)
                                            }
                                        >
                                            <BackspaceOutlined />
                                        </button>
                                    </div>
                                </div>
                            </BorderBox>
                        </SubSection>
                    ))}
                    {isMotivationDraftOpen ? (
                        <>
                            {/*Step1. 계기 발생*/}
                            <BorderBox variant={'danger'}>
                                <h4 className="fs-6 fw-bold mb-3">
                                    [Step1] 계기 발생
                                </h4>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value="교과 학습으로부터"
                                        {...register('motivationDraft.0')}
                                    />
                                    <label className="form-check-label d-flex align-items-center">
                                        <InfoTooltip
                                            label={'교과 학습으로부터'}
                                        >
                                            <p className={'mb-0'}>
                                                교과서, 유인물 등 수업 시간에
                                                학습한 내용으로부터 주제를
                                                이끌어내는 유형이야.
                                                <br />
                                                교과와 가장 직접적으로 연결되지.
                                                <br />
                                                가장 기본적이고 보편적인 동기에
                                                해당해!
                                            </p>
                                        </InfoTooltip>
                                    </label>
                                    {motivationDraftWatch[0] ===
                                        '교과 학습으로부터' && (
                                        <>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value={
                                                        defaultMotivationPart1Option
                                                    }
                                                    {...register(
                                                        'motivationDraft.1',
                                                    )}
                                                />
                                                <label className="form-check-label">
                                                    {
                                                        defaultMotivationPart1Option
                                                    }
                                                </label>
                                            </div>
                                            <div className="form-check my-1">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    {...motivationDraftPart1EtcRadio.registerRadio()}
                                                />
                                                <label className="form-check-label w-100">
                                                    <input
                                                        className="form-control form-control-sm w-50"
                                                        type="text"
                                                        placeholder="직접 입력하기"
                                                        {...motivationDraftPart1EtcRadio.registerInput()}
                                                    />
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value="진로 탐색으로부터"
                                        {...register('motivationDraft.0')}
                                    />
                                    <label className="form-check-label d-flex align-items-center">
                                        <InfoTooltip
                                            label={'진로 탐색으로부터'}
                                        >
                                            <p className={'mb-0'}>
                                                진로 탐색 과정에서 얻은 지식을
                                                탐구의 주제로 발전시키는
                                                유형이야.
                                                <br />
                                                진로 관련 지식을 교과서와 연결할
                                                수 있어야 하지.
                                            </p>
                                        </InfoTooltip>
                                    </label>
                                    {motivationDraftWatch[0] ===
                                        '진로 탐색으로부터' && (
                                        <div className="form-check my-1">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                checked
                                                readOnly
                                            />
                                            <label className="form-check-label w-100">
                                                <input
                                                    className="form-control form-control-sm w-50"
                                                    type="text"
                                                    placeholder="무엇(진로 관련)에 관해 / 탐색하다가, 알아보다가, 읽어보다가, ..."
                                                    {...register(
                                                        'motivationDraft.1',
                                                    )}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value="타과목 학습으로부터"
                                        {...register('motivationDraft.0')}
                                    />
                                    <label className="form-check-label d-flex align-items-center">
                                        <InfoTooltip
                                            label={'타과목 학습으로부터'}
                                        >
                                            <p className={'mb-0'}>
                                                다른 과목에서 배운 내용, 또는
                                                다른 과목에서 탐구한 주제가 다시
                                                탐구의 동기가 될 수도 있어.
                                                <br />
                                                비슷한 또는 같은 현상을 다양한
                                                관점과 방법으로 탐구하는 거지!
                                            </p>
                                        </InfoTooltip>
                                    </label>
                                    {motivationDraftWatch[0] ===
                                        '타과목 학습으로부터' && (
                                        <div className="form-check my-1">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                checked
                                                readOnly
                                            />
                                            <label className="form-check-label w-100">
                                                <input
                                                    className="form-control form-control-sm w-50"
                                                    type="text"
                                                    placeholder="어떤 과목의 어떤 단원에서 어떤 부분에 관해 학습하는 과정에서"
                                                    {...register(
                                                        'motivationDraft.1',
                                                    )}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value="일상 생활로부터"
                                        {...register('motivationDraft.0')}
                                    />
                                    <label className="form-check-label d-flex align-items-center">
                                        <InfoTooltip label={'일상 생활로부터'}>
                                            <p className={'mb-0'}>
                                                고대 그리스의 수학자
                                                아르키메데스는 목욕탕에서 자신의
                                                몸만큼 물이 넘치는 것을 보고
                                                밀도의 개념을 발견했다지?
                                                <br />
                                                주변의 사소한 것들로부터 ‘왜?’를
                                                생각하는 것이야말로 진정한
                                                탐구자의 자세라 할 수 있지!
                                            </p>
                                        </InfoTooltip>
                                    </label>
                                    {motivationDraftWatch[0] ===
                                        '일상 생활로부터' && (
                                        <div className="form-check my-1">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                checked
                                                readOnly
                                            />

                                            <label className="form-check-label w-100">
                                                <input
                                                    className="form-control form-control-sm w-50"
                                                    type="text"
                                                    placeholder="무엇을 하다가/읽다가/보다가, 어디가 가다가, ..."
                                                    {...register(
                                                        'motivationDraft.1',
                                                    )}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        {...motivationDraftPart0EtcRadio.registerRadio()}
                                    />
                                    <label className="form-check-label pt-1">
                                        기타
                                    </label>
                                    {motivationDraftPart0EtcRadio.isChecked && (
                                        <div className="form-check mt-1">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                checked
                                                readOnly
                                            />
                                            <label className="form-check-label w-100">
                                                <input
                                                    className="form-control form-control-sm w-50"
                                                    type="text"
                                                    placeholder="직접 입력하기"
                                                    {...motivationDraftPart1EtcRadio.registerInput()}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </BorderBox>
                            {/*Step2. 관심 Point 작성하기*/}
                            {motivationDraftWatch[1] ? (
                                <BorderBox
                                    variant={'danger'}
                                    className={'mt-3'}
                                >
                                    <h4 className="fs-6 fw-bold mb-3">
                                        [Step2] 관심 Point 작성하기
                                    </h4>
                                    <p className="my-2 text-primary">
                                        잘 생각이 나지 않는다면? 너희의 생각을
                                        돕기 위해 생각 key를 마련했어.
                                        <br />
                                        아래 예시를 참고해서 편안한 마음으로,
                                        자유롭게 입력해 보자. 😊
                                    </p>
                                    <p className="text-danger">
                                        ※ 내용을 입력하면 자동으로 문장에
                                        반영됩니다. (하나의 항목에서만 작성이
                                        가능합니다.)
                                    </p>
                                    <InterestingPoint
                                        setMotivationPart2={(value: string) =>
                                            setValue('motivationDraft.2', value)
                                        }
                                    />
                                    <ErrorMessage
                                        errors={formState.errors}
                                        name="motivationDraft.2"
                                        render={() => (
                                            <p className={'text-danger mb-0'}>
                                                관심 Point를 입력해주세요.
                                            </p>
                                        )}
                                    />
                                </BorderBox>
                            ) : (
                                <BorderBox
                                    variant={'danger'}
                                    className={'mt-3'}
                                >
                                    {' '}
                                    <h4 className="fs-6 fw-bold mb-3">
                                        [Step2] 관심 Point 작성하기
                                    </h4>
                                </BorderBox>
                            )}

                            {/*Step3. 문장 완성하기*/}
                            {motivationDraftWatch[2] ? (
                                <>
                                    <BorderBox
                                        variant={'danger'}
                                        className={'mt-3'}
                                    >
                                        <h4 className="fs-6 fw-bold mb-3">
                                            [Step3] 문장 완성하기
                                        </h4>
                                        <p className="my-2 text-primary">
                                            작성한 관심 Point와 연결해서 문장을
                                            완성해 보자. 😊
                                        </p>
                                        <div className={'row lh-base'}>
                                            <div
                                                className={
                                                    'col d-flex align-items-center px-3'
                                                }
                                            >
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    readOnly
                                                    value={
                                                        motivationDraftWatch[2]
                                                    }
                                                />
                                            </div>
                                            <div className={'col'}>
                                                <StringCheckOptions
                                                    options={
                                                        motivationPart3Options
                                                    }
                                                    fieldName={
                                                        'motivationDraft.3'
                                                    }
                                                    register={register}
                                                    inputProps={{
                                                        type: 'radio',
                                                        className:
                                                            'form-check-input',
                                                    }}
                                                />
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value=""
                                                        {...motivationDraftPart3EtcRadio.registerRadio()}
                                                    />
                                                    <label className="form-check-label container p-0">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="직접 입력하기"
                                                            {...motivationDraftPart3EtcRadio.registerInput()}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </BorderBox>
                                    <div
                                        className={
                                            'row justify-content-center mt-2'
                                        }
                                    >
                                        <div className={'col-auto text-center'}>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary rounded-4"
                                                disabled={
                                                    !isMotivationDraftComplete()
                                                }
                                                onClick={() =>
                                                    completeMotivationDraft()
                                                }
                                            >
                                                선택 및 작성 완료
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col text-center mt-1">
                                        <span className="text-danger small">
                                            버튼을 클릭해야 작성이 완료됩니다.
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <BorderBox
                                    variant={'danger'}
                                    className={'mt-3'}
                                >
                                    <h4 className="fs-6 fw-bold mb-3">
                                        [Step3] 문장 완성하기
                                    </h4>
                                </BorderBox>
                            )}
                        </>
                    ) : (
                        <div className={'row justify-content-center mt-2'}>
                            <div className={'col-auto'}>
                                <button
                                    type="button"
                                    className="btn btn-outline-danger rounded-4"
                                    onClick={() => resetMotivationDraft()}
                                >
                                    문장 추가하기
                                </button>
                            </div>
                        </div>
                    )}
                </SubSection>
                {/* A-2. 탐구 선언 */}
                <SubSection title={'A-2. 탐구 선언'}>
                    <BorderBox variant={'danger'}>
                        <p className={'mb-3'}>
                            이에,{' '}
                            <b className={'fw-semibold text-danger'}>
                                {presentationTitle}
                            </b>{' '}
                            을(를) 주제로 선정하여
                        </p>
                        {applicationOptions.map((value, index) => (
                            <div className="form-check" key={index}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    {...register('application')}
                                    value={value}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={value}
                                >
                                    {value}
                                </label>
                            </div>
                        ))}
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                {...applicationEtcRadio.registerRadio()}
                            />
                            <label className="form-check-label">
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    placeholder="기타 활동 (직접 입력)"
                                    {...applicationEtcRadio.registerInput()}
                                />
                            </label>
                        </div>
                        <p className={'mt-3'}>을(를) 실시하고자 한다.</p>
                    </BorderBox>
                </SubSection>
                {applicationWatch && (
                    <SubSection>
                        <BorderBox variant={'danger'}>
                            <div className={'row align-items-center'}>
                                <div className="col-11">
                                    <span>
                                        {' '}
                                        이에,{' '}
                                        <b className={'fw-semibold'}>
                                            {presentationTitle}
                                        </b>
                                        을(를) 주제로 선정하여{' '}
                                        <b className={'fw-semibold'}>
                                            {applicationWatch}
                                        </b>
                                        을(를) 실시하고자 한다.
                                    </span>
                                </div>
                                <div className="col-1">
                                    <button
                                        type={'button'}
                                        className={'btn btn-outlined text-end'}
                                        onClick={() =>
                                            resetField('application')
                                        }
                                    >
                                        <BackspaceOutlined />
                                    </button>
                                </div>
                            </div>
                        </BorderBox>
                    </SubSection>
                )}
                <SubSection title={'A-3. 탐구 동기 관련 자료 (선택)'}>
                    <ReferenceGroup
                        initialValue={getValues('references')}
                        onChange={(refs) => setValue('references', refs)}
                        renderReferenceDraft={({ onComplete }) => (
                            <div className={'row'}>
                                <BorderBox variant={'danger'}>
                                    <ReferenceDraft onComplete={onComplete} />
                                </BorderBox>
                            </div>
                        )}
                        renderReferenceCard={(
                            { reference, removeReference },
                            index,
                        ) => (
                            <div className={'row mb-3 mx-0 px-0'} key={index}>
                                <BorderBox variant={'danger'}>
                                    <ReferenceCard
                                        reference={reference}
                                        removeReference={removeReference}
                                    />
                                </BorderBox>
                            </div>
                        )}
                        renderNewReferenceButton={({ onClick }) => (
                            <div className={'row justify-content-center mt-n1'}>
                                <div className={'col-auto'}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger rounded-4"
                                        onClick={onClick}
                                    >
                                        참고 자료 추가하기
                                    </button>
                                </div>
                            </div>
                        )}
                    />
                </SubSection>
            </SectionWrapper>

            {/* B. 탐구 문제 */}
            <SectionWrapper id={'B'}>
                <SectionTitle>
                    <Tooltip
                        triggerNode={
                            <div>
                                <span>B. 탐구 문제</span>
                                <span className="text-danger border border-danger rounded-5 fs-6 ms-2 py-1 px-2">
                                    ?
                                </span>
                            </div>
                        }
                    >
                        <div>
                            '이 탐구가 앞으로 다룰 문제들이 무엇인가?'에 관한
                            답변이야. 앞서 설정한 목차를 참고하여 탐구 문제를
                            진술해 보자.
                            <br />
                            탐구 문제를<strong> 문장 형태로 서술</strong>
                            해봄으로써, 앞으로 진행할 과제탐구의 목적과 방향성을
                            명확히 할 수 있어.
                            <br />
                            (~에 대하여 정의한다, 고찰한다, 밝힌다, 찾아본다 등)
                        </div>
                    </Tooltip>
                </SectionTitle>

                <p className="lh-sm">
                    (예)
                    <br />
                    1. 우리나라 젠트리피케이션의 특징→ 구체적인 사례를 바탕으로
                    우리나라 젠트리피케이션의 특징을 밝힌다.
                    <br />
                    2. 임대료 규제 정책 고찰→ 임대료 규제 정책의 효과와 유의점을
                    고찰한다.
                </p>
                <div className={'row mt-3'}>
                    <BorderBox variant={'danger'}>
                        <p className="mb-2">
                            본 탐구를 통해 해결할 탐구 문제는 다음과 같다.
                        </p>
                        <ObjectiveInputGroup
                            researchContents={researchContents}
                            register={register}
                            formState={formState}
                        />
                    </BorderBox>
                </div>
            </SectionWrapper>

            {/*C. 탐구 방법*/}
            <SectionWrapper id={'C'}>
                <SectionTitle>C. 탐구 방법</SectionTitle>
                <p>
                    앞에서 설정한 탐구 문제를 해결하기에 가장 적합한 탐구 방법을
                    선택해 보자. 필요에 따라 복합적으로 선택할 수 있어.
                </p>
                <SubSection>
                    <BorderBox variant={'danger'}>
                        <div className={'row align-items-center mb-3'}>
                            <div className={'col-auto'}>
                                <h5 className={'fs-6 fw-semibold mb-0'}>
                                    (1) 연구 유형 (선택)
                                </h5>
                            </div>
                            <div className={'col-auto ps-0'}>
                                {skipTypes.renderTogglingButton()}
                                <small className={'text-muted ms-2'}>
                                    선택 사항을 건너뛰고 싶다면? Click!
                                </small>
                            </div>
                        </div>
                        {skipTypes.render()(
                            <>
                                <StringCheckOptions
                                    options={typeOptions}
                                    fieldName={'types'}
                                    register={register}
                                    inputProps={{
                                        type: 'checkbox',
                                        className: 'form-check-input',
                                    }}
                                    registerOptions={{ required: false }}
                                    getLabel={(option) => (
                                        <InfoTooltip label={option}>
                                            {researchTypeTooltips[option]}
                                        </InfoTooltip>
                                    )}
                                />
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        {...typesEtcCheckbox.registerCheckbox()}
                                    />
                                    <label className="form-check-label w-75">
                                        <input
                                            className="form-control w-100"
                                            type="text"
                                            placeholder="기타 (직접 입력)"
                                            {...typesEtcCheckbox.registerInput()}
                                        />
                                    </label>
                                </div>
                            </>,
                        )}
                    </BorderBox>
                </SubSection>
                <SubSection>
                    <BorderBox variant={'danger'}>
                        <div className={'row align-items-center mb-3'}>
                            <div className={'col-auto'}>
                                <h5 className={'fs-6 fw-semibold mb-0'}>
                                    (2) 자료 수집 방법 (선택)
                                </h5>
                            </div>
                            <div className={'col-auto ps-0'}>
                                {skipMethods.renderTogglingButton()}
                                <small className={'text-muted ms-2'}>
                                    선택 사항을 건너뛰고 싶다면? Click!
                                </small>
                            </div>
                        </div>
                        {skipMethods.render()(
                            <>
                                <StringCheckOptions
                                    options={methodOptions}
                                    fieldName={'methods'}
                                    register={register}
                                    registerOptions={{ required: false }}
                                    inputProps={{
                                        type: 'checkbox',
                                        className: 'form-check-input',
                                    }}
                                    getLabel={(option) => (
                                        <InfoTooltip label={option}>
                                            {researchMethodTooltips[option]}
                                        </InfoTooltip>
                                    )}
                                />
                                <div className="form-check ">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        {...methodsEtcCheckbox.registerCheckbox()}
                                    />
                                    <label className="form-check-label w-75">
                                        <input
                                            className="form-control w-100"
                                            type="text"
                                            placeholder="기타 (직접 입력)"
                                            {...methodsEtcCheckbox.registerInput()}
                                        />
                                    </label>
                                </div>
                            </>,
                        )}
                    </BorderBox>
                </SubSection>
            </SectionWrapper>
            <Navigator
                onNextStepClick={() => {
                    formRef.current?.requestSubmit();
                }}
                onPreviousStepClick={(prev) => {
                    storeForm();
                    prev();
                }}
                goToNextStep={goToNextStep}
            />
        </form>
    );
}
