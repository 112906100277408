export class Participant {
    constructor(public id: number) {}
}

export class CategoryParticipant extends Participant {
    constructor(
        public id: number,
        public category: string,
        public questionnaires: string[] = [],
        public score: number = 0,
    ) {
        super(id);
    }

    get [Symbol.toStringTag]() {
        return `${this.id}-${this.category}`;
    }
}

export class QuestionnaireParticipant extends Participant {
    constructor(
        public id: number,
        public category: CategoryParticipant,
        public questionnaire: string,
        public score: number = 0,
    ) {
        super(id);
    }

    get [Symbol.toStringTag]() {
        return `${this.id}-${this.category.category}-${this.score}`;
    }
}
