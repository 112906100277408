import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@store';
import {
    BorderBox,
    ReferenceCard,
    SectionTitle,
    SectionWrapper,
} from '@pages/pro12/components';
import { Column, ContentWithLink } from '.';
import { getIndexInKorean, replaceStep } from '@pages/pro12/helpers';

export default function SectionC() {
    const location = useLocation();
    const { tbrForms, rcrForms, tpomSets } = useAppSelector(({ pro12 }) => {
        return {
            tbrForms: pro12.researchTheoreticalBackgroundAndReferencesForms ?? [],
            rcrForms: pro12.researchContentAndReferencesForms ?? [],
            tpomSets: pro12.researchToughPointOvercomingMethodSets ?? [],
        };
    });

    function getLink(section: string) {
        return replaceStep(location, 2, `#${section}`);
    }

    return (
        <SectionWrapper>
            <SectionTitle>C. 본론 검토</SectionTitle>
            <Column
                title={() => (
                    <h5 className="fw-bold text-center mb-0 fs-6">
                        이론적 배경 및<br />
                        용어 정리
                    </h5>
                )}
                variant={'danger'}
            >
                {tbrForms.map((form, index) => (
                    <div
                        className={`row border border-danger border-opacity-25 rounded-2 p-2 ${
                            index > 0 ? 'mt-2' : ''
                        }`}
                        key={index}
                    >
                        <ContentWithLink href={getLink('A')}>
                            <span>이론적 배경 및 용어: </span>
                            <strong className="d-inline-block fw-bold">
                                {form.theoreticalBackground}
                            </strong>
                        </ContentWithLink>

                        <div className="row mt-1">
                            <BorderBox variant={'danger'} className={'mt-2'}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: form.theoreticalBackgroundDescription,
                                    }}
                                ></div>
                            </BorderBox>
                        </div>
                        {form.references.map((reference, index) => (
                            <div className="row mt-2" key={index}>
                                <BorderBox variant={'danger'}>
                                    <ReferenceCard reference={reference} />
                                </BorderBox>
                            </div>
                        ))}
                    </div>
                ))}
            </Column>

            <Column title={'탐구 내용'} variant={'danger'}>
                {rcrForms.map((form, index) => (
                    <div
                        className={`row border border-danger border-opacity-25 rounded-2 p-2 ${
                            index > 0 ? 'mt-2' : ''
                        }`}
                        key={index}
                    >
                        <ContentWithLink href={getLink('B')}>
                            <span>({index + 1}) </span>
                            <strong className="d-inline-block fw-bold">
                                {form.researchContent}
                            </strong>
                        </ContentWithLink>

                        <div className="row mt-1">
                            <BorderBox variant={'danger'} className={'mt-2'}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: form.researchContentDescription,
                                    }}
                                ></div>
                            </BorderBox>
                        </div>
                        {form.references.map((reference, index) => (
                            <div className="row mt-2" key={index}>
                                <BorderBox variant={'danger'}>
                                    <ReferenceCard reference={reference} />
                                </BorderBox>
                            </div>
                        ))}
                    </div>
                ))}
            </Column>
            <Column title={'어려움과 극복'} variant={'danger'}>
                <ContentWithLink
                    className={
                        'border border-danger border-opacity-25 rounded-2 p-2'
                    }
                    href={getLink('C')}
                >
                    탐구과정에서 어려웠던 지점은 다음과 같다.
                    {tpomSets.map((pair, index) => (
                        <span key={index}>
                            ({getIndexInKorean(index)},){' '}
                            <span>{pair.toughPoint}</span> 이를 해결하기 위해{' '}
                            <span>{pair.overcomingMethod} </span>
                        </span>
                    ))}
                </ContentWithLink>
            </Column>
        </SectionWrapper>
    );
}
