import React from 'react';

export default function SubSection({
    title,
    children,
}: {
    title?: string;
    children?: React.ReactNode;
}) {
    return (
        <div className={'row mt-3 mx-0 px-0'}>
            {title && <h4 className="fs-6 fw-bold mb-3">{title}</h4>}
            {children}
        </div>
    );
}
